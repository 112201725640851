(function () {
    'use strict';

    var biologyResultsBlock = {
        bindings: {
            patientId: '=',
            expand: '='
        },
        controller: BiologyResultsBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/biology-result/biology-results-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('biologyResultsBlock', biologyResultsBlock);

    BiologyResultsBlockController.$inject = ['$rootScope', '$scope', '$state', '$log', '$uibModal', 'Principal', 'BiologyResult'];

    function BiologyResultsBlockController($rootScope, $scope, $state, $log, $uibModal, Principal, BiologyResult) {

        const vm = this;

        vm.open = open;
        vm.importBiologyResult = importBiologyResult;
        vm.toggleBiologyResultHistory = toggleBiologyResultHistory;

        vm.showBiologyResultHistory = false;

        var unsubscribe = $rootScope.$on('continuumplatformApp:biologyResultUpdate', load);
        $scope.$on('$destroy', function (event) {
            unsubscribe(event);
        });

        vm.$onInit = function () {
            load();
        };

        function open(biologyResult) {
            $state.go('visit-detail', {id: biologyResult.visitId});
        }

        function load() {
            BiologyResult.query({
                "patientId.equals": vm.patientId,
                "sort": ["date,desc", "id,desc"],
                size: 1000
            }, function (value) {
                vm.biologyResults = value;
            });
        }

        function importBiologyResult() {
            $uibModal.open({
                templateUrl: 'app/activity/biology-result/biology-result-import.html',
                controller: 'BiologyResultImportController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    patientId: () => vm.patientId
                }
            }).result.then(() => {
                // do nothing
            }, () => {
                // do nothing
            });
        }

        function toggleBiologyResultHistory() {
            vm.showBiologyResultHistory = !vm.showBiologyResultHistory;
        }

    }

})();
