(function () {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('DownloadFile', DownloadFile);

    DownloadFile.$inject = ['FileSaver'];

    function DownloadFile(FileSaver) {

        function downloadFile(response, disableAutoBom) {
            var filename = extractFileName(response.headers('Content-Disposition'));
            FileSaver.saveAs(response.data, filename, disableAutoBom);
        }

        function extractFileName(disposition) {
            var filename = "";
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }

            return filename;
        }

        return {
            downloadFile: downloadFile
        };
    }
})();
