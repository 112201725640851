(function () {
    'use strict';

    var mediaBlock = {
        bindings: {
            patientId: '=',
            open: '=?',
            notify: '=?',
            expand: '='
        },
        controller: MediaBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/media/media-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('mediaBlock', mediaBlock);

    MediaBlockController.$inject = ['$rootScope', '$scope', '$state', '$log', '$uibModal', '$window', 'Principal', 'Media'];

    function MediaBlockController($rootScope, $scope, $state, $log, $uibModal, $window, Principal, Media) {

        var vm = this;

        var unsubscribe = $rootScope.$on('continuumplatformApp:mediaUpdate', load);
        $scope.$on('$destroy', function (event) {
            unsubscribe(event);
        });

        vm.$onInit = function () {
            load();

            vm.open = vm.open || openDocument;
        };

        function load() {
            Media.query({
                "deletedDate.specified": false,
                "biologyResultId.specified": false,
                "prescriptionId.specified": false,
                "pharmAnalysisId.specified": false,
                "sideEffectReportId.specified": false,
                "draft.equals": false,
                "patientId.equals": vm.patientId,
                "sort": ["id,desc"],
                "size": 1000
            }, function (value) {
                vm.media = value;
            });
        }

        vm.importDocument = function () {
            $uibModal.open({
                templateUrl: 'app/activity/media/media-upload.html',
                controller: 'MediaUploadController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            date: moment().startOf('minute').toDate(),
                            patientId: vm.patientId,
                            notify: vm.notify
                        };
                    }
                }
            }).result.then(function () {
            }, function () {
            });
        };

        function openDocument(media) {
            $state.go('media-detail', {id: media.id});
        }

    }

})();
