(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('PatientTreatmentPhase', PatientTreatmentPhase);

    PatientTreatmentPhase.$inject = ['$resource', 'DateUtils'];

    function PatientTreatmentPhase($resource, DateUtils) {
        var resourceUrl = 'api/patient-treatment-phases/:id';

        var service = $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        var decoded = angular.fromJson(data);
                        if (angular.isArray(decoded)) {
                            var transformed = [];
                            angular.forEach(decoded, function (v, k) {
                                transformed.push(transformResponse(v));
                            });
                            return transformed;
                        } else {
                            return decoded;
                        }
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return transformResponse(data);
                }
            }
        });

        service.transformResponse = transformResponse;

        function transformResponse(data) {
            if (data) {
                data = angular.fromJson(data);
                data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                data.stopDate = DateUtils.convertLocalDateFromServer(data.stopDate);
                data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);

                if (data.hours)
                    data.planDetailLength = Object.keys(data.hours).length;
            }
            return data;
        }

        return service;
    }
})();
