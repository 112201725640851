(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MeasureChartController', MeasureChartController);

    MeasureChartController.$inject = ['$uibModalInstance', 'patientId', 'Measure', 'MeasureType', 'type'];

    function MeasureChartController($uibModalInstance, patientId, Measure, MeasureType, type) {
        const vm = this;

        const GRAPH_PERIOD = 3; // plage de temps pour l'affichage du graph en mois
        let INIT_DATE = moment().endOf('day');

        const MIN_MAX = {
            "SATO2": {suggestedMin: 90, suggestedMax: 100},
            "BLOOD_PRESSURE": {suggestedMin: 50, suggestedMax: 180},
            "BLOOD_PRESSURE_SYSTOLIC": {suggestedMin: 100, suggestedMax: 180},
            "BLOOD_PRESSURE_DIASTOLIC": {suggestedMin: 60, suggestedMax: 110},
            "PAIN": {suggestedMin: 0, suggestedMax: 10},
            "TEMPERATURE": {suggestedMin: 36, suggestedMax: 39},
            "HEART_RATE": {suggestedMin: 60, suggestedMax: 100},
            "RESPIRATORY_RATE": {suggestedMin: 10, suggestedMax: 25},
            "WEIGHT": {suggestedMin: 60, suggestedMax: 100},
            "SIZE": {suggestedMin: 100, suggestedMax: 200},
            "BMI": {suggestedMin: 15, suggestedMax: 50}
        };

        vm.patientId = patientId;

        vm.name = MeasureType.getRules(type).name;
        vm.type = type;

        vm.close = close;
        vm.previousChartData = previousChartData;
        vm.nextChartData = nextChartData;

        vm.$onInit = function () {

            // détermination du type de données à récupérer
            if (vm.type === 'BLOOD_PRESSURE') {
                vm.types = ['BLOOD_PRESSURE_SYSTOLIC', 'BLOOD_PRESSURE_DIASTOLIC'];
            } else {
                vm.types = [vm.type];
            }

            loadData();
        };

        /**
         * Ferme la fenêtre modale.
         */
        function close() {
            $uibModalInstance.dismiss('cancel');
        }

        /**
         * Effectue le chargement des données nécessaires à l'affichage du graphe et du tableau de données.
         */
        function loadData() {
            // chargement et flitrage des mesures
            loadMeasure(vm.patientId, vm.types).then((measures) => {
                vm.measures = measures;

                if(measures.length > 0) {
                    INIT_DATE = moment(measures[measures.length - 1].date).endOf('day');

                    // calcul de la période initiale
                    vm.graphStartDate = moment(INIT_DATE).subtract(GRAPH_PERIOD, 'months');
                    vm.graphEndDate = moment(INIT_DATE);
                }

                return filteredMeasures(vm.measures, vm.graphStartDate, vm.graphEndDate);
            }).then((filteredMeasures) => {
                vm.filteredMeasures = filteredMeasures;

                if(filteredMeasures.length === 0) {
                    return;
                }

                // construction du jeu de données pour l'affichage du graph
                vm.graphData = {};

                if (vm.type === 'BLOOD_PRESSURE') {
                    vm.graphData.x = [];
                    vm.graphData.y = [[],[]];

                    filteredMeasures.forEach((m) => {
                        if (m.type === 'BLOOD_PRESSURE_SYSTOLIC') {
                            vm.graphData.x.push(moment(m.date).toDate());
                            vm.graphData.y[0].push(m.value);
                        } else if (m.type === 'BLOOD_PRESSURE_DIASTOLIC') {
                            vm.graphData.y[1].push(m.value);
                        }
                    });

                } else {
                    vm.graphData.x = filteredMeasures.map((m) => moment(m.date).toDate());
                    vm.graphData.y = [filteredMeasures.map((m) => m.value)];
                }

                // définition des options à appliquer au graph
                vm.options = defineOptions(vm.graphData, vm.graphStartDate, vm.graphEndDate);

                if (vm.type === "BLOOD_PRESSURE") {
                    vm.series = ['S', 'D']; // libellé pour chaque série de données
                    // option d'affichage pour les points correspondant aux valeurs
                    vm.datasetOverride = [
                        {pointRadius: 5, pointHoverRadius: 13, fill: false, showLine: false,},
                        {pointRadius: 5, pointHoverRadius: 13, fill: '-1', showLine: false,}
                    ];
                } else {
                    vm.series = null;
                    vm.datasetOverride = [{pointRadius: 5, pointHoverRadius: 13, fill: false, showLine: false,}];
                }

                // on détermine si des données plus anciennes ou plus récentes existent
                vm.isPreviousGraphDataPossible = moment(vm.measures[0].date).isBefore(moment(vm.graphStartDate));
                vm.isNextGraphDataPossible = moment(vm.measures[vm.measures.length -1].date).isAfter(moment(vm.graphEndDate));

            });
        }

        /**
         * Charge les mesures nécessaires
         * @param patientId identifiant du patient
         * @param types les types de données à charger
         * @returns {Promise<unknown>}
         */
        function loadMeasure(patientId, types) {
            return Measure.query({
                "patientId.equals": patientId,
                "type.in": types,
                "sort": ["date,asc"],
                size: 9999
            }).$promise
                .then((measures) => measures);
        }

        /**
         * Permet d'afficher la période précédente des données sur le graph.
         */
        function previousChartData() {
            vm.graphStartDate.subtract(GRAPH_PERIOD, 'months');
            vm.graphEndDate.subtract(GRAPH_PERIOD, 'months');
            generateGraphData(filteredMeasures);
        }

        /**
         * Permet d'afficher la période suivante des données sur le graph.
         */
        function nextChartData() {
            vm.graphStartDate.add(GRAPH_PERIOD, 'months');
            vm.graphEndDate.add(GRAPH_PERIOD, 'months');
            generateGraphData(filteredMeasures);
        }

        function generateGraphData() {
            filteredMeasures(vm.measures, vm.graphStartDate, vm.graphEndDate).then((filteredMeasures) => {
                vm.filteredMeasures = filteredMeasures;

                vm.graphData = {};

                if (vm.type === 'BLOOD_PRESSURE') {
                    vm.graphData.x = [];
                    vm.graphData.y = [[],[]];

                    filteredMeasures.forEach((m) => {
                        if (m.type === 'BLOOD_PRESSURE_SYSTOLIC') {
                            vm.graphData.x.push(moment(m.date).toDate());
                            vm.graphData.y[0].push(m.value);
                        } else if (m.type === 'BLOOD_PRESSURE_DIASTOLIC') {
                            vm.graphData.y[1].push(m.value);
                        }
                    });

                } else {
                    vm.graphData.x = filteredMeasures.map((m) => moment(m.date).toDate());
                    vm.graphData.y = [filteredMeasures.map((m) => m.value)];
                }

                vm.options = defineOptions(vm.graphData, vm.graphStartDate, vm.graphEndDate);

                vm.isPreviousGraphDataPossible = moment(vm.measures[0].date).isBefore(moment(vm.graphStartDate));
                vm.isNextGraphDataPossible = moment(vm.measures[vm.measures.length -1].date).isAfter(moment(vm.graphEndDate));
            });
        }

        /**
         * Filtre les mesures fournis en entrées en fonction de la date de début et de fin souhaité
         * @param measures les measures à filtrer
         * @param startDate la date de début de période
         * @param endDate la date de fin de période
         * @returns {Promise<unknown>}
         */
        function filteredMeasures(measures, startDate, endDate) {
            return new Promise((resolve, reject) => {
                resolve(measures.filter((m) => moment(m.date).isBetween(startDate, endDate)));
            });
        }

        /**
         * Définit les options pour l'affichage du graph
         * @param data
         * @param startDate
         * @param endDate
         * @returns {{responsive: boolean, scales: {yAxes: [{ticks: {suggestedMin: number, suggestedMax: number}, display: boolean, scaleLabel: {display: boolean}}], xAxes: {ticks: {min: *, major: {fontStyle: string, fontColor: string}, max: *}, display: boolean, scaleLabel: {labelString: string, display: boolean}, time: {unit: string, tooltipFormat: string}, type: string, distribution: string}[]}, maintainAspectRatio: boolean}}
         */
        function defineOptions(data, startDate, endDate) {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: defineAxesOptions(data, startDate, endDate),
                    yAxes: [{
                        display: true,
                        scaleLabel: {display: true},
                        ticks: MIN_MAX[vm.type] ? MIN_MAX[vm.type] : {},
                    }],
                },
            };
        }

        /**
         * Définit les options des axes pour l'affichage du graph
         * @param data
         * @param startDate
         * @param endDate
         * @returns {[{ticks: {min: *, major: {fontStyle: string, fontColor: string}, max: *}, display: boolean, scaleLabel: {labelString: string, display: boolean}, time: {unit: string, tooltipFormat: string}, type: string, distribution: string}]}
         */
        function defineAxesOptions(data, startDate, endDate) {
            return [{
                type: 'time',
                distribution: 'linear',
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Date'
                },
                ticks: {
                    major: {
                        fontStyle: 'bold',
                        fontColor: '#000000'
                    },
                    min: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
                    max: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
                },
                time: {
                    tooltipFormat: 'LLL',
                    unit: 'week',
                },
            }];

        }
    }

})();
