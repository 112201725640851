(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SideEffectReportIdentifiantBNPVController', SideEffectReportIdentifiantBNPVController);

    SideEffectReportIdentifiantBNPVController.$inject = ['$uibModalInstance', 'bnpvId'];

    function SideEffectReportIdentifiantBNPVController($uibModalInstance, bnpvId) {
        const vm = this;

        vm.bnpvId = bnpvId;

        vm.cancel = cancel;
        vm.confirm = confirm;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm() {
            $uibModalInstance.close(vm.bnpvId);
        }

    }
})();
