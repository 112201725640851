(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HealthFacilityDeleteController', HealthFacilityDeleteController);

    HealthFacilityDeleteController.$inject = ['$uibModalInstance', 'entity', 'HealthFacility'];

    function HealthFacilityDeleteController($uibModalInstance, entity, HealthFacility) {
        var vm = this;

        vm.healthFacility = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            HealthFacility.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
