(function () {
    'use strict';

    const hdjItem = {
        bindings: {
            visit: '='
        },
        controller: HdjItemController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/hdj/hdj-item.html'
    };

    angular
        .module('continuumplatformApp')
        .component('hdjItem', hdjItem);

    HdjItemController.$inject = [];

    function HdjItemController() {

        const vm = this;

        vm.$onInit = () => {
            // nothing to do
        };

    }

})();
