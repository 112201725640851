(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .filter('messageRecipientName', translateMessageRecipientName);

    translateMessageRecipientName.$inject = ['$filter', 'Principal'];

    function translateMessageRecipientName($filter, Principal) {

        var currentUser = {};
        Principal.identity().then(function (account) {
            currentUser = account;
        });

        return formatRecipientName;

        function formatRecipientName(recipient) {

            if (currentUser.id === recipient.userId) {
                return 'Moi';
            }

            if (!recipient.practitioner) {
                return 'Patient';
            }

            return $filter('translatePractitionerName')(recipient.practitioner, 'TITLE_FULLNAME_JOB');
        }

    }

})();
