(function () {
    'use strict';

    var billsBox = {
        bindings: {
            entity: '='
        },
        controller: BillsBoxController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/bill/bills-box.html'
    };

    angular
        .module('continuumplatformApp')
        .component('billsBox', billsBox);

    BillsBoxController.$inject = ['$rootScope', '$scope', '$uibModal', 'Bill'];

    function BillsBoxController($rootScope, $scope, $uibModal, Bill) {

        const vm = this;

        const unsubscribe = $rootScope.$on('continuumplatformApp:billUpdate', function () {
            loadBills();
        });
        $scope.$on('$destroy', function () {
            unsubscribe();
        });

        vm.$onInit = () => {
            loadBills();
        };

        vm.billVisits = () => {
            if (!vm.entity || !vm.entity.id) {
                return;
            }
            $uibModal.open({
                templateUrl: 'app/activity/bill/billing-entity.html',
                controller: 'BillingEntityController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: () => vm.entity
                }
            }).result.then(() => {
                // do nothing
            }, () => {
                // do nothing
            });
        };

        function loadBills() {
            Bill.query({
                "healthEntityId.equals": vm.entity.id,
                sort: "date,desc",
                size: 1000 // TODO pagination?
            }, function (bills) {
                vm.bills = bills;
            });
        }

    }

})();
