(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('auditP', auditP);

    function auditP() {

        return {
            restrict: 'E',
            scope: {
                audited: '='
            },
            templateUrl: 'app/components/audit-p.html'
        };


    }

})();
