(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('MedicationVariant', MedicationVariant);

    MedicationVariant.$inject = ['$resource'];

    function MedicationVariant($resource) {
        const resourceUrl = 'api/medication-variants/:id';

        function transformRequest(data) {
            const copy = angular.copy(data);
            // fix empty string to null
            copy.form = data.form === '' ? null : data.form;
            return angular.toJson(copy);
        }

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'search': {
                url: 'api/medication-variants/search',
                params: {
                    query: null
                },
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: transformRequest
            },
            'save': {
                method: 'POST',
                transformRequest: transformRequest
            }
        });
    }
})();
