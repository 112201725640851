(function () {
    'use strict';

    /** @constant {number} PAGE_SIZE Size of the page */
    const PAGE_SIZE = 10;

    const suspendedFollowUpBlock = {
        bindings: {
            filters: '=',
        },
        controller: SuspendedFollowUpBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/suspended-follow-up/suspended-follow-up-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('suspendedFollowUpBlock', suspendedFollowUpBlock);

    SuspendedFollowUpBlockController.$inject = ['$scope', 'DashboardService'];

    function SuspendedFollowUpBlockController($scope, DashboardService) {

        const vm = this;

        vm.$onInit = function () {

            vm.datasource = DashboardService.createUiScrollDatasource(DashboardService.loadSuspendedFollowUp, PAGE_SIZE, vm.filters);

            vm.unsubscribeWatchFilter = $scope.$watch('vm.filters', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    vm.datasource.reloadDatasource();
                }
            }, true);

        };

        /**
         * Call when component is destroyed.
         */
        vm.$onDestroy = function () {
            if (vm.unsubscribeWatchFilter) {
                vm.unsubscribeWatchFilter();
            }
        };

    }

})();
