(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientPharmaceuticsController', PatientPharmaceuticsController);

    PatientPharmaceuticsController.$inject = ['$state', '$translate', '$uibModal', '$window', 'entity', 'PatientTherapy', 'Visit', 'PharmAnalysis', 'Principal', 'DownloadFile'];

    function PatientPharmaceuticsController($state, $translate, $uibModal, $window, entity, PatientTherapy, Visit, PharmAnalysis, Principal, DownloadFile) {
        const vm = this;

        vm.plan = plan;
        vm.startNewPharmAnalysis = startNewPharmAnalysis;
        vm.updateTreatmentPlan = updateTreatmentPlan;
        vm.isStartable = Visit.isStartable;
        vm.isResumable = Visit.isResumable;
        vm.isAllowed = Visit.isAllowed;

        vm.patient = entity;
        vm.canStartNewPharmAnalysis = null;
        vm.canStartNewTreatmentPlan = null;
        vm.lastPatientTherapyId = null;

        vm.$onInit = () => {

            $translate('continuumplatformApp.patient.pharmaceutics.title', {initials: entity.initials}).then(title => {
                $window.document.title = title;
            });

            loadAll();
        };

        function loadAll() {
            Promise.all([Principal.identity(), loadVisits(), loadPharmAnalyses(), getLastPatientTherapyId()])
                .then(([
                    account,
                    visits,
                    pharmAnalyses,
                    lastPatientTherapyId
                ]) => {

                    vm.account = account;

                    const pharmAnalysisByVisitId = pharmAnalyses.reduce((acc, pharmAnalysis) => {

                        acc[pharmAnalysis.visitId] = pharmAnalysis;
                        return acc;
                    }, {});

                    vm.visits = visits
                        .map(visit => {
                            visit.uiActions = [];
                            const pharmAnalysis = pharmAnalysisByVisitId[visit.id];

                            // ajout d'un indicateur sur la visit que l'analyse pharmaceutique ne contient que le plan de prise médicamenteux
                            if (pharmAnalysis && pharmAnalysis.data) {
                                visit.onlyTreatmentPlan = pharmAnalysis.data.onlyTreatmentPlan;
                            }

                            // si la visite est finalisée et contient une analyse pharmaceutique
                            if (visit.status === 'DONE' && pharmAnalysis) {

                                // on ajout un lien vers l'analyse pharmaceutique
                                if (pharmAnalysis.data && !pharmAnalysis.data.onlyTreatmentPlan) {
                                    visit.uiActions.push({
                                        fn: () => $state.go('pharm-analysis-read', {id: pharmAnalysis.id}),
                                        icon: 'fa-solid fa-folder-open',
                                        label: "Analyse pharmaceutique"
                                    });
                                }

                                // on récupère le nombre de points d'attention provenant des intéractions médicamenteuses
                                if (pharmAnalysis.data) {
                                    visit.pharmAnalysisIssueCount = pharmAnalysis.data.analysisResultBimedocIssueCount;
                                }

                                // on ajout un lien de téléchargement pour le plan de prise si la visite ne contient qu'un plan de prise médicamenteux
                                if (pharmAnalysis.data && pharmAnalysis.data.hasMedicationPlanWithData) {
                                    visit.uiActions.push({
                                        fn: (action) => {
                                            if (action.loading) {
                                                return;
                                            }

                                            action.loading = true;
                                            generateTreatmentPlanPdf(visit.id).finally(() => {
                                                action.loading = false;
                                            });
                                        },
                                        icon: 'fa-solid fa-pills',
                                        label: "Télécharger le plan de prise",
                                        loading: false
                                    });
                                }


                            }
                            return visit;
                        });

                    vm.lastPatientTherapyId = lastPatientTherapyId;

                    if(account.authorities.includes('PHARM_ANALYSIS') && lastPatientTherapyId) {
                        const hasStartableOrResumablePharmaAnalysis = !!visits.filter(isPharmAnalysisStartableOrResumableByMeOrMyColleague).length;
                        vm.canStartNewPharmAnalysis = !hasStartableOrResumablePharmaAnalysis && vm.lastPatientTherapyId;
                        vm.canStartNewTreatmentPlan = !!visits.filter(isPhamAnalysisWithMedications).length;
                    } else {
                        vm.canStartNewPharmAnalysis = false;
                        vm.canStartNewTreatmentPlan = false;
                    }

                    vm.lastPharmAnalysis = pharmAnalyses.length ? pharmAnalyses[0] : null;
                });
        }

        function isPharmAnalysisStartableOrResumableByMeOrMyColleague(v) {
            if (!v.steps.includes('PHARMA_ITW_TREATMENT')) {
                return false;
            }
            if(vm.isStartable(v)) {
                return true;
            }
            if(vm.isResumable(v)) {
                return true;
            }
            return ['TODO', 'IN_PROGRESS'].includes(v.status)
                && v.assignee
                && v.assignee.healthFacilityId === vm.account.practitioner.healthFacilityId;
        }

        function isPhamAnalysisWithMedications(v) {
            return v.status === 'DONE' && (v.steps.includes('PHARMA_ITW_TREATMENT') || v.steps.includes('PHARMA_ITW_TREATMENT_PLAN'))
                && v.data && v.data.pharmAnalysisId;
        }

        function loadVisits() {
            return Visit.query({
                "patientId.equals": vm.patient.id,
                "type.in": ['PHARMA_CONSULT', 'PHARMA_ITW'],
                "sort": ['orderingDate', 'id'],
            }).$promise;
        }

        function loadPharmAnalyses() {
            return PharmAnalysis.query({
                "patientId.equals": vm.patient.id,
                "validated.equals": true,
                "sort": "lastModifiedDate,desc"
            }).$promise;
        }

        function getLastPatientTherapyId() {
            return PatientTherapy.query({
                "patientId.equals": vm.patient.id,
                "status.in": ["S11_VALIDATED", "S20_ACTIVE", "S30_PAUSED"],
                "sort": 'createdDate,desc',
                "size": 1
            }).$promise.then(patientTherapies => {
                if (!patientTherapies.length) {
                    return null;
                }
                return patientTherapies[0].id;
            });
        }

        function plan(visit, index) {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-plan-dialog.html',
                controller: 'VisitPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    visit: ['Visit', function (Visit) {
                        return Visit.get({id: visit.id}).$promise;
                    }],
                }
            }).result.then((updatedVisit) => {
                // on met à jour la visite dans la liste pour refléter les changements sur l'affichage
                vm.visits.splice(index, 1, updatedVisit);
            }, () => {
                // do nothing
            });
        }

        function startNewPharmAnalysis() {
            vm.isStartingVisit = true;
            createPharmAnalysis('Analyse pharmaceutique', ['IMPORT_PRESCRIPTIONS', 'PHARMA_ITW_TREATMENT'])
                .then(visit => $state.go('visit-wizard', {id: visit.id}))
                .catch(error => {
                    // nothing
                })
                .finally(() => {
                    vm.isStartingVisit = false;
                });
        }

        function updateTreatmentPlan() {
            vm.isStartingVisit = true;
            createPharmAnalysis('Plan de prise médicamenteux', ['PHARMA_ITW_TREATMENT_PLAN'])
                .then(visit => $state.go('visit-wizard', {id: visit.id}))
                .catch(error => {
                    // nothing
                })
                .finally(() => {
                    vm.isStartingVisit = false;
                });
        }

        function createPharmAnalysis(name, steps) {
            if (!vm.lastPatientTherapyId) {
                return Promise.reject(new Error('Pas de suivi ouvert'));
            }
            const visit = {
                name,
                comment: null,
                dueDate: null,
                frequency: null,
                limitDate: null,
                nextReminderDate: moment().add(2, 'days').toDate(),
                patientTherapyId: vm.lastPatientTherapyId,
                plannedDate: null,
                reminderDay: null,
                startDate: null,
                steps,
                type: 'PHARMA_CONSULT',
                adhoc: true
            };

            return Visit.createOwn(visit).$promise
                .then(data => {
                    visit.id = data.id;
                    return visit;
                })
                .catch(error => {
                    // nothing;
                });
        }

        function generateTreatmentPlanPdf(visitId) {
            vm.isExportingPdf = true;
            return Visit.exportPdf({
                id: visitId,
                steps: ['PHARMA_ITW_TREATMENT_PLAN']
            }).$promise
                .then(DownloadFile.downloadFile)
                .finally(function () {
                    vm.isExportingPdf = !vm.isExportingPdf;
                });
        }

    }
})();
