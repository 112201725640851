(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('SideEffectScore', SideEffectScore);

    SideEffectScore.$inject = ['$resource'];

    function SideEffectScore($resource) {
        var resourceUrl = 'api/side-effects/:sideEffectId/scores/:score';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'put': {method: 'PUT'},
            'delete': {method: 'DELETE'}
        });
    }
})();
