(function () {
    'use strict';

    const alertBlockItem = {
        bindings: {
            filters: '=',
            grade: '=',
            alertNumber: '=',
        },
        controller: AlertBlockItemController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/alerts/alert-block-item.html'
    };

    angular
        .module('continuumplatformApp')
        .component('alertBlockItem', alertBlockItem);

    AlertBlockItemController.$inject = [];

    function AlertBlockItemController() {

        const vm = this;

        vm.$onInit = function () {

        };

    }

})();
