(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyManagementController', PatientTherapyManagementController);

    PatientTherapyManagementController.$inject = ['$scope', '$state', '$uibModalInstance', 'action', 'patientTherapy', 'Principal', 'PatientTherapy', 'Therapy', 'Visit', 'ContractLine', 'Program'];

    function PatientTherapyManagementController($scope, $state, $uibModalInstance, action, patientTherapy, Principal, PatientTherapy, Therapy, Visit, ContractLine, Program) {
        const vm = this;
        vm.nextStep = nextStep;
        vm.previous = previous;
        vm.shift = shift;
        vm.pause = pause;
        vm.resume = resume;
        vm.renew = renew;
        vm.stop = stop;
        vm.cancel = cancel;

        vm.patientTherapy = patientTherapy;

        vm.action = action; // permet d'afficher la modale sur pré-définit. Valeurs possibles : SHIFT_THERAPY, PAUSE_THERAPY, RESUME_THERAPY, STOP_THERAPY, RENEW_THERAPY
        vm.patient = patientTherapy.patient;
        vm.today = new Date();

        vm.$onInit = function () {
            vm.step = ['MENU'];

            if (vm.action) {
                vm.step.push(vm.action);
            }

            vm.data = {
                stopDate: vm.today,
                resumeDate: vm.today,
                note: null,
                date: vm.today,
                shiftDays: 0,
            };

            vm.stopData = {
                stopDate: null,
                stopCause: null,
                stopCauseDetail: null,
                internalNote: null,
            };

            loadAccount();
            loadContractLine(patientTherapy.contractLineId);
            loadProgram(patientTherapy.programId);
        };

        function loadAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (account.practitioner) {
                    vm.isDoctor = account.practitioner.job === 'J10';
                }
            });
        }

        function loadContractLine(contractLineId) {
            ContractLine.get({id: contractLineId}, function (cl) {
                vm.contractLine = cl;
                vm.hasNextContractLine = !!vm.contractLine.nextContractLines && !!vm.contractLine.nextContractLines.length;
            });
        }

        function loadProgram(programId) {
            Program.get({id: programId}, program => vm.program = program);
        }

        function nextStep(step) {
            vm.step.push(step);
        }

        function previous() {
            vm.step.pop();
        }

        /**
         * Permet de décaler un parcours
         */
        function shift() {
            vm.isSaving = true;
            PatientTherapy.shift(
                {id: vm.patientTherapy.id},
                {internalNote: vm.data.internalNote, shiftDays: vm.data.shiftDays},
                onSaveSuccess, onSaveError
            );
        }

        /**
         * Permet de mettre en pause un parcours
         */
        function pause() {
            vm.isSaving = true;
            PatientTherapy.pause(
                {id: vm.patientTherapy.id},
                {internalNote: vm.data.internalNote},
                onSaveSuccess, onSaveError
            );
        }

        /**
         * Permet de reprendre un parcours mis en pause.
         */
        function resume() {
            vm.isSaving = true;
            PatientTherapy.resume(
                {id: vm.patientTherapy.id},
                {internalNote: vm.data.internalNote, resumeDate: vm.data.resumeDate},
                onSaveSuccess, onSaveError
            );
        }

        /**
         * Permet d'arrêter un parcours en cours
         */
        function stop() {
            vm.isSaving = true;
            PatientTherapy.stop({
                id: vm.patientTherapy.id
            }, vm.stopData, onSaveSuccess, onSaveError);
        }

        /**
         * Permet de renouveler un parcours en cours
         */
        function renew() {
            vm.isSaving = true;
            PatientTherapy.renew(
                {id: vm.patientTherapy.id},
                vm.data,
                onSaveSuccess, onSaveError
            );
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close({
                patientTherapyId: result.patientTherapyId,
                openStartDialog: vm.step.includes('NEXT_THERAPY') || vm.step.includes('RENEW_THERAPY'),
            });

            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
