(function () {
    'use strict';

    var visitActions = {
        bindings: {
            data: '=',
            questions: '<'
        },
        controller: VisitActionsController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-actions.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitActions', visitActions);

    VisitActionsController.$inject = ['$rootScope'];

    function VisitActionsController($rootScope) {

        var vm = this;

        vm.step = 'actions';

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

    }

})();
