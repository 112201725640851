(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .filter('userAvatar', userAvatar);

    userAvatar.$inject = ['Avatar'];

    function userAvatar(Avatar) {

        return getAvatarPath;

        function getAvatarPath(user) {
            return Avatar.getUserAvatar(user);
        }

    }

})();
