(function() {
    'use strict';

    const settingsMeasureTypes = {
        bindings: {
            practitioner: '='
        },
        controller: SettingsMeasureTypesController,
        controllerAs: 'vm',
        templateUrl: 'app/account/settings-practitioner/settings-measure-types.html'
    };

    angular
        .module('continuumplatformApp')
        .component('settingsMeasureTypes', settingsMeasureTypes);

    SettingsMeasureTypesController.$inject = ['MeasureType', 'Practitioner', 'Principal'];

    function SettingsMeasureTypesController(MeasureType, Practitioner, Principal) {
        const vm = this;

        const measureRules = MeasureType.getRules();
        const optionalMeasureTypes = [
            'BLOOD_PRESSURE',
            'HEART_RATE',
            'SATO2',
            'RESPIRATORY_RATE',
            'TEMPERATURE'
        ];
        vm.measureTypeSettings = {};

        vm.$onInit = () => {
            for (const type of optionalMeasureTypes) {
                vm.measureTypeSettings[type] = {
                    name: measureRules[type].name,
                    enabled: vm.practitioner.settings.measureTypes ? vm.practitioner.settings.measureTypes.includes(type) : false
                };
            }

            Principal.identity().then(account => {
                vm.selfUpdate = account.practitioner && account.practitioner.id === vm.practitioner.id;
            });
        };

        vm.$onDestroy = () => {
            if(vm.selfUpdate) {
                // reload practitioner to reflect changes
                Principal.identity(true);
            }
        };

        vm.toggle = type => {
            if(vm.isToggling) {
                return;
            }
            vm.isToggling = true;
            const params = {type};
            if(!vm.selfUpdate) {
                params.id = vm.practitioner.id;
            }
            let fn;
            let newValue;
            if(vm.measureTypeSettings[type].enabled) {
                fn = Practitioner.removeMeasureType;
                newValue = false;
            } else {
                fn = Practitioner.addMeasureType;
                newValue = true;
            }
            fn(params, {}).$promise
                .then(() => {
                    vm.measureTypeSettings[type].enabled = newValue;
                })
                .finally(() => {
                    vm.isToggling = false;
                });
        };

    }
})();
