(function () {
    'use strict';

    var pharmaItw1Step1Picto = {
        bindings: {
            data: '=',
            questions: '<'
        },
        controller: PharmaItw1Step1PictoController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/pharma-itw-1-step1-picto.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmaItw1Step1Picto', pharmaItw1Step1Picto);

    PharmaItw1Step1PictoController.$inject = ['$rootScope'];

    function PharmaItw1Step1PictoController($rootScope) {

        var vm = this;

        vm.step = 'PHARMA_ITW_1_STEP1_PICTO';

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

    }

})();
