(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('patientDetailProperty', patientDetailProperty);

    function patientDetailProperty() {

        return {
            restrict: 'E',
            scope: {
                label: '@',
                value: '='
            },
            templateUrl: 'app/activity/patient/patient-detail-property.html'
        };


    }

})();
