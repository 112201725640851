(function () {
    'use strict';

    const patientEtatPhysiopathologie = {
        bindings: {
            data: '=',
            compareData: '=',
            showDataDiff: '=',
            readonly: '='
        },
        controller: patientEtatPhysiopathologieController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient/patient-etat-physiopathologie.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientEtatPhysiopathologie', patientEtatPhysiopathologie);

    patientEtatPhysiopathologieController.$inject = [];

    function patientEtatPhysiopathologieController() {

        const vm = this;

        vm.questions = [
            {
                id : 'COMORBIDITES',
                "type": "TEXT",
                "label": "Comorbidités",
                "required": false
            },
            {
                id: 'ANTECEDENTS_MEDICAUX_ET_CHIRURGICAUX',
                "type": "TEXT",
                "label": "Antécédents médicaux et chirurgicaux",
                "required": false
            },
            {
                id: 'ALLERGIES',
                "type": "TEXT",
                "label": "Allergies",
                "required": false
            },
        ];

    }

})();
