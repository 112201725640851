(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$rootScope', '$scope', 'Principal', 'HealthFacility', 'Practitioner', 'ToasterService', 'Visit', 'FeatureFlags'];

    function HomeController($rootScope, $scope, Principal, HealthFacility, Practitioner, ToasterService, Visit, FeatureFlags) {

        const vm = this;

        vm.reportMeNotAway = reportMeNotAway;

        vm.account = null;

        vm.$onInit = () => {
            getAccount();
            subscribeTotalVisitTodoOrInProgress();

            vm.unsubscribeAuthenticationSuccess = $scope.$on('authenticationSuccess', () => {
                getAccount();
            });
            vm.unsubscribeAccountSelfUpdate = $rootScope.$on('continuumplatformApp:accountSelfUpdate', (event, account) => {
                loadAccount(account);
            });
        };

        vm.$onDestroy = () => {
            vm.unsubscribeTotalVisitTodoOrInProgress();
            vm.unsubscribeAuthenticationSuccess();
            vm.unsubscribeAccountSelfUpdate();
        };

        function subscribeTotalVisitTodoOrInProgress() {
            vm.unsubscribeTotalVisitTodoOrInProgress = $scope.$watch(
                () => Visit.totalVisitTodoOrInProgress,
                (newVal, oldVal) => {vm.totalVisitTodoOrInProgress = newVal;}
            );
        }

        function getAccount() {
            return Principal.identity().then(loadAccount);
        }

        function loadAccount(account) {
            vm.account = account;
            vm.practitioner = account.practitioner;
            assignRoles(account);
            if (vm.practitioner) {
                vm.isPractitionerAway = isPractitionerAway(vm.practitioner);
            }
        }

        function assignRoles(account) {
            vm.isAdmin = account.authorities && account.authorities.includes('ROLE_ADMIN');

            if (vm.account.practitioner) {
                vm.isCoordination = vm.account.practitioner.healthFacilityType === 'COORDINATION';
                vm.isHospitalUser = vm.account.practitioner.healthFacilityType === 'HOSPITAL';
                vm.isCrpvUser = vm.account.practitioner.healthFacilityType === 'PV';

                vm.isDoctor = vm.account.practitioner.job === 'J10';
                vm.isPharmacist = vm.account.practitioner.job === 'J21';
                vm.isPharmacyTechnician = vm.account.practitioner.job === 'J38';
                vm.isNurse = vm.account.practitioner.job === 'J60';
                vm.isOtherPractitioner = vm.account.practitioner.job === 'J99';

                if (vm.account.practitioner.healthFacilityId) {
                    HealthFacility.get({id: vm.account.practitioner.healthFacilityId, view: 'summary'}, function (healthFacility) {
                        vm.healthFacility = healthFacility;
                    });
                }

                if ((vm.isHospitalUser && (vm.isDoctor || vm.isPharmacist || vm.isNurse))
                    || vm.isCoordination) {
                    FeatureFlags.isEnabled('DASHBOARD').then(enabled => vm.isDashboardEnabled = enabled);
                } else {
                    vm.isDashboardEnabled = false;
                }
            } else {
                vm.isDashboardEnabled = false;
            }
        }

        function reportMeNotAway() {
            Practitioner.absenceUpdate({
                leaveFrom: null,
                leaveTo: null,
                leaveMessage: vm.practitioner.leaveMessage
            }).$promise.then(() => {
                vm.practitioner.leaveFrom = null;
                vm.practitioner.leaveTo = null;
                vm.isPractitionerAway = isPractitionerAway(vm.practitioner);
                Principal.identity(true).then(account => {
                    $scope.$emit('continuumplatformApp:accountSelfUpdate', account);
                });
                ToasterService.success('Absence annulée');
            });
        }

        function isPractitionerAway(practitioner) {
            const today = moment().format('yyyy-MM-DD');
            return (!!practitioner.leaveFrom && practitioner.leaveTo >= today) || (!!practitioner.leaveFrom && !practitioner.leaveTo);
        }

    }
})();
