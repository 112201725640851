(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('practitionerProfile', practitionerProfile);

    function practitionerProfile() {

        return {
            restrict: 'E',
            scope: {
                practitioner: '='
            },
            templateUrl: 'app/entities/practitioner/practitioner-profile.html'
        };

    }

})();
