(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Bill', Bill);

    Bill.$inject = ['$resource', 'DateUtils'];

    function Bill($resource, DateUtils) {
        const resourceUrl = 'api/bills/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertLocalDateFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            },
            'pay': {
                url: 'api/bills/:id/pay',
                method: 'POST'
            },
            'billOwn': {
                url: 'api/bills/bill-own',
                method: 'POST'
            },
            'billEntity': {
                url: 'api/bills/bill-entity',
                method: 'POST'
            },
            'unbilledEntityVisits': {
                url: 'api/bills/unbilled-entity-visits',
                method: 'GET',
                isArray: true
            },

        });
    }
})();
