(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('alert', {
                parent: 'patients',
                url: '/alert?page&history',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'global.menu.alert'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/alert/alerts.html',
                        controller: 'AlertController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true,
                        dynamic: true
                    },
                    scoreGreaterThanOrEqualTo: {
                        value: undefined,
                        squash: true,
                        dynamic: true
                    },
                    history: {
                        value: undefined,
                        squash: true,
                        dynamic: true
                    },
                    search: {
                        value: undefined,
                        squash: true,
                        dynamic: true
                    },
                    facilityDoctorId: {
                        value: null,
                        squash: true,
                        dynamic: true
                    }
                },
                resolve: {
                    page: ['$transition$', function ($transition$) {
                        return parseInt($transition$.params().page);
                    }],
                    scoreGreaterThanOrEqualTo: ['$transition$', function ($transition$) {
                        return $transition$.params().scoreGreaterThanOrEqualTo;
                    }],
                    history: ['$transition$', function ($transition$) {
                        return $transition$.params().history === 'true' || $transition$.params().history === true;
                    }],
                    search: ['$transition$', function ($transition$) {
                        return $transition$.params().search;
                    }],
                    facilityDoctorId: ['$transition$', function ($transition$) {
                        return $transition$.params().facilityDoctorId ? parseInt($transition$.params().facilityDoctorId) : null;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('visitStatus');
                        return $translate.refresh();
                    }],
                    patient: () => null,
                    previousState: () => ({}),
                }
            })
            .state('patient-detail.alert', {
                url: '/alert?page',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'global.menu.alert'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/alert/alerts.html',
                        controller: 'AlertController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true,
                        dynamic: true
                    }
                },
                resolve: {
                    page: ['$transition$', function ($transition$) {
                        return parseInt($transition$.params().page);
                    }],
                    scoreGreaterThanOrEqualTo: () => null,
                    history: () => true,
                    search: () => null,
                    facilityDoctorId: () => null,
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('gender');
                        return $translate.refresh();
                    }],
                    patient: ['$stateParams', 'Patient', function ($stateParams, Patient) {
                        return Patient.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name.replace('visit-detail', '') || 'patient-detail.follow-up',
                            params: $transition$.from().name.replace('visit-detail', '') ? $transition$.params('from') : {id: $transition$.params().id}
                        };
                    }]
                }
            })
        ;
    }

})();
