(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('contract', {
                parent: 'master',
                url: '/contract?page&sort&search',
                data: {
                    authorities: ['ROLE_EDITOR'],
                    pageTitle: 'continuumplatformApp.contract.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/contract/contracts.html',
                        controller: 'ContractController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'startDate,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('contract');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('contract-detail', {
                parent: 'contract',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_EDITOR'],
                    pageTitle: 'continuumplatformApp.contract.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/contract/contract-detail.html',
                        controller: 'ContractDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('contract');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Contract', function ($stateParams, Contract) {
                        return Contract.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('contract-detail.delete', {
                url: '/delete',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/activity/contract/contract-delete-dialog.html',
                        controller: 'ContractDeleteController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Contract', function (Contract) {
                                return Contract.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('contract', null, {reload: 'contract'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
