(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .directive('isolateClick', isolateClick);

    function isolateClick() {
        return {
            link: function (scope, elem) {
                elem.on('click', function (e) {
                    e.stopPropagation();
                });
            }
        };
    }

})();
