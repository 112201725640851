(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('LiquibaseController', LiquibaseController);

    LiquibaseController.$inject = ['Liquibase'];

    function LiquibaseController(Liquibase) {
        const vm = this;
        vm.ranChangeSets = null;
        vm.desc = true;
        vm.orderBy = 'dateExecuted';
        Liquibase.getRanChangeSets()
            .then(response => vm.ranChangeSets = response);
    }
})();
