(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('BiologyResultController', BiologyResultController);

    BiologyResultController.$inject = ['$state', '$uibModal', 'BiologyResult', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'previousState', 'patient', 'Principal'];

    function BiologyResultController($state, $uibModal, BiologyResult, ParseLinks, AlertService, paginationConstants, pagingParams, previousState, patient, Principal) {

        const vm = this;

        vm.patient = patient;

        vm.previousState = previousState;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.loadPage = loadPage;
        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.importBiologyResult = importBiologyResult;

        loadAll();

        function loadAll() {
            BiologyResult.query({
                "patientId.equals": patient ? patient.id : undefined,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                const result = [vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc')];
                if (vm.predicate !== 'date') {
                    result.push('date');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.biologyResults = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                id: patient ? patient.id : undefined,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc')
            });
        }

        function importBiologyResult() {
            Principal.identity().then(account => {
                $uibModal.open({
                    templateUrl: 'app/activity/biology-result/biology-result-import.html',
                    controller: 'BiologyResultImportController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        patientId: () => account.patient.id
                    }
                }).result.then(
                    id => $state.go('visit-detail', {id: id}),
                    () => {
                        // do nothing
                    });
            });
        }
    }
})();
