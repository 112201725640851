(function () {
    'use strict';

    const patientTreatmentPlanBlock = {
        bindings: {
            pharmAnalysis: '=',
            readonly: '=',
            withoutTitle: '='
        },
        controller: PatientTreatmentPlanBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient-treatment-plan/patient-treatment-plan-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientTreatmentPlanBlock', patientTreatmentPlanBlock);

    PatientTreatmentPlanBlockController.$inject = ['$state', '$window', '$uibModal', '_', 'Principal', 'moment'];

    function PatientTreatmentPlanBlockController($state, $window, $uibModal, _, Principal, moment) {

        const vm = this;

        vm.openTreatment = openTreatment;
        vm.openPractitioner = openPractitioner;
        vm.showDetails = showDetails;

        /**
         * Executée à l'initialisation du composant.
         */
        vm.$onInit = function () {
            Principal.identity().then(function (account) {
                vm.isAdmin = account.authorities.includes('ROLE_ADMIN');
                vm.isPharmacist = account.practitioner && account.practitioner.job === 'J21';
            });

            initMedicationPlan(vm.pharmAnalysis.data.medications);
            vm.pharmAnalysis.data.hasMedicationPlanWithData = vm.pharmAnalysis.data.medications.reduce((hasMedicationPlanWithData, m) => hasMedicationPlanWithData || !!m.medicationPlan.schedule, false);
        };

        /**
         * Executée à la destruction du composant.
         */
        vm.$onDestroy = function() {};

        /**
         * Permet d'ouvrir la modale permettant de visualiser et/ou modifier le plan de prise du médicament.
         * @param medication le plan de prise
         * @param index l'index du plan de prise dans la liste des plans de prise.
         */
        function openTreatment(medication, index) {
            if (vm.readonly) {
                showDetails(medication);
                return;
            }

            $uibModal.open({
                templateUrl: 'app/activity/patient-treatment-plan/patient-treatment-plan-dialog.html',
                controller: 'PatientTreatmentPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    medication: medication.medication,
                    medicationPlan: () => _.clone(medication.medicationPlan),
                    readonly: () => !vm.isPharmacist || vm.readonly,
                }
            }).result.then((newPlan) => {
                calculateEndDate(newPlan);
                vm.pharmAnalysis.data.medications[index].medicationPlan = newPlan;
            }, function () {
                // nothing
            });
        }

        function showDetails(medication) {
            $uibModal.open({
                templateUrl: 'app/activity/patient-treatment-plan/patient-treatment-plan-dialog.html',
                controller: 'PatientTreatmentPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    medication: medication.medication,
                    medicationPlan: () => _.clone(medication.medicationPlan),
                    readonly: () => true,
                }
            }).result.then(() => {
                // nothing
            }, function () {
                // nothing
            });
        }

        /**
         * Permet d'initialiser les plans de prise des médicaments du patient
         * @return {{dosage: null, hours: {}, notes: null, withMeal: null, endDate: null, nbDayTaken: null, durationDays: null, schedule: null, nbDayTreatmentBreak: null, comment: null, indication: null, medicationVariant: *, startDate: null}[]}
         */
        function initMedicationPlan(medications) {
            medications.forEach((m) => {
                if (!m.medicationPlan) {
                    m.medicationPlan = {
                        schedule: null,
                        indication: null,
                        notes: null,
                        startDate: null,
                        durationDays: null,
                        endDate: null,
                        withMeal: null,
                        hours: {},
                        comment: null,
                        dosage: null,
                        nbDayTaken: null,
                        nbDayTreatmentBreak: null,
                    };
                }
            });
        }

        /**
         * Permet de calculer si possible la date de fin de prise pour un médicament
         * @param medicationPlan
         */
        function calculateEndDate(medicationPlan) {
            if (medicationPlan.durationDays) {
                medicationPlan.endDate = moment(medicationPlan.startDate).add(medicationPlan.durationDays, 'days').toDate();
            }
        }

        /**
         * Permet d'ouvrir une modale avec les informations concernant le pharmacien ayant réalisé l'analyse pharmaceutique
         * @param pharmacist le pharmacien ayant réalisé l'analyse
         */
        function openPractitioner(pharmacist) {

            Principal.hasAuthority('ROLE_ADMIN').then(function (hasAuthority) {
                if (hasAuthority) {
                    const url = $state.href('practitioner-detail', {id: pharmacist.id});
                    $window.open(url, 'practitioner-detail-' + pharmacist.id);
                } else {
                    return $uibModal.open({
                        templateUrl: 'app/entities/practitioner/practitioner-modal.html',
                        controller: 'PractitionerModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            practitioner: function () {
                                return pharmacist;
                            }
                        }
                    }).result.then(() => {
                        // nothing
                    }, function () {
                        // nothing
                    });
                }
            });
        }

    }

})();
