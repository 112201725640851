(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MediaDialogController', MediaDialogController);

    MediaDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Media', 'User', 'Patient', 'PatientTherapy'];

    function MediaDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Media, User, Patient, PatientTherapy) {
        var vm = this;

        vm.media = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            Media.update(vm.media, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('continuumplatformApp:mediaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
