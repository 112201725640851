(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('QolQlqC30ChartController', QolQlqC30ChartController);

    QolQlqC30ChartController.$inject = ['$timeout', '$scope', '$stateParams', '$translate', '$uibModalInstance', '$filter', 'Measure', 'type', 'data', 'moment'];

    function QolQlqC30ChartController($timeout, $scope, $stateParams, $translate, $uibModalInstance, $filter, Measure, type, data, moment) {
        const vm = this;

        $translate(`continuumplatformApp.qolType.${type}`).then(function (trad) {
            vm.type = trad;

            const timeDiffInDay = moment(data[data.length - 1].date).diff(moment(data[0].date), 'days');
            const timeDiffInHours = moment(data[data.length - 1].date).diff(moment(data[0].date), 'hours');

            let timeUnit = 'day';

            if (timeDiffInHours < 1) {
                timeUnit = 'minute';
            } else if (timeDiffInDay < 1) {
                timeUnit = 'hour';
            } else if (timeDiffInDay > 15 && timeDiffInDay < 60) {
                timeUnit = 'week';
            } else if (timeDiffInDay >= 60) {
                timeUnit = 'month';
            }

            vm.chart = {
                type: 'line',
                labels: data.map((d) => d.date),
                data: [data.map((d) => d.value.toFixed(0))],
                series: [vm.type],
                colors: ['#273FDC', '#ded73f', '#de5b5b', '#81bd41'],
                options: {
                    scales: {
                        xAxes: [{
                            type: 'time',
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Date'
                            },
                            ticks: {
                                major: {
                                    fontStyle: 'bold',
                                    fontColor: '#000000'
                                }
                            },
                            time: {
                                tooltipFormat: 'LLL',
                                unit: timeUnit,
                                displayFormats: {
                                    hour: 'H:mm',
                                    minute: 'H:mm'
                                }
                            }
                        }],
                        yAxes: [{
                            display: true,
                            scaleLabel: {
                                display: false
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 100
                            }
                        }]
                    },
                    legend: {
                        display: true,
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                },
            };
        });

        vm.close = () => {
            $uibModalInstance.close('cancel');
        };
    }
})();
