(function () {
    'use strict';

    var pharmaItwPps = {
        bindings: {
            data: '=',
            patientId: '=',
            questions: '<'
        },
        controller: pharmaItwPpsController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/pharma-itw-pps.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmaItwPps', pharmaItwPps);

    pharmaItwPpsController.$inject = ['$rootScope'];

    function pharmaItwPpsController($rootScope) {

        var vm = this;

        vm.step = 'PHARMA_ITW_PPS';

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

    }

})();
