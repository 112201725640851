(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User($resource) {
        return $resource('api/users/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getSensitive': {
                url: 'api/users/:id/sensitive',
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'enable': {
                url: 'api/users/:id/enable',
                method: 'POST',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'disable': {
                url: 'api/users/:id/disable',
                method: 'POST',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'unlock': {
                url: 'api/users/:id/unlock',
                method: 'POST',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'addAuthority': {
                url: 'api/users/:id/authorities/:authority',
                method: 'PUT'
            },
            'removeAuthority': {
                url: 'api/users/:id/authorities/:authority',
                method: 'DELETE'
            },
            'save': {
                method: 'POST',
                transformResponse: function (data) {
                    if (data) {
                        data = {id: data};
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'delete': {method: 'DELETE'}
        });
    }
})();
