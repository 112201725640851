(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('showValidation', ['$timeout', showValidation]);

    function showValidation($timeout) {
        var directive = {
            restrict: 'A',
            require: 'form',
            link: function ($scope, element, attrs, formCtrl) {

                $scope.$on('continuumplatformApp:showValidation',
                    function (e) {
                        apply('ui-select[ng-model]');
                        $timeout(function () {
                            apply('input[ng-model],textarea[ng-model],select[ng-model]');
                        });
                    }
                );

                apply('ui-select[ng-model]');
                $timeout(function () {
                    apply('input[ng-model],textarea[ng-model],select[ng-model]');
                });

                function apply(selector) {
                    element.find('.form-group').each(function () {
                        var $formGroup = angular.element(this);
                        var $inputs = $formGroup.find(selector);
                        if ($inputs.length > 0 && !$formGroup.hasClass('has-error')) {
                            $inputs.each(function () {
                                var $input = angular.element(this);
                                var inputName = $input.attr('name');
                                // console.log("input", inputName, $input);
                                $scope.$watch(function () {
                                    return formCtrl[inputName] ? formCtrl[inputName].$invalid : false; // TODO red for all?
                                }, function (isInvalid) {
                                    $formGroup.toggleClass('has-error', isInvalid);
                                });
                            });
                        }
                    });
                }
            }
        };

        return directive;

    }
})();
