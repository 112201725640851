(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HealthFacilityEmailDialogController', HealthFacilityEmailDialogController);

    HealthFacilityEmailDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'healthFacility', 'HealthFacility'];

    function HealthFacilityEmailDialogController($timeout, $scope, $stateParams, $uibModalInstance, healthFacility, HealthFacility) {
        var vm = this;

        vm.healthFacility = healthFacility;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            HealthFacility.updateEmail({id: vm.healthFacility.id}, {email: vm.healthFacility.email}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $scope.$emit('continuumplatformApp:healthFacilityEmailUpdate', vm.healthFacility.email);
            $uibModalInstance.close(vm.healthFacility.email);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
