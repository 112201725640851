(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('AbsenceModalController', AbsenceModalController);

    AbsenceModalController.$inject = ['$uibModalInstance', 'practitioner', 'Practitioner'];

    function AbsenceModalController($uibModalInstance, practitioner, Practitioner) {
        const vm = this;

        vm.absence = {
            from: new Date(),
            to: null,
            undeterminedEndDate: false,
            message: practitioner.leaveMessage,
            replacedById: null
        };

        if (practitioner.healthFacilityType === 'COORDINATION') {
            Practitioner.query({
                "healthFacilityId.equals": practitioner.healthFacilityId
            }, practitioners => {
                vm.colleagues = practitioners;
            });
        }

        vm.$onInit = () => {

        };

        vm.clear = () => {
            $uibModalInstance.dismiss('cancel');
        };

        vm.save = () => {
            $uibModalInstance.close({
                leaveFrom: moment(vm.absence.from).format('yyyy-MM-DD'),
                leaveTo: vm.absence.to ? moment(vm.absence.to).format('yyyy-MM-DD'): null,
                leaveMessage: vm.absence.message,
                leaveReplacedById: vm.absence.replacedById
            });
        };

        vm.onClickUndeterminedEndDate = (undeterminedEndDate) => {
            if (undeterminedEndDate) {
                vm.absence.to = null;
            }
        };
    }
})();
