(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('FeatureFlags', FeatureFlags);

    FeatureFlags.$inject = ['$http'];

    function FeatureFlags($http) {

        return {isEnabled, getSelfService};

        function isEnabled(feature) {
            return $http.get('api/togglz/' + feature)
                .then(response => response.data);
        }

        function getSelfService() {
            return $http.get('api/togglz/self-service')
                .then(response => response.data);
        }

    }
})();
