(function () {
    'use strict';

    var radioPtaPpaPaTfa = {
        bindings: {
            form: '=',
            value: '=',
            required: '=',
            group: '@',
            key: '@'
        },
        controller: radioPtaPpaPaTfa,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/radio-pta-ppa-pa-tfa.html'
    };

    angular
        .module('continuumplatformApp')
        .component('radioPtaPpaPaTfa', radioPtaPpaPaTfa);

    radioPtaPpaPaTfa.$inject = [];

    function radioPtaPpaPaTfa() {

        // var vm = this;

    }

})();
