(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('RequestResetController', RequestResetController);

    RequestResetController.$inject = ['$scope', '$timeout', 'errorConstants', 'PasswordReset'];

    function RequestResetController($scope, $timeout, errorConstants, PasswordReset) {
        var vm = this;

        vm.error = null;
        vm.requestReset = requestReset;
        vm.resetAccount = {};
        vm.success = null;

        $timeout(function () {
            angular.element('#email').focus();
        });

        $scope.$watch('vm.resetAccount.email', function (newVal, oldVal) {
            if (newVal !== oldVal)
                vm.error = null;
        });

        function requestReset() {

            vm.error = null;

            PasswordReset.init(vm.resetAccount.email).then(function () {
                vm.success = 'OK';
            }).catch(function (response) {
                vm.success = null;
                vm.error = parseError(response);
            });
        }

        function parseError(response) {
            if (response.status === 400 && response.data) {
                const type = response.data.type;
                if (type === errorConstants.EMAIL_NOT_FOUND_TYPE) {
                    return 'EMAIL_NOT_FOUND';
                }
            } else if(response.status === 401) {
                if(response.data && response.data.detail) {
                    return response.data.detail;
                }
                return 'AUTH_FAILED';
            }
            return 'UNKNOWN';
        }
    }
})();
