(function () {
    'use strict';

    var visitEnd = {
        bindings: {
            data: '=',
            questions: '<'
        },
        controller: VisitEndController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-end.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitEnd', visitEnd);

    VisitEndController.$inject = ['$rootScope'];

    function VisitEndController($rootScope) {

        var vm = this;

        vm.step = 'end';

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

    }

})();
