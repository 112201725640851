(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('patient-detail.treatments', {
                url: '/treatments?page&sort',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'continuumplatformApp.patientTreatment.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient-treatment/patient-treatments.html',
                        controller: 'PatientTreatmentController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true,
                        inherit: false
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true,
                        inherit: false
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('patientTreatment');
                        $translatePartialLoader.addPart('patientTreatmentStatus');
                        $translatePartialLoader.addPart('patientTreatmentPhase');
                        $translatePartialLoader.addPart('medication');
                        $translatePartialLoader.addPart('medicationInfoType');
                        $translatePartialLoader.addPart('medicationVariant');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    patient: ['$stateParams', 'Patient', function ($stateParams, Patient) {
                        return Patient.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name.replace('patient-detail.treatments', '').replace('patient-treatment-detail', '') || 'patient-detail.pharmaceutics',
                            params: $transition$.from().name.replace('patient-detail.treatments', '').replace('patient-treatment-detail', '') ? $transition$.params('from') : {id: $transition$.params().id}
                        };
                    }]
                }
            })
            .state('patient-treatment-detail', {
                parent: 'patients',
                url: '/patient-treatment/{id}',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'continuumplatformApp.patientTreatment.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient-treatment/patient-treatment-detail.html',
                        controller: 'PatientTreatmentDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('patientTreatment');
                        $translatePartialLoader.addPart('patientTreatmentPhase');
                        $translatePartialLoader.addPart('patientTreatmentStatus');
                        $translatePartialLoader.addPart('healthFacilityType');
                        $translatePartialLoader.addPart('medication');
                        $translatePartialLoader.addPart('medicationInfoType');
                        $translatePartialLoader.addPart('medicationVariant');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PatientTreatment', function ($stateParams, PatientTreatment) {
                        return PatientTreatment.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ['$transition$', function ($transition$) {
                        return {
                            name: $transition$.from().name,
                            params: $transition$.params('from')
                        };
                    }]
                }
            });
    }

})();
