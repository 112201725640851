(function () {
    'use strict';

    var pharmaItwTreatmentPicto = {
        bindings: {
            data: '=',
            questions: '<'
        },
        controller: PharmaItwTreatmentPictoController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/pharma-itw-treatment-picto.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmaItwTreatmentPicto', pharmaItwTreatmentPicto);

    PharmaItwTreatmentPictoController.$inject = ['$rootScope'];

    function PharmaItwTreatmentPictoController($rootScope) {

        var vm = this;

        vm.currentPage = 1;

        vm.step = 'PHARMA_ITW_TREATMENT_PICTO';

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

    }

})();
