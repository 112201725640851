(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('MedicationGroup', MedicationGroup);

    MedicationGroup.$inject = ['$resource'];

    function MedicationGroup($resource) {
        var resourceUrl = 'api/medication-groups/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }
})();
