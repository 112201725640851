(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('HealthEntityContract', HealthEntityContract);

    HealthEntityContract.$inject = ['$http', '$resource', 'DateUtils'];

    function HealthEntityContract($http, $resource, DateUtils) {
        var resourceUrl = 'api/health-entity-contracts/:id';

        var service = $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getMySignedSubstituteContractsFor': {
                url: 'api/my-signed-substitute-contracts',
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.signed = DateUtils.convertDateTimeFromServer(data.signed);
                        data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                    }
                    return data;
                }
            },
            'update': {
                url: 'api/health-entity-contracts/:id',
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);
                    return angular.toJson(copy);
                }
            }
        });

        service.requestSignature = function (id) {
            return $http.get('api/health-entity-contracts/' + id + '/sign', {responseType: 'text'}).then(function (response) {
                return response;
            });
        };

        return service;
    }
})();
