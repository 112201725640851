(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PrescriptionDetailController', PrescriptionDetailController);

    PrescriptionDetailController.$inject = ['$rootScope', '$state', '$timeout', '$sce', '$uibModal', 'previousState', 'entity', 'Prescription', 'Patient', 'Principal', 'Inbox'];

    function PrescriptionDetailController($rootScope, $state, $timeout, $sce, $uibModal, previousState, entity, Prescription, Patient, Principal, Inbox) {
        const vm = this;

        vm.deletePrescription = deletePrescription;
        vm.print = print;

        vm.prescription = entity;

        if (previousState.name) {
            vm.previousState = previousState;
        } else {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isPharmacist = account.practitioner && ['J21', 'J38'].includes(account.practitioner.job);
                if (account.patient) {
                    vm.previousState = {
                        name: 'prescription'
                    };
                } else {
                    vm.previousState = {
                        name: vm.isPharmacist ? 'patient-detail.pharmaceutics' : 'patient-detail.follow-up',
                        params: {id: vm.prescription.patientId}
                    };
                }
            });
        }

        Patient.get({id: vm.prescription.patientId}).$promise.then(patient => vm.patient = patient);

        vm.anonymous = false;

        if (vm.prescription.messageId)
            $timeout(function () {
                Inbox.readMessage(vm.prescription.messageId).then(function (response) {
                    // TODO improve with event listener
                    $rootScope.unreadMessages -= response.data;
                    $rootScope.$emit('continuumplatformApp:messageRead', response.data);
                });
            });


        if (vm.prescription.mediaId) {
            vm.trustedInlineUrl = $sce.trustAsResourceUrl('/api/media/' + vm.prescription.mediaId + '/download?disposition=inline');
        }

        function deletePrescription() {
            $uibModal.open({
                templateUrl: 'app/activity/prescription/prescription-delete-dialog.html',
                controller: 'PrescriptionDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function () {
                        return vm.prescription;
                    }]
                }
            }).result.then(() => {
                $state.go(vm.previousState.name, vm.previousState.params, {reload: true});
            }, () => {
                // nothing
            });
        }

    }
})();
