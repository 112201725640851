(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('AlertbarController', AlertbarController);

    AlertbarController.$inject = ['$rootScope', '$scope', '$interval', '$state', '$filter', 'Auth', 'Account', 'Principal', 'termsConstants', 'CareTeam'];

    function AlertbarController($rootScope, $scope, $interval, $state, $filter, Auth, Account, Principal, termsConstants, CareTeam) {
        const vm = this;

        vm.closeAwayMessage = closeAwayMessage;


        let interval = null;
        vm.account = null;
        vm.pendingValidation = null;
        $rootScope.termsPendingAccept = null;
        $rootScope.contractPendingSignature = null;
        $rootScope.practitionerInfoMissing = null;

        vm.$onInit = () => {
            Principal.identity().then(handleAccount);
        };

        function handleAccount(account) {
            vm.account = account;
            if(!vm.account) {
                return;
            }

            vm.pendingValidation = vm.account.practitioner && !vm.account.practitioner.validated;

            let termsVersion = null;
            if (vm.account.practitioner) {
                termsVersion = termsConstants.versionPractitioner;
            } else if (vm.account.patient) {
                termsVersion = termsConstants.versionPatient;
            }
            $rootScope.termsPendingAccept = !vm.account.acceptedTermsVersion || vm.account.acceptedTermsVersion < termsVersion;

            $rootScope.contractPendingSignature = !!(vm.account.practitioner && vm.account.practitioner.contractPendingSignature);

            $rootScope.practitionerInfoMissing = !!(vm.account.practitioner && !vm.account.practitioner.complete);

            if ((vm.pendingValidation || $rootScope.contractPendingSignature || $rootScope.practitionerInfoMissing) && interval === null) {
                interval = $interval(refresh, $rootScope.contractPendingSignature || $rootScope.practitionerInfoMissing ? 5000 : 30000);
            } else if (!vm.pendingValidation && !$rootScope.contractPendingSignature && !$rootScope.practitionerInfoMissing && interval) {
                $interval.cancel(interval);
                interval = null;
                Principal.authenticate(vm.account);
            }

            if (vm.account.patient) {
                // si l'utilisateur est un patient on récupère son équipe de soin
                // pour afficher les professionnels de santé s'étant déclaré absent
                loadCareTeam(vm.account.patient.id);
            }
        }

        function refresh() {
            Account.get().$promise
                .then(result => handleAccount(result.data))
                .catch(angular.noop);
        }

        $rootScope.$on('logout', function (event) {
            vm.account = null;
            vm.pendingValidation = null;
            $rootScope.termsPendingAccept = null;
            $rootScope.contractPendingSignature = null;
            $rootScope.practitionerInfoMissing = null;
            $interval.cancel(interval);
            interval = null;
        });
        $scope.$on('$destroy', function (event) {
            $interval.cancel(interval);
            interval = null;
        });

        function loadCareTeam(patientId) {
            vm.careTeam = [];
            return CareTeam.getMembers(patientId).then(response => {
                vm.careTeam = response.data;
                return vm.careTeamMemberAway = CareTeam.getAwayMembers(vm.careTeam);
            });
        }

        function closeAwayMessage() {
            vm.careTeamMemberAway = [];
        }

    }
})();
