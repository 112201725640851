(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('BiologyResultDetailController', BiologyResultDetailController);

    BiologyResultDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$sce', '$timeout', '$uibModal', 'previousState', 'entity', 'BiologyResult', 'Patient', 'PatientTherapy', 'HealthFacility', 'Media', 'Principal', 'Lightbox'];

    function BiologyResultDetailController($scope, $rootScope, $state, $stateParams, $sce, $timeout, $uibModal, previousState, entity, BiologyResult, Patient, PatientTherapy, HealthFacility, Media, Principal, Lightbox) {
        var vm = this;

        vm.openImage = openImage;
        vm.modifyBiologyResult = modifyBiologyResult;
        vm.deleteBiologyResult = deleteBiologyResult;

        vm.biologyResult = entity;

        vm.previousState = previousState;

        loadMedia();

        Patient.get({
            id: vm.biologyResult.patientId
        }, function (result) {
            vm.patient = result;
        });

        Principal.identity().then(function (account) {
            vm.account = account;
            if (account.patient) {
                vm.defaultPreviousStep = {
                    name: 'biology-result'
                };
            } else {
                vm.defaultPreviousStep = {
                    name: 'patient-detail.follow-up',
                    params: {id: vm.biologyResult.patientId}
                };
            }
        });

        var unsubscribe = $rootScope.$on('continuumplatformApp:biologyResultUpdate', () => {
            BiologyResult.get({id: vm.biologyResult.id}, result => vm.biologyResult = result);
            loadMedia();
        });
        $scope.$on('$destroy', unsubscribe);

        function loadMedia() {
            Media.query({
                "biologyResultId.equals": vm.biologyResult.id
            }, function (result) {
                vm.media = result.map(function (m) {
                    m.trustedInlineUrl = $sce.trustAsResourceUrl('/api/media/' + m.id + '/download?disposition=inline');
                    return m;
                });
            });
        }

        function openImage(media) {
            Lightbox.openModal([{
                caption: media.text,
                url: media.trustedInlineUrl
            }], 0);
        }

        function modifyBiologyResult() {
            $uibModal.open({
                templateUrl: 'app/activity/biology-result/biology-result-modify.html',
                controller: 'BiologyResultModifyController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: () => BiologyResult.get({id: $stateParams.id}).$promise,
                    notify: () => true
                }
            }).result.then(() => {
                // do nothing
            }, () => {
                // do nothing
            });
        }

        function deleteBiologyResult() {
            $uibModal.open({
                templateUrl: 'app/activity/biology-result/biology-result-delete-dialog.html',
                controller: 'BiologyResultDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function () {
                        return vm.biologyResult;
                    }]
                }
            }).result.then(() => {
                if (vm.previousState.name)
                    $state.go(vm.previousState.name, vm.previousState.params, {reload: true});
                else
                    $state.go(vm.defaultPreviousStep.name, vm.defaultPreviousStep.params, {reload: true});
            }, () => {
                // do nothing
            });
        }
    }
})();
