(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('ResetFinishController', ResetFinishController);

    ResetFinishController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$window', 'errorConstants', 'Auth', 'PasswordReset', 'Otp'];

    function ResetFinishController($rootScope, $scope, $state, $stateParams, $timeout, $window, errorConstants, Auth, PasswordReset, Otp) {
        const vm = this;

        vm.resendOtp = resendOtp;
        vm.finishReset = finishReset;
        vm.login = login;

        const key = $stateParams.key;

        vm.keyMissing = angular.isUndefined(key);
        vm.confirmPassword = null;
        vm.error = null;
        vm.resetAccount = {};
        vm.success = null;
        vm.check = null;
        vm.authenticationOtp = null;

        $scope.$watch("vm.otp", function (newVal, oldVal) {
            if (vm.otp && vm.otp.length === 6) {
                vm.error = null;
                $timeout(() => {
                    angular.element('#new-password').focus();
                });
            }
        });

        if (vm.keyMissing) {
            $timeout(() => {
                $state.go('login');
            }, 5000);
        } else {
            PasswordReset.check(key).then(response => {
                vm.check = 'OK';
                vm.needsOtp = response.data && response.data.needsOtp;
                if (vm.needsOtp) {
                    vm.otpHint = response.data.hint;
                }
                $timeout(() => {
                    angular.element(vm.needsOtp ? '#otp' : '#new-password').focus();
                });

            }).catch(response => {
                vm.success = null;
                vm.error = parseError(response);
                vm.check = 'ERROR';

                $timeout(() => {
                    $state.go('login');
                }, 5000);
            });
        }

        function finishReset() {
            vm.resetting = true;
            vm.error = null;
            PasswordReset.finish(
                key,
                vm.resetAccount.password,
                vm.needsOtp ? vm.otp : undefined)
                .then(response => {
                    vm.rememberMe = Auth.hasRememberMeEnabled();
                    if (vm.needsOtp) {
                        vm.success = 'loginInProgress';
                        vm.username = response.data && response.data.login;
                        vm.authenticationOtp = vm.otp;
                        login();
                    } else {
                        vm.success = 'autoLoginNeedsUsername';
                        vm.username = null;
                        vm.authenticationOtp = key;
                        $timeout(() => {
                            angular.element('#username').focus();
                        });
                    }
                })
                .catch(response => {
                    vm.success = null;
                    vm.error = parseError(response);
                })
                .finally(() =>
                    vm.resetting = false);
        }

        function login() {
            vm.success = 'loginInProgress';
            Auth.login({
                username: vm.username,
                password: vm.resetAccount.password,
                otp: vm.authenticationOtp,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.success = 'loggedIn';
                if ($window.localStorage) {
                    if (vm.rememberMe) {
                        $window.localStorage.setItem('username', vm.username);
                    } else {
                        $window.localStorage.removeItem('username');
                    }
                }
                $rootScope.$broadcast('authenticationSuccess');
                $state.go('home');
            }).catch(() => {
                vm.success = 'manualLogin';
                $timeout(function () {
                    $state.go('login');
                }, 5000);
            });
        }

        function parseError(response) {
            if (!response) {
                return 'UNKNOWN';
            }
            if (response.status === 401) {
                vm.otp = null;
                if (vm.needsOtp) {
                    $timeout(() => {
                        angular.element('#otp').focus();
                    });
                }
                if(response.data && response.data.detail) {
                    return response.data.detail;
                }
                return 'AUTH_FAILED';
            } else if (response.status === 400 && response.data) {
                if (response.data.message === 'error.validation' || response.data.type === errorConstants.INVALID_PASSWORD_TYPE) {
                    return 'INVALID_PASSWORD';
                }
            }
            return 'UNKNOWN';
        }

        function resendOtp() {
            vm.sendingOtp = true;
            Otp.resendInitialOtp(key)
                .then(() => {
                    vm.otpResent = true;
                })
                .catch(response => {
                    vm.error = parseError(response);
                })
                .finally(() => {
                    vm.sendingOtp = false;
                });
        }
    }
})();
