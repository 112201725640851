(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .filter('objlengthdefined', objlengthdefined);

    function objlengthdefined() {
        return function (obj) {
            if (!obj)
                return null;
            var c = 0;
            angular.forEach(obj, function (v, k) {
                if (v !== null && v !== undefined && v !== '') {
                    c++;
                }
            })
            return c;
        }
    }
})();
