(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('ProgramController', ProgramController);

    ProgramController.$inject = ['Program'];

    function ProgramController(Program) {

        var vm = this;

        vm.programs = [];

        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            Program.query({
                "sort": "name"
            }, function (result) {
                vm.programs = result;
            });
        }
    }
})();
