(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SideEffectReportPreviewController', SideEffectReportPreviewController);

    SideEffectReportPreviewController.$inject = ['$filter', '$timeout', '$scope', '$stateParams', '$sce', '$uibModalInstance', 'entity', 'SideEffectReport', 'Media', 'Lightbox'];

    function SideEffectReportPreviewController($filter, $timeout, $scope, $stateParams, $sce, $uibModalInstance, entity, SideEffectReport, Media, Lightbox) {
        var vm = this;

        vm.ser = entity;
        vm.clear = clear;
        vm.select = select;
        vm.openImage = openImage;

        loadMedia();
        loadHistory();

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function select(ser) {
            vm.ser = ser;
            loadMedia();
        }

        function loadHistory() {
            vm.history = SideEffectReport.query({
                "patientTherapyId.equals": vm.ser.patientTherapyId,
                "sideEffectId.equals": vm.ser.sideEffectId,
                sort: "date,desc",
                size: 1000 // TODO all
            });
            Media.query({
                "patientId.equals": vm.patientId,
                "sideEffectReportId.specified": true,
                size: 1000 // TODO all
            }, function (pictures) {
                vm.historyPictures = $filter('groupBy')(pictures, 'sideEffectReportId');
            });
        }

        function loadMedia() {
            Media.query({
                "sideEffectReportId.equals": vm.ser.id,
                "contentType.contains": "image/" // TODO only images?
                // TODO size?
            }, function (value) {
                vm.pictures = value.map(function (m) {
                    m.trustedInlineUrl = $sce.trustAsResourceUrl('/api/media/' + m.id + '/download?disposition=inline');
                    return m;
                });
                vm.nbPicturesByColumn = Math.ceil(vm.pictures.length / 2);
            });
        }

        function openImage(index) {
            Lightbox.openModal(vm.pictures
                .map(function (m) {
                    return {
                        caption: m.title,
                        url: m.trustedInlineUrl
                    };
                }), index);
        }

    }
})();
