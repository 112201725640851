(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('side-effect-report', {
                parent: 'patients',
                url: '/side-effect-report?page&sort&score&sideEffectId&patientId&pvLaboratoryId&pvCenterId&fromDate&toDate&pvCenterStatus',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'continuumplatformApp.sideEffectReport.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/side-effect-report/side-effect-report-list.html',
                        controller: 'SideEffectReportListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'date,desc',
                        squash: true
                    },
                    score: {
                        type: 'int',
                        value: [-1, 1, 2, 3, 4],
                        squash: true,
                        array: true
                    },
                    sideEffectId: {
                        type: 'int',
                        value: null,
                        squash: true
                    },
                    patientId: {
                        type: 'int',
                        value: null,
                        squash: true
                    },
                    pvLaboratoryId: {
                        type: 'int',
                        value: null,
                        squash: true
                    },
                    pvCenterId: {
                        type: 'int',
                        value: null,
                        squash: true
                    },
                    pvCenterStatus: {
                        type: 'string',
                        value: null,
                        squash: true
                    },
                    fromDate: {
                        type: 'date',
                        squash: true
                    },
                    toDate: {
                        type: 'date',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sideEffectReport');
                        $translatePartialLoader.addPart('therapy');
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('patientTreatment');
                        $translatePartialLoader.addPart('patientTreatmentPhase');
                        $translatePartialLoader.addPart('patientTreatmentStatus');
                        $translatePartialLoader.addPart('medicationVariant');
                        $translatePartialLoader.addPart('medicationInfoType');
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('side-effect-report-detail', {
                parent: 'side-effect-report',
                url: '/{id}',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'continuumplatformApp.sideEffectReport.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/side-effect-report/side-effect-report-detail.html',
                        controller: 'SideEffectReportDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sideEffectReport');
                        $translatePartialLoader.addPart('medication');
                        $translatePartialLoader.addPart('healthFacilityType');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SideEffectReport', function ($stateParams, SideEffectReport) {
                        return SideEffectReport.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('side-effect-report-detail.edit', {
                url: '/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/activity/side-effect-report/side-effect-report-dialog.html',
                        controller: 'SideEffectReportDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['SideEffectReport', function (SideEffectReport) {
                                return SideEffectReport.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('side-effect-report-detail.delete', {
                url: '/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/activity/side-effect-report/side-effect-report-delete-dialog.html',
                        controller: 'SideEffectReportDeleteController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['SideEffectReport', function (SideEffectReport) {
                                return SideEffectReport.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('side-effect-report', null, {reload: 'side-effect-report'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
