(function () {
    'use strict';

    const aohSymptomsPanel = {
        bindings: {
            visits: '=',
        },
        controller: aohSymptomsPanelController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient/aoh-symptoms-panel.html'
    };

    angular
        .module('continuumplatformApp')
        .component('aohSymptomsPanel', aohSymptomsPanel);

    aohSymptomsPanelController.$inject = ['$scope', '$uibModal'];

    function aohSymptomsPanelController($scope, $uibModal) {

        const vm = this;
        vm.onClickSymptom = onClickSymptom;

        /**
         * Initialisation du composant.
         */
        vm.$onInit = function() {
            vm.unWatchVisit = $scope.$watch("vm.visits", onVisitsChange);
        };

        /**
         * Destruction du composant.
         */
        vm.$onDestroy = function() {
            vm.unWatchVisit();
        };

        /**
         * Éxecutée lorsque la liste des visites est mises à jour
         * @param newVal la nouvelle liste des visites
         * @param oldVal l'ancienne liste de visites
         */
        function onVisitsChange(newVal, oldVal) {
            if (newVal) {
                const visitList = newVal.filter((v) => v.steps.includes('AOH_SYMPTOMS')).sort((v1,v2) => v2.date.localeCompare(v1.date));

                vm.data = visitList.map(v => {
                    const visitDate = new Date(v.date);
                    return Object.assign({
                        date: visitDate,
                        author: v.author,
                        dateYear: visitDate.getFullYear(),
                        dateMonth: visitDate.getMonth(),
                        dateMonthString: moment(visitDate).format('MMM'),

                    }, v.data.details.AOH_SYMPTOMS);
                });

                // const lastVisit = visitList[0];
                //
                // console.log('lastVisit', lastVisit);
                //
                // if (lastVisit.data.details.AOH_SYMPTOMS.previousSymptomsInProgress) {
                //     vm.data[0].
                // }



                vm.yearData = generateYearList(vm.data);
                vm.symptomsMatrix = generateSymptomsMatrix(vm.data);
                vm.monthData = generateMonthList(vm.data);
            }
        }

        /**
         * Permet de générer un tableau avec les années des visites et le nombre de cellules à utiliser
         * @param data les données des visites avec l'étape AOH_SYMPTOMS
         * @returns {{year: number, colspan: number}[]}
         */
        function generateYearList(data) {
            const yearData = {};
            data.forEach((d) => {
                if (!yearData[d.dateYear]) {
                    yearData[d.dateYear] = {year: d.dateYear, colspan: 0};
                }
                yearData[d.dateYear].colspan++;
            });

            return [...Object.values(yearData)].sort((y1, y2) => y2.year - y1.year);
        }

        /**
         * Permet de générer un tableau avec les mois des visites et le nombre de cellules à utiliser
         * @param data les données des visites avec l'étape AOH_SYMPTOMS
         * @returns {{id: string, month: string, colspan: number}[]}
         */
        function generateMonthList(data) {
            const monthData = {};
            data.forEach((d) => {

                if (!monthData[`${d.dateYear}_${d.dateMonth}`]) {
                    monthData[`${d.dateYear}_${d.dateMonth}`] = {id: `${d.dateYear}-${d.dateMonth}`, month: d.dateMonthString, colspan: 0};
                }
                monthData[`${d.dateYear}_${d.dateMonth}`].colspan++;
            });

            return [...Object.values(monthData)].sort((m1, m2) => m2.id.localeCompare(m1.id));
        }

        /**
         * Permet de générer un tableau des symptomes à afficher
         * @param data les données des visites avec l'étape AOH_SYMPTOMS
         * @returns {{CONSTIPATION: *[], DOULEUR_ABDOMINALE: *[], FATIGUE: *[], REFLUX: *[], DIARRHEE: *[], MAUX_DE_TETE: *[], AUTRE: *[], BALLONNEMENT: *[], VOMISSEMENT: *[]}}
         */
        function generateSymptomsMatrix(data) {
            const symptomsMatrix = {
                FATIGUE: [],
                MAUX_DE_TETE: [],
                DOULEUR_ABDOMINALE: [],
                DIARRHEE: [],
                VOMISSEMENT: [],
                CONSTIPATION: [],
                BALLONNEMENT: [],
                REFLUX: [],
                AUTRE: []
            };

            data.forEach((d) => {
                if (!d.symptoms) {
                    d.symptoms = {
                        "AUTRE": {
                            id: "AUTRE",
                            name: "Autre"
                        },
                        "REFLUX": {
                            id: "REFLUX",
                            name: "Reflux",
                        },
                        "FATIGUE": {
                            id: "FATIGUE",
                            name: "Fatigue"
                        },
                        "DIARRHEE": {
                            id: "DIARRHEE",
                            name: "Diarrhée"
                        },
                        "VOMISSEMENT": {
                            id: "VOMISSEMENT",
                            name: "Vomissement"
                        },
                        "BALLONNEMENT": {
                            id: "BALLONNEMENT",
                            name: "Ballonnement"
                        },
                        "CONSTIPATION": {
                            id: "CONSTIPATION",
                            name: "Constipation"
                        },
                        "MAUX_DE_TETE": {
                            id: "MAUX_DE_TETE",
                            name: "Maux de tête"
                        },
                        "DOULEUR_ABDOMINALE": {
                            id: "DOULEUR_ABDOMINALE",
                            name: "Douleur abdominale"
                        }
                    };
                }

                for(const key in symptomsMatrix) {
                    d.symptoms[key].visitDate = d.date;
                    d.symptoms[key].author = d.author;

                    // on regarde si le symptôme est toujours déclaré en cours
                    let symptomInProgress = null;
                    if (d.previousSymptomsInProgress) {
                        symptomInProgress = d.previousSymptomsInProgress.filter((s) => s.id === key && s.state === 'En cours')[0];
                    }

                    // si un symptôme est toujours déclaré en cours, on le prend
                    if (symptomInProgress) {
                        symptomInProgress.date = d.date;
                        symptomInProgress.author = d.author;
                        symptomInProgress.dateYear = d.dateYear;
                        symptomInProgress.dateMonth = d.dateMonth;
                        symptomInProgress.dateMonthString = d.dateMonthString;
                        symptomInProgress.visitDate = d.date;

                        symptomsMatrix[key] = [
                            ...symptomsMatrix[key],
                            symptomInProgress || {}
                        ];
                    } else {
                        symptomsMatrix[key] = [
                            ...symptomsMatrix[key],
                            d.symptoms[key] || {}
                        ];
                    }
                }
            });
            return symptomsMatrix;
        }

        function onClickSymptom(symptom) {
            $uibModal.open({
                templateUrl: 'app/activity/patient/symptom-history-modal.html',
                controller: 'SymptomHistoryModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    symptom: () => [...symptom],
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
        }

    }

})();
