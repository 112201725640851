(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('TokensController', TokensController);

    TokensController.$inject = ['Tokens', 'Principal'];

    function TokensController(Tokens, Principal) {
        var vm = this;

        vm.account = null;
        vm.error = null;
        vm.success = null;

        vm.invalidate = invalidate;

        Tokens.getAll((tokens) => vm.tokens = tokens);

        Principal.identity().then(function (account) {
            vm.account = account;
        });

        function invalidate (series) {
            Tokens.delete({series: encodeURIComponent(series)},
                function () {
                    vm.error = null;
                    vm.success = 'OK';
                    vm.tokens = Tokens.getAll();
                },
                function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
        }
    }
})();
