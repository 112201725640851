(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('userStatus', userStatus);

    function userStatus() {

        return {
            restrict: 'E',
            scope: {
                status: '@',
                showText: '='
            },
            templateUrl: 'app/components/user-status.html'
        };


    }

})();
