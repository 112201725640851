(function () {
    'use strict';

    const visitDetailAohCrisisEvaluation = {
        bindings: {
            data: '=',
            questions: '<',
            patient: '<'
        },
        controller: VisitDetailAohCrisisEvaluationController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-detail-aoh-crisis-evaluation.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitDetailAohCrisisEvaluation', visitDetailAohCrisisEvaluation);

    VisitDetailAohCrisisEvaluationController.$inject = [];

    function VisitDetailAohCrisisEvaluationController() {

        const vm = this;

        vm.$onInit = function() {
            if (vm.data.previousCriseEvaluationInProgress) {
                vm.data.crisis = [vm.data.previousCriseEvaluationInProgress, ...vm.data.crisis];
            }

        };

        vm.$onDestroy = function() {
        };

    }

})();
