(function() {
    'use strict';

    angular.module('continuumplatformApp')
        .controller('IdleModalController', IdleModalController);

    IdleModalController.$inject = ['$scope', '$uibModalInstance', 'IdleTimer'];

    function IdleModalController($scope, $uibModalInstance, IdleTimer) {
        const vm = this;
        vm.IdleTimer = IdleTimer;

        vm.close = () => {
            $uibModalInstance.close('stayLogin');
        };

        vm.logout = () => {
            $uibModalInstance.close('logout');
        };

    }
})();
