(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PublicHelpController', PublicHelpController);

    PublicHelpController.$inject = [];

    function PublicHelpController() {
        const vm = this;

        vm.isAuthenticated = false;

    }
})();
