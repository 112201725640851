(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('scrollBottom', ['$timeout', scrollBottom]);

    function scrollBottom($timeout) {
        var directive = {
            scope: {
                scrollBottom: "="
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs, parentCtrl) {
            scope.$watchCollection('scrollBottom', function (newValue) {
                if (newValue) {
                    $timeout(function () {
                        console.log("scrolling", $(element)[0].scrollHeight);
                        element[0].scrollTop = element[0].scrollHeight;
                    }, 50);
                }
            });
        }
    }
})();
