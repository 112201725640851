(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('AlertCount', AlertCount);

    AlertCount.$inject = ['$rootScope', '$http'];

    function AlertCount($rootScope, $http) {

        return {
            countByLevel
        };

        function countByLevel(params) {
            return $http.get('api/alert-count/by-level', {params}).then(response => response);
        }
    }

})();
