(function () {
    'use strict';

    const followUpToStartBlock = {
        bindings: {
            filters: '=',
        },
        controller: FollowUpToStartBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/follow-up-to-start/follow-up-to-start-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('followUpToStartBlock', followUpToStartBlock);

    FollowUpToStartBlockController.$inject = ['$scope', '$state', 'DashboardService'];

    function FollowUpToStartBlockController($scope, $state, DashboardService) {

        const vm = this;

        vm.patientTherapies = null;
        vm.error = null;
        vm.loading = false;

        vm.open = pt => {
            $state.go('patient-therapy-detail', { id: pt.id });
        };

        /**
         * Initialize the component.
         */
        vm.$onInit = function () {
            loadFollowUpToStart();

            vm.unsubscribeWatchFilter = $scope.$watch('vm.filters', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    loadFollowUpToStart();
                }
            }, true);
        };

        /**
         * Loads the therapies to be started.
         *
         * @return {void} This method does not return a value.
         *
         */
        function loadFollowUpToStart() {
            vm.loading = true;

            DashboardService
                .loadFollowUpToStart(vm.filters)
                .then((patientTherapies) => {
                    vm.patientTherapies = patientTherapies;
                    vm.error = null;
                })
                .catch((error) => {
                    vm.patientTherapies = null;
                    vm.error = error;
                })
                .finally(() => vm.loading = false);
        }


    }

})();
