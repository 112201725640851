(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .filter('practitionerAvatar', practitionerAvatar);

    practitionerAvatar.$inject = ['Avatar'];

    function practitionerAvatar(Avatar) {

        return getAvatarPath;

        function getAvatarPath(practitioner) {
            return Avatar.getPractitionerAvatar(practitioner);
        }

    }

})();
