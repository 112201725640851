(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('messaging', {
                parent: 'patients',
                url: '/messaging?message',
                data: {
                    pageTitle: 'continuumplatformApp.message.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/messaging/messaging.html',
                        controller: 'MessagingController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    message: null
                },
                resolve: {
                    currentMessage: ['$stateParams', 'Inbox', '$log', function ($stateParams, Inbox, $log) {
                        if ($stateParams.message) {
                            return Inbox.getMessage($stateParams.message).then(function (response) {
                                return response.data;
                            }, function () {
                                $log.error("Message not found.");
                            });
                        } else
                            return null;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('message');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
