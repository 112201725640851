(function () {
    'use strict';

    const visitDetailAohSymptoms = {
        bindings: {
            data: '=',
            questions: '<',
            patient: '<'
        },
        controller: VisitDetailAohSymptomsController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-detail-aoh-symptoms.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitDetailAohSymptoms', visitDetailAohSymptoms);

    VisitDetailAohSymptomsController.$inject = [];

    function VisitDetailAohSymptomsController() {

        const vm = this;

        vm.symptoms = [];

        /**
         * Initialisation du composant.
         */
        vm.$onInit = function() {
            vm.symptoms = sortSymptomsByIntensity([...Object.values(vm.data.symptoms), ...(vm.data.previousSymptomsInProgress ? vm.data.previousSymptomsInProgress : [])]);
        };

        /**
         * Destruction du composant.
         */
        vm.$onDestroy = function() {
        };

        /**
         * Trie les symptômes en fonction de l'intensité.
         * @param symptoms  les symptomes à trier
         * @returns {*}
         */
        function sortSymptomsByIntensity(symptoms) {
            return symptoms.sort( (s1, s2) => {
                let s1IntensityValue  = 0;
                let s2IntensityValue  = 0;

                switch (s1.intensity) {
                case 'faible':
                    s1IntensityValue = 1;
                    break;
                case 'modérée':
                    s1IntensityValue = 2;
                    break;
                case 'intense':
                    s1IntensityValue = 3;
                    break;
                }

                switch (s2.intensity) {
                case 'faible':
                    s2IntensityValue = 1;
                    break;
                case 'modérée':
                    s2IntensityValue = 2;
                    break;
                case 'intense':
                    s2IntensityValue = 3;
                    break;
                }

                return s2IntensityValue - s1IntensityValue;
            });
        }

    }

})();
