(function () {
    'use strict';

    angular
        .module('continuumplatformApp.idleTimer')
        .factory('IdleTimer', IdleTimer);

    IdleTimer.$inject = ['$log', '$rootScope', '$window', '$interval', '$uibModal', 'Auth', 'SecurityConfig', 'moment'];

    function IdleTimer($log, $rootScope, $window, $interval, $uibModal, Auth, SecurityConfig, moment) {

        // timeout et alertTime sont en secondes
        const service = {
            timeout: 30 * 60,
            alertTime: 60, // TODO à sortir dans la conf
            timeLeft: null,
            timeoutTracker: null,
            interval: null,
            isStarted: false,
            modal: null,
            start: start,
            clear: clear,
            eventHandler: updateExpiredTime,
        };

        function start() {
            if (service.isStarted || service.isStarting) {
                $log.debug("Idle timer already started");
                return;
            }
            service.isStarting = true;
            SecurityConfig.get('IDLE_TIMEOUT_MINUTES')
                .then(idleTimeoutMinutes => {
                    service.timeout = (idleTimeoutMinutes || 30) * 60;
                })
                .catch((error) => {
                    $log.error("Unable to load idle timeout configuration", error);
                })
                .finally(startTimer);
        }

        function startTimer() {
            $log.debug(`Starting idle timer with timeout of ${service.timeout} seconds.`);
            localStorage.removeItem('_expiredTime');
            tracker();
            startInterval();
            service.isStarted = true;
            service.isStarting = false;
        }

        function startInterval() {
            updateExpiredTime();
            service.interval =  $interval(() => {
                const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);

                const startTime = moment();
                const endTime = moment(expiredTime);

                const duration = moment.duration(endTime.diff(startTime));
                const minutes = duration.minutes();
                const seconds = duration.seconds();
                service.timeLeft = minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;

                if (expiredTime < Date.now()) {
                    if (onTimeout) {
                        onTimeout();
                        clear();
                    }
                } else if (!service.modal && expiredTime - Date.now() < service.alertTime * 1000) {
                    if (onAlertTime) {
                        onAlertTime();
                    }
                }
            }, 1000);
        }

        function updateExpiredTime() {
            if (service.timeoutTracker) {
                $interval.cancel(service.timeoutTracker);
                service.timeoutTracker = null;
            }
            if (!service.modal) {
                localStorage.setItem("_expiredTime", Date.now() + service.timeout * 1000);
            }

            // on arrêt de tracker les événements pendant 300 millisecondes pour des raisons de performance.
            unTrack();
            service.timeoutTracker = $interval(() => {
                tracker();
                $interval.cancel(service.timeoutTracker);
                service.timeoutTracker = null;
            }, 300);
        }

        function tracker() {
            if (!service.onLogout) {
                service.onLogout = $rootScope.$on("logout", ( event ) => clear());
            }
            if (!service.onDestroy) {
                service.onDestroy = $rootScope.$on("$destroy", ( event ) => clear());
            }
            $window.addEventListener("mousemove", service.eventHandler);
            $window.addEventListener("scroll", service.eventHandler);
            $window.addEventListener("keydown", service.eventHandler);
        }

        function unTrack() {
            $window.removeEventListener("mousemove", service.eventHandler);
            $window.removeEventListener("scroll", service.eventHandler);
            $window.removeEventListener("keydown", service.eventHandler);
        }

        function clear() {
            $log.debug('Clearing idle timer.');
            if (service.timeoutTracker) {
                $interval.cancel(service.timeoutTracker);
                service.timeoutTracker = null;
            }
            if (service.interval) {
                $interval.cancel(service.interval);
                service.interval = null;
            }

            if (service.onLogout) {
                service.onLogout();
            }

            if (service.onDestroy) {
                service.onDestroy();
            }
            localStorage.removeItem('_expiredTime');
            service.modal = null;
            unTrack();
            service.isStarted = false;
        }

        function onTimeout() {
            if (service.modal) {
                service.modal.close('timeout');
                service.modal = null;
            }
            Auth.logout();
        }

        function onAlertTime() {

            if (!service.modal) {
                service.modal = $uibModal.open({
                    templateUrl: 'app/idleTimer/idle.modal.html',
                    controller: 'IdleModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {}
                });
                service.modal.result.then((result) => {
                    service.modal = null;
                    localStorage.setItem("_expiredTime", Date.now() + service.timeout * 1000);
                    if (result === 'logout') {
                        Auth.logout();
                    }
                }, (error) => {
                    service.modal = null;
                    Auth.logout();
                });
            }

        }

        return service;
    }

})();
