(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('sideEffectScoreSelectionBlock', sideEffectScoreSelectionBlock);

    function sideEffectScoreSelectionBlock() {

        return {
            restrict: 'E',
            scope: {
                sideEffectId: '=',
                score: '=',
                contactLevel: '=',
                description: '=',
                select: '=',
                currentScore: '='
            },
            templateUrl: 'app/entities/side-effect/side-effect-score-selection-block.html'
        };


    }

})();
