(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MedicationInfoDialogController', MedicationInfoDialogController);

    MedicationInfoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'medicationInfo', 'MedicationInfo', 'Medication'];

    function MedicationInfoDialogController($timeout, $scope, $stateParams, $uibModalInstance, medicationInfo, MedicationInfo, Medication) {
        var vm = this;

        vm.medicationInfo = medicationInfo;
        vm.clear = clear;
        vm.save = save;
        vm.medications = Medication.query({
            size: 1000, // TODO search
            sort: "name"
        });

        $timeout(function () {
            angular.element('.form-group:eq(0)>select').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.medicationInfo.id !== null) {
                MedicationInfo.update(vm.medicationInfo, onSaveSuccess, onSaveError);
            } else {
                MedicationInfo.save(vm.medicationInfo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('continuumplatformApp:medicationInfoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
