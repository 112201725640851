(function () {
    'use strict';

    /** @constant {number} PAGE_SIZE Size of the page */
    const PAGE_SIZE = 10;

    const newMessageBlock = {
        bindings: {
            filters: '=',
        },
        controller: NewMessageBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/new-messages/new-message-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('newMessageBlock', newMessageBlock);

    NewMessageBlockController.$inject = ['$rootScope', '$scope', 'DashboardService'];

    function NewMessageBlockController($rootScope, $scope, DashboardService) {

        const vm = this;

        /**
         * Gets the number of unread messages for the user.
         */
        vm.unreadMessages = $rootScope.unreadMessages;

        /**
         * Initialize the component.
         */
        vm.$onInit = function () {

            // Création de la data source pour le scroll
            vm.datasource = DashboardService.createUiScrollDatasource(DashboardService.loadNewMessages, PAGE_SIZE, vm.filters);

            // si les filtres change on recharge les données
            vm.unsubscribeWatchFilter = $scope.$watch('vm.filters', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    vm.datasource.reloadDatasource();
                }
            }, true);

        };

        /**
         * Call when component is destroyed.
         */
        vm.$onDestroy = function () {
            if (vm.unsubscribeWatchFilter) {
                vm.unsubscribeWatchFilter();
            }
        };

    }

})();
