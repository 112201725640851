(function () {
    'use strict';
    angular
        .module('continuum-alert')
        .factory('HDJ', HDJ);

    HDJ.$inject = ['$rootScope', '$http'];

    function HDJ($rootScope, $http) {

        return {query, createPreHospitalVisit};

        function query(params) {
            return $http.get('api/hdj/planning', {params});
        }

        function createPreHospitalVisit({hospitalVisitId, type, pricing}) {
            return $http.post('api/hdj/create-pre-hospital-visit', {hospitalVisitId, type, pricing}).then(response => response);
        }

    }

})();
