(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('patient-detail.measures', {
                url: '/measures?page&sort',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'continuumplatformApp.measure.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/measure/measures.html',
                        controller: 'MeasureController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true,
                        inherit: false
                    },
                    sort: {
                        value: 'date,desc',
                        squash: true,
                        inherit: false
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('measure');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        return $translate.refresh();
                    }],
                    patient: ['$stateParams', 'Patient', function ($stateParams, Patient) {
                        return Patient.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name || 'patient-detail.follow-up',
                            params: $transition$.from().name ? $transition$.params('from') : {id: $transition$.params().id}
                        };
                    }]
                }
            })
            .state('patient-detail.pain-history', {
                url: '/pain-history?page&sort',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'continuumplatformApp.pain.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/measure/pain-history.html',
                        controller: 'PainHistoryController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true,
                        inherit: false
                    },
                    sort: {
                        value: 'date,desc',
                        squash: true,
                        inherit: false
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('measure');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        return $translate.refresh();
                    }],
                    patient: ['$stateParams', 'Patient', function ($stateParams, Patient) {
                        return Patient.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name || 'patient-detail.follow-up',
                            params: $transition$.from().name ? $transition$.params('from') : {id: $transition$.params().id}
                        };
                    }]
                }
            });
    }

})();
