(function () {
    'use strict';

    const visitDetailDateOfNextMedicalConsult = {
        bindings: {
            data: '=',
        },
        controller: VisitDetailDateOfNextMedicalConsultController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-detail-date-of-next-medical-consult.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitDetailDateOfNextMedicalConsult', visitDetailDateOfNextMedicalConsult);

    VisitDetailDateOfNextMedicalConsultController.$inject = [];

    function VisitDetailDateOfNextMedicalConsultController() {

        const vm = this;

        vm.$onInit = function() {};

        vm.$onDestroy = function() {};

    }

})();
