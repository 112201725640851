(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MedicationDetailController', MedicationDetailController);

    MedicationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'MedicationVariant', 'MedicationInfo'];

    function MedicationDetailController($scope, $rootScope, $stateParams, entity, MedicationVariant, MedicationInfo) {
        var vm = this;

        vm.medication = entity;

        MedicationVariant.query({
            "medicationId.equals": vm.medication.id,
            sort: 'name',
            size: 1000 // TODO all
        }, function (variants) {
            vm.medicationVariants = variants;
        });

        loadInfo();

        var unsubscribe1 = $rootScope.$on('continuumplatformApp:medicationUpdate', function (event, result) {
            vm.medication = result;
        });
        var unsubscribe2 = $rootScope.$on('continuumplatformApp:medicationInfoUpdate', loadInfo);
        $scope.$on('$destroy', function () {
            unsubscribe1();
            unsubscribe2();
        });

        function loadInfo() {
            MedicationInfo.query({
                "medicationId.equals": vm.medication.id
            }, function (infos) {
                vm.medicationInfos = infos;
            });
        }
    }
})();
