(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PharmacyOrderDialogController', PharmacyOrderDialogController);

    PharmacyOrderDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PharmacyOrder'];

    function PharmacyOrderDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, PharmacyOrder) {
        var vm = this;

        vm.pharmacyOrder = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            PharmacyOrder.update(vm.pharmacyOrder, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $scope.$emit('continuumplatformApp:pharmacyOrderUpdate');
            $uibModalInstance.close();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
