(function () {
    'use strict';

    const intakeRecordGraph = {
        bindings: {
            intakeRecordHistory: '=',
        },
        controller: IntakeRecordGraphController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/intake-record/intake-record-graph.html'
    };

    angular
        .module('continuumplatformApp')
        .component('intakeRecordGraph', intakeRecordGraph);

    IntakeRecordGraphController.$inject = [];

    function IntakeRecordGraphController() {
        const vm = this;

        // on récupère la taille de la zone d'affichage
        // si celle-ci est inférieur à 768 on affiche le graphe sur une période de 2 semaines au lieu d'un mois
        // pour rendre le graph plus lisible
        vm.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        let GRAPH_PERIOD = vm.width > 768 ? 1 : 2;
        let GRAPH_PERIOD_UNIT = vm.width > 768 ? 'month': 'week';
        let INIT_DATE = moment().endOf('day');

        vm.$onInit = initializeGraph;
        vm.previousChartData = moveChartDataBackwards;
        vm.nextChartData = moveChartDataForwards;

        function initializeGraph() {
            if (!vm.intakeRecordHistory) {
                return new Error('Aucun relevé de prise');
            }

            INIT_DATE = vm.intakeRecordHistory.length ? moment(vm.intakeRecordHistory[0].date).endOf('day') : moment();

            const { startDate, endDate } = calculateGraphDateRange(INIT_DATE, GRAPH_PERIOD, GRAPH_PERIOD_UNIT);
            vm.graphStartDate = startDate;
            vm.graphEndDate = endDate;
            updateGraphData(vm.intakeRecordHistory, startDate, endDate);

            vm.datasetOverride = [
                {
                    backgroundColor: 'rgba(28, 145, 173, 1)',
                    borderColor: 'rgba(28, 145, 173, 1)',
                    // barPercentage: 0.5,
                    // barThickness: 10,
                    maxBarThickness: 10,
                    // minBarLength: 10,
                },
                {
                    backgroundColor: 'rgb(92,184,92)',
                    borderColor: 'rgb(92,184,92)',
                    // barThickness: 10
                    maxBarThickness: 10,
                    // minBarLength: 10,
                }
            ];
        }

        function calculateGraphDateRange(date, period, unit) {
            const startDate = moment(date).subtract(period, unit);
            const endDate = moment(date);

            return { startDate, endDate };
        }

        function updateGraphData(records, startDate, endDate) {
            filteredIntakeRecord(records, startDate, endDate).then((filteredIntakeRecord) => {
                vm.filteredIntakeRecord = filteredIntakeRecord;

                vm.graphData = {
                    x: filteredIntakeRecord.map((m) => moment(m.date).toDate()),
                    y: [filteredIntakeRecord.map((m) => m.sum1)]
                };

                // si l'on a une deuxième substance active, on l'ajoute au graph
                if (filteredIntakeRecord && filteredIntakeRecord.length && filteredIntakeRecord[0].description2) {
                    vm.graphData.y.push(filteredIntakeRecord.map((m) => m.sum2));
                }

                vm.options = defineGraphOptions(vm.graphData, startDate, endDate);

                vm.isPreviousGraphDataPossible = isMovingDataPeriodPossible(records, startDate, -1, GRAPH_PERIOD, GRAPH_PERIOD_UNIT);
                vm.isNextGraphDataPossible = isMovingDataPeriodPossible(records, endDate, 1, GRAPH_PERIOD, GRAPH_PERIOD_UNIT);
            });
        }

        function isMovingDataPeriodPossible(records, date, direction, period, unit) {
            const adjacentDate = moment(date).add(period * direction , unit);

            return direction === -1
                ? moment(date).isAfter(moment(records[records.length - 1].date))
                : adjacentDate.isSameOrBefore(INIT_DATE);
        }

        function moveChartDataBackwards() {
            moveChartDataPeriod(-1);
        }

        function moveChartDataForwards() {
            moveChartDataPeriod(1);
        }

        function moveChartDataPeriod(direction) {
            vm.graphStartDate.add(GRAPH_PERIOD * direction, GRAPH_PERIOD_UNIT);
            vm.graphEndDate.add(GRAPH_PERIOD * direction, GRAPH_PERIOD_UNIT);

            updateGraphData(vm.intakeRecordHistory, vm.graphStartDate, vm.graphEndDate);
        }

        function defineXAxesOptions(data, startDate, endDate) {

            // on prend la date maximum pour éviter d'avoir rien sur tout une période
            const newGraphStartDate = moment.max(startDate, moment(data.x[data.x.length - 1]).startOf('day'));

            return [{
                type: 'time',
                distribution: 'linear',
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Date'
                },
                ticks: {
                    major: {
                        fontStyle: 'bold',
                        fontColor: '#000000'
                    },
                    min: newGraphStartDate.subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'), // on retire 1 jour pour que la bar ne soit pas coupée
                    max: moment(endDate).add(1, 'days').format('YYYY-MM-DD HH:mm:ss'), // on ajout un jour pour que la bar ne soit pas coupée
                    maxRotation: 45,
                    minRotation: vm.width > 768 ? 0 : 45,
                },
                time: {
                    tooltipFormat: 'LLL',
                    unit: 'week',
                },
            }];

        }

        function defineGraphOptions(data, startDate, endDate) {
            const maxYVal = Math.max(...data.y[0]);

            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: defineXAxesOptions(data, startDate, endDate),
                    yAxes: [{
                        display: true,
                        scaleLabel: {display: true},
                        ticks: {
                            beginAtZero: true,
                            suggestedMin: 0,
                            suggestedMax:  maxYVal + (maxYVal / 10),
                            callback: function(value, index, values) {
                                return value + ' mg'; // ajout de l'unité après chaque valeur
                            }
                        },
                    }],
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            const label = data.labels[tooltipItem.index];
                            const description1 = vm.intakeRecordHistory.filter((r) => r.date === moment(label).format('YYYY-MM-DD'))[0][`description${tooltipItem.datasetIndex + 1}`];

                            return description1.split(' + ');
                        },
                        title: function (context, data) {
                            const date = data.labels[context[0].index];
                            return moment(date).format('DD/MM/YYYY');
                        }
                    }
                },
                animation: {
                    onComplete: function() {
                        if (!vm.graphData.y[0].length) {  // check if data is empty
                            const ctx = this.chart.ctx;  // get the context of the canvas
                            ctx.save();
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            ctx.font = vm.width > 768 ? '24px normal' : '16px normal';
                            ctx.fillText('Aucune données', this.chart.width / 2, this.chart.height / 2);  // draw the text
                            ctx.restore();
                        }
                    }
                }
            };
        }

        function filteredIntakeRecord(records, startDate, endDate) {
            return new Promise((resolve, reject) => {
                resolve(records.filter((m) => moment(m.date).isBetween(startDate, endDate, undefined, '[]')));
            });
        }

    }

})();
