(function () {
    'use strict';

    var pharmaItwModalitePriseStep1Picto = {
        bindings: {
            data: '=',
            questions: '<'
        },
        controller: PharmaItwModalitesPriseStep1PictoController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/pharma-itw-modalites-prise-step1-picto.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmaItwModalitePriseStep1Picto', pharmaItwModalitePriseStep1Picto);

    PharmaItwModalitesPriseStep1PictoController.$inject = ['$rootScope'];

    function PharmaItwModalitesPriseStep1PictoController($rootScope) {

        var vm = this;

        vm.step = 'PHARMA_ITW_MODALITES_PRISE_STEP1_PICTO';

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

    }

})();
