(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MyPlanningController', MyPlanningController);

    MyPlanningController.$inject = ['$log', 'Visit'];

    function MyPlanningController($log, Visit) {

        const vm = this;

        vm.loading = true;
        vm.loadingHistory = false;
        vm.historyShown = false;
        vm.visits = null;

        vm.isStartable = Visit.isStartable;
        vm.isResumable = Visit.isResumable;

        vm.$onInit = () => {
            getCurrentVisits().then((visits) => {
                vm.visits = split(filterAdhoc(visits));
            }).finally(() => {
                vm.loading = false;
            });
        };

        vm.$onDestroy = () => {
            // nothing to do
        };

        vm.showHistory = () => {
            vm.loadingHistory = true;
            getHistory().then(visits => {
                vm.historyShown = true;
                vm.visits.push(...visits);
            }).finally(() => {
                vm.loadingHistory = false;
            });
        };

        function getCurrentVisits() {
            return Visit.query({
                "status.in": ['TODO', 'IN_PROGRESS'],
                size: 1000,
                sort: ['orderingDate,asc', 'id,asc'],
            }).$promise;
        }

        function getHistory() {
            return Visit.query({
                "status.equals": 'DONE',
                size: 1000,
                sort: ['orderingDate,desc', 'id,desc'],
            }).$promise;
        }

        function filterAdhoc(visits) {
            return visits.filter((visit) => !visit.adhoc || visit.assigned);
        }

        function split(visits) {
            return [

                // questionnaires en cours que le patient peut renseigner
                ...visits.filter((visit) => visit.status === 'IN_PROGRESS' && visit.assigned && (vm.isStartable(visit) || vm.isResumable(visit))),

                // questionnaires à renseigner, sauf consultation d'annonce et CPPP
                ...visits.filter((visit) => visit.status === 'TODO' && visit.type !== 'ANNOUNCEMENT_CONSULTATION' && visit.name !== 'Consultation pharmaceutique de primo-prescription')
            ];
        }

    }
})();
