(function () {
    'use strict';

    const visitAohSymptoms = {
        bindings: {
            patient: '<',
            data: '=',
            questions: '<'
        },
        controller: VisitAohSymptoms,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-aoh-symptoms.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitAohSymptoms', visitAohSymptoms);

    VisitAohSymptoms.$inject = ['$rootScope', '$scope', '_', 'Visit'];

    function VisitAohSymptoms($rootScope, $scope, _, Visit) {

        const vm = this;

        vm.maxEndDate = moment().format('YYYY-MM-DD');
        vm.wizardStep = 1;

        vm.symptomsList = {
            FATIGUE: {
                id: 'FATIGUE',
                name: 'Fatigue',
                description: '',
                state: null,
                startDate: null,
                endDate: null,
                intensity: null,
                details: null,
                lookLikeUsualCrises: null,
                show: true
            },
            MAUX_DE_TETE: {id: 'MAUX_DE_TETE', name: 'Maux de tête', show: true},
            DOULEUR_ABDOMINALE: {id: 'DOULEUR_ABDOMINALE', name: 'Douleur abdominale', show: true},
            DIARRHEE: {id:'DIARRHEE', name: 'Diarrhée', show: true},
            VOMISSEMENT: {id:'VOMISSEMENT', name: 'Vomissement', show: true},
            CONSTIPATION: {id: 'CONSTIPATION', name: 'Constipation', show: true},
            BALLONNEMENT: {id: 'BALLONNEMENT', name: 'Ballonnement', show: true},
            REFLUX: {id: 'REFLUX', name: 'Reflux', show: true},
            AUTRE: {id: 'AUTRE', name: 'Autre', show: true}
        };

        vm.selectedSymptom = null;

        vm.selectSymptom = selectSymptom;
        vm.onCheckSymptom = onCheckSymptom;
        vm.validateSymptom = validateSymptom;
        vm.cancelSelectSymptom = cancelSelectSymptom;
        vm.onClickNext = onClickNext;
        vm.onClickPrevious = onClickPrevious;
        vm.finish = finish;

        /**
         * Initialisation du composant
         */
        vm.$onInit = function () {
            vm.step = 'AOH_SYMPTOMS';
            vm.isSaving = false;

            initStepData().then(() => {
                vm.unWatchSelectedSymptom = $scope.$watchGroup(['vm.selectedSymptom.state', 'vm.selectedSymptom.startDate', 'vm.selectedSymptom.endDate'], _.debounce(onSelectedSymptomChange, 300, false));
            });
        };

        /**
         * Destruction du composant.
         */
        vm.$onDestroy = () => {
            vm.unWatchSelectedSymptom();
        };


        /**
         * Initialise les données de l'étape
         */
        function initStepData() {
            return loadSymptomHistory().then((previousSymptomsInProgress) => {

                if (!vm.data) {

                    vm.wizardStep = previousSymptomsInProgress ? 0 : 1;

                    vm.data = {
                        symptoms: vm.symptomsList,
                        previousSymptomsInProgress: previousSymptomsInProgress
                    };

                } else {

                    vm.wizardStep = vm.data.previousSymptomsInProgress ? 0 : 1;

                    // conversion des dates
                    if (vm.data.previousSymptomsInProgress) {
                        vm.data.previousSymptomsInProgress.forEach((s) => {
                            s.startDate = new Date(s.startDate);
                            s.endDate = s.endDate ? new Date(s.endDate) : null;
                        });
                    }

                    for (const symptomsKey in vm.data.symptoms) {
                        const s = vm.data.symptoms[symptomsKey];
                        s.startDate = s.startDate ? new Date(s.startDate) : null;
                        s.endDate = s.endDate ? new Date(s.endDate) : null;
                    }

                    // on écrase la liste des symptômes avec les données sauvegarder
                    [...Object.values(vm.data.symptoms)].forEach((s) => {
                        vm.symptomsList[s.id] = s;
                    });

                }

            });

        }

        function loadSymptomHistory() {
            return Visit.query({
                "patientId.equals": vm.patient.id,
                "sort": "date,desc",
                "status.equals": "DONE",
                "steps.contains": "AOH_SYMPTOMS",
                "size": 1000
            }).$promise.then(function (visits) {
                vm.visitsWithSymptoms = visits;

                const firstVisitWithSymptoms = !vm.visitsWithSymptoms.length;

                if (firstVisitWithSymptoms) {
                    return null;
                }

                vm.wizardStep = 0;

                const lastSymptomsEvaluation = vm.visitsWithSymptoms[0].data.details.AOH_SYMPTOMS;

                // Récupération des symptômes en cours de la visite précédente
                const previousSymptomsInProgress = [
                    ...Object.values(lastSymptomsEvaluation.symptoms || {}),
                    ...(lastSymptomsEvaluation.previousSymptomsInProgress ? lastSymptomsEvaluation.previousSymptomsInProgress : [])
                ].filter((s) => s.state === 'En cours')
                    .map((s) => {
                        s.startDate = new Date(s.startDate);
                        return s;
                    });

                return previousSymptomsInProgress.length ? previousSymptomsInProgress : null;
            });
        }


        /**
         * Éxecuter lorsque le symptôme selectionné est modifié.
         * @param newValue les nouvelles valeurs.
         * @param oldValue les anciennes valeurs.
         */
        function onSelectedSymptomChange(newValue, oldValue) {
            if (!vm.selectedSymptom) {
                return;
            }

            const newSate = newValue[0];
            const newStartDate = newValue[1];
            const newEndDate = newValue[2];

            if (newSate === 'En cours' && vm.selectedSymptom.endDate) {

                vm.selectedSymptom.endDate = null;

            } else {

                calculateDateRange(newSate, newStartDate, newEndDate);
                validateDate(newStartDate, newEndDate);

            }

        }

        function calculateDateRange(newSate, newStartDate, newEndDate) {

            const previousSymptom = vm.data.previousSymptomsInProgress ? vm.data.previousSymptomsInProgress.filter((s) => vm.selectedSymptom.id === s.id) : null;

            // si le symtome sélectionné correspond à un symptome en cours lors de la visite précédente
            if (previousSymptom && previousSymptom.length && previousSymptom[0] === vm.selectedSymptom) {

                vm.minStartDate = undefined; // champ date disabled donc pas besoin de date min

            // si le symptome selectionné correspond à un symptome qui était cours lors de la visite précédente mais terminer
            } else if (previousSymptom && previousSymptom.length) {

                vm.minStartDate = moment(previousSymptom[0].endDate).add(1, 'day').format('YYYY-MM-DD');

            // si on a une visite précédente on utilise la date de la visite comme référence
            } else if (vm.visitsWithSymptoms && vm.visitsWithSymptoms.length) {

                if (moment(vm.visitsWithSymptoms[0].date).isSame(moment(), 'day')) {
                    vm.minStartDate = moment(vm.visitsWithSymptoms[0].date).format('YYYY-MM-DD');
                } else {
                    vm.minStartDate = moment(vm.visitsWithSymptoms[0].date).add(1, 'day').format('YYYY-MM-DD');
                }

            } else {

                vm.minStartDate = undefined;
            }


            if (newEndDate && newSate === 'Terminé') {
                vm.maxStartDate = moment(newEndDate).format('YYYY-MM-DD');
            } else {
                vm.maxStartDate = moment().format('YYYY-MM-DD');
            }

            if (newStartDate) {
                vm.minEndDate = moment(newStartDate).format('YYYY-MM-DD');
            } else {
                vm.minEndDate = vm.minStartDate;
            }

        }

        function validateDate(newStartDate, newEndDate) {
            if ($scope.form.Q02_2) {
                $scope.form.Q02_2.$setValidity('dateOverlaps', true);
            }
            if ($scope.form.Q02_3) {
                $scope.form.Q02_3.$setValidity('dateOverlaps', true);
            }

            const selectedSymptomHistory = [
                // historique du symptome terminé
                ...vm.visitsWithSymptoms.reduce((listSymptom, v) => {

                    if (v.data.details.AOH_SYMPTOMS.symptoms[vm.selectedSymptom.id].state === 'Terminé') {
                        listSymptom.push(v.data.details.AOH_SYMPTOMS.symptoms[vm.selectedSymptom.id]);
                    }
                    return listSymptom;
                }, []),
                // historique du symptome encore en cours au début de la visite
                ...vm.visitsWithSymptoms.reduce((listSymptom, v) => {
                    if (v.data.details.AOH_SYMPTOMS.previousSymptomsInProgress) {

                        const symptomsInProgressList = v.data.details.AOH_SYMPTOMS.previousSymptomsInProgress.filter((s) => s[vm.selectedSymptom.id]);
                        if (symptomsInProgressList.length) {
                            listSymptom.push(symptomsInProgressList[0]);
                        }
                    }
                    return listSymptom;
                }, []),
                // symptome sélectionné
                vm.selectedSymptom
            ];

            const symptomHistoryCalendar = generateSelectedDateCalendar(selectedSymptomHistory);

            const symptomReportAtStartDate = symptomHistoryCalendar.get(moment(vm.selectedSymptom.startDate).format('YYYY-MM-DD'));
            const symptomReportAtEndDate = symptomHistoryCalendar.get(moment(vm.selectedSymptom.endDate).format('YYYY-MM-DD'));

            if ($scope.form.Q02_2 && symptomReportAtStartDate && symptomReportAtStartDate.size > 1) {
                $scope.form.Q02_2.$setValidity('dateOverlaps', false);
            }

            if ($scope.form.Q02_3 && symptomReportAtEndDate && symptomReportAtEndDate.size > 1) {
                $scope.form.Q02_3.$setValidity('dateOverlaps', false);
            }
        }

        function generateSelectedDateCalendar(selectedSymptomHistory) {
            const selectedDateCalendar = new Map();

            for (let i = 0; i < selectedSymptomHistory.length; i++) {
                const s = selectedSymptomHistory[i];

                let startDate = null;
                let endDate = null;
                let currentDate = null;

                if (s.state === 'Terminé') {
                    startDate = s.startDate ? moment(s.startDate) : null;
                    endDate = s.endDate ? moment(s.endDate) : null;

                } else if (s.state === 'En cours') {
                    startDate = s.startDate ? moment(s.startDate) : null;
                    endDate = moment();
                }

                // si la date est trop ancienne on ne fait pas la validation pour des raisons de performance
                if (startDate && startDate.isBefore(moment().subtract(1, 'year'))) {
                    continue;
                }

                if (s.state && startDate && endDate) {
                    currentDate = moment(startDate);

                    do {
                        if (!selectedDateCalendar.get(currentDate.format('YYYY-MM-DD'))) {
                            selectedDateCalendar.set(currentDate.format('YYYY-MM-DD'), new Set([s]));
                        } else {
                            selectedDateCalendar.get(currentDate.format('YYYY-MM-DD')).add(s);
                        }

                        currentDate.add(1, 'day');

                    } while(!currentDate.isAfter(endDate, 'day'));
                }

            }
            return selectedDateCalendar;
        }

        /**
         * Executée lorsqu'on sélectionne un nouveau symptôme.
         * @param symptom le symptome sélectionné
         */
        function selectSymptom(symptom) {
            vm.selectedSymptomCopy = Object.assign({}, symptom);
            vm.selectedSymptom = symptom;
        }

        /**
         * Éxecutée lorsqu'on clique sur le bouton "annule" la sélection d'un symptôme.
         */
        function cancelSelectSymptom() {
            // on restaure les anciennes valeurs
            Object.assign(vm.selectedSymptom, vm.selectedSymptomCopy);

            vm.selectedSymptom = null;
        }

        /**
         * Éxecutée lorsqu'on clique sur la checkbox correspondant à un symptôme.
         * @param symptom le symptôme sélectionné.
         */
        function onCheckSymptom(symptom) {
            if (symptom.checked) {
                vm.selectedSymptomCopy = Object.assign({}, symptom);
                vm.selectedSymptomCopy.checked = false;
                vm.selectedSymptom = symptom;
            }
        }

        /**
         * Éxecutée lorsqu'on clique sur le bouton "valider" lorsqu'on sélectionne un symptôme.
         */
        function validateSymptom() {
            vm.selectedSymptom.checked = true;
            vm.selectedSymptom = null;
        }

        function onClickNext() {
            this.wizardStep++;

            if (vm.data.previousSymptomsInProgress) {

                vm.data.previousSymptomsInProgress.forEach((s) => {
                    // seul les symptômes terminés et dont la date de fin ne correspond pas à aujourd'hui remontés lors de la précédente
                    // doivent être affiché
                    vm.data.symptoms[s.id].show = s.state === 'Terminé' && !moment(s.endDate).isSame(moment(), 'day');
                });
            }
        }

        function onClickPrevious() {
            this.wizardStep--;
        }

        /**
         * Éxecutée lorsqu'on clique sur le bouton permettant de valider l'étape.
         */
        function finish() {

            // on remet à null les valeurs du symptôme pour ceux non sélectionnés
            for (const key in vm.data.symptoms) {
                if (!vm.data.symptoms[key].checked) {
                    vm.data.symptoms[key] = Object.assign(vm.data.symptoms[key], {
                        state: null,
                        startDate: null,
                        endDate: null,
                        intensity: null,
                        details: null,
                        lookLikeUsualCrises: null
                    });
                }
            }

            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        }
    }

})();
