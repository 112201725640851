(function () {
    'use strict';

    const loadingDatasourceIndicator = {
        bindings: {
            datasource: '=',
        },
        controller: LoadingDatasourceIndicatorController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/loading-datasource-indicator.html',
    };

    angular
        .module('continuumplatformApp')
        .component('loadingDatasourceIndicator', loadingDatasourceIndicator);

    LoadingDatasourceIndicatorController.$inject = [];

    function LoadingDatasourceIndicatorController() {
        // empty
    }

})();
