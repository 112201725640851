(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('patientTherapyStatus', patientTherapyStatus);

    function patientTherapyStatus() {

        return {
            restrict: 'E',
            scope: {
                status: '@'
            },
            templateUrl: 'app/activity/patient-therapy/patient-therapy-status.html'
        };


    }

})();
