(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('visit', {
                parent: 'patients',
                url: '/visit',
                data: {
                    authorities: ['ROLE_PRACTITIONER', 'ROLE_PATIENT'],
                    pageTitle: 'continuumplatformApp.visit.home.title'
                },
                redirectTo: (trans) => {
                    const Principal = trans.injector().get('Principal');
                    return Principal.identity().then(account => {
                        if(account.patient) {
                            return 'my-planning';
                        }
                        if(account.practitioner) {
                            if(['HOSPITAL', 'COORDINATION'].includes(account.practitioner.healthFacilityType)) {
                                return 'facility-planning';
                            }
                            return 'practitioner-planning';
                        }
                        return 'accessdenied';
                    });
                }
            })
            .state('visit-history', {
                parent: 'patients',
                url: '/visit-history?page&sort',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'continuumplatformApp.visit.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/visit/visits.html',
                        controller: 'VisitController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'orderingDate',
                        squash: true
                    },
                    filters: {
                        value: {
                            programId: null,
                            therapyId: null,
                            type: null,
                            status: ['TODO', 'IN_PROGRESS'],
                            query: null,
                            feasibleByMe: null
                        }
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('entity-audit');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('visit-expired', {
                parent: 'patients',
                url: '/expired?page',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'global.menu.expired'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/visit/expired.html',
                        controller: 'ExpiredController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    filters: {
                        value: {
                            diseaseIds: undefined,
                            patientTherapyAssigneeId: undefined,
                            type: null,
                        }
                    }
                },
                resolve: {
                    filters: ['$transition$', $transition$ => $transition$.params().filters],
                    pagingParams: ['$transition$', 'PaginationUtil', ($transition$, PaginationUtil) => ({
                        page: PaginationUtil.parsePage($transition$.params().page),
                    })],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('visit-pending', {
                parent: 'patients',
                url: '/pending',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'global.menu.pending'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/visit/pending.html',
                        controller: 'PendingVisitsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    filters: {
                        value: {
                            diseaseIds: undefined,
                            fromDate: new Date(),
                            therapyType: null,
                            assigneeId: null
                        }
                    }
                },
                resolve: {
                    filters: ['$transition$', $transition$ => $transition$.params().filters],
                    pagingParams: ['$transition$', 'PaginationUtil', ($transition$, PaginationUtil) => ({
                        page: PaginationUtil.parsePage($transition$.params().page),
                    })],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('visit-detail', {
                parent: 'visit',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_PRACTITIONER', 'ROLE_PATIENT'],
                    pageTitle: 'custom'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/visit/visit-detail.html',
                        controller: 'VisitDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('entity-audit');
                        $translatePartialLoader.addPart('healthFacilityType');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('adherenceReport');
                        $translatePartialLoader.addPart('biologyResult');
                        $translatePartialLoader.addPart('qolType');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('message');
                        $translatePartialLoader.addPart('medicationInfoType');
                        $translatePartialLoader.addPart('medication');

                        return $translate.refresh();
                    }],
                    entity: ['$state', '$stateParams', '$document', 'Visit', function ($state, $stateParams, $document, Visit) {
                        return Visit.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name.replace('-wizard', '').replace('-detail.edit', '') || 'visit',
                            params: $transition$.params('from')
                        };
                    }]
                }
            })
            .state('visit-detail.done', {
                url: '/detail/done',
                data: {
                    authorities: ['ROLE_PRACTITIONER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/activity/visit/my-visit-dialog.html',
                        controller: 'MyVisitDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Visit', function (Visit) {
                                return Visit.get({id: $stateParams.id}).$promise;
                            }],
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('visit-detail.edit', {
                url: '/edit',
                data: {
                    authorities: ['ROLE_PRACTITIONER']
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/visit/visit-edition.html',
                        controller: 'VisitEditionController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name || 'visit-detail',
                            params: $transition$.from().name ? $transition$.params('from') : {id: $transition$.params().id}
                        };
                    }]
                }
            })
            .state('visit-wizard', {
                parent: 'visit',
                url: '/{id}/wizard',
                data: {
                    authorities: ['ROLE_PRACTITIONER', 'ROLE_PATIENT'],
                    pageTitle: 'continuumplatformApp.visit.wizard.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/visit/visit-wizard.html',
                        controller: 'VisitWizardController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('adherenceReport');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('patientTreatment');
                        $translatePartialLoader.addPart('patientTreatmentStatus');
                        $translatePartialLoader.addPart('patientTreatmentPhase');
                        $translatePartialLoader.addPart('biologyResult');
                        $translatePartialLoader.addPart('medicationInfoType');
                        $translatePartialLoader.addPart('medicationVariant');
                        $translatePartialLoader.addPart('pharmAnalysis');
                        $translatePartialLoader.addPart('action');
                        $translatePartialLoader.addPart('actionStatus');
                        $translatePartialLoader.addPart('media');
                        return $translate.refresh();
                    }],
                    entity: ['$state', '$transition$', 'Visit', function ($state, $transition$, Visit) {
                        const visitId = $transition$.params().id;
                        return Visit.get({id: visitId}).$promise.then(visit => {
                            if (!Visit.isStartable(visit) && !Visit.isResumable(visit)) {
                                $state.go('visit-detail', {id: visitId});
                                return;
                            }
                            return Visit.start({id: visitId}, {}).$promise
                                .catch(() => $state.go('visit-detail', {id: visitId}));
                        });
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name.replace('-wizard', '') || 'visit',
                            params: $transition$.from().name.replace('-wizard', '') ? $transition$.params('from') : {id: $transition$.params().id}
                        };
                    }]
                }
            })
            .state('visit.delete', {
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/activity/visit/visit-delete-dialog.html',
                        controller: 'VisitDeleteController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Visit', function (Visit) {
                                return Visit.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('visit', null, {reload: 'visit'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('visit-new', {
                url: '/visit-new',
                parent: 'patients',
                data: {
                    authorities: ['PATIENT_THERAPY_MANAGE']
                },
                params: {
                    visit: {
                        value: null
                    }
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/visit/visit-edition.html',
                        controller: 'VisitEditionController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$transition$', ($transition$) => {
                        return $transition$.params().visit;
                    }],
                    previousState: ['$transition$', function ($transition$) {
                        return {
                            name: $transition$.from().name || 'visit',
                            params: $transition$.params('from')
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('healthFacility');
                        $translatePartialLoader.addPart('healthFacilityType');
                        $translatePartialLoader.addPart('practitioner');
                        return $translate.refresh();
                    }],
                }
            });
    }

})();
