(function () {
    'use strict';

    /** @constant {number} PAGE_SIZE Size of the page */
    const PAGE_SIZE = 10;

    const newBiologiesResultsBlock = {
        bindings: {
            filters: '=',
        },
        controller: NewBiologiesResultsBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/new-biologies-results/new-biologies-result-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('newBiologiesResultsBlock', newBiologiesResultsBlock);

    NewBiologiesResultsBlockController.$inject = ['$scope', 'DashboardService'];

    function NewBiologiesResultsBlockController($scope, DashboardService) {

        const vm = this;

        /**
         * Initialize the component.
         */
        vm.$onInit = function () {

            vm.datasource = DashboardService.createUiScrollDatasource(DashboardService.loadNewBiologiesResults, PAGE_SIZE, vm.filters);

            vm.unsubscribeWatchFilter = $scope.$watch('vm.filters', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    vm.datasource.reloadDatasource();
                }
            }, true);

        };

        /**
         * Call when component is destroyed.
         */
        vm.$onDestroy = function () {
            if (vm.unsubscribeWatchFilter) {
                vm.unsubscribeWatchFilter();
            }
        };
    }

})();
