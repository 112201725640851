(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('VisitWizardController', VisitWizardController);

    VisitWizardController.$inject = ['$transitions', '$state', '$scope', '$rootScope', '$stateParams', '$filter', '$timeout', '$uibModal', '$window', 'previousState', 'entity', 'DateUtils', 'Principal', 'Visit', 'SideEffectReport', 'SideEffect', 'MeasureType', 'BiologyResult', 'Practitioner', 'HealthFacility', 'PatientTreatment', 'Upload', 'Media', 'MedicationInfo', 'Patient', 'PatientTherapy', 'IdleTimer', 'CompressImage', 'CareTeam', 'HealthEntityContract', 'WorkingDayService'];

    function VisitWizardController($transitions, $state, $scope, $rootScope, $stateParams, $filter, $timeout, $uibModal, $window, previousState, entity, DateUtils, Principal, Visit, SideEffectReport, SideEffect, MeasureType, BiologyResult, Practitioner, HealthFacility, PatientTreatment, Upload, Media, MedicationInfo, Patient, PatientTherapy, IdleTimer, CompressImage, CareTeam, HealthEntityContract, WorkingDayService) {
        const vm = this;

        vm.visit = entity;

        vm.step = 'loading';
        vm.showContacts = false;

        // conservation de l'ordre et écrasement des sauvegardes successives
        let nextRequest = null;
        vm.isExecuting = false;
        vm.previousState = previousState;

        vm.closeAwayMessage = closeAwayMessage;

        // on relance le timer avec la valeur plus long pour laisser plus de temps pour le questionnaire
        // 5400 secondes soit 1h30
        IdleTimer.clear();
        IdleTimer.start('5400');
        $scope.$on('$destroy', () => {
            IdleTimer.clear();
            IdleTimer.start();
        });

        Principal.identity().then(function (account) {
            vm.account = account;

            if (account.practitioner) {
                vm.data.facilitySsn = account.practitioner.healthFacilitySsn;
                vm.hospitalPractitioner = vm.account.practitioner.healthFacilityType === 'HOSPITAL';
                vm.isDoctor = vm.account.practitioner.job === 'J10';
                vm.isPharmacist = account.practitioner.job === 'J21';
                vm.isNurse = vm.account.practitioner.job === 'J60';
                vm.isHospitalPharmacist = vm.isPharmacist && vm.hospitalPractitioner;

                vm.userInPatientHospitalTeam = vm.visit.patient.healthFacilityId === vm.account.practitioner.healthFacilityId;

            } else if (account.patient) {
                vm.isPatient = true;
            }

            loadPatientTherapy();
        });

        // prevent user from exiting visit
        $rootScope.warnBeforeUnload = true;
        vm.uiCanExit = function (transition) {
            if ($rootScope.warnBeforeUnload) {
                if (transition.to().name === 'visit') {
                    return back(null, pushState);
                } else {
                    return cancel(false, pushState);
                }
            }
        };

        vm.yOffset = null;


        // load basic patient data - will be overridden with full data
        vm.patient = vm.visit.patient;

        Visit.getQuestions({id: vm.visit.id}, function (result) {
            vm.questions = result.questions;
        });

        vm.visit.data = vm.visit.data || {};
        vm.data = vm.visit.data;
        vm.data.practitionerComment = vm.data.practitionerComment || null;
        vm.data.validatedSteps = vm.data.validatedSteps || [];
        vm.data.date = vm.data.date ? moment(vm.data.date).toDate() : null;
        vm.data.details = vm.data.details || {};
        vm.data.details.GLOBAL_DATA = vm.data.details.GLOBAL_DATA || {};
        if(vm.visit.type === 'ANNOUNCEMENT_CONSULTATION') {
            vm.data.details.GLOBAL_DATA.primoPrescriptionDate = vm.data.details.GLOBAL_DATA.primoPrescriptionDate ? moment(vm.data.details.GLOBAL_DATA.primoPrescriptionDate).toDate() : null;
        }

        // load patient
        function loadPatientTherapy() {
            PatientTherapy.get({id: vm.visit.patientTherapyId})
                .$promise
                .then(function (patientTherapy) {
                    vm.patientTherapy = patientTherapy;
                    vm.data.pv = vm.patientTherapy.pv;
                    vm.patient = patientTherapy.patient;

                    // jump to first step
                    if (vm.visit.steps.length > 0) {
                        stepTo(vm.visit.steps[0]);
                    } else {
                        stepTo('menu');
                    }

                    loadPatient().then(() => {
                        if ('PATIENT_PROFILE' in vm.steps && hasNewerPatientProfileVersion()) {
                            warnNewerPatientProfileVersion();
                        }
                        loadContacts();
                    });

                    if (vm.account.practitioner) {
                        if (vm.account.practitioner.legalSubStatus === 'SUBSTITUTE') {
                            // en tant que remplaçant je sélectionne le remplacé
                            HealthEntityContract.getMySignedSubstituteContractsFor({contractLineId: vm.patientTherapy.contractLineId},
                                substituteContracts => {
                                    // prestataires remplaçables
                                    const replaces = new Map();
                                    for (const contract of substituteContracts) {
                                        replaces.set(contract.executorId, contract.executorName);
                                    }
                                    vm.replacesPractitioners = [];
                                    for (const [id, name] of replaces) {
                                        vm.replacesPractitioners.push({id, name});
                                    }
                                    if (vm.replacesPractitioners.length === 1) {
                                        // s'il n'y a qu'un remplacé possible, le forcer
                                        vm.replacesPractitionerIdReadonly = true;
                                        vm.data.replacesPractitionerId = vm.replacesPractitioners[0].id;
                                    }
                                });
                        } else {
                            vm.data.replacesPractitionerId = null;
                        }
                    }

                    vm.showFacilitySsn = ['picto', 'oncolink'].includes(vm.patientTherapy.programCode)
                        && vm.account.practitioner.healthFacilityType === 'PHARMACY'
                        && ['PHARMA_ITW', 'PHARMA_CONSULT'].includes(vm.visit.type)
                        && !vm.account.practitioner.healthFacilitySsn;

                    vm.showHdjForm = vm.visit.type === 'PHARMA_CONSULT'
                        && vm.visit.name === 'Consultation pharmaceutique de primo-prescription'
                        && vm.patientTherapy.article51 === '19GRE08'
                        && vm.isHospitalPharmacist;
                });
        }

        function loadPatient() {
            return Patient.get({id: vm.patient.id})
                .$promise
                .then((patient) => {
                    vm.patient = patient;
                    vm.patientLoaded = true;
                    return vm.patient;
                });
        }

        // workflow
        vm.finish = finish;
        vm.back = back;
        vm.cancel = cancel;
        vm.stepTo = stepTo;
        vm.validateIntakeRecord = validateIntakeRecord;
        vm.validateStep = validateStep;
        vm.openDetail = openDetail;
        vm.addSideEffect = addSideEffect;
        vm.filterSideEffects = filterSideEffects;
        vm.sideEffectsSearchCriteria = '';

        // visit steps
        vm.steps = {};
        angular.forEach(vm.visit.steps, function (step) {
            vm.steps[step] = vm.data.validatedSteps.includes(step);
        });
        vm.data.validatedSteps = vm.data.validatedSteps.filter(step => vm.visit.steps.includes(step));
        // clear details related to steps that are not in the visit anymore
        Object.keys(vm.data.details)
            .filter(step => step !== 'GLOBAL_DATA' && !vm.visit.steps.includes(step))
            .forEach(step => delete vm.data.details[step]);

        // measures: vital signs
        if ('measures' in vm.steps) {
            vm.data.measures = vm.data.measures || {};
        } else if(vm.data.measures) {
            delete vm.data.measures['BLOOD_PRESSURE_SYSTOLIC'];
            delete vm.data.measures['BLOOD_PRESSURE_DIASTOLIC'];
            delete vm.data.measures['HEART_RATE'];
            delete vm.data.measures['SATO2'];
            delete vm.data.measures['RESPIRATORY_RATE'];
            delete vm.data.measures['TEMPERATURE'];
            delete vm.data.measures['WEIGHT'];
            delete vm.data.measures['SIZE'];
        }

        // measures: pain
        if ('pain' in vm.steps) {
            vm.data.measures = vm.data.measures || {};
            vm.data.measures['PAIN'] = vm.data.measures['PAIN'] || {
                value: 0,
                comment: null
            };
        } else if(vm.data.measures) {
            delete vm.data.measures['PAIN'];
        }

        // pharma itw treatments
        if ('PHARMA_ITW_TREATMENT' in vm.steps || 'PHARMA_ITW_TREATMENT_PLAN' in vm.steps) {
            vm.data.pharmAnalysisId = vm.data.pharmAnalysisId || null;
        }

        // side effects (symptoms/biology)
        if ('symptoms' in vm.steps || 'biology' in vm.steps) {
            vm.selectSideEffect = selectSideEffect;
            vm.selectSideEffectScore = selectSideEffectScore;
            vm.reportSideEffectScore = reportSideEffectScore;
            vm.uploadSideEffectReportFile = uploadSideEffectReportFile;
            vm.deleteSideEffectReportFile = deleteSideEffectReportFile;
            vm.onSelectAdditionnalSideEffect = onSelectAdditionnalSideEffect;
            vm.currentSideEffect = null;
            vm.data.sideEffectReports = vm.data.sideEffectReports || {};
            loadSideEffects();
        } else {
            delete vm.data.sideEffectReports;
        }

        // biology
        if ('biology' in vm.steps) {
            vm.uploadBiologyFile = uploadBiologyFile;
            vm.deleteBiologyFile = deleteBiologyFile;
            vm.measureRules = MeasureType.getRules();
            vm.data.biologyResult = vm.data.biologyResult || {
                sampleDate: null,
                comments: null,
                mediaIds: []
            };
            if(vm.data.biologyResult.sampleDate) {
                vm.data.biologyResult.sampleDate = DateUtils.convertDateTimeFromServer(vm.data.biologyResult.sampleDate);
                vm.hasRecentBiologyResult = 'Y';
            } else {
                vm.hasRecentBiologyResult = null;
            }
            vm.biologyStep = 1;
            const twoWeeksAgo = moment().subtract(2, 'weeks');
            vm.minSampleDate = twoWeeksAgo.format('yyyy-MM-DD');
            vm.maxSampleDate = moment().format('yyyy-MM-DD');
            BiologyResult.query({
                "patientId.equals": vm.patient.id,
                "sampleDate.specified": true,
                size: 1,
                sort: "sampleDate,desc"
            }, results => {
                if (results.length) {
                    vm.lastBiologyResult = results[0];
                } else {
                    vm.lastBiologyResult = null;
                }
                if(vm.lastBiologyResult
                    && vm.lastBiologyResult.sampleDate
                    && moment(vm.lastBiologyResult.sampleDate).isAfter(twoWeeksAgo)) {
                    vm.minSampleDate = moment(vm.lastBiologyResult.sampleDate).format('yyyy-MM-DD');
                }
            });

            vm.biologyMedia = [];

            // load draft media
            if(vm.data.biologyResult.mediaIds && vm.data.biologyResult.mediaIds.length) {
                vm.biologyMedia = vm.data.biologyResult.mediaIds.map(id => ({id: id}));
            }

            $scope.$watch('vm.hasRecentBiologyResult', (newValue, oldValue) => {
                if (newValue === 'N') {
                    vm.data.biologyResult.sampleDate = null;
                    vm.data.biologyResult.comments = null;
                    deleteAllBiologyFiles();
                }
            });
        } else {
            delete vm.data.biologyResult;
        }

        // adherence
        if ('adherence' in vm.steps) {
            vm.data.adherenceReport = vm.data.adherenceReport || {};
        } else {
            delete vm.data.adherenceReport;
        }

        // intake record
        if('INTAKE_RECORD' in vm.steps) {
            vm.data.intakeRecord = vm.data.intakeRecord || [];
        } else {
            delete vm.data.intakeRecord;
        }

        // events
        const unsubscribeFinishStep = $rootScope.$on('continuumplatformApp:visit-wizard:finishStep', function (event, details) {
            validateStep(details.step);
        });

        const unsubscribeInvalidateStep = $rootScope.$on('continuumplatformApp:visit-wizard:invalidateStep', function (event, details) {
            invalidateStep(details.step);
        });

        $scope.$on('$destroy', unsubscribeFinishStep);
        $scope.$on('$destroy', unsubscribeInvalidateStep);

        function scrollToTop() {
            $timeout(function () {
                $window.document.body.scrollTop = $window.document.documentElement.scrollTop = 0;
            });
        }

        function scrollToSaved() {
            $timeout(function () {
                $window.scrollTo(0, vm.yOffset);
            });
        }

        function stepTo(to) {
            vm.step = to;

            switch (to) {// TODO a voir si besoin de généraliser à toutes les étapes d'une visite
            case 'INTAKE_RECORD':
                // on invalide le relevé de prise si on revient dessus en modification
                vm.steps[to] = false;
                break;

            case 'symptoms': {
                const medicationsWithPointsOfVigilance = vm.patientTherapy.medications
                    .filter(m => !!m.pointsOfVigilance);
                if (medicationsWithPointsOfVigilance.length) {
                    showPointsOfVigilanceModal(medicationsWithPointsOfVigilance);
                }
                break;
            }
            }

            scrollToTop();
        }

        function showPointsOfVigilanceModal(medications) {
            $uibModal.open({
                templateUrl: 'app/activity/visit/medication-points-of-vigilance-modal.html',
                controller: 'MedicationPointsOfVigilanceModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    medications: () => medications,
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
        }

        function validateIntakeRecord (data) {
            vm.data.intakeRecord = data;
            validateStep('INTAKE_RECORD');
        }

        function validateStep(step) {

            if(vm.isSaving) {
                return;
            }

            if (vm.steps[step] === false) {
                vm.steps[step] = true;
            }

            if (!vm.data.validatedSteps.includes(step)) {
                vm.data.validatedSteps.push(step);
            }

            queueRequest('draft', angular.toJson(vm.data));

            if (!['DATE_OF_NEXT_MEDICAL_CONSULTATION'].includes(step) ) {
                const possibleNextSteps = vm.visit.steps.filter((s) => !vm.data.validatedSteps.includes(s) && s !== 'DATE_OF_NEXT_MEDICAL_CONSULTATION');
                const nextStep = possibleNextSteps.length > 0 ? possibleNextSteps[0] : 'menu';

                vm.stepTo(nextStep);
            }
        }

        function invalidateStep(step) {
            vm.steps[step] = false;
        }

        function finish() {
            for (let step in vm.steps) {
                if (!vm.steps[step]) {
                    alert("Vous devez valider tous les questionnaires pour finaliser la visite.");
                    return;
                }
            }
            vm.isSaving = true;

            if ('PATIENT_PROFILE' in vm.steps) {
                // si l'étape permettant de renseigner / modifier le profile patient se trouve dans la visite
                loadPatient().then(() => {
                    if (hasNewerPatientProfileVersion()) {
                        warnNewerPatientProfileVersion();
                        vm.isSaving = false;
                    } else {
                        doFinish();
                    }
                });
            } else {
                doFinish();
            }
        }

        function hasNewerPatientProfileVersion() {
            return vm.patient.details.version && vm.data.details.PATIENT_PROFILE && vm.patient.details.version > vm.data.details.PATIENT_PROFILE.version;
        }

        function warnNewerPatientProfileVersion() {
            // on invalide le profil médico-social du patient
            vm.steps['PATIENT_PROFILE'] = false;

            $uibModal.open({
                templateUrl: 'app/activity/visit/patient-profile-change-dialog.html',
                controller: 'PatientProfileChangeController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {}
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });

        }

        function doFinish() {
            // on relance le timer à sa valeur par défaut
            IdleTimer.clear();
            IdleTimer.start();

            scrollToTop();

            queueRequest('finish', angular.toJson(vm.data), onSaveSuccess, onSaveError);
        }

        function queueRequest(type, data, success, error) {
            nextRequest = {type, data, success, error};
            execute();
        }

        function execute(force) {

            if (!nextRequest) {
                vm.isExecuting = false;
                return;
            }

            if (vm.isExecuting && !force) {
                return;
            }

            vm.isExecuting = true;

            const request = nextRequest;

            nextRequest = null;

            let fn;
            if (request.type === 'draft') {
                fn = Visit.draft;
            } else if (request.type === 'finish') {
                fn = Visit.finish;
            } else if (request.type === 'abort') {
                fn = Visit.abort;
            } else {
                // should not happen!
                return;
            }

            fn({id: vm.visit.id}, angular.fromJson(request.data), request.success, request.error)
                .$promise.finally(() => execute(true));
        }

        function onSaveSuccess() {
            vm.isSaving = false;
            vm.isSaved = true;
            $rootScope.warnBeforeUnload = false;

            if ('ONCOLINK_COORDINATION' in vm.steps && ['NEXT_THERAPY', 'RENEW_THERAPY'].includes(vm.data.details.ONCOLINK_COORDINATION.nextFollowUpStep)) {
                PatientTherapy.query({
                    'patientId.equals': vm.patient.id,
                    'previousPatientTherapyId.equals': vm.patientTherapy.id,
                    'startDate.specified': false,
                    'sort': ['id,desc']
                }).$promise.then(patientTherapies => {
                    $uibModal.open({
                        templateUrl: 'app/activity/patient-therapy/patient-therapy-start.html',
                        controller: 'PatientTherapyStartController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PatientTherapy', function (PatientTherapy) {
                                return PatientTherapy.get({id: patientTherapies[0].id}).$promise;
                            }],
                        }
                    }).result.then(() => bye(), () => bye());
                });

            } else {
                bye();
            }
        }

        function bye() {
            const isTownPharmacistOrNurse = (vm.isPharmacist || vm.isNurse) && !vm.hospitalPractitioner;

            if (vm.isPatient || isTownPharmacistOrNurse) {
                WorkingDayService.isWorkingHours(vm.visit.patient.healthFacilityId).then((isWorkingHours) => {
                    if(!isWorkingHours) {
                        // affichage d'un message d'information lors que l'utilisateur
                        // (patient, IDEL ou pharmacien d'officine) lorsqu'on est en dehors des heures
                        // ou des jours ouvrés de l'hôpital du patient
                        showModalWarningNotWorkingHours(() => byeNavigate(vm));
                    } else {
                        byeNavigate(vm);
                    }
                });

            } else {
                byeNavigate(vm);
            }
        }

        function byeNavigate(vm) {
            if(!vm.hospitalPractitioner && vm.patientTherapy.day >= 60 && (!vm.visit.adhoc || vm.isPatient) && !isPatientPromoterScore()) {
                vm.step = 'satisfaction';
            } else {
                if ('ONCOLINK_COORDINATION' in vm.steps) {
                    $state.go('patient-detail.therapies', {id:vm.patient.id});
                } else {
                    $state.go('visit-detail', {id: vm.visit.id});
                }
            }
        }

        function isPatientPromoterScore() {
            return Number.isInteger(vm.patientTherapy.patientPromoterScore);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        /**
         * Go back
         *
         * @param visitType Visit type. If specified, go to corresponding state name if the user is on main menu. Else, do nothing
         * @param stayHere Callback to stay here
         * @returns {Promise<boolean>|boolean}
         */
        function back(visitType, stayHere) {
            let scrollTo = 'top';

            if (vm.showContacts) {
                vm.showContacts = false;
            } else if (vm.step === 'menu') {
                return cancel(true, stayHere);
            } else if (vm.currentSideEffect) {
                if (vm.currentSideEffect.showOptions) {
                    vm.currentSideEffect.showOptions = false;
                } else {
                    vm.currentSideEffect = null;
                    scrollTo = 'saved';
                }
            } else if (vm.sideEffectAdding) {
                vm.sideEffectAdding = false;
            } else {
                vm.stepTo('menu');
            }

            if (scrollTo === 'top') {
                scrollToTop();
            } else if (scrollTo === 'saved') {
                scrollToSaved();
            }

            angular.element('#back').blur();
            angular.isFunction(stayHere) && stayHere();

            return false;
        }

        function openDetail() {
            $state.go('visit-detail', {id: vm.visit.id});
        }

        /**
         * Leave visit wizard
         *
         * @param toPreviousState true to send user to previous state or false to do nothing
         * @param stayHere Callback to stay here
         * @returns {Promise<boolean>}
         */
        function cancel(toPreviousState, stayHere) {

            if(vm.isSaving) {
                alert('Enregistrement en cours... veuillez patienter.');
            } else if (confirmCancel()) {
                vm.isSaving = true;
                return new Promise((resolve, reject) => {
                    queueRequest('abort', angular.toJson(vm.data), () => {
                        $rootScope.warnBeforeUnload = false;
                        resolve(true);
                        if (toPreviousState)
                            $state.go(vm.previousState.name, vm.previousState.params).catch(() => {
                                vm.isSaved = true;
                                $rootScope.warnBeforeUnload = false;
                            });
                    }, () => {
                        vm.isSaving = false;
                        reject('Unable to abort');
                    });
                });
            }

            angular.isFunction(stayHere) && stayHere();
            return Promise.resolve(false);
        }

        function confirmCancel() {
            let message;
            if(vm.visit.adhoc && vm.data.validatedSteps.length === 0) {
                message = 'Les données saisies seront perdues.';
            } else {
                message = 'Vous pourrez reprendre plus tard.';
            }
            return confirm('Êtes-vous sûr de vouloir quitter le questionnaire ?\n\n' + message);
        }

        function pushState() {
            history.pushState(null, $window.document.title, location.href);
        }

        function loadSideEffects() {
            const callPromiseTab = [
                // récupération des évènements indésirables associés au traitement du patient
                PatientTherapy.getSideEffects({id: vm.visit.patientTherapyId}).$promise
                    .then(sideEffects => sideEffects.filter(sideEffect =>
                        (sideEffect.category === 'CLINICAL' && 'symptoms' in vm.steps)
                        || (sideEffect.category === 'BIOLOGICAL' && 'biology' in vm.steps)))
            ];

            if ('symptoms' in vm.steps) {
                callPromiseTab.push(
                    // récupération des évènements indésirables déclarés précédemment dans le suivit du patient
                    new Promise((resolve, reject) => {
                        SideEffectReport.query({
                            view: 'base',
                            "patientId.equals": vm.visit.patientId,
                            "sort": ["date,desc"],
                            "sideEffectCategory.equals": 'CLINICAL',
                            "sideEffectType.notEquals": 'OTHER',
                            size: 1000 // TODO all
                        }, (previousSideEffects) => {
                            resolve(previousSideEffects);
                        });
                    })
                );
            }

            Promise.all(callPromiseTab).then(([patientTherapySideEffects, previousSideEffects]) => {
                previousSideEffects = previousSideEffects ? previousSideEffects : [];
                vm.previousSideEffects = $filter('groupBy')(previousSideEffects, 'sideEffectId');

                // on dédoublonne les identifiants des évènements indésirables à récupérer
                const sideEffectIdsToLoad = [...new Set(
                    [
                        ...previousSideEffects.map((previousSideEffects) => previousSideEffects.sideEffectId), // Evènements indésirables remonté précédemment pour ce patient
                        ...patientTherapySideEffects.map((patientTherapySideEffect) => patientTherapySideEffect.id), // Evènements indésirables associés au traitement du patient
                        ...Object.keys(vm.data.sideEffectReports) // Evènements indésirables présents dans le rapport de la visite en cours de réalisation
                    ]
                )];

                return new Promise((resolve, reject) => {
                    SideEffect.getSideEffectsWithScore({'id.in': sideEffectIdsToLoad, sort: "group", size: 1000}, (sideEffects) => resolve(sideEffects));
                });

            }).then((sideEffects) => {
                vm.sideEffects = sideEffects;

                for (const sideEffect of vm.sideEffects) {
                    if (!(sideEffect.id in vm.data.sideEffectReports)) {
                        vm.data.sideEffectReports[sideEffect.id] = {
                            mediaIds: sideEffect.photo ? [] : undefined,
                            date: null,
                            score: null,
                            canBeCausedByTreatmentV2: null,
                            comment: null,
                            updated: false
                        };
                    }
                    vm.data.sideEffectReports[sideEffect.id].commentRequired = sideEffect.type === 'OTHER';
                    vm.data.sideEffectReports[sideEffect.id].required = vm.visit.type.startsWith('STD') && sideEffect.category === 'CLINICAL';

                    const lastSideEffectReport = vm.previousSideEffects[sideEffect.id] ? vm.previousSideEffects[sideEffect.id][0] : null;

                    if (lastSideEffectReport && lastSideEffectReport.score > 0) {
                        if (vm.data.sideEffectReports[sideEffect.id].updated) {
                            vm.data.sideEffectReports[sideEffect.id].previousScore = lastSideEffectReport.score;
                        } else {
                            vm.data.sideEffectReports[sideEffect.id].score = lastSideEffectReport.score;
                            vm.data.sideEffectReports[sideEffect.id].date = lastSideEffectReport.date;
                            vm.data.sideEffectReports[sideEffect.id].canBeCausedByTreatmentV2 = mapCanBeCausedByTreatmentV2(lastSideEffectReport.canBeCausedByTreatment);
                            if (!vm.data.sideEffectReports[sideEffect.id].required) {
                                vm.data.sideEffectReports[sideEffect.id].required = lastSideEffectReport.score > 0;
                            }
                        }
                    }

                }

                if ('symptoms' in vm.steps) {

                    vm.sideEffectReportMedia = {};

                    // load draft media
                    for (const [sideEffectId, sideEffectReport] of Object.entries(vm.data.sideEffectReports)) {
                        if(sideEffectReport.mediaIds && sideEffectReport.mediaIds.length) {
                            vm.sideEffectReportMedia[sideEffectId] = sideEffectReport.mediaIds.map(id => {return {id: id};});
                        }
                    }
                }

                if ('biology' in vm.steps) {
                    // sync date of biological side effect reports with sample date
                    vm.biologicalSideEffectIds = vm.sideEffects
                        .filter(sideEffect => sideEffect.category === 'BIOLOGICAL')
                        .map(sideEffect => sideEffect.id.toString());
                    $scope.$watch('vm.data.biologyResult.sampleDate', (newValue, oldValue) => {
                        if (newValue) {
                            for (const sideEffectId of Object.keys(vm.data.sideEffectReports)) {
                                if (vm.data.sideEffectReports[sideEffectId].date
                                    && vm.biologicalSideEffectIds.includes(sideEffectId)) {
                                    vm.data.sideEffectReports[sideEffectId].date = newValue;
                                }
                            }
                        } else if (oldValue) {
                            for (const sideEffectId of Object.keys(vm.data.sideEffectReports)) {
                                if (vm.data.sideEffectReports[sideEffectId].updated
                                    && vm.biologicalSideEffectIds.includes(sideEffectId)) {
                                    clearSideEffectReport(sideEffectId);
                                }
                            }
                        }
                    });
                }
            });
        }

        function mapCanBeCausedByTreatmentV2(canBeCausedByTreatment) {
            if (canBeCausedByTreatment === true) {
                return 'Y';
            } else if (canBeCausedByTreatment === false) {
                return 'N';
            } else {
                return 'NSP';
            }
        }

        function uploadSideEffectReportFile(sideEffectId, file) {
            if(!file)
                return;

            vm.sideEffectReportMedia[sideEffectId] = vm.sideEffectReportMedia[sideEffectId] || [];
            let index = vm.sideEffectReportMedia[sideEffectId].length;
            vm.sideEffectReportMedia[sideEffectId][index] = {
                file: file,
                id : null
            };
            if (file.type.startsWith('image/')) {
                CompressImage.compress(file).then( (compressImageFile) => {
                    file = compressImageFile;
                    doUploadSideEffectReportFile(sideEffectId, index, file);
                });
            } else {
                doUploadSideEffectReportFile(sideEffectId, index, file);
            }
        }

        function doUploadSideEffectReportFile(sideEffectId, index, file) {
            vm.uploadStatus = 'uploading';
            vm.uploadProgress = 0;
            const upload = Upload.upload({
                url: 'api/media',
                method: 'POST',
                arrayKey: '',
                data: {
                    file: file,
                    patientId: vm.visit.patientId,
                    author: vm.visit.authorId,
                    comment: vm.currentSideEffect.name,
                    draft: true
                }
            });

            upload.then(function (response) {
                vm.uploadStatus = 'success';
                vm.sideEffectReportMedia[sideEffectId][index].id = response.data.id;
                vm.data.sideEffectReports[sideEffectId].mediaIds.push(response.data.id);
            }, function (resp) {
                vm.uploadStatus = 'error';
            }, function (evt) {
                vm.uploadProgress = Math.round(100 * evt.loaded / evt.total);
            });

            upload.catch(function (error) {
                vm.uploadStatus = 'error';
            });
        }

        function deleteSideEffectReportFiles(sideEffectId) {
            if(vm.data.sideEffectReports[sideEffectId].mediaIds) {
                vm.data.sideEffectReports[sideEffectId].mediaIds.forEach(mediaId => {
                    Media.delete({id: mediaId});
                });
            }
            vm.sideEffectReportMedia[sideEffectId] = [];
            vm.data.sideEffectReports[sideEffectId].mediaIds = [];
        }

        function deleteSideEffectReportFile(sideEffectId, index) {
            const mediaId = vm.sideEffectReportMedia[sideEffectId][index].id;
            if(mediaId) {
                Media.delete({id: mediaId});
            }
            vm.sideEffectReportMedia[sideEffectId].splice(index, 1);
            vm.data.sideEffectReports[sideEffectId].mediaIds = vm.data.sideEffectReports[sideEffectId].mediaIds
                .filter(id => id !== mediaId);
        }

        function selectSideEffect(sideEffect) {
            vm.currentSideEffect = sideEffect;
            vm.yOffset = $window.pageYOffset;
            scrollToTop();
        }

        function selectSideEffectScore(sideEffectId, score) {
            score = parseInt(score);

            vm.currentSideEffect.selectedScore = score;

            // show options (comment, photos)
            if (score > 0) {
                vm.currentSideEffect.showOptions = true;
                scrollToSaved();
            } else {
                vm.data.sideEffectReports[sideEffectId].canBeCausedByTreatment = undefined; // clears old version's data
                vm.data.sideEffectReports[sideEffectId].canBeCausedByTreatmentV2 = null;
                vm.data.sideEffectReports[sideEffectId].comment = null;
                deleteSideEffectReportFiles(vm.currentSideEffect.id);
                reportSideEffectScore();
            }

        }

        function clearSideEffectReport(sideEffectId) {
            vm.data.sideEffectReports[sideEffectId].canBeCausedByTreatment = undefined; // clears old version's data
            vm.data.sideEffectReports[sideEffectId].canBeCausedByTreatmentV2 = null;
            vm.data.sideEffectReports[sideEffectId].comment = null;
            vm.data.sideEffectReports[sideEffectId].date = null;
            vm.data.sideEffectReports[sideEffectId].mediaIds = undefined; // it should also remove media, but this function is only used for biology (without media)
            vm.data.sideEffectReports[sideEffectId].score = null;
            vm.data.sideEffectReports[sideEffectId].updated = false;
        }

        function reportSideEffectScore() {
            const sideEffectId = vm.currentSideEffect.id;

            if (!vm.data.sideEffectReports[sideEffectId].updated) {
                vm.data.sideEffectReports[sideEffectId].date = null;
                vm.data.sideEffectReports[sideEffectId].previousScore = vm.data.sideEffectReports[sideEffectId].score;
            }

            if (vm.currentSideEffect.category === 'BIOLOGICAL' && vm.data.biologyResult && vm.data.biologyResult.sampleDate) {
                vm.data.sideEffectReports[sideEffectId].date = vm.data.biologyResult.sampleDate;
            }

            vm.data.sideEffectReports[sideEffectId].score = vm.currentSideEffect.selectedScore;
            vm.data.sideEffectReports[sideEffectId].updated = true;
            // changes on comment and canBeCausedByTreatment are stored even if "back" button is clicked instead of "validate"

            if (vm.sideEffectAdding) {
                vm.sideEffects.push(vm.currentSideEffect);
                vm.sideEffectAdding = false;
            }

            vm.currentSideEffect.showOptions = false;
            vm.currentSideEffect = null;

            scrollToSaved();
        }

        function uploadBiologyFile(file) {
            if (!file)
                return;

            vm.biologyMedia = vm.biologyMedia || [];
            let index = vm.biologyMedia.length;
            vm.biologyMedia[index] = {
                file: file,
                id: null
            };
            if (file.type.startsWith('image/')) {
                CompressImage.compress(file).then((compressImageFile) => {
                    file = compressImageFile;
                    doUploadBiologyFile(index, file);
                });
            } else {
                doUploadBiologyFile(index, file);
            }
        }

        function doUploadBiologyFile(index, file) {
            vm.uploadStatus = 'uploading';
            vm.uploadProgress = 0;
            const upload = Upload.upload({
                url: 'api/media',
                method: 'POST',
                arrayKey: '',
                data: {
                    file: file,
                    patientId: vm.visit.patientId,
                    author: vm.visit.authorId,
                    draft: true
                }
            });

            upload.then(function (response) {
                vm.uploadStatus = 'success';
                vm.biologyMedia[index].id = response.data.id;
                vm.data.biologyResult.mediaIds = vm.data.biologyResult.mediaIds || [];
                vm.data.biologyResult.mediaIds.push(response.data.id);
            }, function (resp) {
                vm.uploadStatus = 'error';
            }, function (evt) {
                vm.uploadProgress = Math.round(100 * evt.loaded / evt.total);
            });

            upload.catch(function (error) {
                vm.uploadStatus = 'error';
            });
        }

        function deleteAllBiologyFiles() {
            if (vm.biologyMedia.length) {
                deleteBiologyFile(0).then(() =>
                    deleteAllBiologyFiles());
            }
        }

        function deleteBiologyFile(index) {
            vm.isDeletingBiologyFile = true;
            const mediaId = vm.biologyMedia[index].id;
            if (mediaId) {
                return Media.delete({id: mediaId}).$promise.then(() => {
                    vm.biologyMedia.splice(index, 1);
                    vm.data.biologyResult.mediaIds = vm.data.biologyResult.mediaIds
                        .filter(id => id !== mediaId);
                }).finally(() => {
                    vm.isDeletingBiologyFile = false;
                });
            }
        }

        function loadContacts() {
            if (vm.patient.healthFacilityId) {
                vm.healthFacility = HealthFacility.get({id: vm.patient.healthFacilityId, view: 'summary'});
            }

            CareTeam.getMembers(vm.patient.id).then(response => {
                vm.team = response.data;
                return vm.careTeamMemberAway = CareTeam.getAwayMembers(vm.team);
            });
        }

        function closeAwayMessage() {
            vm.careTeamMemberAway = [];
        }

        function addSideEffect() {
            scrollToTop();
            vm.sideEffectAdding = true;
            $timeout(() => angular.element('#searchQuery').focus());
            searchSideEffects();
        }

        function searchSideEffects() {
            SideEffect.query({
                'id.notIn': vm.sideEffects.map((s) => s.id),
                'category.equals': 'CLINICAL',
                sort: "group",
                size: 1000 // TODO search
            }, (sideEffectList) => {
                vm.allSideEffects = sideEffectList;
                vm.filteredSideEffects = sideEffectList.filter((s) => s.type !== 'OTHER' && s.name !== 'Autres');
            });
        }

        function filterSideEffects() {

            const normalizedSearchQuery = normalize(vm.sideEffectsSearchCriteria);

            vm.filteredSideEffects = vm.allSideEffects.filter((s) => {
                if(!vm.sideEffectsSearchCriteria) {
                    return true;
                }
                let result = normalize(s.name).includes(normalizedSearchQuery)
                    || normalize(s.nameForPatient).includes(normalizedSearchQuery);
                if(s.description) {
                    result = normalize(s.description).includes(normalizedSearchQuery) || result;
                }
                return result && s.type !== 'OTHER';
            });

            if (vm.filteredSideEffects.length === 0) {
                vm.filteredSideEffects = vm.allSideEffects.filter((s) => s.type === 'OTHER');
            }
        }

        function normalize(str) {
            return str
                .normalize('NFKD')
                .replace(/\p{Diacritic}/gu, "")
                .replace(/[^\p{Alpha}]/gu, "")
                .toUpperCase();
        }

        function onSelectAdditionnalSideEffect(sideEffect) {
            SideEffect.get({id: sideEffect.id}, (result) => {
                if (!(sideEffect.id in vm.data.sideEffectReports)) {
                    vm.data.sideEffectReports[sideEffect.id] = {
                        mediaIds: sideEffect.photo ? [] : undefined,
                        date: null,
                        score: null,
                        comment: null,
                        updated: false
                    };
                }
                vm.selectSideEffect(result);
            });
        }

        function showModalWarningNotWorkingHours(onClose) {

            $uibModal.open({
                templateUrl: 'app/activity/visit/not-working-warning-dialog.html',
                controller: 'NotWorkingWarningDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {}
            }).result.then(
                onClose, // on success
                onClose // on error
            );
        }

    }
})();
