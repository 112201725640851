(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('patientTreatmentPhaseBlock', patientTreatmentPhaseBlock);

    function patientTreatmentPhaseBlock() {

        return {
            restrict: 'E',
            scope: {
                phase: '=',
                prescription: '='
            },
            templateUrl: 'app/activity/patient-treatment-phase/patient-treatment-phase-block.html',
            link: link
        };

        function link(scope) {

            let maxDays;

            switch (scope.phase.cycle) {
            case 'CYCLE_7':
                maxDays = Math.min(scope.phase.durationDays || 7, 7);
                break;
            case 'CYCLE_14':
                maxDays = Math.min(scope.phase.durationDays || 14, 14);
                break;
            case 'CYCLE_21':
                maxDays = Math.min(scope.phase.durationDays || 21, 21);
                break;
            case 'CYCLE_28':
                maxDays = Math.min(scope.phase.durationDays || 28, 28);
                break;
            default :
                maxDays = scope.phase.durationDays;
            }

            scope.days = [];
            for (let d = 1; d <= maxDays; d++) {
                scope.days.push(d);
            }
        }


    }

})();
