(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MedicationPointsOfVigilanceModalController', MedicationPointsOfVigilanceModalController);

    MedicationPointsOfVigilanceModalController.$inject = ['$uibModalInstance', 'medications'];

    function MedicationPointsOfVigilanceModalController($uibModalInstance, medications) {
        const vm = this;

        vm.medications = medications;

        vm.close = close;

        function close() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
