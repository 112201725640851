(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PrescriptionImportController', PrescriptionImportController);

    PrescriptionImportController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'notify', 'Prescription', 'Patient', 'PatientTherapy', 'Upload', 'CompressImage', 'CareTeam'];

    function PrescriptionImportController($timeout, $scope, $uibModalInstance, entity, notify, Prescription, Patient, PatientTherapy, Upload, CompressImage, CareTeam) {
        const vm = this;

        vm.clear = clear;
        vm.save = save;

        vm.prescription = entity;
        vm.notify = notify;

        if (!vm.prescription.patientId)
            throw new Error('Missing patient id');

        Patient.get({id: vm.prescription.patientId}, patient =>
            vm.patient = patient);

        loadPractitioners();

        // init patient therapies
        if (!vm.prescription.patientTherapyId) {
            loadPatientTherapies();
        } else {
            vm.patientTherapies = [PatientTherapy.get({id: vm.prescription.patientTherapyId})];
        }

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.file.type.startsWith('image/')) {
                CompressImage.compress(vm.file).then((compressImageFile) => {
                    vm.file = compressImageFile;
                    uploadFile(compressImageFile);
                });
            } else {
                uploadFile(vm.file);
            }
        }

        function uploadFile(file) {
            Upload.upload({
                url: 'api/prescriptions',
                method: 'POST',
                arrayKey: '',
                data: {
                    date: moment(vm.prescription.date).format('YYYY-MM-DD'),
                    patientId: vm.prescription.patientId,
                    patientTherapyId: vm.prescription.patientTherapyId || undefined,
                    prescriberId: vm.prescription.prescriberId || undefined,
                    comment: vm.prescription.comment || undefined,
                    file,
                    notify
                }
            }).then(
                onFinish,
                () => vm.isSaving = false,
                evt => vm.uploadProgress = Math.round(100 * evt.loaded / evt.total));
        }

        function onFinish(response) {
            const id = response.data;
            $scope.$emit('continuumplatformApp:prescriptionUpdate', {id});
            $uibModalInstance.close({id});
            vm.isSaving = false;
        }

        function loadPractitioners() {
            CareTeam.getMembers(vm.prescription.patientId).then(response => {
                vm.prescribers = response.data
                    .filter(filterPrescribers)
                    .map(m => m.entity);
            });
        }

        // médecins ou IPA
        function filterPrescribers(member) {
            return member.entity.job === 'J10'
                || (member.entity.job === 'J60' && member.entity.speciality !== null);
        }

        function loadPatientTherapies() {
            PatientTherapy.query({
                "patientId.equals": vm.prescription.patientId,
                "status.in": ["S20_ACTIVE", "S30_PAUSED"]
            }, function (result) {
                vm.patientTherapies = result;
                if (result.length === 1)
                    vm.prescription.patientTherapyId = result[0].id;
            });
        }
    }
})();
