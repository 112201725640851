(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PersistentTokenDeleteController', PersistentTokenDeleteController);

    PersistentTokenDeleteController.$inject = ['$uibModalInstance', 'entity', 'PersistentToken'];

    function PersistentTokenDeleteController($uibModalInstance, entity, PersistentToken) {
        var vm = this;

        vm.persistentToken = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            PersistentToken.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
