(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('SideEffectReport', SideEffectReport);

    SideEffectReport.$inject = ['$resource', '$filter', '$http', '$sce', 'DateUtils', 'Blob'];

    function SideEffectReport($resource, $filter, $http, $sce, DateUtils, Blob) {
        var resourceUrl = 'api/side-effect-reports/:id';

        var service = $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                        data.date.setSeconds(0);
                        data.date.setMilliseconds(0);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'updatePvCenterStatus': {
                url: 'api/side-effect-reports/:id/pvCenterStatus',
                method: 'PUT',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'downloadQuery': {
                url: 'api/side-effect-reports/export',
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
                transformResponse:
                    function (data, headers) {
                        return {
                            data: new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
                            headers: headers
                        };
                    }
            }
        });

        service.countPending = function () {
            return $http.get('api/side-effect-reports/count', {
                params: {
                    "pvCenterStatus.equals": "PENDING"
                }
            });
        };

        service.buildMatrix = function (data, pictures, showPatientLabels) {

            var matrix = {
                dates: [],
                years: {},
                months: {},
                content: []
            };

            //$filter('unique')($filter('map')(data, 'date')).sort(function (a, b) {
            //    console.log(a, b);
            //    return b.getTime() - a.getTime();
            //});

            var pictureMap = $filter('groupBy')(pictures, 'sideEffectReportId');

            var date;
            var month;
            var year;
            var mt;
            angular.forEach(data, function (v, k) {
                mt = moment(v.date);
                month = mt.format('YYYY-MM');
                year = mt.format('YYYY');
                date = mt.format('YYYY-MM-DD');
                if (matrix.dates.indexOf(date) === -1) {
                    matrix.dates.push(date);
                    matrix.months[month] = (matrix.months[month] || 0) + 1;
                    matrix.years[' ' + year] = (matrix.years[' ' + year] || 0) + 1;
                }
            });

            var reports = $filter('groupBy')(data, showPatientLabels ? 'sideEffectNameForPatient' : 'sideEffectName');
            for (var sideEffectName in reports) {

                var reportsByDay = {};
                var k;
                var currentReports = reports[sideEffectName];
                for (var i in currentReports) {
                    k = moment(currentReports[i].date).format('YYYY-MM-DD');
                    if (!reportsByDay[k] || currentReports[i].score > reportsByDay[k].score) {
                        reportsByDay[k] = currentReports[i];
                        reportsByDay[k].pictures = pictureMap[currentReports[i].id];
                        reportsByDay[k].htmlTooltip = sideEffectTooltip(reportsByDay[k]);
                    }
                }

                matrix.content.push({
                    sideEffectName: sideEffectName,
                    reports: reportsByDay,
                    score: $filter('max')(currentReports, 'score').score
                });
            }

            return matrix;
        };

        function sideEffectTooltip(d) {
            if (d) {
                var str = "";

                if (d.comment)
                    str += '<div>' + d.comment + '</div>';

                if (d.pictures && d.pictures.length)
                    str += '<div>' + d.pictures.length + ' photo' + (d.pictures.length > 1 ? 's' : '') + '</div>';

                str += '<div>';
                str += '<small>Signalé par '
                    + (d.authorId ? 'P.S.' : 'le patient')
                    + ' le '
                    + $filter('date')(d.createdDate, 'shortDate')
                    + '</small>';
                str += '</div>';

                return $sce.trustAsHtml(str);
            }
        }

        return service;
    }
})();
