(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PractitionerDeleteController', PractitionerDeleteController);

    PractitionerDeleteController.$inject = ['$uibModalInstance', 'entity', 'Practitioner'];

    function PractitionerDeleteController($uibModalInstance, entity, Practitioner) {
        var vm = this;

        vm.practitioner = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            Practitioner.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
