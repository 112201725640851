(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MediaUploadController', MediaUploadController);

    MediaUploadController.$inject = ['$timeout', '$scope', '$stateParams', '$document', '$uibModalInstance', 'entity', 'Media', 'Practitioner', 'Principal', 'Upload', 'CompressImage'];

    function MediaUploadController($timeout, $scope, $stateParams, $document, $uibModalInstance, entity, Media, Practitioner, Principal, Upload, CompressImage) {
        var vm = this;

        vm.media = entity;
        vm.clear = clear;
        vm.save = save;

        Principal.identity().then(function (account) {
            if (!vm.media.id && account.authorities.indexOf('ROLE_ADMIN') !== -1 && account.practitioner) {
                vm.media.authorId = account.practitioner.id;

                vm.practitioners = Practitioner.query({
                    size: 1000, // TODO search
                    sort: "lastName,asc"
                });
            }
        });

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            if (vm.isSaving)
                return;

            vm.isSaving = true;

            if (vm.media.file.type.startsWith('image/')) {
                CompressImage.compress(vm.media.file).then( (compressImageFile) => {
                    vm.media.file = compressImageFile;
                    uploadFile();
                });
            } else {
                uploadFile();
            }

        }

        function uploadFile() {
            Upload.upload({
                url: 'api/media',
                method: 'POST',
                arrayKey: '',
                data: vm.media
            }).then(function (resp) {
                console.log('Upload success', resp);
                onFinish(resp.data);
            }, function (resp) {
                console.log('Error status: ' + resp.status);
                vm.isSaving = false;
            }, function (evt) {
                vm.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
            });
        }

        function onFinish(result) {
            $scope.$emit('continuumplatformApp:mediaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }
    }
})();
