(function () {
    'use strict';

    const adherenceResult = {
        bindings: {
            score: '=',
            date: '='
        },
        controller: AdherenceResultController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/adherence-report/adherence-result.html'
    };

    angular
        .module('continuumplatformApp')
        .component("adherenceResult", adherenceResult);

    AdherenceResultController.$inject = [];

    function AdherenceResultController() {
        const vm = this;

        vm.$onInit = () => {};
    }
})();
