(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTeamController', PatientTeamController);

    PatientTeamController.$inject = ['$translate', '$window', '$rootScope', '$scope', 'entity', 'Patient'];

    function PatientTeamController($translate, $window, $rootScope, $scope, entity, Patient) {
        var vm = this;

        vm.patient = entity;

        $translate('continuumplatformApp.patient.team.title', {initials: entity.initials}).then(title => {
            $window.document.title = title;
        });

        var unsubscribe = $rootScope.$on('continuumplatformApp:patientTeamUpdate', () =>
            Patient.get({id: vm.patient.id}, patient =>
                vm.patient = patient));

        $scope.$on('$destroy', event => {
            unsubscribe(event);
        });

    }
})();
