(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('reports', {
                abstract: true,
                parent: 'app'
            })
            .state('side-effect-report-view', {
                parent: 'reports',
                url: '/side-effect-report-view',
                data: {
                    authorities: ['ROLE_REPORT'],
                    pageTitle: 'global.menu.reports.sideEffectReport'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/reports/side-effect-reports.html',
                        controller: 'SideEffectReportController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'therapy,desc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('healthFacility');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('therapy');
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('medication');
                        return $translate.refresh();
                    }]
                }
            })
            .state('patient-therapy-report', {
                parent: 'reports',
                url: '/patient-therapy-report',
                data: {
                    authorities: ['ROLE_REPORT'],
                    pageTitle: 'global.menu.reports.patientTherapyReport'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/reports/patient-therapy-reports.html',
                        controller: 'PatientTherapyReportController',
                        controllerAs: 'vm'
                    }
                },
                params: {},
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('healthFacility');
                        $translatePartialLoader.addPart('therapy');
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('practitioner');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
