(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .filter('messageAuthorName', translateMessageAuthorName);

    translateMessageAuthorName.$inject = ['$filter', 'Principal'];

    function translateMessageAuthorName($filter, Principal) {

        var currentUser = {};
        Principal.identity().then(function (account) {
            currentUser = account;
        });

        return formatAuthorName;

        function formatAuthorName(message) {
            if ((currentUser.patient && !message.author && currentUser.patient.id === message.patientId)
                || (currentUser.practitioner && message.author && currentUser.practitioner.id === message.author.id)) {
                return 'Moi';
            }

            if (message.authorPatient) {
                return 'Patient';
            }

            if (message.author) {
                return $filter('translatePractitionerName')(message.author, 'TITLE_FULLNAME_JOB_SPECIALITY');
            }

            return 'Continuum+';
        }

    }

})();
