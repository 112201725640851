(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('EntityAuditController', EntityAuditController);

    EntityAuditController.$inject = ['$scope', '$filter', '$uibModal', 'EntityAuditService', 'AlertService', 'ObjectDiff'];

    function EntityAuditController ($scope, $filter, $uibModal, EntityAuditService, AlertService, ObjectDiff) {
        var vm = this;

        vm.entities = [];
        vm.audits = [];
        vm.limits = [25, 50, 100, 200, 1000];
        vm.limit = 25;
        vm.loading = false;
        vm.loadChanges = loadChanges;
        vm.findAllAudited = findAllAudited;
        vm.getEntityName = getEntityName;
        vm.format = format;
        vm.openChange = openChange;

        vm.findAllAudited();

        loadChanges();

        function findAllAudited() {
            EntityAuditService.findAllAudited().then(function (data) {
                vm.entities = data;
            });
        }

        function loadChanges() {
            vm.loading = true;
            var entityType = vm.qualifiedName;
            EntityAuditService.findByEntity(entityType, vm.limit).then(function (data) {
                vm.audits = data.map(function(it){
                    it.entityValue = JSON.parse(it.entityValue);
                    return it;
                });
                vm.loading = false;
            }, function(){
                vm.loading = false;
            });
        }

        function getEntityName(qualifiedName) {
            if (qualifiedName) {
                var splits = qualifiedName.split(".");
                return splits[splits.length - 1];
            }
            else return null;
        }

        function format(val){
            if(val)
                return ObjectDiff.objToJsonView(val);
            else return '';
        }

        function openChange(audit){

            if(audit.commitVersion < 2){
                AlertService.warning("entityAudit.result.firstAuditEntry");

            } else {
                EntityAuditService.getPrevVersion(audit.entityType, audit.entityId, audit.commitVersion).then(function (data) {
                    var previousVersion = JSON.parse(data.entityValue),
                        currentVersion = audit.entityValue;

                    var diff = ObjectDiff.diffOwnProperties(previousVersion, currentVersion);

                    $uibModal.open({
                        templateUrl: 'app/security/entity-audit/entity-audit.detail.html',
                        controller: 'AuditDetailModalCtrl',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            diff: function () {
                                return diff;
                            },
                            audit: function () {
                                return audit;
                            }
                        }
                    });
                });
            }
        }

    }
})();
