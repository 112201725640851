(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MediaDetailController', MediaDetailController);

    MediaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$sce', '$uibModal', '$state', 'entity', 'previousState', 'Media', 'Principal', 'User', 'Patient', 'Lightbox'];

    function MediaDetailController($scope, $rootScope, $stateParams, $sce, $uibModal, $state, entity, previousState, Media, Principal, User, Patient, Lightbox) {
        var vm = this;

        vm.openImage = openImage;
        vm.deleteMedia = deleteMedia;

        vm.media = entity;

        vm.previousState = previousState;

        Patient.get({id: vm.media.patientId}).$promise.then(patient => vm.patient = patient);

        vm.trustedInlineUrl = $sce.trustAsResourceUrl('/api/media/' + vm.media.id + '/download?disposition=inline');

        var unsubscribe = $rootScope.$on('continuumplatformApp:mediaUpdate', function (event, result) {
            vm.media = result;
        });
        $scope.$on('$destroy', unsubscribe);

        Principal.identity().then(function (account) {
            vm.account = account;
            if(!vm.previousState.name) {
                if (account.patient) {
                    vm.previousState = {
                        name: 'my-media'
                    };
                } else {
                    vm.previousState = {
                        name: account.practitioner && ['J21', 'J38'].includes(account.practitioner.job) ? 'patient-detail.pharmaceutics' : 'patient-detail.follow-up',
                        params: {id: vm.media.patientId}
                    };
                }
            }
        });

        function openImage(media) {
            Lightbox.openModal([{
                caption: media.text,
                url: '/api/media/' + media.id + '/download?disposition=inline'
            }], 0);
        }

        function deleteMedia() {
            $uibModal.open({
                templateUrl: 'app/activity/media/media-delete-dialog.html',
                controller: 'MediaDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function () {
                        return vm.media;
                    }]
                }
            }).result.then(() => {
                $state.go(vm.previousState.name, vm.previousState.params, {reload: true});
            }, () => {
                // do nothing
            });
        }

    }
})();
