(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Avatar', Avatar);

    Avatar.$inject = [];

    function Avatar() {

        var AVATAR_BASE_PATH = 'content/fixed/';

        var service = {
            getPractitionerAvatar: getPractitionerAvatar,
            getPatientAvatar: getPatientAvatar,
            getUserAvatar: getUserAvatar,
            getDefaultAvatar: getDefaultAvatar
        };

        return service;

        function getPractitionerAvatar(practitioner) {
            // TODO do not use id :D
            if (practitioner.healthFacilityId === 38532) {
                return AVATAR_BASE_PATH + 'avatar-continuum.png';
            }
            switch (practitioner.job) {
            case 'J10':
                return AVATAR_BASE_PATH + 'avatar-doctor.png';
            case 'J60':
                return AVATAR_BASE_PATH + 'avatar-nurse.png';
            case 'J21':
            case 'J38':
                return AVATAR_BASE_PATH + 'avatar-pharmacist.png';
            default:
                return getDefaultAvatar();
            }
        }

        function getPatientAvatar(patient) {
            return getDefaultAvatar();
        }

        function getUserAvatar(user) {
            if (user.practitioner) {
                return getPractitionerAvatar(user.practitioner);
            }
            if (user.patient) {
                return getPatientAvatar(user.patient);
            }
            return getDefaultAvatar();
        }

        function getDefaultAvatar() {
            return AVATAR_BASE_PATH + 'avatar-default.jpg';
        }

    }

})();
