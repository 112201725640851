(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SideEffectReportController', SideEffectReportController);

    SideEffectReportController.$inject = ['$state', '$filter', 'SideEffectReportView', 'ParseLinks', 'AlertService', 'Principal'];

    function SideEffectReportController($state, $filter, SideEffectReportView, ParseLinks, AlertService, Principal) {

        const vm = this;

        const colors = [
            '#f39c12',
            '#ff7701',
            '#ff0000',
            '#000000'];

        vm.loadAll = loadAll;

        Principal.hasAuthority('REPORT_GLOBAL').then(function (hasAuthority) {
            vm.hasGlobalReport = hasAuthority;

            loadAll();
        });

        vm.medicationId = null;
        vm.therapyId = null;
        vm.healthFacilityId = null;
        vm.diseaseId = null;

        function loadAll() {

            if (vm.hasGlobalReport)
                SideEffectReportView.queryDistinctFacilities({
                    "sort": "healthFacility.name,asc",
                    "diseaseId.specified": vm.diseaseId === null ? false : undefined,
                    "diseaseId.equals": vm.diseaseId === null ? undefined : vm.diseaseId,
                    "therapyId.specified": vm.therapyId === null ? false : undefined,
                    "therapyId.equals": vm.therapyId === null ? undefined : vm.therapyId,
                    "medicationId.specified": vm.medicationId === null ? false : undefined,
                    "medicationId.equals": vm.medicationId === null ? undefined : vm.medicationId
                }, function (healthFacilities) {
                    vm.healthfacilities = healthFacilities;
                });

            SideEffectReportView.queryDistinctDiseases({
                "sort": "disease.name",
                "therapyId.specified": vm.therapyId === null ? false : undefined,
                "therapyId.equals": vm.therapyId === null ? undefined : vm.therapyId,
                "healthFacilityId.specified": vm.hasGlobalReport && vm.healthFacilityId === null ? false : undefined,
                "healthFacilityId.equals": vm.hasGlobalReport && vm.healthFacilityId === null ? undefined : vm.healthFacilityId,
                "medicationId.specified": vm.medicationId === null ? false : undefined,
                "medicationId.equals": vm.medicationId === null ? undefined : vm.medicationId
            }, function (therapies) {
                vm.diseases = therapies;
            });

            SideEffectReportView.queryDistinctTherapies({
                "sort": "therapy.name",
                "diseaseId.specified": vm.diseaseId === null ? false : undefined,
                "diseaseId.equals": vm.diseaseId === null ? undefined : vm.diseaseId,
                "healthFacilityId.specified": vm.hasGlobalReport && vm.healthFacilityId === null ? false : undefined,
                "healthFacilityId.equals": vm.hasGlobalReport && vm.healthFacilityId === null ? undefined : vm.healthFacilityId,
                "medicationId.specified": vm.medicationId === null ? false : undefined,
                "medicationId.equals": vm.medicationId === null ? undefined : vm.medicationId
            }, function (therapies) {
                vm.therapies = therapies;
            });

            SideEffectReportView.queryDistinctMedications({
                sort: ["medication.name"],
                "diseaseId.specified": vm.diseaseId === null ? false : undefined,
                "diseaseId.equals": vm.diseaseId === null ? undefined : vm.diseaseId,
                "healthFacilityId.specified": vm.hasGlobalReport && vm.healthFacilityId === null ? false : undefined,
                "healthFacilityId.equals": vm.hasGlobalReport && vm.healthFacilityId === null ? undefined : vm.healthFacilityId,
                "therapyId.specified": vm.therapyId === null ? false : undefined,
                "therapyId.equals": vm.therapyId === null ? undefined : vm.therapyId
            }, function (medications) {
                vm.medications = medications;
            });

            SideEffectReportView.query({
                sort: ["countTotal,desc"],
                "indicator.equals": "count_distinct",
                "diseaseId.specified": vm.diseaseId === null ? false : undefined,
                "diseaseId.equals": vm.diseaseId === null ? undefined : vm.diseaseId,
                "healthFacilityId.specified": vm.hasGlobalReport && vm.healthFacilityId === null ? false : undefined,
                "healthFacilityId.equals": vm.hasGlobalReport && vm.healthFacilityId === null ? undefined : vm.healthFacilityId,
                "therapyId.specified": vm.therapyId === null ? false : undefined,
                "therapyId.equals": vm.therapyId === null ? undefined : vm.therapyId,
                "medicationId.specified": vm.medicationId === null ? false : undefined,
                "medicationId.equals": vm.medicationId === null ? undefined : vm.medicationId
            }, drawTypeScoreBarChart, onError);

            SideEffectReportView.query({
                sort: ["score"],
                "indicator.equals": "count_distinct",
                "diseaseId.specified": vm.diseaseId === null ? false : undefined,
                "diseaseId.equals": vm.diseaseId === null ? undefined : vm.diseaseId,
                "healthFacilityId.specified": vm.hasGlobalReport && vm.healthFacilityId === null ? false : undefined,
                "healthFacilityId.equals": vm.hasGlobalReport && vm.healthFacilityId === null ? undefined : vm.healthFacilityId,
                "sideEffectId.specified": false,
                "score.specified": true,
                "therapyId.specified": vm.therapyId === null ? false : undefined,
                "therapyId.equals": vm.therapyId === null ? undefined : vm.therapyId,
                "medicationId.specified": vm.medicationId === null ? false : undefined,
                "medicationId.equals": vm.medicationId === null ? undefined : vm.medicationId
            }, drawScoreDoughnut, onError);

            SideEffectReportView.query({
                sort: ["countTotal,desc"],
                "indicator.equals": "count_distinct",
                "diseaseId.specified": vm.diseaseId === null ? false : undefined,
                "diseaseId.equals": vm.diseaseId === null ? undefined : vm.diseaseId,
                "healthFacilityId.specified": vm.hasGlobalReport && vm.healthFacilityId === null ? false : undefined,
                "healthFacilityId.equals": vm.hasGlobalReport && vm.healthFacilityId === null ? undefined : vm.healthFacilityId,
                "sideEffectId.specified": true,
                "score.specified": false,
                "therapyId.specified": vm.therapyId === null ? false : undefined,
                "therapyId.equals": vm.therapyId === null ? undefined : vm.therapyId,
                "medicationId.specified": vm.medicationId === null ? false : undefined,
                "medicationId.equals": vm.medicationId === null ? undefined : vm.medicationId
            }, drawTypeDoughnut, onError);

            SideEffectReportView.query({
                "indicator.equals": "duration",
                "diseaseId.specified": vm.diseaseId === null ? false : undefined,
                "diseaseId.equals": vm.diseaseId === null ? undefined : vm.diseaseId,
                "healthFacilityId.specified": vm.hasGlobalReport && vm.healthFacilityId === null ? false : undefined,
                "healthFacilityId.equals": vm.hasGlobalReport && vm.healthFacilityId === null ? undefined : vm.healthFacilityId,
                "sideEffectId.specified": true,
                "score.specified": false,
                "therapyId.specified": vm.therapyId === null ? false : undefined,
                "therapyId.equals": vm.therapyId === null ? undefined : vm.therapyId,
                "medicationId.specified": vm.medicationId === null ? false : undefined,
                "medicationId.equals": vm.medicationId === null ? undefined : vm.medicationId
            }, drawDurationCharts, onError);

        }

        function drawTypeScoreBarChart(data, headers) {
            vm.reportLines = data;

            const labels = [];
            const chartData = [];

            angular.forEach(vm.reportLines, function (value, key) {
                if (!value.sideEffectName) return;

                if (labels.indexOf(value.sideEffectName) === -1) {
                    labels.push(value.sideEffectName);
                }

                if (value.score) {
                    (chartData[value.score - 1] = chartData[value.score - 1] || [])[labels.indexOf(value.sideEffectName)] = value.countTotal;
                }
            });

            // TODO NaN in tooltip for empty values
            vm.typeScoreBarChart = {
                labels: labels,
                series: [1, 2, 3, 4],
                data: chartData,
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: true
                    },
                    scales: {
                        xAxes: [{
                            type: 'category',
                            stacked: true,
                            display: true,
                            scaleLabel: {
                                display: false
                            },
                            ticks: {
                                autoSkip: false
                            }
                        }],
                        yAxes: [{
                            stacked: true,
                            display: true,
                            scaleLabel: {
                                display: false
                            },
                            ticks: {
                                autoSkip: false,
                                precision: 0
                            }
                        }]
                    }
                },
                colors: colors,
                datasetOverride: [{borderWidth: 1}, {borderWidth: 1}, {borderWidth: 1}, {borderWidth: 1}]
            };

        }

        function drawScoreDoughnut(data, headers) {

            vm.totalCount = data.reduce(function (tot, line) {
                tot += line.countTotal; // TODO include others
                return tot;
            }, 0);

            vm.scoreCounts = data.reduce(function (arr, v) {
                v.ratio = v.countTotal / vm.totalCount;
                arr.push(v);
                return arr;
            }, []);

            vm.scoreDoughnut = {
                labels: data
                    .reduce(function (arr, line) {
                        arr.push(
                            line['score']
                        );
                        return arr;
                    }, []),
                data: data
                    .reduce(function (arr, line) {
                        arr.push(
                            line['countTotal']
                        );
                        return arr;
                    }, []),
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: true,
                        position: 'left'
                    }
                },
                colors: colors
            };

        }

        function drawTypeDoughnut(data, headers) {

            vm.typeDoughnut = {
                labels: data
                    .reduce(function (arr, line) {
                        arr.push(
                            line['sideEffectName']
                        );
                        return arr;
                    }, []),
                data: data
                    .reduce(function (arr, line) {
                        arr.push(
                            line['countTotal']
                        );
                        return arr;
                    }, []),
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: true,
                        position: 'left'
                    }
                }
            };
        }

        function drawDurationCharts(data, headers) {

            vm.durations = $filter('omit')(
                $filter('orderBy')(data, 'avgDuration', true),
                function (a) {
                    return !a.avgDuration;
                });

            vm.resolutionTimeChart = {
                labels: vm.durations.reduce(function (arr, line) {
                    arr.push(line['sideEffectName']);
                    return arr;
                }, []),
                series: ["Min", "Avg", "Max"],
                data: [
                    vm.durations.reduce(function (arr, line) {
                        arr.push(line['minDuration']);
                        return arr;
                    }, []),
                    vm.durations.reduce(function (arr, line) {
                        arr.push(line['avgDuration']);
                        return arr;
                    }, []),
                    vm.durations.reduce(function (arr, line) {
                        arr.push(line['maxDuration']);
                        return arr;
                    }, [])
                ],
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            type: 'category',
                            stacked: true,
                            display: true,
                            scaleLabel: {
                                display: false
                            },
                            ticks: {
                                autoSkip: false
                            }
                        }]
                    }
                },
                colors: [
                    '#dddddd',
                    '#0000ff',
                    '#ff0000'
                ],
                datasetOverride: [
                    {borderWidth: 0, backgroundColor: 'rgba(250, 250, 250, 1)'},
                    {borderWidth: 0},
                    {borderWidth: 0}
                ]
            };

            vm.d = $filter('omit')(
                $filter('orderBy')(data, 'avgD', true),
                function (a) {
                    return !a.avgD;
                });

            vm.delayChart = {
                labels: vm.d.reduce(function (arr, line) {
                    arr.push(line['sideEffectName']);
                    return arr;
                }, []),
                series: ["Min", "Avg", "Max"],
                data: [
                    vm.d.reduce(function (arr, line) {
                        arr.push(line['minD']);
                        return arr;
                    }, []),
                    vm.d.reduce(function (arr, line) {
                        arr.push(line['avgD']);
                        return arr;
                    }, []),
                    vm.d.reduce(function (arr, line) {
                        arr.push(line['maxD']);
                        return arr;
                    }, [])
                ],
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            type: 'category',
                            stacked: true,
                            display: true,
                            scaleLabel: {
                                display: false
                            },
                            ticks: {
                                autoSkip: false
                            }
                        }]
                    }
                },
                colors: [
                    '#dddddd',
                    '#0000ff',
                    '#ff0000'
                ],
                datasetOverride: [
                    {borderWidth: 0, backgroundColor: 'rgba(250, 250, 250, 1)'},
                    {borderWidth: 0},
                    {borderWidth: 0}
                ]
            };

        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

    }
})();
