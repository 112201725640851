(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Liquibase', Liquibase);

    Liquibase.$inject = ['$rootScope', '$http'];

    function Liquibase($rootScope, $http) {

        return {getRanChangeSets};

        function getRanChangeSets() {
            return $http.get('management/liquibase').then(response => {
                console.log(response.data.contexts.continuumplatform.liquibaseBeans.liquibase.changeSets);
                return response.data.contexts.continuumplatform.liquibaseBeans.liquibase.changeSets;
            });
        }

    }

})();
