(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SideEffectDeleteController', SideEffectDeleteController);

    SideEffectDeleteController.$inject = ['$uibModalInstance', 'entity', 'SideEffect'];

    function SideEffectDeleteController($uibModalInstance, entity, SideEffect) {
        var vm = this;

        vm.sideEffect = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            SideEffect.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
