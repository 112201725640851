(function () {
    'use strict';

    var pharmaItw1Step1 = {
        bindings: {
            data: '=',
            questions: '<',
            programCode: '='
        },
        controller: pharmaItw1Step1Controller,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/pharma-itw-1-step1.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmaItw1Step1', pharmaItw1Step1);

    pharmaItw1Step1Controller.$inject = ['$rootScope'];

    function pharmaItw1Step1Controller($rootScope) {

        var vm = this;

        vm.step = 'PHARMA_ITW_1_STEP1';

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

    }

})();
