(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .filter('patientName', PatientName);

    PatientName.$inject = [];

    function PatientName() {

        return function (patient) {

            if (!patient)
                return undefined;

            let fullName = patient.birthName + ' ';

            if(patient.lastName) {
                fullName += patient.lastName + ' ';
            }

            return fullName + patient.firstName;

        };

    }

})();
