(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('my-follow-up', {
                parent: 'patients',
                url: '/my-follow-up',
                data: {
                    authorities: ['ROLE_PATIENT'],
                    pageTitle: 'global.menu.patient-follow-up'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/my-follow-up/my-follow-up.html',
                        controller: 'MyFollowUpController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('sideEffectReport');
                        $translatePartialLoader.addPart('measure');
                        $translatePartialLoader.addPart('biologyResult');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name.replace('pain-history', '') || 'home',
                            params: $transition$.params('from')
                        };
                    }]
                }
            })
            .state('pain-history', {
                parent: 'patients',
                url: '/pain-history?page&sort',
                data: {
                    authorities: ['ROLE_PATIENT'],
                    pageTitle: 'continuumplatformApp.pain.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/measure/pain-history.html',
                        controller: 'PainHistoryController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true,
                        inherit: false
                    },
                    sort: {
                        value: 'date,desc',
                        squash: true,
                        inherit: false
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('measure');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        return $translate.refresh();
                    }],
                    patient: ['Principal', function (Principal) {
                        return Principal.identity().then(function (account) {
                            return account.patient;
                        });
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name || 'my-follow-up',
                            params: $transition$.from().name ? $transition$.params('from') : {}
                        };
                    }]
                }
            })
            .state('aoh-crisis-history', {
                parent: 'patients',
                url: '/aoh-crisis-history/{id}',
                data: {
                    pageTitle: 'continuumplatformApp.aoh-crisis-history.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient/aoh-crisis-evaluation-history.html',
                        controller: 'AohCrisisEvaluationHistoryController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true,
                        inherit: false
                    },
                    sort: {
                        value: 'date,desc',
                        squash: true,
                        inherit: false
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('patient');
                        return $translate.refresh();
                    }],
                    patient: ['$stateParams', 'Patient', function ($stateParams, Patient) {
                        return Patient.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name || 'patient-detail.follow-up',
                            params: $transition$.from().name ? $transition$.params('from') : {}
                        };
                    }]
                }
            })
        ;
    }

})();
