(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('program', {
                parent: 'master',
                url: '/program',
                data: {
                    authorities: ['ROLE_EDITOR'],
                    pageTitle: 'continuumplatformApp.program.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/program/programs.html',
                        controller: 'ProgramController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('program');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('program.new', {
                url: '/new',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/program/program-dialog.html',
                        controller: 'ProgramDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    code: null,
                                    name: null,
                                    description: null,
                                    active: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('program', null, {reload: 'program'});
                    }, function () {
                        $state.go('program');
                    });
                }]
            })
            .state('program-detail', {
                parent: 'program',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_EDITOR'],
                    pageTitle: 'continuumplatformApp.program.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/program/program-detail.html',
                        controller: 'ProgramDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('program');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Program', function ($stateParams, Program) {
                        return Program.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('program-detail.edit', {
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/program/program-dialog.html',
                        controller: 'ProgramDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Program', function (Program) {
                                return Program.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('program-detail.delete', {
                url: '/delete',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/program/program-delete-dialog.html',
                        controller: 'ProgramDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Program', function (Program) {
                                return Program.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('program', null, {reload: 'program'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
