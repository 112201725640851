(function () {
    'use strict';

    const visitDetailEpices = {
        bindings: {
            data: '=',
            questions: '<',
            patient: '<'
        },
        controller: VisitDetailEpicesController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-detail-epices.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitDetailEpices', visitDetailEpices);

    VisitDetailEpicesController.$inject = [];

    function VisitDetailEpicesController() {

        const vm = this;

        vm.$onInit = function () {
            vm.evaluated = [...Object.values(vm.data)].length > 1;
        };

    }

})();
