(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyNewDialogController', PatientTherapyNewDialogController);

    PatientTherapyNewDialogController.$inject = ['$filter', '$scope', '$uibModalInstance', 'patientTherapy', 'nextContractLines'];

    function PatientTherapyNewDialogController($filter, $scope, $uibModalInstance, patientTherapy, nextContractLines) {
        const vm = this;

        vm.patientTherapy = patientTherapy;
        vm.therapies = $filter('unique')(nextContractLines, 'therapyId')
            .reduce((total, currentValue, currentIndex, arr) => {
                total.push({
                    id: currentValue.therapyId,
                    name: currentValue.therapyName,
                    programName: currentValue.programName
                });
                return total;
            }, []);

        vm.cancel = cancel;
        vm.confirm = confirm;

        vm.$onInit = function () {

        };

        vm.$onDestroy = function () {

        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm() {
            $uibModalInstance.close(vm.selectedAction);
        }

    }
})();
