(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('documents', {
                parent: 'app',
                url: '/documents',
                data: {
                    pageTitle: 'continuumplatformApp.documents.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/documents/documents.html',
                        controller: 'DocumentsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('documents');
                        $translatePartialLoader.addPart('medicationInfoType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
