(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('CareTeam', CareTeam);

    CareTeam.$inject = ['$http'];

    function CareTeam($http) {

        const roles = [
            'HOSPITAL',
            'FACILITY_DOCTOR',
            'FACILITY_PHARMACIST',
            'NURSE_COORD',
            'MAIN_DOCTOR',
            'DOCTOR',
            'PHARMACY',
            'NURSING',
            'NURSE',
            'NURSE_PLUS',
            'NURSE_REGION',
            'HAH',
            'NURSING_HOME',
            'ANALYSIS_LABORATORY',
            'PV_CENTER',
            'COORDINATION_FACILITY'
        ];

        const rules = {
            'HOSPITAL': {type: 'HealthFacility'},
            'FACILITY_DOCTOR': {type: 'Practitioner'},
            'FACILITY_PHARMACIST': {type: 'Practitioner', limit: 1},
            'NURSE_COORD': {type: 'Practitioner', limit: 1},
            'MAIN_DOCTOR': {type: 'Practitioner', limit: 1},
            'DOCTOR': {type: 'Practitioner'},
            'PHARMACY': {type: 'HealthFacility', limit: 1},
            'NURSING': {type: 'HealthFacility'},
            'NURSE': {type: 'Practitioner'},
            'NURSE_PLUS': {type: 'Practitioner', limit: 1, admin: true},
            'NURSE_REGION': {type: 'Practitioner', limit: 1, admin: true},
            'HAH': {type: 'HealthFacility'},
            'NURSING_HOME': {type: 'HealthFacility'},
            'ANALYSIS_LABORATORY': {type: 'HealthFacility'},
            'PV_CENTER': {type: 'HealthFacility', limit: 1, admin: true},
            'COORDINATION_FACILITY': {type: 'HealthFacility', limit: 1}
        };

        return {
            roles,
            rules,
            getMembers,
            addMember,
            removeMember,
            replaceMember,
            sort,
            getAwayMembers
        };

        function getMembers(patientId) {
            return $http.get(url(patientId));
        }

        function addMember(patientId, role, entityId, notification) {
            return $http.post(url(patientId) + '/add-member', {
                role,
                entityId,
                notification
            });
        }

        function removeMember(patientId, role, entityId) {
            return $http.post(url(patientId) + '/remove-member', {
                role,
                entityId
            });
        }

        function replaceMember(patientId, role, entityId, byId, notification) {
            return $http.post(url(patientId) + '/replace-member', {
                role,
                entityId,
                byId,
                notification
            });
        }

        function url(patientId) {
            return 'api/patients/' + patientId + '/care-team';
        }

        function sort(a, b) {
            if (roles.indexOf(a.role) === -1) {
                return 1;
            }
            if (roles.indexOf(b.role) === -1) {
                return -1;
            }
            if (roles.indexOf(a.role) === roles.indexOf(b.role)) {
                return 0;
            }
            if (roles.indexOf(a.role) < roles.indexOf(b.role)) {
                return -1;
            }
            return 1;
        }

        function getAwayMembers(careTeam) {
            const today = moment().format('yyyy-MM-DD');

            return careTeam.filter((m) => {
                return m.entity.job && ((m.entity.leaveFrom <= today && m.entity.leaveTo >= today) || (m.entity.leaveFrom <= today && !m.entity.leaveTo));
            });

        }
    }

})();
