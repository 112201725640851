(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyDeleteController', PatientTherapyDeleteController);

    PatientTherapyDeleteController.$inject = ['$uibModalInstance', 'entity', 'PatientTherapy'];

    function PatientTherapyDeleteController($uibModalInstance, entity, PatientTherapy) {
        var vm = this;

        vm.patientTherapy = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            PatientTherapy.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
