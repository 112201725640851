(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SecurityConfigController', SecurityConfigController);

    SecurityConfigController.$inject = ['$log', 'SecurityConfig'];

    function SecurityConfigController($log, SecurityConfig) {
        const vm = this;

        vm.setParameter = setParameter;

        /**
         * Initialisation du composant.
         */
        vm.$onInit = () => {
            loadSecurityParameters();
        };

        /**
         * Permet de charger l'ensemble des paramètres de sécurité.
         */
        function loadSecurityParameters() {
            SecurityConfig.get()
                .then((securityParameters) => {
                    vm.securityConfig = securityParameters;
                })
                .catch((error) => {
                    $log.error(
                        "impossible de charger les paramètres de sécurité",
                        error
                    );
                });
        }

        /**
         * Permet de mettre un jour un paramètre de config.
         * @param name nom du paramètre à jour
         * @param value la nouvelle valeur du paramètre
         */
        function setParameter(name) {
            SecurityConfig.set(name, vm.securityConfig[name])
                .then(() => {
                    // nothing
                })
                .catch((error) => {
                    $log.error("impossible de mettre à jour le paramètre", error);
                });
        }

    }
})();
