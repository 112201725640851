(function () {
    'use strict';

    var patientNavTabs = {
        bindings: {
            patient: '=',
            disabled: '=',
            active: '@',
            previousState: '=?',
            visitStatus: '=?',
            visitType: '=?'
        },
        controller: PatientNavTabsController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient/patient-nav-tabs.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientNavTabs', patientNavTabs);

    PatientNavTabsController.$inject = [];

    function PatientNavTabsController() {

    }

})();
