(function () {
    'use strict';

    const newPharmAnalysisItem = {
        bindings: {
            newPharmaAnalysis: '=',
        },
        controller: NewPharmAnalysisItemController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/new-pharm-analysis/new-pharm-analysis-item.html'
    };

    angular
        .module('continuumplatformApp')
        .component('newPharmAnalysisItem', newPharmAnalysisItem);

    NewPharmAnalysisItemController.$inject = [];

    function NewPharmAnalysisItemController() {
        // empty
    }

})();
