(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('visitName', visitName);

    function visitName() {

        return {
            restrict: 'E',
            scope: {
                visit: '='
            },
            templateUrl: 'app/activity/visit/visit-name.html'
        };


    }

})();
