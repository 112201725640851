(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('BillingEntityController', BillingEntityController);

    BillingEntityController.$inject = ['$scope', '$state', '$uibModalInstance', 'Visit', 'Bill', 'ParseLinks', 'AlertService', 'Address', 'entity'];

    function BillingEntityController($scope, $state, $uibModalInstance, Visit, Bill, ParseLinks, AlertService, Address, entity) {

        const vm = this;

        vm.bill = bill;
        vm.cancel = cancel;
        vm.searchAddress = searchAddress;
        vm.onSelectAddress = onSelectAddress;

        vm.isLoading = false;

        vm.entity = entity;

        vm.data = {
            entityId: entity.id,
            legalName: entity.legalName,
            legalForm: entity.legalForm,
            siret: entity.siret,
            iban: entity.iban,
            address: entity.address,
            postalCode: entity.postalCode,
            city: entity.city,
            cityCode: entity.cityCode,
            country: entity.country,
            longitude: entity.longitude,
            latitude: entity.latitude
        };

        vm.addressInvalid = false;

        if (vm.entity) {
            vm.address = {
                properties: {
                    label: `${vm.entity.address || ''} ${vm.entity.postalCode || ''} ${vm.entity.city || ''}`.trim()
                }
            };
        }

        load();

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function bill() {

            if (vm.isLoading)
                return;

            vm.isLoading = true;

            Bill.billEntity(
                vm.data,
                () => {
                    $scope.$emit('continuumplatformApp:billUpdate');
                    $uibModalInstance.close();
                    vm.isLoading = false;
                },
                function () {
                    vm.isLoading = false;
                });
        }

        function load() {
            Visit.billableByEntity({entityId: entity.id}, onSuccess, onError);

            function onSuccess(data) {
                vm.visits = data;

                vm.totalAmount = vm.visits.reduce(function (acc, val) {
                    return acc + val.price + val.kmAllowance;
                }, 0);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function searchAddress(search) {
            vm.addressInvalid = !!search;

            if (!search) {
                vm.entity.address = null;
                vm.entity.postalCode = null;
                vm.entity.city = null;

                vm.entity.latitude = null;
                vm.entity.longitude = null;
                vm.entity.cityCode = null;
            }

            if (!search || search.length < 3) {
                return;
            }

            return Address.search(search);
        }

        function onSelectAddress($item, $model, $label, $event) {
            vm.addressInvalid = false;
            vm.data.address = ['housenumber', 'street', 'locality'].includes($item.properties.type) ? $item.properties.name : null;
            vm.data.postalCode = $item.properties.postcode;
            vm.data.city = $item.properties.city;
            vm.data.latitude = $item.geometry && $item.geometry.coordinates && $item.geometry.coordinates[1] ? $item.geometry.coordinates[1] : null;
            vm.data.longitude = $item.geometry && $item.geometry.coordinates && $item.geometry.coordinates[0] ? $item.geometry.coordinates[0] : null;
            vm.data.cityCode = $item.properties.citycode;
        }
    }
})();
