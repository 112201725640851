(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('IntakeRecord', IntakeRecord);

    IntakeRecord.$inject = ['$resource'];

    function IntakeRecord($resource) {
        const resourceUrl = 'api/intake-records/';

        return $resource(resourceUrl, {}, {
            'getHistory': {
                url: 'api/intake-records/history',
                method: 'GET',
                isArray: true,
            },
            'query': {method: 'GET', isArray: true}

        });
    }
})();
