(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('Tokens', Tokens);

    Tokens.$inject = ['$resource'];

    function Tokens ($resource) {
        return $resource('api/account/tokens/:series', {}, {
            'getAll': { method: 'GET', isArray: true}
        });
    }
})();
