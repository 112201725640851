(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MeasureDialogController', MeasureDialogController);

    MeasureDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$filter', '$uibModalInstance', 'entity', 'Measure', 'MeasureType', 'Patient'];

    function MeasureDialogController($timeout, $scope, $stateParams, $filter, $uibModalInstance, entity, Measure, MeasureType, Patient) {
        const vm = this;

        vm.measure = entity;
        vm.disableType = !!entity.type && !entity.id; // si le type est fourni à l'initialisation, on ne peut pas le modifier

        vm.maxMeasureDate = moment().format('YYYY-MM-DD');

        vm.clear = clear;
        vm.save = save;

        vm.patient = Patient.get({
            id: vm.measure.patientId
        });

        vm.measureRules = MeasureType.getRules();

        if (!vm.measure.id) {
            vm.measure.date = moment().startOf('day').toDate();
        }

        $timeout(function () {
            angular.element('.form-group:eq(0)>select').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.measure.id) {
                Measure.update(
                    {id: vm.measure.id},
                    {
                        authorId: vm.measure.authorId,
                        date: vm.measure.date,
                        value: vm.measure.value,
                        comment: vm.measure.comment
                    },
                    onSaveSuccess, onSaveError);
            } else {
                Measure.save(vm.measure, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('continuumplatformApp:measureUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
