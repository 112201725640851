(function () {
    'use strict';

    const telemonitoringRequestItem = {
        bindings: {
            request: '='
        },
        controller: TelemonitoringRequestItemController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/telemonitoring-requests/telemonitoring-request-item.html'
    };

    angular
        .module('continuumplatformApp')
        .component('telemonitoringRequestItem', telemonitoringRequestItem);

    TelemonitoringRequestItemController.$inject = [];

    function TelemonitoringRequestItemController() {

        const vm = this;

        vm.$onInit = () => {
            // nothing to do
        };

    }

})();
