(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('BillDetailController', BillDetailController);

    BillDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$translate', '$log', '$uibModal', '$window', 'bill', 'previousState', 'Bill', 'Practitioner', 'HealthFacility', 'Visit', 'Principal'];

    function BillDetailController($scope, $rootScope, $state, $stateParams, $translate, $log, $uibModal, $window, bill, previousState, Bill, Practitioner, HealthFacility, Visit, Principal) {
        var vm = this;

        vm.bill = bill;

        vm.previousState = previousState;

        vm.pay = pay;
        vm.edit = edit;
        vm.remove = remove;

        $translate('continuumplatformApp.bill.detail.title', {number: bill.number}).then(title => {
            $window.document.title = title;
        });

        function pay(id) {
            return Bill.pay({id: id}, {}, function (result) {
                $log.info("Bill successfully marked for payment", result);
            });
        }

        Principal.identity().then(function (account) {
            if (account.practitioner
                && (account.authorities.includes('ROLE_ADMIN') || account.practitioner.id === vm.bill.healthEntityId))
                vm.visits = Visit.query({
                    "billId.equals": vm.bill.id,
                    sort: "date",
                    size: 1000 // TODO size
                });
        });

        HealthFacility.get({id: vm.bill.customerId, view: 'summary'}, customer => vm.customer = customer);

        var unsubscribe = $rootScope.$on('continuumplatformApp:billUpdate', function (event, result) {
            vm.bill = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function edit() {
            $uibModal.open({
                templateUrl: 'app/activity/bill/bill-dialog.html',
                controller: 'BillDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    bill: [() => Bill.get({id: vm.bill.id}).$promise],
                    healthEntity: [() => getEntity()]
                }
            }).result.then(() => {
                // do nothing
            }, () => {
                // do nothing
            });
        }

        function getEntity() {
            if (vm.bill.healthEntityType === 'Practitioner') {
                return Practitioner.get({id: vm.bill.healthEntityId}).$promise;
            } else if (vm.bill.healthEntityType === 'HealthFacility') {
                return HealthFacility.get({id: vm.bill.healthEntityId}).$promise;
            } else {
                throw new Error("Unknown health entity type: " + vm.bill.healthEntityType);
            }
        }

        function remove() {
            $uibModal.open({
                templateUrl: 'app/activity/bill/bill-delete-dialog.html',
                controller: 'BillDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    bill: () => vm.bill
                }
            }).result.then(() => {
                $state.go(previousState.name, previousState.params);
            }, () => {
                // do nothing
            });
        }

    }
})();
