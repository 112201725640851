(function () {
    'use strict';

    const aohCrisisEvaluationPanel = {
        bindings: {
            visits: '=',
            patientId: '=',
        },
        controller: AohCrisisEvaluationPanelController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient/aoh-crisis-evaluation-panel.html'
    };

    angular
        .module('continuumplatformApp')
        .component('aohCrisisEvaluationPanel', aohCrisisEvaluationPanel);

    AohCrisisEvaluationPanelController.$inject = ['$scope', '$state'];

    function AohCrisisEvaluationPanelController($scope, $state) {

        const vm = this;

        vm.graphVisits = [];

        vm.$onInit = function() {
            vm.unWatchVisit = $scope.$watch("vm.visits", onVisitsChange);
        };

        vm.$onDestroy = function() {
            vm.unWatchVisit();
        };

        function onVisitsChange(newVisitsList, oldVal) {
            vm.graphVisits = [];

            if (newVisitsList) {
                vm.graphVisits = newVisitsList.filter((v) => v.steps.includes('AOH_CRISIS_EVALUATION'));

                // on s'assure de l'ordre pour récupérer la première visite avec un nombre de crise moyen par mois
                vm.graphVisits.sort((v1,v2) =>  v1.date.localeCompare(v2.date));

                const firstVisitWithCrisisEvaluation = vm.graphVisits[0];
                const dataFirstVisit = firstVisitWithCrisisEvaluation.data.details.AOH_CRISIS_EVALUATION;

                vm.averageCrisisNumberByMonth = dataFirstVisit.Q00;
            }

            initGraph();
            initGraphData();
        }

        function initGraph() {
            vm.chart = {
                series: ['Nombre de crises'],
                labels: [],
                data: [[]],
                options: {
                    tooltips: {enabled: false},
                    hover: {
                        animationDuration:0
                    },
                    scales: {
                        yAxes: [
                            {
                                display: true,
                                scaleLabel: {display: true},
                                ticks: {
                                    suggestedMin: 0,
                                    suggestedMax: 5,
                                    stepSize: 1,
                                    beginAtZero: true
                                }
                            }
                        ]
                    },
                    animation: {
                        onComplete: function() {
                            // affihage des valeurs sur le graph
                            const ctx = this.ctx;
                            ctx.textAlign = "center";
                            ctx.textBaseline = "bottom";
                            ctx.font = 'bold 14px "Helvetica Neue", Helvetica, Arial, sans-serif';
                            this.data.datasets.forEach((dataset, i) => {
                                const meta = this.controller.getDatasetMeta(i);
                                meta.data.forEach((bar, index) => {
                                    if (dataset.data[index] > 4) {
                                        ctx.fillStyle = "white";
                                    } else {
                                        ctx.fillStyle = "black";
                                    }
                                    if (dataset.data[index] > 0) {
                                        const data = dataset.data[index];
                                        ctx.fillText(data, bar._model.x, bar._model.y + 18);
                                    }

                                });
                            });
                        }
                    }
                },
                datasetOverride: [{
                    label: "Nombre de crises",
                    borderWidth: 1,
                    type: 'bar',
                    backgroundColor: [],
                    borderColor: [],
                }],
            };
        }

        function initGraphData() {

            const crisisByMonth = {};

            vm.graphVisits.forEach((v) => {
                (v.data.details.AOH_CRISIS_EVALUATION.crisis || []).forEach((c) => {
                    const criseMonth = moment(c.Q02_2).format('YYYY-MM');

                    if (!crisisByMonth[criseMonth]) {
                        crisisByMonth[criseMonth] = {
                            value: 1,
                            label: moment(c.Q02_2).format('MM/YYYY'),
                            month: criseMonth
                        };
                    } else {
                        crisisByMonth[criseMonth].value++;
                    }

                });
            });

            [...Object.values(crisisByMonth)].sort((c1, c2) => c1.month.localeCompare(c2.month)).forEach((c) => {

                vm.chart.labels.push(c.label);

                let backgroundColor;
                switch (c.value) {
                case 0:
                    backgroundColor = 'rgba(255,255,255,1)';
                    break;
                case 1:
                    backgroundColor = 'rgb(254,243,199,1)';
                    break;
                case 2:
                case 3:
                    backgroundColor = 'rgb(253,186,116,1)';
                    break;
                case 4:
                    backgroundColor = 'rgb(229,62,62,1)';
                    break;
                default:
                    backgroundColor = 'rgb(0,0,0,1)';
                }
                vm.chart.datasetOverride[0].backgroundColor.push(backgroundColor);
                vm.chart.datasetOverride[0].borderColor.push(backgroundColor);

                vm.chart.data[0].push(c.value);
            });

        }

    }

})();
