(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('medication-variant', {
                parent: 'master',
                url: '/medication-variant',
                data: {
                    authorities: ['ROLE_EDITOR'],
                    pageTitle: 'continuumplatformApp.medicationVariant.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/medication-variant/medication-variants.html',
                        controller: 'MedicationVariantController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: {
                        value: null,
                        squash: true
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('medication');
                        $translatePartialLoader.addPart('medicationVariant');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('medication-variant.new', {
                url: '/new',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/medication-variant/medication-variant-dialog.html',
                        controller: 'MedicationVariantDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null,
                                    name: null,
                                    form: null,
                                    available: false,
                                    virtual: false,
                                    cis: null,
                                    doseMg: null,
                                    medicationId: null,
                                    laboratoryId: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('medication-variant', null, {reload: 'medication-variant'});
                    }, function () {
                        $state.go('medication-variant');
                    });
                }]
            })
            .state('medication-variant-detail', {
                parent: 'medication-variant',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_EDITOR'],
                    pageTitle: 'continuumplatformApp.medicationVariant.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/medication-variant/medication-variant-detail.html',
                        controller: 'MedicationVariantDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('medication');
                        $translatePartialLoader.addPart('medicationVariant');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'MedicationVariant', function ($stateParams, MedicationVariant) {
                        return MedicationVariant.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('medication-variant-detail.edit', {
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/medication-variant/medication-variant-dialog.html',
                        controller: 'MedicationVariantDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['MedicationVariant', function (MedicationVariant) {
                                return MedicationVariant.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('medication-variant-detail.delete', {
                url: '/delete',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/medication-variant/medication-variant-delete-dialog.html',
                        controller: 'MedicationVariantDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['MedicationVariant', function (MedicationVariant) {
                                return MedicationVariant.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('medication-variant', null, {reload: 'medication-variant'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
