angular
    .module('continuumplatformApp.referenceData', [
        'pascalprecht.translate'
    ])
    .run(['$translate', '$translatePartialLoader', translationConfiguration]);

function translationConfiguration($translate, $translatePartialLoader){
    $translatePartialLoader.addPart('practitioner');
    return $translate.refresh();
}
