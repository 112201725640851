(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('validSsn', validSsn);

    function validSsn() {

        const cleaningRegex = /[^0-9a-b]/ig;

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elm, attrs, ctrl) {

                ctrl.$parsers.push(function (value) {
                    if(!value) {
                        return null;
                    }

                    const clean = value.replaceAll(cleaningRegex, '');

                    return clean || null;
                });

                ctrl.$validators.ssn = function (modelValue) {
                    if (ctrl.$isEmpty(modelValue)) {
                        return true;
                    }

                    if (modelValue.length === 15) {
                        const alphanum = modelValue.substring(0, 13);
                        const d = alphanum.substring(5, 7);
                        let numString;
                        if(d === '2A') {
                            numString = alphanum.replace('2A', '19');
                        } else if(d === '2B') {
                            numString = alphanum.replace('2B', '18');
                        } else {
                            numString = alphanum;
                        }

                        const num = parseInt(numString);
                        const key = parseInt(modelValue.substring(13, 15));

                        return key === 97 - num % 97;
                    }

                    return false;
                };
            }
        };
    }
})();
