(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SideEffectDetailController', SideEffectDetailController);

    SideEffectDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$uibModal', 'entity', 'SideEffect', 'SideEffectScore'];

    function SideEffectDetailController($scope, $rootScope, $state, $stateParams, $uibModal, entity, SideEffect, SideEffectScore) {
        var vm = this;

        vm.sideEffect = entity;
        vm.modifySideEffect = modifySideEffect;
        vm.deleteSideEffect = deleteSideEffect;
        vm.addScore = addScore;
        vm.modifyScore = modifyScore;
        vm.deleteScore = deleteScore;

        var unsubscribe1 = $rootScope.$on('continuumplatformApp:sideEffectUpdate', loadSideEffect);
        var unsubscribe2 = $rootScope.$on('continuumplatformApp:sideEffectScoreUpdate', () => {
            SideEffectScore.query({sideEffectId: vm.sideEffect.id}, (results) =>
                vm.sideEffect.scores = results);
        });
        $scope.$on('$destroy', () => {
            unsubscribe1();
            unsubscribe2();
        });

        function loadSideEffect() {
            SideEffect.get({id: vm.sideEffect.id}, (result) => vm.sideEffect = result);
        }

        function addScore() {
            $uibModal.open({
                templateUrl: 'app/entities/side-effect/side-effect-score-dialog.html',
                controller: 'SideEffectScoreDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    sideEffectId: () => vm.sideEffect.id,
                    sideEffectName: () => vm.sideEffect.name,
                    sideEffectScore: () => {
                        return {
                            score: null,
                            description: null,
                            contactLevel: null
                        };
                    }
                }
            });
        }

        function modifyScore(score) {
            $uibModal.open({
                templateUrl: 'app/entities/side-effect/side-effect-score-dialog.html',
                controller: 'SideEffectScoreDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    sideEffectId: () => vm.sideEffect.id,
                    sideEffectName: () => vm.sideEffect.name,
                    sideEffectScore: () => angular.copy(score)
                }
            });
        }

        function deleteScore(score) {
            SideEffectScore.delete(
                {
                    sideEffectId: vm.sideEffect.id,
                    score: score
                },
                () => $scope.$emit('continuumplatformApp:sideEffectScoreUpdate'));
        }

        function modifySideEffect() {
            $uibModal.open({
                templateUrl: 'app/entities/side-effect/side-effect-dialog.html',
                controller: 'SideEffectDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['SideEffect', (SideEffect) => {
                        return SideEffect.get({id: vm.sideEffect.id}).$promise;
                    }]
                }
            });
        }

        function deleteSideEffect() {
            $uibModal.open({
                templateUrl: 'app/entities/side-effect/side-effect-delete-dialog.html',
                controller: 'SideEffectDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['SideEffect', (SideEffect) => {
                        return SideEffect.get({id: vm.sideEffect.id}).$promise;
                    }]
                }
            }).result.then(() =>
                $state.go('side-effect', null, {reload: 'side-effect'}));
        }
    }
})();
