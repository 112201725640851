(function () {
    'use strict';

    const patientHabitudesDeVie = {
        bindings: {
            data: '=',
            compareData: '=',
            showDataDiff: '=',
            readonly: '='
        },
        controller: patientHabitudesDeVieController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient/patient-habitudes-de-vie.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientHabitudesDeVie', patientHabitudesDeVie);

    patientHabitudesDeVieController.$inject = [];

    function patientHabitudesDeVieController() {

        const vm = this;

        vm.questions = [
            {
                id: "LE_PATIENT_PRATIQUE_DES_ACTIVITES_ET_LOISIRS",
                "type": "YES_NO",
                "label": "Le patient pratique des activités et loisirs",
                "required": false,
            },
            {
                id: "LE_PATIENT_PRATIQUE_UNE_ACTIVITE_PHYSIQUE",
                "type": "YES_NO",
                "label": "Le patient pratique une activité physique",
                "required": false,
            },
            {
                id: "HABITUDES_ALIMENTAIRES",
                "type": "GROUP",
                "label": "Habitudes alimentaires :",
            },
            {
                id: "LE_PATIENT_PRESENTE_T_IL_UNE_MODIFICATION_DE_SON_ALIMENTATION",
                group: "HABITUDES_ALIMENTAIRES",
                "type": "YES_NO",
                "label": "Le patient présente-t-il une modification de son alimentation",
                "required": false,
            },
            {
                id: "LE_PATIENT_PREND_DES_COMPLEMENTS_ALIMENTAIRES",
                group: "HABITUDES_ALIMENTAIRES",
                "type": "YES_NO",
                "label": "Le patient prend des compléments alimentaires",
                "required": false,
            },
            {
                id: "SI_OUI_NOMBRE_DE_PRISES_PAR_JOUR",
                group: "HABITUDES_ALIMENTAIRES",
                "type": "TEXT",
                "label": "Si oui, nombre de prises par jour",
                "required": false
            },
            {
                id: "LE_PATIENT_EST_IL_SUIVI_PAR_UN_DIETETICIEN",
                group: "HABITUDES_ALIMENTAIRES",
                "type": "YES_NO",
                "label": "Le patient est-il suivi par un diététicien",
                "required": false,
            },
            {
                id: "EMPTY",
                "type": "GROUP",
            },
            {
                id: "CONSOMMATION_DE_TABAC",
                "type": "YES_NO_WEANED",
                "label": "Consommation de tabac",
                "required": false,
            },
            {
                id: "SI_OUI_ESTIMATION_DE_LA_CONSOMMATION_TABAC",
                "type": "TEXT",
                "label": "Si oui, estimation de la consommation",
                "required": false,
            },
            {
                id: "CONSOMMATION_D_ALCOOL",
                "type": "YES_NO_WEANED",
                "label": "Consommation d’alcool",
                "required": false,
            },
            {
                id: "SI_OUI_ESTIMATION_DE_LA_CONSOMMATION_D_ALCOOL",
                "type": "TEXT",
                "label": "Si oui, estimation de la consommation",
                "required": false
            }
        ];



    }

})();
