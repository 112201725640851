(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MetricsMonitoringController', MetricsMonitoringController);

    MetricsMonitoringController.$inject = ['MetricsService'];

    function MetricsMonitoringController(MetricsService) {
        const vm = this;

        vm.refresh = refresh;
        vm.httpServerRequestsMeasurements = null;
        vm.updatingMetrics = true;

        vm.refresh();

        function refresh () {
            vm.updatingMetrics = true;
            MetricsService.getMetrics('http.server.requests').then(metrics => {
                vm.httpServerRequestsMeasurements = metrics.measurements;
                vm.updatingMetrics = false;
            }, () => {
                vm.httpServerRequestsMeasurements = null;
                vm.updatingMetrics = false;
            });
        }


    }
})();
