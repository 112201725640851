(function () {
    'use strict';

    const visitDetailIntakeRecord = {
        bindings: {
            visitId: '=',
            patientId: '='
        },
        controller: VisitDetailIntakeRecordController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-detail-intake-record.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitDetailIntakeRecord', visitDetailIntakeRecord);

    VisitDetailIntakeRecordController.$inject = ['$uibModal', 'IntakeRecord', 'Medication'];

    function VisitDetailIntakeRecordController($uibModal, IntakeRecord, Medication) {
        const vm = this;

        const dataMap = new Map();

        vm.data = [];
        vm.showAllRecord = false;
        vm.maxDefaultShowDayOnDesktop = 10;
        vm.maxDefaultShowDayOnMobile = 5;

        vm.toggleAllRecord = toggleAllRecord;
        vm.showHistoryGraph = showHistoryGraph;


        vm.$onInit = function () {
            loadVisitIntakeRecord(vm.visitId).then(() => {

                // Récupération des dates de début et de fin des enregistrements.
                if (vm.visitIntakeRecord && vm.visitIntakeRecord.length) {
                    vm.intakeRecordStartDate = moment(vm.visitIntakeRecord[vm.visitIntakeRecord.length - 1].date).format('DD/MM/YYYY');
                    vm.intakeRecordEndDate = moment(vm.visitIntakeRecord[0].date).format('DD/MM/YYYY');
                }

                // Récupération de la liste des médicaments sans doublons.
                vm.medicationList = [... new Set(vm.visitIntakeRecord.map((r) => r.medicationId))];

                vm.medications = {};

                // Pour chaque médicament, traiter les informations sur le médicament
                const medicationPromises = vm.medicationList.map(medicationId => processMedications(medicationId));
                Promise.all(medicationPromises).then(() => {
                    vm.daysInIntakeRecordHistory = moment(vm.visitIntakeRecord[vm.visitIntakeRecord.length -1].date).diff(vm.visitIntakeRecord[0].date, 'days');
                });

            });
        };

        function processMedications(medicationId) {
            return loadMedication(medicationId).then((medication) => {
                vm.medications[medicationId] = medication;

                // Pour chaque médicament, obtenir l'historique de prise.
                return loadIntakeRecordHistoryByVisit(medication.id).then((intakeRecordHistory) => {

                    // Pour chaque enregistrement de prise, on l'ajoute à notre dans la map à la date correspondante.
                    intakeRecordHistory.forEach((r) => {
                        const date = moment(r.date).format('DD/MM/YYYY');
                        const dayData = dataMap.get(date);
                        if (dayData) {
                            dayData[`${medication.id}`] = r;
                        } else {
                            dataMap.set(date, {
                                date,
                                [`${medication.id}`]: r,
                            });
                        }
                    });
                    vm.data = [...dataMap.entries()];
                });
            });
        }

        function loadVisitIntakeRecord(visitId) {
            return IntakeRecord.query({
                'visitId.equals': visitId,
                "sort": "date,desc",
            }).$promise
                .then((visitIntakeRecord) => vm.visitIntakeRecord = visitIntakeRecord);
        }

        function loadMedication(medicationId) {
            if (medicationId) {
                return Medication.query({"id.equals": medicationId}).$promise.then((medications) => medications[0]);
            }
            return Promise.resolve(null);
        }

        function loadIntakeRecordHistoryByVisit(medicationId) {
            return IntakeRecord.getHistory({
                medicationId: medicationId,
                visitId: vm.visitId
            }).$promise
                .then((intakeRecordHistory) => vm.intakeRecordHistory = intakeRecordHistory);
        }

        function toggleAllRecord() {
            vm.showAllRecord = !vm.showAllRecord;
        }

        function showHistoryGraph(medication) {
            $uibModal.open({
                templateUrl: 'app/entities/medication/medication-preview-modal.html',
                controller: 'MedicationPreviewModalController',
                controllerAs: 'vm',
                size: vm.patientId ? 'lg' : 'md',
                resolve: {
                    medication: () => medication,
                    patientId: () => vm.patientId,
                    options: {}
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
        }

    }

})();
