(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HealthFacilityModalController', HealthFacilityModalController);

    HealthFacilityModalController.$inject = ['$q', '$uibModal', '$uibModalInstance', '$scope', '$state', '$rootScope', 'healthFacility', 'Practitioner'];

    function HealthFacilityModalController($q, $uibModal, $uibModalInstance, $scope, $state, $rootScope, healthFacility, Practitioner) {
        var vm = this;

        vm.healthFacility = healthFacility;

        vm.invitePractitioner = invitePractitioner;
        vm.openPractitioner = openPractitioner;
        vm.clear = clear;
        vm.edit = edit;

        loadPractitioners();

        var unsubscribe = $rootScope.$on('continuumplatformApp:practitionerUpdate', loadPractitioners);
        $scope.$on('$destroy', function () {
            unsubscribe();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function edit() {
            clear();
            $state.go('health-facility-detail.edit', {id: vm.healthFacility.id});
        }

        function loadPractitioners() {
            vm.practitioners = Practitioner.query({
                "healthFacilityId.equals": vm.healthFacility.id,
                sort: ["lastName,asc"],
                size: 1000 // TODO pagination?
            });
        }

        function invitePractitioner() {
            $uibModal.open({
                templateUrl: 'app/entities/practitioner/practitioner-dialog.html',
                controller: 'PractitionerDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: {
                        healthFacilityId: vm.healthFacility.id,
                        job: null,
                        firstName: null,
                        lastName: null,
                        speciality: null,
                        category: null,
                        rpps: null,
                        adeli: null,
                        legalStatus: null,
                        legalSubStatus: null,
                        legalRegistrationNumber: null,
                        phone: null,
                        mobilePhone: null,
                        email: null,
                        address: null,
                        postalCode: null,
                        city: null,
                        country: 'FR',
                        latitude: null,
                        longitude: null,
                        id: null,
                        emailShown: false,
                        mobilePhoneShown: false,
                        validated: true,
                        notify: true
                    },
                    options: {
                        mode: 'addHealthFacility'
                    }
                }
            }).result.then(() => {
                // do nothing
            }, () => {
                // do nothing
            });
        }

        function openPractitioner(practitioner) {
            return $uibModal.open({
                templateUrl: 'app/entities/practitioner/practitioner-modal.html',
                controller: 'PractitionerModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    practitioner: () => practitioner
                }
            }).result.then(() => {
                // do nothing
            }, () => {
                // do nothing
            });
        }

    }
})();
