(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('AohCrisisEvaluationHistoryController', AohCrisisEvaluationHistoryController);

    AohCrisisEvaluationHistoryController.$inject = ['$scope', 'Visit', 'Question', 'previousState', 'patient'];

    function AohCrisisEvaluationHistoryController($scope, Visit, Question, previousState, patient) {
        const vm = this;

        vm.periode = null;
        vm.itemsPerPage = 20;
        vm.page = 1;

        vm.changePage = changePage;

        vm.$onInit = function () {
            vm.patient = patient;
            vm.previousState = previousState;

            loadAllQuestions();

            vm.crisisHistory = [];
            loadVisitHistory().then((visits) => {

                const finishCrisis = getFinishCrisis(visits);
                const lastCrisisInProgress = getLastCrisisInProgress(visits);
                vm.crisisHistory = [...finishCrisis];
                if (lastCrisisInProgress) {
                    vm.crisisHistory.push(lastCrisisInProgress);
                }

                vm.crisisHistory.sort((c1, c2) => c2.Q02_2.localeCompare(c1.Q02_2));

                vm.crisisHistoryPage = vm.crisisHistory
                    .filter((c) => vm.periode ? moment(c.Q02_2).format('MMMM YYYY') === vm.periode : true)
                    .slice((vm.page - 1) * vm.itemsPerPage, (vm.page - 1) * vm.itemsPerPage + vm.itemsPerPage);
                vm.monthFilterList = generateMonthFilterList(vm.crisisHistory);
            });

            vm.unwatchPeriode = $scope.$watch("vm.periode", watchPeriode);

        };

        vm.$onDestroy = function() {
            vm.unwatchPeriode();
        };

        function getFinishCrisis(visits) {
            let finishCrisis = [];
            visits.forEach((v) => {
                const previousCriseEvaluationInProgress = v.data.details.AOH_CRISIS_EVALUATION.previousCriseEvaluationInProgress;
                const crisis = v.data.details.AOH_CRISIS_EVALUATION.crisis || [];

                // récupération des crises terminés
                if (previousCriseEvaluationInProgress && previousCriseEvaluationInProgress.Q02_1 === 'Terminé') {
                    finishCrisis.push(previousCriseEvaluationInProgress);
                }

                crisis.forEach((c) => {
                    if (c.Q02_1 === 'Terminé') {
                        finishCrisis.push(c);
                    }
                });
            });

            return finishCrisis;
        }

        function getLastCrisisInProgress(visits) {
            // récupération de la dernière crise en cours

            const lastVisitPreviousCriseInProgress = visits[0].data.details.AOH_CRISIS_EVALUATION.previousCriseEvaluationInProgress;
            const lastVisitCrisisList = visits[0].data.details.AOH_CRISIS_EVALUATION.crisis || [];

            // on ne peux avoir qu'une seul crise en cours
            if (lastVisitPreviousCriseInProgress && lastVisitPreviousCriseInProgress.Q02_1 === 'En cours') {

                return lastVisitPreviousCriseInProgress;
            } else {
                return lastVisitCrisisList.filter((c) => c.Q02_1 === 'En cours')[0];
            }
        }

        function loadVisitHistory() {
            return Visit.query({
                "patientId.equals": vm.patient.id,
                "sort": "date,desc",
                "status.equals": "DONE",
                "steps.contains": "AOH_CRISIS_EVALUATION",
                "size": 1000
            }).$promise.then(function (visits) {
                return vm.visits = visits;
            });
        }

        function loadAllQuestions() {
            return Question.getAll().then(function (result) {
                return vm.questions = result.AOH_CRISIS_EVALUATION;
            });
        }

        function changePage() {
            vm.crisisHistoryPage = vm.crisisHistory
                .filter((c) => vm.periode ? moment(c.Q02_2).format('MMMM YYYY') === vm.periode : true)
                .slice((vm.page - 1) * vm.itemsPerPage, (vm.page - 1) * vm.itemsPerPage + vm.itemsPerPage);
        }

        function generateMonthFilterList(crisisHistory) {
            return [...new Set(crisisHistory.map((c) => moment(c.Q02_2).format('MMMM YYYY'))).values()];
        }

        function watchPeriode(newVal, oldVal) {
            vm.crisisHistoryPage = vm.crisisHistory
                .filter((c) => {
                    return newVal
                        ? moment(c.Q02_2).format("MMMM YYYY") === newVal
                        : true;
                })
                .slice(
                    (vm.page - 1) * vm.itemsPerPage,
                    (vm.page - 1) * vm.itemsPerPage + vm.itemsPerPage
                );
        }

    }
})();
