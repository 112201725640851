(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('pharmacy-order-detail', {
                parent: 'patients',
                url: '/pharmacy-order/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'continuumplatformApp.pharmacyOrder.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/pharmacy-order/pharmacy-order-detail.html',
                        controller: 'PharmacyOrderDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('pharmacyOrder');
                        $translatePartialLoader.addPart('pharmacyOrderStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PharmacyOrder', function ($stateParams, PharmacyOrder) {
                        return PharmacyOrder.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name,
                            params: $transition$.params('from')
                        };
                    }]
                }
            });
    }

})();
