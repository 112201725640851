(function () {
    'use strict';

    const message = {
        bindings: {
            message: '=',
            activateLink: '='
        },
        controller: MessageController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/messaging/message.html'
    };

    angular
        .module('continuumplatformApp')
        .component('message', message);

    MessageController.$inject = ['$state', 'Principal'];

    function MessageController($state, Principal) {

        const vm = this;
        vm.onClickMessage = onClickMessage;


        vm.$onInit = function () {
            Principal.identity().then(function (account) {
                vm.currentUser = account;
                vm.isPractitioner = account.authorities.includes('ROLE_PRACTITIONER');
                vm.isPatient = account.authorities.includes('ROLE_PATIENT');
            });
        };

        /**
         * Redirect to message detail page based on the given message and account.
         *
         * if vm.activateLink is false, do nothing.
         *
         * @param {object} message - The message object.
         * @param {object} account - The account object.
         */
        function onClickMessage(message, account) {
            // si l'on a pas activé le lien on ne fait rien
            if(!vm.activateLink) {
                return;
            }

            if(message.attachments && message.attachments.visit && message.attachments.visit.length) {
                $state.go( 'visit-detail', {id: message.attachments.visit[0].id});

            } else {
                $state.go( 'message-detail', {id: message.id});
            }
        }

    }

})();
