(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .filter('authorUserStatus', authorUserStatus);

    authorUserStatus.$inject = [];

    function authorUserStatus() {

        return getUserStatus;

        function getUserStatus(item) {

            if (item.author) {
                return item.author.userStatus;
            }

            return item.patientUserStatus;
        }

    }

})();
