(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('NotWorkingWarningDialogController',NotWorkingWarningDialogController);

    NotWorkingWarningDialogController.$inject = ['$uibModalInstance'];

    function NotWorkingWarningDialogController($uibModalInstance) {
        const vm = this;

        vm.close = close;

        function close () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
