(function() {
    'use strict';

    angular.module('continuumplatformApp')
        .controller('HealthModalController', HealthModalController);

    HealthModalController.$inject = ['$uibModalInstance', 'currentHealth', 'component'];

    function HealthModalController($uibModalInstance, currentHealth, component) {
        var vm = this;

        vm.cancel = cancel;
        vm.component = component;
        vm.currentHealth = currentHealth;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
