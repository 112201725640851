(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SetMeasureTypesModalController', SetMeasureTypesModalController);

    SetMeasureTypesModalController.$inject = ['$scope', '$uibModalInstance', 'patientTherapy', 'MeasureType', 'PatientTherapy'];

    function SetMeasureTypesModalController($scope, $uibModalInstance, patientTherapy, MeasureType, PatientTherapy) {
        const vm = this;

        const measureRules = MeasureType.getRules();
        const vitalSigns = [
            'BLOOD_PRESSURE',
            'HEART_RATE',
            'SATO2',
            'RESPIRATORY_RATE',
            'TEMPERATURE'
        ];
        vm.measureTypeSettings = {};

        vm.$onInit = () => {
            vitalSigns.forEach(type => {
                vm.measureTypeSettings[type] = {
                    name: measureRules[type].name,
                    enabled: patientTherapy.measureTypes && patientTherapy.measureTypes.includes(type)
                };
            });
        };

        vm.toggle = type => {
            if (vm.isToggling) {
                return;
            }
            vm.isToggling = true;
            let fn;
            let newValue;
            if (vm.measureTypeSettings[type].enabled) {
                fn = PatientTherapy.removeMeasureType;
                newValue = false;
            } else {
                fn = PatientTherapy.addMeasureType;
                newValue = true;
            }
            fn({id: patientTherapy.id, type}, {}).$promise
                .then(() => {
                    vm.measureTypeSettings[type].enabled = newValue;
                    if(newValue) {
                        patientTherapy.measureTypes.push(type);
                    } else {
                        patientTherapy.measureTypes = patientTherapy.measureTypes.filter(t => t !== type);
                    }
                    $scope.$emit('continuumplatformApp:setMeasureTypes', patientTherapy.measureTypes);
                })
                .finally(() => {
                    vm.isToggling = false;
                });
        };

        vm.dismiss = () => {
            $uibModalInstance.dismiss();
        };

    }
})();
