(function () {
    'use strict';

    const contractInProgressBlock = {
        bindings: {},
        controller: ContractInProgressBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/contract-in-progress/contract-in-progress-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('contractInProgressBlock', contractInProgressBlock);

    ContractInProgressBlockController.$inject = ['DashboardService'];

    function ContractInProgressBlockController(DashboardService) {

        const vm = this;

        vm.contractsInProgress = null;
        vm.error = null;
        vm.loading = false;

        /**
         * Initialize the component.
         */
        vm.$onInit = function () {
            loadContractInProgress();
        };

        /**
         * Loads the contract in progress.
         *
         * @return {void} This method does not return any value.
         */
        function loadContractInProgress() {
            vm.loading = true;

            DashboardService
                .loadContractInProgress()
                .then((contractsInProgress) => {
                    vm.contractsInProgress = contractsInProgress;
                    vm.error = null;
                })
                .catch((error) => {
                    vm.contractsInProgress = null;
                    vm.error = error;
                })
                .finally(() => vm.loading = false);

        }

    }

})();
