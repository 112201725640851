(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('sideEffectSelectionBlock', sideEffectSelectionBlock);

    function sideEffectSelectionBlock() {

        return {
            restrict: 'E',
            scope: {
                sideEffect: '=',
                select: '=',
                report: '=',
                hasPhoto: '='
            },
            templateUrl: 'app/entities/side-effect/side-effect-selection-block.html'
        };


    }

})();
