(function () {
    'use strict';

    const card = {
        bindings: {
            header: '=',
            onClick: '<',
            totalItems: '='
        },
        controller: CardController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/card.html',
        transclude: true
    };

    angular
        .module('continuumplatformApp')
        .component('card', card);

    CardController.$inject = [];

    function CardController() {
        // empty
    }

})();
