(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('user-management', {
                parent: 'security',
                url: '/user-management?page&sort&search',
                data: {
                    authorities: ['ROLE_SECURITY'],
                    pageTitle: 'continuumplatformApp.user.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/security/user-management/user-management.html',
                        controller: 'UserManagementController',
                        controllerAs: 'vm'
                    }
                }, params: {
                    search: {
                        value: null,
                        squash: true
                    },
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'lastModifiedDate,desc',
                        squash: true
                    }
                },
                resolve: {
                    searchQuery: ['$transition$', function ($transition$) {
                        return $transition$.params().search;
                    }],
                    statusFilter: ['$transition$', function ($transition$) {
                        return $transition$.params().statusFilter;
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user');
                        $translatePartialLoader.addPart('audits');
                        $translatePartialLoader.addPart('tokens');
                        return $translate.refresh();
                    }]

                }
            })
            .state('user-management.new', {
                url: '/new',
                data: {
                    authorities: ['ROLE_SECURITY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/security/user-management/user-management-dialog.html',
                        controller: 'UserManagementDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null,
                                    login: null,
                                    firstName: null,
                                    lastName: null,
                                    email: null,
                                    mobilePhone: null,
                                    langKey: 'fr',
                                    authType: 'SMS'
                                };
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('user-management-detail', {id: result.id});
                    }, function () {
                        $state.go('user-management');
                    });
                }]
            })
            .state('user-management-detail', {
                parent: 'user-management',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_SECURITY'],
                    pageTitle: 'custom'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/security/user-management/user-management-detail.html',
                        controller: 'UserManagementDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user');
                        $translatePartialLoader.addPart('audits');
                        $translatePartialLoader.addPart('tokens');
                        $translatePartialLoader.addPart('persistentToken');
                        return $translate.refresh();
                    }]
                }
            })
            .state('user-management-detail.edit', {
                url: '/edit',
                data: {
                    authorities: ['ROLE_SECURITY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/security/user-management/user-management-dialog.html',
                        controller: 'UserManagementDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['User', function (User) {
                                return User.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('user-management-detail.delete', {
                url: '/delete',
                data: {
                    authorities: ['ROLE_SECURITY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/security/user-management/user-management-delete-dialog.html',
                        controller: 'UserManagementDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['User', function (User) {
                                return User.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('user-management', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }
})();
