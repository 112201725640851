(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PractitionerDetailController', PractitionerDetailController);

    PractitionerDetailController.$inject = ['$scope', '$rootScope', '$state', '$http', '$log', '$uibModal', '$filter', '$window', 'entity', 'Practitioner', 'Visit', 'Patient', 'Principal', 'HealthEntityContract', 'Directory'];

    function PractitionerDetailController($scope, $rootScope, $state, $http, $log, $uibModal, $filter, $window, entity, Practitioner, Visit, Patient, Principal, HealthEntityContract, Directory) {
        const vm = this;

        vm.openContract = openContract;
        vm.addBadge = addBadge;
        vm.removeBadge = removeBadge;
        vm.addReplaces = addReplaces;
        vm.removeReplaces = removeReplaces;
        vm.addReplacedBy = addReplacedBy;
        vm.removeReplacedBy = removeReplacedBy;

        vm.nationalIdentifierCheck = null;
        vm.practitioner = entity;

        vm.$onInit = function () {
            $window.document.title = $filter('translatePractitionerName')(entity, 'TITLE_FULLNAME_JOB_SPECIALITY');

            Principal.hasAuthority('ROLE_PRACTITIONER').then(function (hasAuthority) {
                if (hasAuthority) {
                    loadVisits();
                    loadPatients();
                }
            });

            Principal.hasAuthority('ROLE_ADMIN').then(function (hasAuthority) {
                if (hasAuthority) {
                    loadContracts();
                    loadReplacedBy();
                    loadReplaces();
                }
            });

            Principal.hasAuthority('ROLE_FINANCE').then(function (hasAuthority) {
                vm.hasFinanceRole = hasAuthority;
            });

            checkNationalIdentifier();

            vm.unsubscribePractitionerUpdate = $rootScope.$on('continuumplatformApp:practitionerUpdate', () => {
                vm.nationalIdentifierCheck = null;
                loadPractitioner().then(checkNationalIdentifier);
            });
            vm.unsubscribeBills = $rootScope.$on('continuumplatformApp:billUpdate', () => {
                loadPractitioner();
            });
        };

        $scope.$on('$destroy', function () {
            vm.unsubscribePractitionerUpdate();
            vm.unsubscribeBills();
        });

        function loadVisits() {
            return Visit.query({
                "authorId.equals": vm.practitioner.id,
                "sort": "date,asc",
                "size": 1000 // TODO pagination?
            }).$promise.then(visits =>
                vm.visits = visits);
        }

        function loadPatients() {
            return Patient.query({
                "hasCareTeamMemberId": vm.practitioner.id,
                "sort": "lastName,asc",
                "size": 1000 // TODO pagination?
            }).$promise.then(patients =>
                vm.patients = patients);
        }

        function loadPractitioner() {
            return Practitioner.get({
                id: vm.practitioner.id
            }).$promise.then(practitioner =>
                vm.practitioner = practitioner);
        }

        function loadContracts() {
            HealthEntityContract.query({
                "executorId.equals": vm.practitioner.id,
                "sort": "signed"
            }).$promise.then(contracts =>
                vm.contracts = contracts);
        }

        function openContract(c) {
            if (c.signed) {
                if (c.signEnvelopeId) {
                    $state.go('health-entity-contract-detail', {id: c.id});
                } else {
                    alert("Envelope non disponible");
                }
            } else {
                alert("Non signée");
            }
        }

        function addBadge(badge) {
            vm.modifyingBadges = true;
            Practitioner.addBadge(
                {id: vm.practitioner.id},
                badge,
                () => {
                    vm.modifyingBadges = false;
                    vm.practitioner.badges.push(badge);
                },
                () => vm.modifyingBadges = false);
        }

        function removeBadge(badge) {
            vm.modifyingBadges = true;
            Practitioner.removeBadge(
                {id: vm.practitioner.id, badge: badge},
                () => {
                    vm.modifyingBadges = false;
                    vm.practitioner.badges = vm.practitioner.badges.filter(function (item) {
                        return item !== badge;
                    });
                },
                () => vm.modifyingBadges = false);
        }

        function loadReplacedBy() {
            Practitioner.getReplacedBy({id: vm.practitioner.id}, replacedBy => vm.replacedBy = replacedBy);
        }

        function loadReplaces() {
            Practitioner.getReplaces({id: vm.practitioner.id}, replaces => vm.replaces = replaces);
        }

        function openSubstitution(legalSubStatus) {
            return $uibModal.open({
                templateUrl: 'app/entities/practitioner/practitioner-dialog.html',
                controller: 'PractitionerDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: {
                        healthFacilityId: null,
                        job: vm.practitioner.job,
                        speciality: null,
                        category: null,
                        firstName: null,
                        lastName: null,
                        adeli: null,
                        rpps: null,
                        siret: null,
                        iban: null,
                        phone: null,
                        mobilePhone: null,
                        email: null,
                        address: null,
                        postalCode: null,
                        city: null,
                        latitude: null,
                        longitude: null,
                        cityCode: null,
                        country: 'FR',
                        legalRegistrationNumber: null,
                        legalStatus: null,
                        legalSubStatus: legalSubStatus,
                        notes: null,
                        userAuthType: null,
                        validated: true,
                        notify: true
                    },
                    options: {
                        mode: 'addSubstitute',
                        replacesId: vm.practitioner.id,
                        searchCriteria: {
                            address: legalSubStatus === 'SUBSTITUTE' ? null : vm.practitioner.addressOrFacilityAddress,
                            postalCode: legalSubStatus === 'SUBSTITUTE' ? null : vm.practitioner.postalCodeOrFacilityPostalCode,
                            city: legalSubStatus === 'SUBSTITUTE' ? null : vm.practitioner.cityOrFacilityCity,
                            latitude: legalSubStatus === 'SUBSTITUTE' ? null : vm.practitioner.latitude,
                            longitude: legalSubStatus === 'SUBSTITUTE' ? null : vm.practitioner.longitude,
                            legalStatus: 'LIBERAL'
                        }
                    }
                }
            });
        }

        function addReplaces() {
            openSubstitution('INSTALLED').result.then(replacesId =>
                Practitioner.addReplaces({id: vm.practitioner.id}, {replacesId}, loadReplaces));
        }

        function removeReplaces(replacesId) {
            Practitioner.removeReplaces({id: vm.practitioner.id, replacesId}, loadReplaces);
        }

        function addReplacedBy() {
            openSubstitution('SUBSTITUTE').result.then(byId =>
                Practitioner.addReplacedBy({id: vm.practitioner.id}, {byId}, loadReplacedBy));
        }

        function removeReplacedBy(byId) {
            Practitioner.removeReplacedBy({id: vm.practitioner.id, byId}, loadReplacedBy);
        }

        function checkNationalIdentifier() {
            doCheckRppsOrAdeli(vm.practitioner.rpps ? vm.practitioner.rpps : vm.practitioner.adeli)
                .then((check) => vm.nationalIdentifierCheck = check)
                .catch(() => {
                });
        }

        function doCheckRppsOrAdeli(rppsOrAdeli) {

            if (!rppsOrAdeli) {
                return Promise.reject('No identifier to check');
            }

            const criteria = {
                page: 1,
                size: 1,
                idpp: rppsOrAdeli
            };

            const req = Directory.search(criteria);

            return $http(req).then(
                response => response.data.totalElements > 0,
                error => $log.error("Error from api", error));
        }
    }
})();
