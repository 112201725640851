(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('AuthServerProvider', AuthServerProvider);

    AuthServerProvider.$inject = ['$http', '$log'];

    function AuthServerProvider($http, $log) {
        return {
            login: login,
            logout: logout
        };

        function login(credentials, disableRetry) {

            var data = 'j_username=' + encodeURIComponent(credentials.username) +
                '&j_password=' + encodeURIComponent(credentials.password) +
                '&remember-me=' + credentials.rememberMe;

            if (credentials.otp) {
                data += '&otp=' + credentials.otp;
            }

            return $http.post('api/authentication', data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).catch((response) => {
                if (response.status === 403
                    && response.data
                    && response.data.detail
                    && response.data.detail.includes('CSRF')) {
                    if (!disableRetry) {
                        $log.warn('CSRF failure. Retrying login...');
                        // first make a GET request to get a CSRF token for following POST
                        return $http.head('api/empty').then(() => login(credentials, true));
                    }
                }
                throw response;
            });
        }

        function logout () {
            // first make a GET request to make sure we're still logged in and have a CSRF token for POST logout
            return $http.get('api/account')
                .then(() => $http.post('api/logout'));
        }
    }
})();
