(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MessageDialogController', MessageDialogController);

    MessageDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Message'];

    function MessageDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Message) {
        var vm = this;

        vm.message = entity;
        vm.clear = clear;

        vm.save = save;

        vm.message.authorId = vm.message.author && vm.message.author.id;

        $timeout(function () {
            angular.element('#field_body').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            Message.update({id: vm.message.id}, {
                body: vm.message.body,
                date: vm.message.date,
                authorId: vm.message.authorId,
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $scope.$emit('continuumplatformApp:messageUpdate', {id: vm.message.id});
            $uibModalInstance.close({id: vm.message.id});
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
