(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyMediaController', PatientTherapyMediaController);

    PatientTherapyMediaController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$filter', 'entity', 'Media'];

    function PatientTherapyMediaController($timeout, $scope, $stateParams, $uibModalInstance, $filter, entity, Media) {
        var vm = this;

        vm.clear = clear;

        vm.patientTherapy = entity;
        vm.mediaId = $stateParams.mediaId;

        vm.media = Media.get({id: vm.mediaId});

        console.log("media", vm.media);

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
