(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('BillingController', BillingController);

    BillingController.$inject = ['$scope', '$state', 'Visit', 'Bill', 'ParseLinks', 'AlertService', 'Principal', 'Address'];

    function BillingController($scope, $state, Visit, Bill, ParseLinks, AlertService, Principal, Address) {

        var vm = this;

        vm.go = go;
        vm.bill = bill;
        vm.cancel = cancel;
        vm.searchAddress = searchAddress;
        vm.onSelectAddress = onSelectAddress;

        vm.step = 1;

        vm.isLoading = false;

        vm.addressInvalid = false;

        Principal.identity(false).then(function (account) {
            vm.nurse = account.practitioner;
            vm.address = {
                properties: {
                    label: `${vm.nurse.address || ''} ${vm.nurse.postalCode || ''} ${vm.nurse.city || ''}`.trim()
                }
            };
            loadAll();
        });

        function cancel() {
            vm.step = 1;
        }

        function go() {
            vm.step = 2;
        }

        function bill() {

            if (vm.isLoading)
                return;

            vm.isLoading = true;

            Bill.billOwn(
                {
                    siret: vm.nurse.siret,
                    iban: vm.nurse.iban,
                    legalForm: vm.nurse.legalForm,
                    address: vm.nurse.address,
                    postalCode: vm.nurse.postalCode,
                    city: vm.nurse.city,
                    country: vm.nurse.country
                },
                function () {
                    $state.go('bill');
                },
                function () {
                    vm.isLoading = false;
                    // TODO handle errors
                });
        }

        function loadAll() {
            Visit.billableOwn(onSuccess, onError);

            function onSuccess(data) {
                vm.visits = data;

                vm.totalAmount = vm.visits.reduce(function (acc, val) {
                    return acc + val.price + val.kmAllowance;
                }, 0);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function searchAddress(search) {
            vm.addressInvalid = !!search;

            if (!search) {
                vm.nurse.address = null;
                vm.nurse.postalCode = null;
                vm.nurse.city = null;

                vm.nurse.latitude = null;
                vm.nurse.longitude = null;
                vm.nurse.cityCode = null;
            }

            if (!search || search.length < 3) {
                return;
            }

            return Address.search(search);
        }

        function onSelectAddress($item, $model, $label, $event) {
            vm.addressInvalid = false;
            vm.nurse.address = ['housenumber', 'street', 'locality'].includes($item.properties.type) ? $item.properties.name : null;
            vm.nurse.postalCode = $item.properties.postcode;
            vm.nurse.city = $item.properties.city;
            vm.nurse.latitude = $item.geometry && $item.geometry.coordinates && $item.geometry.coordinates[1] ? $item.geometry.coordinates[1] : null;
            vm.nurse.longitude = $item.geometry && $item.geometry.coordinates && $item.geometry.coordinates[0] ? $item.geometry.coordinates[0] : null;
            vm.nurse.cityCode = $item.properties.citycode;
        }
    }
})();
