(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('ChangePasswordController', ChangePasswordController);

    ChangePasswordController.$inject = ['$state', '$stateParams', '$timeout', 'termsConstants', 'errorConstants', 'ChangePassword'];

    function ChangePasswordController($state, $stateParams, $timeout, termsConstants, errorConstants, ChangePassword) {
        const vm = this;

        vm.confirmPassword = null;
        vm.error = null;
        vm.save = save;
        vm.form = {};
        vm.success = null;

        $timeout(() => {
            angular.element('#currentPassword').focus();
        });

        function save() {
            vm.error = null;
            ChangePassword.changePassword(vm.form.currentPassword, vm.form.password)
                .then(() => {
                    vm.success = 'OK';
                })
                .catch(response => {
                    vm.success = null;
                    vm.error = parseError(response);
                });
        }

        function parseError(response) {
            if (response.status === 400 && response.data) {
                const type = response.data.type;
                if (hasInvalidNewPassword(response) || response.data.type === errorConstants.INVALID_PASSWORD_TYPE) {
                    return 'INVALID_PASSWORD';
                } else if (type === errorConstants.BAD_CURRENT_PASSWORD_TYPE) {
                    return 'BAD_CURRENT_PASSWORD';
                }
            }
            return 'UNKNOWN';
        }

        function hasInvalidNewPassword(response) {
            if (response.data.message !== 'error.validation') {
                return false;
            }
            if (!response.data.fieldErrors) {
                return false;
            }
            return response.data.fieldErrors.map(e => e.field).includes('newPassword');
        }
    }
})();
