(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SimpleLayoutController', SimpleLayoutController);

    SimpleLayoutController.$inject = [];

    function SimpleLayoutController() {
        const vm = this;

    }
})();
