(function () {
    'use strict';

    const adherenceBlock = {
        bindings: {
            patientId: '='
        },
        controller: AdherenceBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/adherence-report/adherence-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('adherenceBlock', adherenceBlock);

    AdherenceBlockController.$inject = ['$log', '$uibModal', 'AdherenceReport'];

    function AdherenceBlockController($log, $uibModal, AdherenceReport) {

        const vm = this;

        vm.showHistory = showHistory;

        vm.$onInit = () => {
            loadAdherenceReports(vm.patientId);
        };

        function loadAdherenceReports(patientId) {
            return AdherenceReport.history({"patientId": patientId})
                .$promise
                .then(
                    result => vm.adherenceReportList = result
                ).catch((error) => {
                    vm.adherenceReportList = [];
                    $log.info("Erreur lors de la récupération de la liste des relevés d'observance", error);
                });
        }

        function showHistory() {
            $uibModal.open({
                templateUrl: 'app/activity/adherence-report/adherence-history.modal.html',
                controller: 'AdherenceHistoryModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    adherenceReportList: function () {
                        return vm.adherenceReportList;
                    },
                }
            });
        }

    }

})();
