(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('contactLevel', contactLevel);

    function contactLevel() {

        return {
            restrict: 'E',
            scope: {
                labelkey: '=',
                level: '='
            },
            templateUrl: 'app/entities/side-effect/contact-level.html'
        };


    }

})();
