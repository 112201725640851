(function () {
    'use strict';

    var radioYesNo = {
        bindings: {
            form: '=',
            value: '=',
            required: '=',
            group: '@',
            key: '@'
        },
        controller: RadioYesNo,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/radio-yes-no.html'
    };

    angular
        .module('continuumplatformApp')
        .component('radioYesNo', radioYesNo);

    RadioYesNo.$inject = [];

    function RadioYesNo() {

        // var vm = this;

    }

})();
