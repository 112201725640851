(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('BiologyResultImportController', BiologyResultImportController);

    BiologyResultImportController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'patientId', 'BiologyResult', 'Patient', 'PatientTherapy', 'Media', 'Upload', 'CompressImage'];

    function BiologyResultImportController($timeout, $scope, $uibModalInstance, patientId, BiologyResult, Patient, PatientTherapy, Media, Upload, CompressImage) {
        const vm = this;
        const todayString = moment().format('yyyy-MM-DD');

        vm.clear = clear;
        vm.save = save;
        vm.addFiles = addFiles;
        vm.deleteFile = deleteFile;

        vm.biologyResult = {
            comments: null,
            patientTherapyId: null,
            sampleDate: null,
        };
        vm.files = [];
        vm.minSampleDate = moment().subtract(2, 'weeks').format('yyyy-MM-DD');
        vm.maxSampleDate = todayString;

        $scope.$watch('vm.biologyResult.sampleDate', onChangeSampleDate);

        // init patient therapies
        loadPatientTherapies();

        vm.uploadProgress = null;

        vm.files = [];

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {

            vm.isSaving = true;

            const compressPromises = vm.files.map((f) => {
                if (f.type.startsWith('image/')) {
                    return CompressImage.compress(f).then((compressImageFile) => {
                        return Promise.resolve(compressImageFile);
                    });
                } else {
                    return Promise.resolve(f);
                }
            });

            Promise.all(compressPromises).then(doUpload);
        }

        function addFiles(files) {
            if (!files)
                return;

            files.forEach((file) => {
                vm.files.push(file);
            });
        }

        function deleteFile(index) {
            vm.files.splice(index, 1);
        }

        function doUpload(files) {
            return Upload.upload({
                url: 'api/visits/import-bio',
                method: 'POST',
                arrayKey: '',
                data: {
                    patientTherapyId: vm.biologyResult.patientTherapyId,
                    sampleDate: vm.biologyResult.sampleDate,
                    comments: vm.biologyResult.comments || undefined,
                    files
                }
            }).then(
                onSuccess,
                () => vm.isSaving = false,
                evt => vm.uploadProgress = Math.round(100 * evt.loaded / evt.total));
        }

        function onSuccess(response) {
            $scope.$emit('continuumplatformApp:biologyResultUpdate');
            $uibModalInstance.close(response.data);
            vm.isSaving = false;
        }

        function loadPatientTherapies() {
            if (!patientId) {
                throw new Error('patientId is required');
            }
            PatientTherapy.query({
                "patientId.equals": patientId,
                "status.in": ["S11_VALIDATED", "S20_ACTIVE", "S30_PAUSED", "S40_FINISHED", "S50_STOPPED"],
                "sort": ["startDate,desc"],
            }, function (result) {
                vm.recentPatientTherapyList = result
                    .filter(pt => pt.stopDate === null
                        || moment(pt.stopDate).isAfter(moment().subtract(2, 'weeks')));
                if (vm.recentPatientTherapyList.length) {
                    vm.minSampleDate = moment(vm.recentPatientTherapyList
                        .filter(pt => pt.stopDate === null)
                        .map(pt => pt.day1Date || pt.startDate || pt.createdDate)
                        .reduce((a, b) => moment(a).isBefore(moment(b)) ? a : b))
                        .subtract(2, 'weeks')
                        .format('yyyy-MM-DD');
                }
            });
        }

        function onChangeSampleDate(sampleDate) {
            const sampleDateMoment = moment(sampleDate);
            if (sampleDate) {
                vm.patientTherapies = {};
                const matching = vm.recentPatientTherapyList
                    .filter(pt => {
                        const beforeDay1Moment = pt.day1Date
                            ? moment(pt.day1Date).subtract(2, 'weeks')
                            : moment().subtract(2, 'weeks');
                        return sampleDateMoment.isSameOrAfter(beforeDay1Moment)
                            && (pt.stopDate === null || sampleDateMoment.isSameOrBefore(moment(pt.stopDate)));
                    });
                matching.forEach((pt) => {
                    vm.patientTherapies[pt.id] = pt;
                });
                if (matching.length === 1) {
                    vm.biologyResult.patientTherapyId = matching[0].id;
                }
            } else {
                vm.biologyResult.patientTherapyId = null;
            }
        }

    }
})();
