(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('Principal', Principal);

    Principal.$inject = ['$log', '$q', 'Account'];

    function Principal ($log, $q, Account) {
        let _identity,
            _offline,
            _authenticated = false;

        return {
            authenticate,
            hasAnyAuthority,
            hasAuthority,
            identity,
            isAuthenticated,
            isOffline,
            updateSettings,
            updateSetting,
            deleteSetting
        };

        function authenticate (identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority (authorities) {
            if (!_authenticated || !_identity || !_identity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority (authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                return _id.authorities && _id.authorities.indexOf(authority) !== -1;
            }, function(){
                return false;
            });
        }

        function identity (force) {

            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get().$promise
                .then(getAccountThen)
                .catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen (account) {
                _identity = account.data;
                _authenticated = true;
                _offline = false;
                deferred.resolve(_identity);
            }

            function getAccountCatch (response) {
                _identity = null;
                _authenticated = false;
                _offline = response.status === -1;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated () {
            return _authenticated;
        }

        function isOffline () {
            return _offline;
        }

        function updateSettings(settings) {
            Account.updateSettings(settings)
                .$promise
                .then(() => {
                    if (_identity)
                        _identity.settings = settings;
                })
                .catch(error => {
                    $log.error(error);
                });
        }

        function updateSetting(key, value) {
            Account.updateSetting({key}, value)
                .$promise
                .then(() => {
                    if (_identity && _identity.settings)
                        _identity.settings[key] = value;
                })
                .catch(error => {
                    $log.error(error);
                });
        }

        function deleteSetting(key) {
            Account.deleteSetting({key})
                .$promise
                .then(() => {
                    if (_identity && _identity.settings)
                        delete _identity.settings[key];
                })
                .catch(error => {
                    $log.error(error);
                });
        }
    }
})();
