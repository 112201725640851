(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$log', '$rootScope', '$cookies', '$state', '$scope', '$interval','$timeout', '$window', 'Auth', 'Principal', 'Otp', 'StateSaver', 'A2HS'];

    function LoginController($log, $rootScope, $cookies, $state, $scope, $interval, $timeout, $window, Auth, Principal, Otp, StateSaver, A2HS) {
        const vm = this;

        vm.login = login;
        vm.sendOTP = sendOTP;
        vm.cancelOTP = cancelOTP;
        vm.requestResetPassword = requestResetPassword;
        vm.submit = submit;
        vm.forgetMe = forgetMe;
        vm.hasRememberMeEnabled = Auth.hasRememberMeEnabled;

        vm.isOffline = null;
        vm.authenticationError = false;
        vm.statusCode = null;
        vm.credentials = {};
        vm.password = null;
        vm.askOTP = false;
        vm.showResendOtp = false;
        vm.otp = null;
        vm.mobilePhone = null;
        vm.requestRememberMe = false;
        vm.rememberedUsername = $window.localStorage ? $window.localStorage.getItem('username') : null;
        vm.username = vm.rememberedUsername;
        vm.isLoading = false;

        // A2HS
        vm.showInstallPromotion = A2HS.isShowInstallPromotion;
        vm.install = A2HS.install;

        $scope.$watch("vm.otp", function (newVal, oldVal) {
            if (vm.otp && vm.otp.length === 6) {
                login();
            } else if (vm.otp && vm.otp.length) {
                vm.authenticationError = null;
                vm.errorDetail = null;
            }
        });

        offlineCheck();

        function offlineCheck() {
            if(Principal.isOffline()) {
                vm.isOffline = true;
                $log.warn('Offline!');
                $timeout(() => {
                    $log.debug('Trying to refresh identity...');
                    Principal.identity(true).then(offlineCheck);
                }, 3000);
            } else if(Principal.isAuthenticated()) {
                $log.info('Authenticated, redirecting to home...');
                $state.go('home');
            } else {
                $log.info('Online :)');
                vm.isOffline = false;
                $timeout(() => {
                    if(vm.rememberedUsername) {
                        angular.element('#password').focus();
                    } else {
                        angular.element('#username').focus();
                    }
                });
            }
        }

        /**
         * Submit
         *
         * @param event
         */
        function submit(event) {

            event && event.preventDefault();
            if (vm.otp || (vm.hasRememberMeEnabled() && vm.rememberedUsername === vm.username && vm.password && vm.password.trim())) {
                // if OTP or remember-me cookie is present for this username, authenticate
                login();
            } else if (vm.username && vm.username.trim() && vm.password && vm.password.trim()) {
                // else send OTP
                sendOTP();
            }
        }

        /**
         * Generate and send the OTP, then ask for it
         */
        function sendOTP(authType) {
            if (vm.isLoading)
                return;

            vm.authenticationError = null;
            vm.errorDetail = null;
            vm.isLoading = true;
            vm.otpHint = null;
            vm.showResendOtp = false;
            Otp.askOtp({
                "username": vm.username,
                "password": vm.password,
                "authType": authType
            })
                .then(function (response) {
                    const result = response.data;
                    vm.authenticationError = false;
                    vm.statusCode = null;
                    vm.askOTP = true;
                    $timeout(function () {
                        angular.element('#otp').focus();
                    });
                    vm.otpHint = result;

                    if (result.authType !== 'TEST')
                        $timeout(function () {
                            vm.showResendOtp = true;
                        }, 10000);
                })
                .catch(function (httpResponse) {

                    vm.authenticationError = true;
                    vm.statusCode = httpResponse.status;
                    if (httpResponse.status === 401) {
                        vm.errorDetail = httpResponse.data && httpResponse.data.detail;
                    }
                })
                .finally(() => {
                    vm.isLoading = false;
                });
        }

        function cancelOTP() {
            vm.otp = null;
            vm.askOTP = false;
            vm.authenticationError = null;

            $timeout(function () {
                angular.element('#username').focus();
            });
        }

        function login() {

            let rememberMe = vm.requestRememberMe || vm.hasRememberMeEnabled();

            if (vm.isLoading)
                return;

            vm.authenticationError = null;
            vm.errorDetail = null;
            vm.isLoading = true;
            Auth.login({
                username: vm.username,
                password: vm.password,
                otp: vm.otp,
                rememberMe: rememberMe
            }).then(function () {
                vm.loggedIn = true;
                vm.authenticationError = false;
                vm.statusCode = null;

                if ($window.localStorage) {
                    if(rememberMe) {
                        $window.localStorage.setItem('username', vm.username);
                    } else {
                        $window.localStorage.removeItem('username');
                    }
                }

                $rootScope.$broadcast('authenticationSuccess');

                $state.go('home');
                vm.isLoading = false;

            }).catch(function (httpResponse) {
                vm.isLoading = false;

                if (vm.otp) {
                    vm.showResendOtp = true;
                    $timeout(function () {
                        vm.otp = null;
                        $timeout(function () {
                            angular.element('#otp').focus();
                        });
                    }, 500);
                }
                vm.authenticationError = true;
                vm.statusCode = httpResponse.status;
                vm.errorDetail = httpResponse.data && httpResponse.data.detail;
            });
        }

        function requestResetPassword() {

            $state.go('requestReset');
        }

        function forgetMe() {
            $window.localStorage.removeItem('username');
            vm.rememberedUsername = null;
            vm.username = null;
            $cookies.remove('remember-me-enabled');

            $timeout(() => {
                angular.element('#username').focus();
            });
        }
    }
})();
