(function () {
    'use strict';

    var radioRating = {
        bindings: {
            form: '=',
            value: '=',
            required: '=',
            max: '=',
            group: '@',
            key: '@'
        },
        controller: RadioRating,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/radio-rating.html'
    };

    angular
        .module('continuumplatformApp')
        .component('radioRating', radioRating);

    RadioRating.$inject = [];

    function RadioRating() {

        var vm = this;

        vm.$onInit = function () {
            vm.options = [];
            for (var i = 0; i <= vm.max; i++) {
                vm.options.push(i);
            }
        };

    }

})();
