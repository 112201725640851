(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Directory', Directory);

    Directory.$inject = [];

    function Directory() {

        return {
            search: search,
            invite: invite
        };

        function search(criteria) {
            let fullName = "";
            if (criteria.lastName || criteria.firstName) fullName = criteria.lastName || criteria.firstName;
            if (criteria.lastName && criteria.firstName) fullName = criteria.lastName + " " + criteria.firstName;

            let professionCode = criteria.job && criteria.job.replace('J', '');

            return {
                method: 'GET',
                url: '/api/practitioner/search',
                params: {
                    nomComplet: fullName ? fullName : undefined,
                    page: criteria.page ? criteria.page - 1 : 0,
                    size: criteria.size ? criteria.size : 20,
                    professionCode: professionCode,
                    adresse: !criteria.latitude && !criteria.longitude ? criteria.address : undefined,
                    distance: criteria.latitude &&  criteria.longitude ? 99999 : undefined,
                    idPP: criteria.idpp,
                    savoirFaireCode: criteria.speciality ? criteria.speciality : undefined,
                    finess: criteria.finessEt ? criteria.finessEt : undefined,
                    denominationStructure: criteria.healthFacilityName,
                    latitude: criteria.latitude ? criteria.latitude : undefined,
                    longitude: criteria.longitude ? criteria.longitude : undefined,
                    modeExerciceCode: mapLegalStatusToModeExerciceCode(criteria.legalStatus)
                }
            };
        }

        function invite(result, practitioner) {

            practitioner.job = 'J' + result.profession.valeur;

            if (result.categorieProfessionnelle) {
                practitioner.category = result.categorieProfessionnelle.valeur;
            }

            if (result.idPP && result.typeIdNat_PP) {
                // https://mos.esante.gouv.fr/NOS/TRE_G08-TypeIdentifiantPersonne/TRE_G08-TypeIdentifiantPersonne.pdf
                // 0 : ADELI
                // 8 : RPPS
                if (result.typeIdNat_PP.valeur === '8') {
                    practitioner.rpps = result.idPP.valeur;
                } else if (result.typeIdNat_PP.valeur === '0') {
                    practitioner.adeli = result.idPP.valeur;
                }
            }

            if (practitioner.job === "J10" || practitioner.job === "J21") {
                practitioner.title = 'DR';
            }

            if (result.prenomExercice) {
                practitioner.firstName = result.prenomExercice.valeur;
            }
            if (result.nomExercice) {
                practitioner.lastName = result.nomExercice.valeur;
            }

            // Adresse
            practitioner.address = '';
            if (result.situationExercice.adresse.numeroVoie && result.situationExercice.adresse.numeroVoie.valeur) {
                practitioner.address += result.situationExercice.adresse.numeroVoie.valeur + " ";
            }
            if (result.situationExercice.adresse.typeVoie && result.situationExercice.adresse.typeVoie.libelle) {
                practitioner.address += result.situationExercice.adresse.typeVoie.libelle + " ";
            }
            if (result.situationExercice.adresse.libelleVoie && result.situationExercice.adresse.libelleVoie.valeur) {
                practitioner.address += result.situationExercice.adresse.libelleVoie.valeur;
            }
            if (result.situationExercice.adresse.codePostal) {
                practitioner.postalCode = result.situationExercice.adresse.codePostal.valeur;
            }
            if (result.situationExercice.adresse.localite) {
                practitioner.city = result.situationExercice.adresse.localite.valeur;
            }

            if (result.situationExercice.adresse.location) {
                practitioner.latitude = result.situationExercice.adresse.location.lat;
                practitioner.longitude = result.situationExercice.adresse.location.lon;
            }

            if (result.situationExercice.adresse.cogcommune) {
                practitioner.cityCode =  result.situationExercice.adresse.cogcommune.valeur;
            }

            if ((practitioner.job === "J10" || practitioner.job === "J60")  && result.savoirFaire && result.savoirFaire.length && result.savoirFaire[0].specialite) {
                practitioner.speciality = result.savoirFaire[0].specialite.valeur;

            } else if (practitioner.job === "J21" && result.situationExercice && result.situationExercice.sousSectionOrdrePharmacien && result.situationExercice.sousSectionOrdrePharmacien.valeur) {
                practitioner.speciality = "PHARM_" + result.situationExercice.sousSectionOrdrePharmacien.valeur;
            }

            if (result.modeExercice) {
                if (result.modeExercice.valeur === 'L') {
                    practitioner.legalStatus = 'LIBERAL';

                    if(['J10', 'J60'].includes(practitioner.job)) {
                        if(result.situationExercice.secteurActivite
                            && result.situationExercice.secteurActivite.valeur) {
                            practitioner.legalSubStatus = 'INSTALLED';
                        } else {
                            practitioner.legalSubStatus = 'SUBSTITUTE';
                        }
                    }
                }
                if (result.modeExercice.valeur === 'S') {
                    practitioner.legalStatus = 'EMPLOYEE';
                }
            }

            if (result.situationExercice.telecommunication.length > 0) {
                const tab = result.situationExercice.telecommunication;

                for (const t of tab) {
                    if (['FIX', '2'].includes(t.canal.valeur)) {
                        practitioner.phone = t.adresseTelecom.valeur;
                    }
                    if (['EML', '3'].includes(t.canal.valeur) && t.typeMessagerie && t.typeMessagerie.valeur === 'MSSANTE') {
                        practitioner.mssante = t.adresseTelecom.valeur;
                    }
                }
            }

            return practitioner;
        }

        function mapLegalStatusToModeExerciceCode(legalStatus) {
            switch (legalStatus) {
            case 'LIBERAL':
                return 'L';
            case 'EMPLOYEE':
                return 'S';
            default:
                return null;
            }
        }
    }

})();
