(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MyMediaController', MyMediaController);

    MyMediaController.$inject = ['$rootScope', '$scope', '$state', '$uibModal', 'Media', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Principal'];

    function MyMediaController($rootScope, $scope, $state, $uibModal, Media, ParseLinks, AlertService, paginationConstants, pagingParams, Principal) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;


        vm.loadAll = loadAll;

        vm.upload = upload;

        var unsubscribe = $rootScope.$on('continuumplatformApp:mediaUpdate', loadAll);
        $scope.$on('$destroy', function (event) {
            unsubscribe(event);
        });

        loadAll();

        function loadAll() {
            Media.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                "deletedDate.specified": false,
                "biologyResultId.specified": false,
                "sideEffectReportId.specified": false,
                "prescriptionId.specified": false,
                "patientTherapyId.specified": false,
                "pharmAnalysisId.specified": false
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.media = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')

            });
        }

        function upload() {
            Principal.identity().then(account => {
                $uibModal.open({
                    templateUrl: 'app/activity/media/media-upload.html',
                    controller: 'MediaUploadController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: () => ({
                            id: null,
                            date: moment().startOf('minute').toDate(),
                            patientId: account.patient.id,
                            notify: true
                        })
                    }
                }).result.then(() => {
                    // do nothing
                }, () => {
                    // do nothing
                });
            });
        }
    }
})();
