(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider

            .state('health-entity-contract-detail', {
                parent: 'account',
                url: '/health-entity-contract/{id}',
                data: {
                    authorities: ['ROLE_PRACTITIONER', 'ROLE_ADMIN'],
                    pageTitle: 'global.menu.account.sign'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/health-entity-contract/health-entity-contract-detail.html',
                        controller: 'HealthEntityContractDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('healthEntityContract');
                        return $translate.refresh();
                    }],
                    hec: ['HealthEntityContract', '$transition$', function (HealthEntityContract, $transition$) {
                        return HealthEntityContract.get({
                            id: $transition$.params().id
                        }).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name.replace('health-entity-contract-sign', ''),
                            params: $transition$.params('from')
                        };
                    }]
                }
            })
            .state('health-entity-contract-sign', {
                parent: 'account',
                url: '/health-entity-contract/{id}/sign',
                data: {
                    authorities: ['SIGN'],
                    pageTitle: 'global.menu.account.sign'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/health-entity-contract/health-entity-contract-sign.html',
                        controller: 'HealthEntityContractSignController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    hec: ['HealthEntityContract', '$transition$', function (HealthEntityContract, $transition$) {
                        return HealthEntityContract.get({
                            id: $transition$.params().id
                        }).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name.replace('health-entity-contract-detail', ''),
                            params: $transition$.params('from')
                        };
                    }]
                }
            });
    }
})();
