(function () {
    'use strict';

    var visitVitalSigns = {
        bindings: {
            data: '<',
            patientTherapy: '<',
        },
        controller: VisitVitalSignsController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-vital-signs.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitVitalSigns', visitVitalSigns);

    VisitVitalSignsController.$inject = ['$filter', '$rootScope', 'Measure','MeasureType'];

    function VisitVitalSignsController($filter, $rootScope, Measure, MeasureType) {

        const vm = this;

        vm.step = 'measures';

        vm.measureRules = MeasureType.getRules();

        vm.$onInit = () => {
            loadMeasureTypes();
            loadPreviousMeasures();
        };

        vm.finish = () => {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

        function loadMeasureTypes() {

            const measureTypes = new Set(vm.patientTherapy.measureTypes.filter(type => [
                'BLOOD_PRESSURE',
                'BLOOD_PRESSURE_SYSTOLIC',
                'BLOOD_PRESSURE_DIASTOLIC',
                'HEART_RATE',
                'SATO2',
                'RESPIRATORY_RATE',
                'TEMPERATURE',
                'BMI',
                'WEIGHT',
                'SIZE'
            ].includes(type)));

            // TODO convert virtual/calculated measureTypes
            if(measureTypes.has('BLOOD_PRESSURE')) {
                measureTypes.delete('BLOOD_PRESSURE');
                measureTypes.add('BLOOD_PRESSURE_SYSTOLIC');
                measureTypes.add('BLOOD_PRESSURE_DIASTOLIC');
            }
            if(measureTypes.has('BMI')) {
                measureTypes.delete('BMI');
                measureTypes.add('WEIGHT');
                measureTypes.add('SIZE');
            }

            vm.measureTypes = [...measureTypes];
        }

        function loadPreviousMeasures() {
            Measure.query({
                view: 'base',
                "patientId.equals": vm.patientTherapy.patientId,
                "sort": ["date,desc"],
                size: 100  // TODO hard-coded size
            }, function (previousMeasures) {
                vm.previousMeasures = $filter('groupBy')(previousMeasures, 'type');
            });
        }

    }

})();
