(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MedicationVariantDetailController', MedicationVariantDetailController);

    MedicationVariantDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity'];

    function MedicationVariantDetailController($scope, $rootScope, $stateParams, entity) {
        var vm = this;

        vm.medicationVariant = entity;

        // TODO display info

        var unsubscribe = $rootScope.$on('continuumplatformApp:medicationVariantUpdate', function (event, result) {
            vm.medicationVariant = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
