(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PharmAnalysisDeleteController', PharmAnalysisDeleteController);

    PharmAnalysisDeleteController.$inject = ['$uibModalInstance', 'entity'];

    function PharmAnalysisDeleteController($uibModalInstance, entity) {
        var vm = this;

        vm.pharmAnalysis = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete() {
            $uibModalInstance.close(true);
        }
    }
})();
