(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('ActionController', ActionController);

    ActionController.$inject = ['$uibModal', '$state', 'Action', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'previousState', 'patient'];

    function ActionController($uibModal, $state, Action, ParseLinks, AlertService, paginationConstants, pagingParams, previousState, patient) {

        const vm = this;

        vm.patient = patient;

        vm.previousState = previousState;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.addAction = addAction;
        vm.editAction = editAction;
        vm.changeActionStatus = changeActionStatus;
        vm.removeAction = removeAction;
        vm.loadPage = loadPage;
        vm.transition = transition;

        loadAll();

        function loadAll() {
            Action.query({
                "patientId.equals": patient.id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.actions = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                id: vm.patient.id,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function addAction() {
            $uibModal.open({
                templateUrl: 'app/activity/action/action-dialog.html',
                controller: 'ActionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            patientId: vm.patient.id,
                            type: 'PHARMA',
                            text: null,
                            status: 'S10_TODO',
                            actor: null,
                            id: null
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('action');
                        $translatePartialLoader.addPart('actionStatus');
                        return $translate.refresh();
                    }]
                }
            }).result.then(loadAll, () => {
                // nothing
            });
        }

        function editAction(action) {
            $uibModal.open({
                templateUrl: 'app/activity/action/action-dialog.html',
                controller: 'ActionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return Action.get({id: action.id});
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('action');
                        $translatePartialLoader.addPart('actionStatus');
                        return $translate.refresh();
                    }]
                }
            }).result.then(loadAll, () => {
                // nothing
            });
        }

        function changeActionStatus(action) {
            Action.update(action, result => {
                // no op
            });
        }

        function removeAction(action) {
            $uibModal.open({
                templateUrl: 'app/activity/action/action-delete-dialog.html',
                controller: 'ActionDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: () => action
                }
            }).result.then(loadAll, () => {
                // nothing
            });
        }
    }
})();
