(function () {
    'use strict';

    var patientInternalNoteList = {
        bindings: {
            patientTherapyIds: '<',
            patientTherapyId: '<'
        },
        controller: PatientInternalNoteListController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient-therapy/patient-internal-note-list.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientInternalNoteList', patientInternalNoteList);

    PatientInternalNoteListController.$inject = ['$rootScope', '$timeout', '$interval', 'InternalNoteService', 'AlertService'];

    function PatientInternalNoteListController($rootScope, $timeout, $interval, InternalNoteService, AlertService) {

        const vm = this;
        const REFRESH_INTERVAL = 60000;

        let stopLoadNotes;

        vm.internalNotes = [];
        vm.readonly = true;
        vm.internalNoteTextToSend = null;
        vm.mostRecentNoteDate = '1970-01-01T00:00:00.000Z';
        vm.isSendingNote = false;
        vm.isLoadingNotes = false;
        vm.isFirstLoading = true;
        vm.atLeastOneTherapyId = atLeastOneTherapyId;
        vm.addInternalNote = addInternalNote;

        const unsubscribeInternalNotesRefresh = $rootScope.$on('continuumplatformApp:internalNotesUpdate', () => {
            loadInternalNotes(vm.therapyIds);
        });

        vm.$onInit =  () => {

            if (vm.patientTherapyIds) {
                vm.therapyIds = vm.patientTherapyIds;
                vm.readonly = true;
            } else if (vm.patientTherapyId) {
                vm.therapyIds = [vm.patientTherapyId];
                vm.readonly = false;
            }

            loadInternalNotes(vm.therapyIds);

            if (!stopLoadNotes && vm.atLeastOneTherapyId(vm.therapyIds)) {
                stopLoadNotes = $interval(loadInternalNotes, REFRESH_INTERVAL, 0, vm.therapyIds);
            }
        };

        vm.$onDestroy = function () {
            stopLoadNotes && $interval.cancel(stopLoadNotes);
            unsubscribeInternalNotesRefresh();
        };

        function atLeastOneTherapyId(therapyIds) {
            return therapyIds && therapyIds.length;
        }

        function loadInternalNotes(therapyIds) {
            if (vm.isLoadingNotes || !vm.atLeastOneTherapyId(therapyIds)) {
                return;
            }

            vm.isLoadingNotes = true;

            InternalNoteService.query({
                "patientTherapyId.in": therapyIds,
                "date.greaterThan": vm.mostRecentNoteDate,
                "sort": "date,desc",
                "size": 1000
            }, onLoadSuccess, onError);
        }

        function onLoadSuccess(data) {
            vm.isLoadingNotes = false;

            if (data.length) {
                vm.mostRecentNoteDate = data[0].date;
                for (let i = data.length - 1; i >= 0; i--)
                    vm.internalNotes.unshift(data[i]);
            }
            if (vm.isFirstLoading) {
                vm.isFirstLoading = false;
            }
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function addInternalNote() {
            vm.isSendingNote = true;

            InternalNoteService.save({
                "text": vm.internalNoteTextToSend,
                "patientTherapyId": vm.patientTherapyId
            }, onInternalNoteAdded, onInternalNoteError);
        }

        function onInternalNoteAdded() {
            loadInternalNotes(vm.therapyIds);
            vm.isSendingNote = false;
            vm.internalNoteTextToSend = null;
            $timeout(function () {
                angular.element('.messaging textarea').focus();
            });
        }

        function onInternalNoteError() {

            vm.isSendingNote = false;
            $timeout(function () {
                angular.element('.messaging textarea').focus();
            });
        }
    }

})();
