(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Medication', Medication);

    Medication.$inject = ['$resource'];

    function Medication($resource) {
        const resourceUrl = 'api/medications/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'search': {
                url: 'api/medications/search',
                params: {
                    query: null,
                    patientId: null,
                    diseaseId: null
                },
                method: 'GET',
                isArray: true
            },
            'suggestRestrict': {
                url: 'api/medications/suggest-restrict',
                params: {
                    patientId: null,
                    diseaseId: null
                },
                method: 'GET',
                isArray: true
            },
            'suggestAssoc': {
                url: 'api/medications/suggest-assoc',
                params: {
                    diseaseId: null,
                    medicationIds: null
                },
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
