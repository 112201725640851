(function () {
    'use strict';

    var qualityOfLife = {
        bindings: {
            data: '=',
            questions: '<',
            patient: '='
        },
        controller: qualityOfLifeController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/qol-QLQ-C30.html'
    };

    angular
        .module('continuumplatformApp')
        .component('qualityOfLifeQlqC30', qualityOfLife);

    qualityOfLifeController.$inject = ['$rootScope', '$window', '$timeout', 'moment'];

    function qualityOfLifeController($rootScope, $window, $timeout, moment) {

        const vm = this;

        vm.step = 'QOL_QLQ_C30';
        vm.wizardStep = 0;

        vm.$onInit = function () {
            vm.questionsByStep = [
                [],
                [
                    {
                        questionNumber: 1,
                        questionType: 'Functional',
                        questionGroup: 'Physical functioning',
                        label: 'Avez-vous des difficultés à faire certains efforts physiques pénibles comme porter un sac à provisions chargé ou une valise?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }],
                    },
                    {
                        questionNumber: 2,
                        questionType: 'Functional',
                        questionGroup: 'Physical functioning',
                        label: 'Avez-vous des difficultés à faire une longue promenade?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 3,
                        questionType: 'Functional',
                        questionGroup: 'Physical functioning',
                        label: 'Avez-vous des difficultés à faire un petit tour dehors?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 4,
                        questionType: 'Functional',
                        questionGroup: 'Physical functioning',
                        label: 'Etes-vous obligé(e) de rester au lit ou dans un fauteuil pendant la journée?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 5,
                        questionType: 'Functional',
                        questionGroup: 'Physical functioning',
                        label: 'Avez-vous besoin d\'aide pour manger, vous habiller, faire votre toilette ou aller aux toilettes?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                ],
                [
                    {
                        questionNumber: 6,
                        questionType: 'Functional',
                        questionGroup: 'Role functioning',
                        label: 'Avez-vous été gêné(e) pour faire votre travail ou vos activités de tous les jours?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 7,
                        questionType: 'Functional',
                        questionGroup: 'Role functioning',
                        label: 'Avez-vous été gêné(e) dans vos activités de loisirs?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 8,
                        questionType: 'Symptom',
                        questionGroup: 'Dyspnoea',
                        label: 'Avez-vous eu le souffle court?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 9,
                        questionType: 'Symptom',
                        questionGroup: 'Pain',
                        label: 'Avez-vous ressenti de la douleur?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 10,
                        questionType: 'Symptom',
                        questionGroup: 'Fatigue',
                        label: 'Avez-vous eu besoin de repos?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 11,
                        questionType: 'Symptom',
                        questionGroup: 'Insomnia',
                        label: 'Avez-vous eu des difficultés pour dormir?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 12,
                        questionType: 'Symptom',
                        questionGroup: 'Fatigue',
                        label: 'Vous êtes-vous senti(e) faible?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 13,
                        questionType: 'Symptom',
                        questionGroup: 'Appetite loss',
                        label: 'Avez-vous manqué d\'appétit?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 14,
                        questionType: 'Symptom',
                        questionGroup: 'Nausea and vomiting',
                        label: 'Avez-vous eu des nausées (mal au coeur)?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 15,
                        questionType: 'Symptom',
                        questionGroup: 'Nausea and vomiting',
                        label: 'Avez-vous vomi?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 16,
                        questionType: 'Symptom',
                        questionGroup: 'Constipation',
                        label: 'Avez-vous été constipé(e)?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 17,
                        questionType: 'Symptom',
                        questionGroup: 'Diarrhoea',
                        label: 'Avez-vous eu de la diarrhée?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 18,
                        questionType: 'Symptom',
                        questionGroup: 'Fatigue',
                        label: 'Etiez-vous fatigué(e)?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 19,
                        questionType: 'Symptom',
                        questionGroup: 'Pain',
                        label: 'Des douleurs ont-elles perturbé vos activités quotidiennes?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 20,
                        questionType: 'Functional',
                        questionGroup: 'Cognitive functioning',
                        label: 'Avez-vous eu des difficultés à vous concentrer sur certaines choses, par exemple, pour lire le journal ou regarder la télévision?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 21,
                        questionType: 'Functional',
                        questionGroup: 'Emotional functioning',
                        label: 'Vous êtes-vous senti(e) tendu(e)?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 22,
                        questionType: 'Functional',
                        questionGroup: 'Emotional functioning',
                        label: 'Vous êtes-vous fait du souci?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 23,
                        questionType: 'Functional',
                        questionGroup: 'Emotional functioning',
                        label: 'Vous êtes-vous senti(e) irritable?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 24,
                        questionType: 'Functional',
                        questionGroup: 'Emotional functioning',
                        label: 'Vous êtes-vous senti(e) déprimé(e)?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 25,
                        questionType: 'Functional',
                        questionGroup: 'Cognitive functioning',
                        label: 'Avez-vous eu des difficultés pour vous souvenir de certaines choses?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 26,
                        questionType: 'Functional',
                        questionGroup: 'Social functioning',
                        label: 'Votre état physique ou votre traitement médical vous ont-ils gêné(e) dans votre vie familiale?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 27,
                        questionType: 'Functional',
                        questionGroup: 'Social functioning',
                        label: 'Votre état physique ou votre traitement médical vous ont-ils gêné(e) dans vos activités sociales (par exemple, sortir avec des amis, aller au cinéma...)?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                    {
                        questionNumber: 28,
                        questionType: 'Symptom',
                        questionGroup: 'Financial difficulties',
                        label: 'Votre état physique ou votre traitement médical vous ont-ils causé des problèmes financiers?',
                        possibleScores : [{value: 1, placeholder: 'pas du tout'  }, {value: 2, placeholder: 'un peu' }, {value: 3, placeholder: 'assez' }, {value: 4, placeholder: 'beaucoup' }]
                    },
                ],
                [
                    {
                        questionNumber: 29,
                        questionType: 'Global health status/QoL',
                        questionGroup: 'Global health status/QoL',
                        label: 'Comment évalueriez-vous votre état de santé au cours de la semaine passée?', possibleScores : [
                            {value: 1, placeholder: 'très mauvais' },
                            {value: 2},
                            {value: 3},
                            {value: 4},
                            {value: 5},
                            {value: 6},
                            {value: 7, placeholder: 'Excellent'},
                        ]
                    },
                    {
                        questionNumber: 30,
                        questionType: 'Global health status/QoL',
                        questionGroup: 'Global health status/QoL',
                        label: 'Comment évalueriez-vous l\'ensemble de votre qualité de vie au cours de la semaine passée?', possibleScores : [
                            {value: 1, placeholder: 'très mauvaise' },
                            {value: 2},
                            {value: 3},
                            {value: 4},
                            {value: 5},
                            {value: 6},
                            {value: 7, placeholder: 'excellente'},
                        ]
                    },
                ]
            ];

            vm.data = vm.data ? vm.data : {};

            if (!vm.data.date) {
                vm.data.date = new Date();
                vm.data.patient = {
                    birthDate: moment(vm.patient.birthDate).toDate(),
                    initials: vm.patient.initials,
                };
            } else {
                const flatQuestions = vm.questionsByStep.flat();
                for (let i = 0; i < flatQuestions.length; i++) {
                    flatQuestions[i].score = vm.data[`${i + 1}`];
                }
                if(vm.data.patient.birthDate) {
                    vm.data.patient.birthDate = moment(vm.data.patient.birthDate).toDate();
                }
                if (vm.data.date) {
                    vm.data.date = moment(vm.data.date).toDate();
                }
            }

        };

        vm.onClickValue = (question, score) => {
            if (!vm.data) {
                vm.data = {};
            }

            question.score = score;
            vm.data[question.questionNumber] = score;
        };

        vm.finish = function () {
            vm.data.scoring = {};

            const flatQuestions = vm.questionsByStep.flat();
            const rawData = {};

            // calcul des raw score
            flatQuestions.forEach((q) => {
                if (rawData[q.questionGroup]) {
                    rawData[q.questionGroup].totalScore += q.score;
                    rawData[q.questionGroup].nbQuestion++;
                } else {
                    rawData[q.questionGroup] = {totalScore: q.score, nbQuestion: 1, questionType: q.questionType, questionGroup: q.questionGroup};
                }
            });

            // calcul scoring
            Object.values(rawData).forEach((rawData) => {
                const rawScore = rawData.totalScore / rawData.nbQuestion;

                vm.data.scoring[rawData.questionType] = vm.data.scoring[rawData.questionType] ? vm.data.scoring[rawData.questionType] : {};

                if (rawData.questionType === 'Functional') {
                    vm.data.scoring[rawData.questionType][rawData.questionGroup] = (1 - ((rawScore - 1) / 3)) * 100;
                } else if (rawData.questionType === 'Symptom') {
                    vm.data.scoring[rawData.questionType][rawData.questionGroup] = ((rawScore - 1) / 3) * 100;

                } else if (rawData.questionType === 'Global health status/QoL') {
                    vm.data.scoring[rawData.questionType][rawData.questionGroup] = ((rawScore - 1) / 6) * 100;
                }
            });

            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

        vm.previous = function() {
            vm.wizardStep--;
            vm.scrollToTop();
        };

        vm.next = function() {
            vm.wizardStep++;
            vm.scrollToTop();
        };

        vm.scrollToTop = () => {
            $timeout(function () {
                $window.document.body.scrollTop = $window.document.documentElement.scrollTop = 0;
            });
        };

    }

})();
