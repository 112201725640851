(function () {
    'use strict';

    var pharmAnalysisContent = {
        bindings: {
            pharmAnalysis: '='
        },
        controller: PharmAnalysisContentController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/pharm-analysis/pharm-analysis-content.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmAnalysisContent', pharmAnalysisContent);

    PharmAnalysisContentController.$inject = [];

    function PharmAnalysisContentController() {

        // var vm = this;

    }

})();
