(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyMedicationAddDialogController', PatientTherapyMedicationAddDialogController);

    PatientTherapyMedicationAddDialogController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'options'];

    function PatientTherapyMedicationAddDialogController($rootScope, $scope, $uibModalInstance, options) {
        const vm = this;

        vm.options = options;
        vm.clear = clear;

        const unsubscribe = $rootScope.$on('continuumplatformApp:medication-selection:select', function (event, medication) {
            select(medication);
        });
        $scope.$on('$destroy', unsubscribe);

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function select(medication) {
            $uibModalInstance.close(medication);
        }

    }
})();
