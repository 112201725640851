(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(measureTypeConfig);

    measureTypeConfig.$inject = ['MeasureTypeProvider'];

    function measureTypeConfig(MeasureTypeProvider) {
        MeasureTypeProvider.addRules('BLOOD_PRESSURE', {
            name: 'Pression artérielle',
            unit: 'mmHg',
            virtual: true
        });
        MeasureTypeProvider.addRules('SIZE', {
            name: 'Taille',
            unit: 'cm',
            validation: {
                min: 30,
                max: 300,
                step: 1
            }
        });
        MeasureTypeProvider.addRules('WEIGHT', {
            name: 'Poids',
            unit: 'kg',
            validation: {
                min: 0,
                max: 300,
                step: .1,
            }
        });
        MeasureTypeProvider.addRules('BMI', {
            name: 'IMC',
            unit: 'kg/m²',
            calculated: true,
            validation: {
                min: 0,
                max: 100,
                step: .1
            }
        });
        MeasureTypeProvider.addRules('GFR', {
            name: 'Débit de filtration glomérulaire CDK-EPI',
            unit: 'mL/min/1,73 m²',
            validation: {
                min: 0,
                max: 200,
                step: .1
            }
        });
        MeasureTypeProvider.addRules('CLAIRANCE', {
            name: 'Clairance',
            unit: 'ml/min',
            validation: {
                min: 0, // TODO min / max ??????
                max: 200,
                step: .1
            }
        });
    }
})();
