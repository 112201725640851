(function () {
    'use strict';

    var patientTreatmentPhaseHoursText = {
        bindings: {
            phase: '=',
            default: '@'
        },
        controller: PatientTreatmentPhaseHoursTextController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient-treatment-phase/patient-treatment-phase-hours-text.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientTreatmentPhaseHoursText', patientTreatmentPhaseHoursText);

    PatientTreatmentPhaseHoursTextController.$inject = [];

    function PatientTreatmentPhaseHoursTextController() {

        var vm = this;

    }

})();
