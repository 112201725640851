(function () {
    'use strict';

    var patientTreatmentPhaseDaysText = {
        bindings: {
            phase: '=',
            default: '@',
            sum: '='
        },
        controller: PatientTreatmentPhaseDaysTextController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient-treatment-phase/patient-treatment-phase-days-text.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientTreatmentPhaseDaysText', patientTreatmentPhaseDaysText);

    PatientTreatmentPhaseDaysTextController.$inject = [];

    function PatientTreatmentPhaseDaysTextController() {

        const vm = this;

        vm.daysInCycle = {
            'CYCLE_7': 7,
            'CYCLE_14': 14,
            'CYCLE_21': 21,
            'CYCLE_28': 28
        };

    }

})();
