(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SideEffectReportListController', SideEffectReportListController);

    SideEffectReportListController.$inject = ['$state', '$stateParams', 'SideEffectReport', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'SideEffect', 'DownloadFile', 'HealthFacility'];

    function SideEffectReportListController($state, $stateParams, SideEffectReport, ParseLinks, AlertService, paginationConstants, pagingParams, SideEffect, DownloadFile, HealthFacility) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.exportInProgress = false;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        HealthFacility.query({
            view: 'summary',
            'type.equals': 'PHARM_LAB',
            size: 1000,
            sort: 'name'
        }).$promise
            .then(function (response) {
                if (response) {
                    vm.pvLaboratory = response;
                } else {
                    vm.pvLaboratory = [];
                }
            });

        HealthFacility.getPvCenters({
            size: 1000
        }).$promise
            .then(function (response) {
                if (response) {
                    vm.pvCenter = response;
                } else {
                    vm.pvCenter = [];
                }
            });

        vm.loadAll = loadAll;

        vm.sideeffects = SideEffect.query({
            sort: "name",
            size: 1000 // TODO search
        });

        vm.scores = [-1, 0, 1, 2, 3, 4];

        vm.pvCenterStatuses = ['PENDING', 'PROCESSED', 'IGNORED'];

        vm.advancedSearch = {
            score: $stateParams.score,
            sideEffectId: $stateParams.sideEffectId,
            patientId: $stateParams.patientId,
            pvLaboratoryId: $stateParams.pvLaboratoryId,
            pvCenterId: $stateParams.pvCenterId,
            pvCenterStatus: $stateParams.pvCenterStatus,
            fromDate: $stateParams.fromDate,
            toDate: $stateParams.toDate
        };

        vm.exportSideEffectReports = exportAllNewSideEffectReports;

        loadAll();

        function loadAll() {
            SideEffectReport.query({
                "score.in": vm.advancedSearch.score,
                "sideEffectId.equals": vm.advancedSearch.sideEffectId,
                "patientId.equals": vm.advancedSearch.patientId,
                "pvLaboratoryId.equals": vm.advancedSearch.pvLaboratoryId,
                "pvCenterId.equals": vm.advancedSearch.pvCenterId,
                "pvCenterStatus.equals": vm.advancedSearch.pvCenterStatus,
                "date.greaterThanOrEqual": vm.advancedSearch.fromDate,
                "date.lessThanOrEqual": vm.advancedSearch.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.error = null;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.sideEffectReports = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                vm.error = error;
                vm.sideEffectReports = [];
            }
        }

        function exportAllNewSideEffectReports() {
            vm.exportInProgress = true;

            SideEffectReport.downloadQuery({
                "score.in": vm.advancedSearch.score,
                "sideEffectId.equals": vm.advancedSearch.sideEffectId,
                "patientId.equals": vm.advancedSearch.patientId,
                "pvLaboratoryId.equals": vm.advancedSearch.pvLaboratoryId,
                "pvCenterId.equals": vm.advancedSearch.pvCenterId,
                "pvCenterStatus.equals": vm.advancedSearch.pvCenterStatus,
                "date.greaterThanOrEqual": vm.advancedSearch.fromDate,
                "date.lessThanOrEqual": vm.advancedSearch.toDate,
                sort: "date,desc"
            }).$promise
                .then(DownloadFile.downloadFile)
                .finally(() => vm.exportInProgress = !vm.exportInProgress);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                score: vm.advancedSearch.score,
                sideEffectId: vm.advancedSearch.sideEffectId,
                patientId: vm.advancedSearch.patientId,
                pvLaboratoryId: vm.advancedSearch.pvLaboratoryId,
                pvCenterId: vm.advancedSearch.pvCenterId,
                pvCenterStatus: vm.advancedSearch.pvCenterStatus,
                fromDate: vm.advancedSearch.fromDate,
                toDate: vm.advancedSearch.toDate
            });
        }
    }
})();
