(function () {
    'use strict';

    const visitDateOfNextMedicalConsult = {
        bindings: {
            patient: '<',
            data: '=',
            questions: '<'
        },
        controller: VisitDateOfNextMedicalConsult,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-date-of-next-medical-consult.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitDateOfNextMedicalConsult', visitDateOfNextMedicalConsult);

    VisitDateOfNextMedicalConsult.$inject = ['$rootScope', 'Visit'];

    function VisitDateOfNextMedicalConsult($rootScope, Visit) {

        const vm = this;

        vm.onChangeDateOfNextConsult = onChangeDateOfNextConsult;

        vm.$onInit = function () {
            vm.step = 'DATE_OF_NEXT_MEDICAL_CONSULTATION';

            if (!vm.data) {
                vm.MIN_DATE = moment().format('YYYY-MM-DD');
                vm.data = {};
            } else {
                vm.data.Q01 = vm.data.Q01 ? new Date(vm.data.Q01) : null;
                vm.MIN_DATE = moment(vm.data.Q01).format('YYYY-MM-DD');
            }

            loadStepHistory();
        };

        vm.$onDestroy = () => {

        };

        function loadStepHistory() {
            return Visit.query({
                "patientId.equals": vm.patient.id,
                "sort": "date,desc",
                "status.equals": "DONE",
                "steps.contains": "DATE_OF_NEXT_MEDICAL_CONSULTATION",
                "size": 1
            }).$promise.then(function (visits) {

                if (visits && visits.length) {
                    const lastDateOfNextConsult = moment(visits[0].data.details.DATE_OF_NEXT_MEDICAL_CONSULTATION.Q01);

                    if (lastDateOfNextConsult.isSameOrAfter(moment(), 'day')) {
                        vm.data.Q01 = lastDateOfNextConsult.toDate();
                        onChangeDateOfNextConsult();
                    }
                }
            });
        }

        function onChangeDateOfNextConsult() {
            if (vm.data.Q01) {
                $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                    step: vm.step
                });
            } else {
                $rootScope.$emit('continuumplatformApp:visit-wizard:invalidateStep', {
                    step: vm.step
                });
            }
        }

    }

})();
