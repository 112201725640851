(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientContactsController', PatientContactsController);

    PatientContactsController.$inject = ['previousState', 'entity', 'Principal', 'Practitioner', 'HealthFacility', 'CareTeam'];

    function PatientContactsController(previousState, entity, Principal, Practitioner, HealthFacility, CareTeam) {
        const vm = this;

        vm.patient = entity;
        vm.previousState = previousState;
        vm.closeAwayMessage = closeAwayMessage;

        vm.$onInit = function () {
            if (vm.patient.healthFacilityId) {
                vm.healthFacility = HealthFacility.get({id: vm.patient.healthFacilityId, view: 'summary'});
            }

            Principal.identity().then(function (account) {
                vm.currentUser = account;
                vm.isPatient = account.authorities.includes('ROLE_PATIENT');
            });

            loadCareTeam(vm.patient.id);
        };

        function loadCareTeam(patientId) {
            vm.team = [];
            return CareTeam.getMembers(patientId).then(response => {
                vm.team = response.data;
                return vm.careTeamMemberAway = CareTeam.getAwayMembers(vm.team);
            });
        }

        function closeAwayMessage() {
            vm.careTeamMemberAway = [];
        }

    }
})();
