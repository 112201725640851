(function () {
    'use strict';

    const contractInProgressItem = {
        bindings: {
            contract: '=',
        },
        controller: ContractInProgressItemController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/contract-in-progress/contract-in-progress-item.html'
    };

    angular
        .module('continuumplatformApp')
        .component('contractInProgressItem', contractInProgressItem);

    ContractInProgressItemController.$inject = [];

    function ContractInProgressItemController() {

        const vm = this;

        vm.$onInit = function () {

            vm.customerName = vm.contract.customerName;
            vm.contractLines = [...new Set(vm.contract.lines.map(cl => (cl.diseaseName||'*') + ' / ' + (cl.treatmentGroupName||'*')))];
            vm.endDate = vm.contract.endDate;
            vm.consumption = vm.contract.quota ? `${vm.contract.consumption || 0}/${vm.contract.quota}` : '';

        };

    }

})();
