(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Article51', Article51);

    Article51.$inject = ['$rootScope', '$http', 'Upload'];

    function Article51($rootScope, $http, Upload) {

        return {count, select, insert, exportConvention, exportPaiement, importResults, clear, export19GRE08FacilityReport};

        function count(code, history) {
            return $http.get('api/article51/count', {
                params: {code, history}
            });
        }

        function select(code, history, page, size) {
            return $http.get('api/article51/select', {
                params: {code, history, page, size}
            });
        }

        function insert(code) {
            return $http.post('api/article51/insert', code);
        }

        function exportConvention(code) {
            return $http.get('api/article51/export-convention', {
                params: {code}
            });
        }

        function exportPaiement(code) {
            return $http.get('api/article51/export', {
                params: {code}
            });
        }

        function importResults(file) {
            return Upload.upload({
                url: 'api/article51/import-results',
                method: 'POST',
                arrayKey: '',
                data: {file}
            });
        }

        function clear(code) {
            return $http.delete('api/article51/clear', {
                params: {code}
            });
        }

        function export19GRE08FacilityReport() {
            return $http.get('api/article51/export-19GRE08-facility-report');
        }

    }

})();
