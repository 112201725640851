(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('pharmAnalysisStatus', pharmAnalysisStatus);

    function pharmAnalysisStatus() {

        return {
            restrict: 'E',
            scope: {
                status: '@'
            },
            templateUrl: 'app/activity/pharm-analysis/pharm-analysis-status.html'
        };


    }

})();
