(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Prescription', Prescription);

    Prescription.$inject = ['$resource', 'DateUtils'];

    function Prescription($resource, DateUtils) {
        const resourceUrl = 'api/prescriptions/:id';

        const resource = $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertLocalDateFromServer(data.date);
                    }
                    return data;
                }
            }
        });

        delete resource.save;

        return resource;

    }
})();
