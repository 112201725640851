(function () {
    'use strict';

    const visitDetailMedicalSocialPatientProfile = {
        bindings: {
            data: '=',
            patient: '<'
        },
        controller: VisitDetailMedicalSocialPatientProfileController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-detail-medical-social-patient-profile.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitDetailMedicalSocialPatientProfile', visitDetailMedicalSocialPatientProfile);

    VisitDetailMedicalSocialPatientProfileController.$inject = [];

    function VisitDetailMedicalSocialPatientProfileController() {
    }

})();
