(function () {
    'use strict';

    const visitTodoItem = {
        bindings: {
            visit: '=',
            height: '=',
        },
        controller: VisitTodoItemController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/visits-todo/visit-todo-item.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitTodoItem', visitTodoItem);

    VisitTodoItemController.$inject = ['Visit'];

    function VisitTodoItemController(Visit) {

        const vm = this;

        /**
         * Initializes the component.
         */
        vm.$onInit = function () {

            vm.isStartable = Visit.isStartable(vm.visit);
            vm.isResumable = Visit.isResumable(vm.visit);

        };

    }

})();
