(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('ThreadsService', ThreadsService);

    ThreadsService.$inject = ['$rootScope', '$http'];

    function ThreadsService($rootScope, $http) {

        return {threadDump};

        function threadDump() {
            return $http.get('management/threaddump').then(response => response.data);
        }
    }
})();
