(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('patient-detail.media-list', {
                url: '/media-list?page&sort',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'continuumplatformApp.media.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/media/media.html',
                        controller: 'MediaController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true,
                        inherit: false
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true,
                        inherit: false
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('media');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    patient: ['$stateParams', 'Patient', function ($stateParams, Patient) {
                        return Patient.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name || 'patient-detail',
                            params: $transition$.from().name ? $transition$.params('from') : {id: $transition$.params().id}
                        };
                    }]
                }
            })
            .state('media-detail', {
                parent: 'patients',
                url: '/media/{id}',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'continuumplatformApp.media.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/media/media-detail.html',
                        controller: 'MediaDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('media');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Media', function ($stateParams, Media) {
                        return Media.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name,
                            params: $transition$.params('from')
                        };
                    }]
                }
            })
            .state('media-detail.edit', {
                url: '/edit',
                data: {
                    authorities: ['PATIENT_HEALTH_READ']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/activity/media/media-dialog.html',
                        controller: 'MediaDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Media', function (Media) {
                                return Media.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('media-detail.delete', {
                url: '/delete',
                data: {
                    authorities: ['PATIENT_HEALTH_READ']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/activity/media/media-delete-dialog.html',
                        controller: 'MediaDeleteController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Media', function (Media) {
                                return Media.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('media', null, {reload: 'media'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
