(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('BillDialogController', BillDialogController);

    BillDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'bill', 'healthEntity', 'Bill', 'HealthFacility'];

    function BillDialogController($timeout, $scope, $stateParams, $uibModalInstance, bill, healthEntity, Bill, HealthFacility) {
        const vm = this;

        vm.bill = bill;
        vm.healthEntity = healthEntity;
        vm.clear = clear;
        vm.save = save;

        vm.$onInit = function () {
            $timeout(function () {
                angular.element('.form-group:eq(0)>select').focus();
            });

            loadCustomer();
        };

        function loadCustomer() {
            HealthFacility.query({
                "type.equals": "COORDINATION"
            }).$promise.then(customers => {
                vm.customers = customers;
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.bill.id !== null) {
                Bill.update(vm.bill, onSaveSuccess, onSaveError);
            } else {
                Bill.save(vm.bill, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('continuumplatformApp:billUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
