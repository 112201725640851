(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyHistoryController', PatientTherapyHistoryController);

    PatientTherapyHistoryController.$inject = ['$translate', '$window', '$state', '$uibModal', 'entity', 'PatientTherapy', 'ContractLine', 'Coordination', 'Principal'];

    function PatientTherapyHistoryController($translate, $window, $state, $uibModal, entity, PatientTherapy, ContractLine, Coordination, Principal) {
        const vm = this;

        vm.patient = entity;

        vm.newRequest = newRequest;

        vm.$onInit = function () {
            setTitle();
            loadAccount();
            loadTherapies()
                .then((patientTherapies) => {
                    if (patientTherapies && patientTherapies.length) {
                        return Coordination.getNextContractLines(patientTherapies[0].id);
                    } else {
                        return Promise.resolve(null);
                    }
                })
                .then((nextContractLines) => {
                    vm.nextContractLines = nextContractLines;
                    vm.hasNextContractLine = !!nextContractLines && !!nextContractLines.length;
                });
        };

        vm.$onDestroy = function () {

        };

        function loadAccount() {
            Principal.hasAuthority('PATIENT_THERAPY_MANAGE')
                .then(hasAuthority => vm.hasPatientTherapyManagementAuthority = hasAuthority);
        }

        function setTitle() {
            $translate('continuumplatformApp.patient.coordination.title', {initials: entity.initials}).then(title => {
                $window.document.title = title;
            });
        }

        function loadTherapies() {
            return PatientTherapy.query({
                "patientId.equals": vm.patient.id,
                "sort": ['startDate,desc', 'id,desc']

            }).$promise.then((patientTherapies) => {
                vm.patientTherapies = patientTherapies;
                vm.patientTherapyIds = patientTherapies.map((therapy) => therapy.id);

                return vm.patientTherapies;
            });
        }

        function newRequest() {
            const lastPatientTherapy = vm.patientTherapies[0];
            Coordination.newRequest(vm.patient.id, lastPatientTherapy, vm.nextContractLines);
        }

    }
})();
