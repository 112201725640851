(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('Coordination', Coordination);

    Coordination.$inject = ['$rootScope', '$state', '$window', '$interval', '$uibModal', 'PatientTherapy'];

    function Coordination($rootScope, $state, $window, $interval, $uibModal, PatientTherapy) {


        const service = {
            renew: function(patientTherapyId, data) {
                return PatientTherapy.renew({id: patientTherapyId}, data).$promise;
            },

            getNextContractLines: function (patientTherapyId) {
                if (!patientTherapyId) {
                    return Promise.resolve([]);
                }

                return PatientTherapy.nextContractLines({id: patientTherapyId}).$promise.then((nextContractLines) => nextContractLines);
            },

            newRequest: function (patientId, patientTherapy, nextContractLines) {

                const hasNextContractLine = !!nextContractLines && !!nextContractLines.length;

                // si suivie arrêté ou terminé et qu'on à un suivie suivant de pré-défini
                if (patientTherapy && ['S40_FINISHED', 'S50_STOPPED'].includes(patientTherapy.status) && hasNextContractLine
                    && patientTherapy.programCode !== 'oncolink'
                ) {

                    return new Promise((resolve, reject) => {
                        $uibModal.open({
                            templateUrl: 'app/activity/patient-therapy/patient-therapy-new-dialog.html',
                            controller: 'PatientTherapyNewDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                patientTherapy: () => patientTherapy,
                                nextContractLines: () => nextContractLines
                            }
                        }).result.then(({action, therapyId}) => {
                            switch (action) {
                            case 'NEW_REQUEST':
                                $state.go('patient-detail.request-therapy', {id: patientId});
                                resolve('NEW_REQUEST');
                                break;

                            case 'NEXT_THERAPY':
                                PatientTherapy.nextPatientTherapy({id: patientTherapy.id}, {therapyId}).$promise
                                    .then((result) => {
                                        PatientTherapy.get({id: result.patientTherapyId}).$promise.then(nextPatientTherapy => {
                                            if (nextPatientTherapy.actions.includes('START')) {
                                                openStartDialog(result.patientTherapyId);
                                            } else {
                                                $state.go('patient-therapy-detail', {id: result.patientTherapyId});
                                            }
                                        });
                                    });
                                resolve('NEXT_THERAPY');
                                break;

                            default:
                                resolve('NEW_REQUEST');
                                $state.go('patient-detail.request-therapy', {id: patientId});
                            }

                        }).catch(() => {
                            // nothing
                            resolve(null);
                        });
                    });
                } else {
                    $state.go('patient-detail.request-therapy', {id: patientId});
                    return Promise.resolve('NEW_REQUEST');
                }
            },
        };

        function openStartDialog(patientTherapyId) {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-start.html',
                controller: 'PatientTherapyStartController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['PatientTherapy', (PatientTherapy) => PatientTherapy.get({id: patientTherapyId}).$promise],
                }
            }).result.then(function () {
                $state.go('patient-therapy-detail', {id: patientTherapyId});
            }, function () {
                $state.go('patient-therapy-detail', {id: patientTherapyId});
            });
        }

        return service;
    }

})();
