(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('patientTreatmentStatus', patientTreatmentStatus);

    function patientTreatmentStatus() {

        return {
            restrict: 'E',
            scope: {
                status: '='
            },
            templateUrl: 'app/activity/patient-treatment/patient-treatment-status.html'
        };


    }

})();
