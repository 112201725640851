(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(toasterServiceConfig);

    toasterServiceConfig.$inject = ['NotificationProvider'];

    function toasterServiceConfig(NotificationProvider) {
        NotificationProvider.setOptions({
            delay: 10000,
            startTop: 20,
            startRight: 10,
            verticalSpacing: 20,
            horizontalSpacing: 20,
            positionX: 'left',
            positionY: 'bottom',
            maxCount: 8,
        });
    }
})();
