(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('Matomo', Matomo);

    Matomo.$inject = ['MATOMO_ENABLED', 'ProfileService', '$log', '$window'];

    function Matomo(MATOMO_ENABLED, ProfileService, $log, $window) {

        const host = 'matomo.continuumplus.net';
        const localSiteId = 2;
        const productionSiteId = 3;
        let initialized = false;
        $window['_paq'] = $window['_paq'] || [];

        return {
            init: init
        };

        function init(userId) {
            if(!MATOMO_ENABLED) {
                $log.debug("Matomo is disabled, skipped initialization.");
                return;
            }

            if(!initialized) {
                initialized = true;

                getSiteId().then(siteId => {

                    if(!siteId) {
                        $log.debug("Matomo has no site id, won't be activated.");
                        return;
                    }

                    trackPageView();
                    if(userId) {
                        $window['_paq'].push(['setUserId', userId]);
                    }
                    $window['_paq'].push(['enableLinkTracking']);

                    (function () {
                        var u = "//" + host + "/";
                        $window['_paq'].push(['setTrackerUrl', u + 'matomo.php']);
                        $window['_paq'].push(['setSiteId', siteId]);
                        var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
                        g.type = 'text/javascript';
                        g.async = true;
                        g.src = u + 'matomo.js';
                        s.parentNode.insertBefore(g, s);
                    })();

                    $window.addEventListener('hashchange', trackPageView);
                });
            }
        }

        function trackPageView() {
            if(!MATOMO_ENABLED) {
                $log.debug("Matomo is disabled, skipped tracking.");
                return;
            }

            $log.debug("Matomo: track page view", '/' + $window.location.hash);
            $window['_paq'].push(['setCustomUrl', '/' + $window.location.hash]);
            $window['_paq'].push(['setDocumentTitle', $window.location.hash]); // title can contain personal information, so we use location hash instead
            $window['_paq'].push(['trackPageView']);
        }

        function getSiteId() {
            return ProfileService.getProfileInfo().then(response => {
                if(response.local) {
                    return localSiteId;
                } else if(response.inProduction) {
                    return productionSiteId;
                }
                return null;
            });
        }
    }
})();
