(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {

        $stateProvider.state('login', {
            parent: 'root',
            url: '/login',
            data: {
                pageTitle: 'login.title',
                public: true
            },
            views: {
                'root@': {
                    templateUrl: 'app/layouts/simple.html',
                    controller: 'SimpleLayoutController',
                    controllerAs: 'vm'
                },
                'content@login': {
                    templateUrl: 'app/account/login/login.html',
                    controller: 'LoginController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                    $translatePartialLoader.addPart('login');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
