(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('Otp', Otp);

    Otp.$inject = ['$http', '$log'];

    function Otp($http, $log) {

        return {askOtp, resendInitialOtp};

        function askOtp(data, disableRetry) {
            return $http.post('api/otp', data).catch((response) => {
                if (response.status === 403
                    && response.data
                    && response.data.detail
                    && response.data.detail.includes('CSRF')) {
                    if (!disableRetry) {
                        $log.warn('CSRF failure. Retrying OTP request...');
                        // first make a GET request to get a CSRF token for following POST
                        return $http.head('api/empty').then(() => askOtp(data, true));
                    }
                }
                throw response;
            });
        }

        function resendInitialOtp(key) {
            return $http.post('api/otp-initial', key);
        }

    }
})();
