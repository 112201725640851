(function () {
    'use strict';

    const visitAnnouncementConsultationSynthesis = {
        bindings: {
            patient: '<',
            data: '=',
            questions: '<'
        },
        controller: VisitAnnouncementConsultationSynthesis,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-announcement-consultation-synthesis.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitAnnouncementConsultationSynthesis', visitAnnouncementConsultationSynthesis);

    VisitAnnouncementConsultationSynthesis.$inject = ['$rootScope'];

    function VisitAnnouncementConsultationSynthesis($rootScope) {

        const vm = this;

        vm.$onInit = function () {
            vm.step = 'ANNOUNCEMENT_CONSULTATION_SYNTHESIS';
            vm.isSaving = false;
        };

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };
    }

})();
