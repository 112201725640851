(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('article51', {
                parent: 'remuneration',
                url: '/article51',
                data: {
                    authorities: ['ROLE_ARTICLE51_BILL'],
                    pageTitle: 'custom'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/article51/article51.html',
                        controller: 'Article51Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('article51-report', {
                parent: 'remuneration',
                url: '/article51-report',
                data: {
                    authorities: ['ARTICLE51_REPORT_19GRE08'],
                    pageTitle: 'custom'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/article51/article51-report.html',
                        controller: 'Article51ReportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
