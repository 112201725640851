(function () {
    'use strict';

    const hdjBlock = {
        bindings: {
            filters: '=',
        },
        controller: HdjBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/hdj/hdj-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('hdjBlock', hdjBlock);

    HdjBlockController.$inject = ['$scope', '$state', 'DashboardService'];

    function HdjBlockController($scope, $state, DashboardService) {

        const vm = this;

        /**
         * Initialize the component.
         */
        vm.$onInit = function () {

            vm.datasource = DashboardService.createUiScrollDatasource(DashboardService.loadHdj, null, vm.filters);
            // On récupère toutes les données à l'initialisation, donc on ne peut pas charger de données supplémentaires par la suite
            vm.datasource.shouldLoadMoreData = () => {
                if (vm.initialDataLoaded) {
                    return false;
                } else {
                    vm.initialDataLoaded = true;
                    return true;
                }
            };

            vm.unsubscribeWatchFilter = $scope.$watch('vm.filters', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    vm.initialDataLoaded = false;
                    vm.datasource.reloadDatasource();
                }
            }, true);

        };

        /**
         * Call when component is destroyed.
         */
        vm.$onDestroy = function () {
            if (vm.unsubscribeWatchFilter) {
                vm.unsubscribeWatchFilter();
            }
        };

        vm.onClick = () => {
            $state.go('visit-pending');
        };

    }

})();
