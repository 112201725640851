(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('settings-practitioner', {
                parent: 'account',
                url: '/settings-practitioner',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'global.menu.account.settings'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/account/settings-practitioner/settings-practitioner.html',
                        controller: 'SettingsPractitionerController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settings');
                        $translatePartialLoader.addPart('settings-practitioner');
                        $translatePartialLoader.addPart('practitioner');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        return $translate.refresh();
                    }],
                    practitioner: ['Principal', function (Principal) {
                        return Principal.identity().then(function (account) {
                            return angular.copy(account.practitioner);
                        });
                    }]
                }
            })
            .state('settings-practitioner-detail', {
                parent: 'account',
                url: '/settings-practitioner-detail',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'global.menu.account.settings'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/account/settings-practitioner/settings-practitioner-detail.html',
                        controller: 'SettingsPractitionerDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settings');
                        $translatePartialLoader.addPart('settings-practitioner');
                        $translatePartialLoader.addPart('practitioner');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('user');
                        return $translate.refresh();
                    }],
                    practitioner: ['Principal', function (Principal) {
                        return Principal.identity().then(function (account) {
                            return angular.copy(account.practitioner);
                        });
                    }]
                }
            });
    }
})();
