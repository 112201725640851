(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MeasureDeleteController', MeasureDeleteController);

    MeasureDeleteController.$inject = ['$uibModalInstance', '$scope', 'entity', 'Measure'];

    function MeasureDeleteController($uibModalInstance, $scope, entity, Measure) {
        var vm = this;

        vm.measure = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            Measure.delete({id: id},
                function () {
                    $scope.$emit('continuumplatformApp:measureUpdate');
                    $uibModalInstance.close(true);
                });
        }
    }
})();
