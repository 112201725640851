(function () {
    'use strict';

    var pharmaItwEndReviewPicto = {
        bindings: {
            data: '=',
            questions: '<'
        },
        controller: PharmaItwEndReviewPictoController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/pharma-itw-end-review-picto.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmaItwEndReviewPicto', pharmaItwEndReviewPicto);

    PharmaItwEndReviewPictoController.$inject = ['$rootScope'];

    function PharmaItwEndReviewPictoController($rootScope) {

        var vm = this;

        vm.step = 'PHARMA_ITW_END_REVIEW_PICTO';

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

    }

})();
