(function () {
    'use strict';

    const patientQualityOfLifeBlock = {
        bindings: {
            visits: '=',
        },
        controller: PatientQualityOfLifeBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/qol/patient-qol-QLQ-C30-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientQualityOfLifeQlqC30Block', patientQualityOfLifeBlock);

    PatientQualityOfLifeBlockController.$inject = ['$uibModal', 'moment'];

    function PatientQualityOfLifeBlockController($uibModal, moment) {

        const vm = this;
        vm.showSymptom = false;
        vm.showFunctional = false;
        vm.showGlobalHealth = false;

        vm.$onInit = function () {
            // liste des visites avec des données de qualité de vie QLQ C30 triée par date
            const visitWithQlqC30 = vm.visits.filter( (v) => !!v.data.details.QOL_QLQ_C30 && v.data.details.QOL_QLQ_C30.scoring).sort((v1, v2) => v1.date.localeCompare(v2.date));

            vm.qlqC30 = visitWithQlqC30.map((v) => v.data.details.QOL_QLQ_C30);

            initGraph();


            const dataGroupByGroup = new Map();
            vm.qlqC30.forEach((data) => {
                const scoring = data.scoring;
                for (const dataGroup in scoring) {
                    for (const key in scoring[dataGroup]) {
                        const group = dataGroupByGroup.get(key);
                        if (group) {
                            group.max = Math.max(scoring[dataGroup][key], group.max).toFixed(0);
                            group.min = Math.min(scoring[dataGroup][key], group.min).toFixed(0);
                            group.sparkline[0].push(scoring[dataGroup][key].toFixed(2));

                        } else {
                            dataGroupByGroup.set(key, {
                                lastScore: {value: vm.qlqC30[vm.qlqC30.length - 1].scoring[dataGroup][key].toFixed(0)},
                                max: scoring[dataGroup][key].toFixed(2),
                                min: scoring[dataGroup][key].toFixed(2),
                                sparkline: [[scoring[dataGroup][key].toFixed(0)], []],
                                type: key,
                                dataGroup,
                            });
                        }
                    }
                }
            });

            vm.functionalScoring = [];
            vm.globalHealthStatusScoring = [];
            vm.symptomScoring = [];

            Array.from(dataGroupByGroup.values()).forEach((d) => {
                switch (d.dataGroup) {
                case 'Global health status/QoL':
                    vm.globalHealthStatusScoring.push(d);
                    break;
                case 'Functional':
                    vm.functionalScoring.push(d);
                    break;
                case 'Symptom' :
                    vm.symptomScoring.push(d);
                    break;
                }
            });
        };

        vm.openChart = (group, type) => {
            $uibModal.open({
                templateUrl: 'app/activity/qol/qol-QLQ-C30-chart.html',
                controller: 'QolQlqC30ChartController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    type: () => type,
                    data: () => vm.qlqC30.map(( data) => ({date: moment(data.date).toDate(), value: data.scoring[group][type],})),
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('qolType');
                        return $translate.refresh();
                    }],
                }
            }).result.then(function () {
            }, function () {
            });
        };

        function initGraph() {
            vm.chart = {
                series: ['Scores'],
                labels: [],
                data: [[]],
                options: {
                    title: {
                        display: false,
                        text: '',
                        fontSize: 16
                    },
                    scales: {
                        yAxes: [
                            {
                                display: true,
                                scaleLabel: {display: true},
                                ticks: {
                                    stepSize: 1
                                }
                            }
                        ]
                    },
                },
                datasetOverride: [{}],
            };

            vm.visits.sort((v1, v2) => {
                return v1.date.localeCompare(v2.date);
            }).forEach((v) => {
                vm.chart.labels.push(moment(v.date).format('DD/MM/YY'));
                vm.chart.data[0].push(v.data.details['QOL_QLQ_C30'].scoring['Global health status/QoL']['Global health status/QoL']);
            });

        }


    }

})();
