(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('healthFacilityProfile', healthFacilityProfile);

    function healthFacilityProfile() {

        return {
            restrict: 'E',
            scope: {
                healthFacility: '='
            },
            templateUrl: 'app/entities/health-facility/health-facility-profile.html'
        };

    }

})();
