(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('fegala-report', {
                parent: 'reports',
                url: '/fegala-report',
                data: {
                    authorities: ['ROLE_REPORT'],
                    pageTitle: 'global.menu.reports.fegala'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/reports/fegala-report.html',
                        controller: 'FegalaReportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', $translate => $translate.refresh()]
                }
            });
    }

})();
