(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Measure', Measure);

    Measure.$inject = ['$resource', 'DateUtils'];

    function Measure($resource, DateUtils) {
        const resourceUrl = 'api/measures/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                        data.date.setSeconds(0);
                        data.date.setMilliseconds(0);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'save': {
                method: 'POST',
                transformResponse: function (data) {
                    if (data) {
                        data = {id: data};
                    }
                    return data;
                }
            }
        });
    }

})();
