(function () {
    'use strict';

    const visitSchedule = {
        bindings: {
            visit: '=',
            showLastReminderSentDate: '='
        },
        controller: VisitScheduleController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-schedule.html'
    };

    angular
        .module('continuumplatformApp')
        .component("visitSchedule", visitSchedule);

    VisitScheduleController.$inject = [];

    function VisitScheduleController() {
        const vm = this;

        vm.$onInit = () => {
            vm.isReminderInFuture = vm.visit.nextReminderDate && moment(vm.visit.nextReminderDate).isAfter(new Date());
            vm.showLastReminderSentDateValue = angular.isDefined( vm.showLastReminderSentDate) ?  vm.showLastReminderSentDate : true;
        };

    }
})();
