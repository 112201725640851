(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('TelemonitoringRequestsController', TelemonitoringRequestsController);

    TelemonitoringRequestsController.$inject = ['$timeout', 'Pecam', 'Principal'];

    function TelemonitoringRequestsController($timeout, Pecam, Principal) {
        const vm = this;

        vm.telemonitoringRequests = null;
        vm.telemonitoringRequestsInPendingState = null;
        vm.selected = [];
        vm.isLoading = true;

        Principal.identity().then(account => {
            vm.prescriberId = account.practitioner.id;

            loadTelemonitoringRequests()
                .finally(() => {
                    vm.isLoading = false;
                });
        });

        vm.$onInit = () => {

        };

        vm.$onDestroy = () => {

        };

        vm.selectAll = () => {
            vm.selected = vm.telemonitoringRequestsInPendingState
                .map(r => r.id);
        }

        vm.prescribe = () => {
            vm.isPrescribing = true;
            Pecam.prescribeRequests(vm.selected)
                .then(() => {
                    vm.selected = [];
                    vm.telemonitoringRequests = null;
                    vm.telemonitoringRequestsInPendingState = null;
                    reload();
                })
                .finally(() => {
                    vm.isPrescribing = false;
                })
        }

        function loadTelemonitoringRequests() {
            return Pecam.queryTelemonitoringRequests({
                prescriberId: vm.prescriberId
            }).then(response => {
                vm.telemonitoringRequests = response.data;
                vm.telemonitoringRequestsInPendingState = vm.telemonitoringRequests
                    .filter(r => r.status === 'PENDING');
                return response;
            })
        }

        function reload() {
            loadTelemonitoringRequests().then(() => {
                const needsRefresh = vm.telemonitoringRequests.some(telemonitoringRequest => telemonitoringRequest.status === 'PRESCRIBING');
                if (needsRefresh) {
                    $timeout(reload, 1000);
                }
            });
        }

    }
})();
