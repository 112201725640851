(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('side-effect', {
                parent: 'master',
                url: '/side-effect?page&sort',
                data: {
                    authorities: ['ROLE_EDITOR'],
                    pageTitle: 'continuumplatformApp.sideEffect.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/side-effect/side-effects.html',
                        controller: 'SideEffectController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'name,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sideEffect');
                        $translatePartialLoader.addPart('sideEffectScore');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('side-effect.new', {
                url: '/new',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/side-effect/side-effect-dialog.html',
                        controller: 'SideEffectDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null,
                                    name: null,
                                    description: null,
                                    instructions: null,
                                    group: null,
                                    category: null,
                                    photo: false
                                };
                            }
                        }
                    }).result.then(function (result) {
                        console.log("id", result.id);
                        $state.go('side-effect-detail', {id: result.id});
                    }, function () {
                        $state.go('side-effect');
                    });
                }]
            })
            .state('side-effect-detail', {
                parent: 'side-effect',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_EDITOR'],
                    pageTitle: 'continuumplatformApp.sideEffect.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/side-effect/side-effect-detail.html',
                        controller: 'SideEffectDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sideEffect');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SideEffect', function ($stateParams, SideEffect) {
                        return SideEffect.get({id: $stateParams.id}).$promise;
                    }]
                }
            });
    }

})();
