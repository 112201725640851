(function () {
    'use strict';

    const visitDetailOrientation = {
        bindings: {
            data: '=',
            questions: '<',
            patient: '<'
        },
        controller: visitDetailOrientationController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-detail-orientation.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitDetailOrientation', visitDetailOrientation);

    visitDetailOrientationController.$inject = [];

    function visitDetailOrientationController() {
    }

})();
