(function () {
    'use strict';

    const pharmaItwTreatmentPlan = {
        bindings: {
            data: '=',
            patient: '<',
            patientTherapy: '<',
            visit: '=',
            questions: '<'
        },
        controller: pharmaItwTreatmentPlanController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/pharma-itw-treatment-plan.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmaItwTreatmentPlan', pharmaItwTreatmentPlan);

    pharmaItwTreatmentPlanController.$inject = ['$log', '$rootScope', '$scope', 'PharmAnalysis', 'PatientTreatment'];

    function pharmaItwTreatmentPlanController($log, $rootScope, $scope, PharmAnalysis, PatientTreatment) {

        const vm = this;

        vm.step = 'PHARMA_ITW_TREATMENT_PLAN';
        vm.state = 'initializing';

        vm.finish = finish;

        vm.$onInit = () => {

            loadPharmAnalysisByVisitId(vm.visit.id)
                .then(existingPharmAnalysis => {
                    let pharmAnalysis;
                    if (!existingPharmAnalysis) {
                        // crée une nouvelle analyse pharmaceutique si aucune n'existe et l'initialise.
                        pharmAnalysis = createNewPharmAnalysis().then(initPharmAnalysisData);
                    } else if (!existingPharmAnalysis.data) {
                        // initialise l'analyse pharmaceutique si elle n'a pas encore été initialisée.
                        pharmAnalysis = initPharmAnalysisData(existingPharmAnalysis);
                    } else {
                        pharmAnalysis = existingPharmAnalysis;
                    }
                    vm.visit.data.pharmAnalysisId = pharmAnalysis.id;
                    return pharmAnalysis;
                })
                .then((pharmAnalysis) => {
                    vm.pharmAnalysis = pharmAnalysis;

                    // récupération de la liste des médicaments pour pouvoir créer le plan de prise
                    return loadLastValidatedPharmAnalysis(pharmAnalysis.patientId).then((lastPharmAnalysis) => {
                        if (lastPharmAnalysis && lastPharmAnalysis.data && lastPharmAnalysis.data.medications) {
                            return lastPharmAnalysis.data.medications;

                        } else {
                            // chargement de l'historique de traitement du patient
                            return loadPatientTreatmentByPatientId(pharmAnalysis.patientId)
                                .then(deduplicateTreatments);
                        }
                    }).then((medications) => {
                        vm.pharmAnalysis.data.medications = medications;
                        vm.state = 'initialized';
                    });

                })
                .catch(error => {
                    $log.error(error);
                    vm.state = 'error';
                });
        };

        vm.$onDestroy = () => {
        };

        function loadPharmAnalysisByVisitId(visitId) {
            return PharmAnalysis.query({"visitId.equals": visitId})
                .$promise
                .then(pharmAnalysis => {
                    return pharmAnalysis && pharmAnalysis.length ? pharmAnalysis[0] : null;
                });
        }

        function createNewPharmAnalysis() {
            return PharmAnalysis.save({
                id: null,
                visitId: vm.visit.id,
                validated: false,
                text: '',
                media: [],
                data: null,
            }).$promise;
        }

        /**
         * Finish the step.
         */
        function finish() {
            vm.visit.data.pharmAnalysisId = vm.pharmAnalysis.id;
            vm.pharmAnalysis.data.hasMedicationPlanWithData = vm.pharmAnalysis.data.medications.reduce((hasMedicationPlanWithData, m) => hasMedicationPlanWithData || !!m.medicationPlan.schedule, false);

            vm.state = 'finishing';
            updatePharmAnalysis()
                .then(() => {
                    $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                        step: vm.step
                    });
                })
                .catch(error => {
                    $log.error(error);
                    vm.state = 'initialized';
                    alert("Une erreur est survenue lors de l'enregistrement. Veuillez réessayer");
                });
        }

        /**
         * Permet de mettre à jour l'analyse pharmaceutique.
         * @returns {Promise} le résultat de la mise à jour
         */
        function updatePharmAnalysis() {
            return PharmAnalysis.update({
                id: vm.pharmAnalysis.id
            }, {
                text: vm.pharmAnalysis.text,
                visitId: vm.pharmAnalysis.visitId,
                data: vm.pharmAnalysis.data,
                validated: vm.pharmAnalysis.validated ? vm.pharmAnalysis.validated : false
            }).$promise;
        }

        /**
         * Initialize the data of the pharma analysis.
         * @param pharmAnalysis - the pharma analysis to initialize.
         * @return {*} - the pharma analysis with the data initialized
         */
        function initPharmAnalysisData(pharmAnalysis) {
            pharmAnalysis.text = PharmAnalysis.DEFAULT_PHARMA_ANALYSIS_TEXT;

            pharmAnalysis.data = {
                onlyTreatmentPlan: true,
                analysisResultBimedoc: null,
                medications: [],
                patient: {
                    last_name: null,
                    first_name: null,
                    date_birth: null,
                    gender: null,
                    age: null,
                    weight: null,
                    size: null,
                },
                measures: {
                    SIZE: {
                        value: null,
                        type: 'SIZE'
                    },
                    WEIGHT: {
                        value: null,
                        type: 'WEIGHT'
                    },
                    BMI : {
                        value: null,
                        type: 'BMI'
                    },
                    dfg: {
                        value: null,
                        methodology: 'Ckd-epi',
                        date: null,
                        type: 'GFR'
                    },
                    clairance: {
                        value: null,
                        date: null,
                        type: 'CLAIRANCE'
                    },
                },
            };
            return pharmAnalysis;
        }


        /**
         * Load the last pharma analysis of the patient.
         * @param patientId - the id of the patient for which we want to load the last pharma analysis.
         * @return {Promise} - a Promise that will be resolved with the last pharma analysis of the patient
         *      or null if there is no pharma analysis for this patient.
         */
        function loadLastValidatedPharmAnalysis(patientId) {
            return PharmAnalysis.query({
                "patientId.equals": patientId,
                "validated.equals": true,
                "sort": "lastModifiedDate,desc",
                "size": 1
            }).$promise
                .then((pharmAnalyses) => {
                    return vm.lastPharmAnalysis = pharmAnalyses.length ? pharmAnalyses[0] : null;
                });
        }

        /**
         * Load the treatment of a patient
         * @param patientId - the id of the patient for which we want to load the treatment.
         * @return {Promise} - a Promise that will be resolved with the treatment of the patient.
         */
        function loadPatientTreatmentByPatientId(patientId) {
            return PatientTreatment.query({
                "patientId.equals": patientId,
                "status.notIn": ['S40_FINISHED', 'S50_STOPPED', 'S99_CANCELED'],
                "size": 1000  // TODO hard-coded size
            }).$promise.then(treatments => {
                return vm.patientTreatments = treatments;
            });
        }

        /**
         * Deduplicate treatment of a treatment list base on CIS.
         * @param treatments - the treatment list to deduplicate
         * @return {any[]|*[]} - the deduplicated treatment list.
         */
        function deduplicateTreatments(treatments) {
            if (!treatments || !treatments.length) {
                return [];
            }

            const mapUniqueTreatment = treatments.reduce((acc, t) => {
                acc.set(t.medicationVariantCis, {
                    medication : {
                        id: t.id,
                        name: t.medicationVariantName,
                        atc5: null,
                        code13: null,
                        label_atc5: null,
                        default_administration_route: {
                            id: null,
                            snomed_ct_term: t.medicationVariantRoute
                        },
                        default_intake_unit: {
                            id: null,
                            is_ucd: null,
                            label: t.medicationVariantForm,
                            short_label: null,
                            theriaque_label: t.medicationVariantForm.toUpperCase()
                        }
                    }
                });

                return acc;
            }, new Map());

            return [...mapUniqueTreatment.values()];

        }

    }

})();
