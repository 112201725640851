(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('visitStatus', visitStatus);

    function visitStatus() {

        return {
            restrict: 'E',
            scope: {
                status: '@'
            },
            templateUrl: 'app/activity/visit/visit-status.html'
        };


    }

})();
