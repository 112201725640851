(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MyFollowUpController', MyFollowUpController);

    MyFollowUpController.$inject = ['previousState', 'Principal', 'Visit'];

    function MyFollowUpController(previousState, Principal, Visit) {
        const vm = this;

        vm.previousState = previousState;

        Principal.identity().then(account => {
            vm.patient = account.patient;
            loadVisits(vm.patient.id);
        });

        function loadVisits(patientId) {
            Visit.query({
                "patientId.equals": patientId,
                "status.equals": 'DONE',
                "sort": "date,desc",
                "size": 1000
            }, function (visits) {
                vm.visits = visits;
            });
        }
    }
})();
