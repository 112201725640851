(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('patient-detail.pecam', {
                url: '/pecam',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'custom'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/pecam/patient-pecam.html',
                        controller: 'PatientPecamController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('practitioner');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('gender');
                        return $translate.refresh();
                    }],
                }
            })
            .state('telemonitoring-requests', {
                parent: 'patients',
                url: '/telemonitoring-requests',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'global.menu.telemonitoring-requests'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/pecam/telemonitoring-requests.html',
                        controller: 'TelemonitoringRequestsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('practitioner');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('gender');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
