(function () {
    'use strict';

    const patientSituationSocioProfessionnelle = {
        bindings: {
            data: '=',
            compareData: '=',
            showDataDiff: '=',
            readonly: '='
        },
        controller: patientSituationSocioProfessionnelleController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient/patient-situation-socio-professionnelle.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientSituationSocioProfessionnelle', patientSituationSocioProfessionnelle);

    patientSituationSocioProfessionnelleController.$inject = [];

    function patientSituationSocioProfessionnelleController() {

        const vm = this;

        vm.questions = [
            {
                id:"FAMILIALE",
                type: "SELECT",
                label: "Familiale",
                possibleValues: [
                    {id: "Marié", label: "Marié",},
                    {id: "PACS", label: "PACS",},
                    {id: "Veuf", label: "Veuf/Veuve",},
                    {id: "Célibataire", label: "Célibataire",}
                ],
                required: false
            },
            {
                id:"PROFESSIONNELLE",
                type: "SELECT",
                label: "Professionnelle",
                possibleValues: [
                    {id: "Actif", label: "Actif",},
                    {id: "Retraité", label: "Retraité",},
                    {id: "SANS_EMPLOI", label: "Sans emploi",}
                ],
                required: false
            },
            {
                id: "COUVERTURE_SOCIALE",
                "type": "GROUP",
                "label": "Couverture sociale :",
            },
            {
                id: "SECURITE_SOCIALE",
                group: "COUVERTURE_SOCIALE",
                type: "YES_NO",
                label: "Sécurité sociale",
                required: false,
            },
            {
                id:"COMPLEMENTAIRE_SANTE",
                group: "COUVERTURE_SOCIALE",
                type: "YES_NO",
                label: "Complémentaire Santé",
                required: false,
            },
            {
                id: "CMU",
                group: "COUVERTURE_SOCIALE",
                type: "YES_NO",
                label: "CMU ",
                required: false,
            },
            {
                id: "PRISE_EN_CHARGE_ALD",
                group: "COUVERTURE_SOCIALE",
                type: "YES_NO",
                label: "Prise en charge ALD",
                required: false,
            },
            {
                id: "ENVIRONNEMENT_SOCIAL",
                "type": "GROUP",
                "label": "Environnement social :",
            },
            {
                id: "VIT_SEUL",
                group: "ENVIRONNEMENT_SOCIAL",
                type: "YES_NO",
                label: "Vit seul",
                required: false,
            },
            {
                id:"LOGEMENT_1",
                group: "ENVIRONNEMENT_SOCIAL",
                type: "SELECT",
                label: "Statut logement ",
                "possibleValues": [
                    {id: "LOCATAIRE", label: "Locataire",},
                    {id: "PROPRIETAIRE", label: "Propriétaire",}
                ],
            },
            {
                id:"LOGEMENT_2",
                group: "ENVIRONNEMENT_SOCIAL",
                type: "SELECT",
                label: "Type logement",
                "possibleValues": [
                    {id:"MAISON", label: "Maison",},
                    {id:"APPARTEMENT", label: "Appartement",},
                    {id:"FOYER", label: "Foyer",},
                    {id:"EHPAD", label: "EHPAD",},
                    {id:"AUTRE_STRUCTURE_MÉDICO_SOCIAL", label: "Autre structure médico-sociale",}],
                required: false
            },
            {
                id: "LE_PATIENT_EST_IL_VULNERABLE",
                group: "ENVIRONNEMENT_SOCIAL",
                type: "YES_NO",
                label: "Le patient est-il vulnérable",
                required: false,
            },
            {
                id: "AUTONOMIE_DANS_LES_ACTES_DE_LA_VIE_QUOTIDIENNE",
                group: "ENVIRONNEMENT_SOCIAL",
                type: "YES_NO",
                label: "Autonomie dans les actes de la vie quotidienne",
                required: false,
            },
            {
                id:"AUTONOMIE_DANS_LA_GESTION_DES_TACHES_DE_LA_VIE_QUOTIDIENNE",
                group: "ENVIRONNEMENT_SOCIAL",
                type: "YES_NO",
                label: "Autonomie dans la gestion des tâches de la vie quotidienne",
                required: false,
            },
            {
                id: "SITUATION_DE_HANDICAP",
                group: "ENVIRONNEMENT_SOCIAL",
                type: "YES_NO",
                label: "Situation de handicap",
                required: false,
            },
            {
                id: "SI_OUI_PRECISIONS_SITUATION_DE_HANDICAP",
                group: "ENVIRONNEMENT_SOCIAL",
                "type": "TEXT",
                "label": "Si oui, veuillez précisez",
                "required": false
            },
            {
                id: "PRESENCE_D_UN_ENTOURAGE_AIDANT_EN_CAS_DE_BESOIN",
                group: "ENVIRONNEMENT_SOCIAL",
                type: "YES_NO",
                label: "Présence d’un entourage aidant en cas de besoin",
                required: false,
            },
            {
                id: "SI_OUI_COORDONNEES_DES_AIDANTS",
                group: "ENVIRONNEMENT_SOCIAL",
                "type": "TEXT",
                "label": "Coordonnées des aidants",
                "required": false
            },
            {
                id: "PRESENCE_D_UN_SERVICE_D_AIDE_A_DOMICILE",
                group: "ENVIRONNEMENT_SOCIAL",
                type: "YES_NO",
                label: "Présence d’un service d’aide à domicile",
                required: false,
            },
            {
                id: "SI_OUI_COORDONNEES_DU_SERVICE_D_AIDE_A_DOMICILE",
                group: "ENVIRONNEMENT_SOCIAL",
                "type": "TEXT",
                "label": "Coordonnées du service d’aide à domicile",
                "required": false
            },
        ];
    }

})();
