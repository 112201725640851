(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HealthEntityContractDetailController', HealthEntityContractDetailController);

    HealthEntityContractDetailController.$inject = ['hec', '$state', '$sce', '$timeout', '$log', '$uibModal', 'previousState', 'Principal', 'HealthEntityContract'];

    function HealthEntityContractDetailController(hec, $state, $sce, $timeout, $log, $uibModal, previousState, Principal, HealthEntityContract) {
        var vm = this;

        vm.previousState = previousState;

        vm.hec = hec;

        vm.back = back;
        vm.edit = edit;
        vm.remove = remove;

        Principal.identity().then(account => {
            vm.account = account;
            if (vm.hec.signed) {
                view();
            } else if (account.authorities.includes('ROLE_ADMIN')) {
                vm.isAdmin = true;
                view();
            } else if (account.authorities.includes('SIGN')) {
                $state.go('health-entity-contract-sign', {id: vm.hec.id});
            }
        });

        function view() {
            if (vm.hec.signEnvelopeId) {
                vm.documentUrl = $sce.trustAsResourceUrl('/api/health-entity-contracts/' + vm.hec.id + '/download?disposition=inline');
            }
        }

        function edit() {
            $uibModal.open({
                templateUrl: 'app/entities/health-entity-contract/health-entity-contract-dialog.html',
                controller: 'HealthEntityContractDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: vm.hec,
                }
            }).result.then(() => {
                $state.reload();
            }, function () {
                // nothing
            });
        }

        function remove() {
            if (confirm("Confirmez-vous la suppression de ce contrat ?")) {
                HealthEntityContract.remove({id: vm.hec.id}, {}, back);
            }
        }

        function back() {
            if (vm.previousState.name) {
                $state.go(vm.previousState.name, vm.previousState.params);
            } else if (vm.account.practitioner && !vm.isAdmin) {
                $state.go('settings-practitioner-detail');
            } else {
                $state.go('home');
            }
        }

    }
})();
