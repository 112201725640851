(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Question', Question);

    Question.$inject = ['$http'];

    function Question($http) {
        var url = 'api/questions';

        return {
            getAll: function () {
                return $http.get(url).then(function (result) {
                    return result.data.questions;
                });
            }
        };
    }
})();
