(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MedicationDeleteController', MedicationDeleteController);

    MedicationDeleteController.$inject = ['$uibModalInstance', 'entity', 'Medication'];

    function MedicationDeleteController($uibModalInstance, entity, Medication) {
        var vm = this;

        vm.medication = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            Medication.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
