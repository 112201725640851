(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MessageDetailController', MessageDetailController);

    MessageDetailController.$inject = ['$scope', '$state', '$uibModal', '$rootScope', '$stateParams', 'entity', 'previousState', 'Message', 'MessageTo', 'Patient'];

    function MessageDetailController($scope, $state, $uibModal, $rootScope, $stateParams, entity, previousState, Message, MessageTo, Patient) {
        const vm = this;

        vm.edit = edit;
        vm.remove = remove;

        vm.message = entity;

        vm.previousState = previousState;
        if (!vm.previousState.name) {
            vm.previousState = {
                name: 'patient-detail.messages',
                params: {id: vm.message.patientId}
            };
        }

        Patient.get({id: vm.message.patientId}).$promise.then(patient => {
            vm.patient = patient;

            MessageTo.query({
                "messageId.equals": vm.message.id,
                size: 1000 // TODO hard-coded size
            }).$promise
                .then(recipients => vm.recipients = recipients.map(recipient => {
                    recipient.patientFacility = recipient.practitioner && recipient.practitioner.healthFacilityId === vm.patient.healthFacilityId;
                    return recipient;
                }));
        });

        const unsubscribe = $rootScope.$on('continuumplatformApp:messageUpdate', () => {
            Message.read({
                id: vm.message.id
            }, message => vm.message = message);
        });
        $scope.$on('$destroy', unsubscribe);

        function edit() {
            $uibModal.open({
                templateUrl: 'app/activity/message/message-dialog.html',
                controller: 'MessageDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Message', (Message) => Message.read({id: vm.message.id}).$promise]
                }
            }).result.then(() => {
                // do nothing
            }, () => {
                // do nothing
            });
        }

        function remove() {
            $uibModal.open({
                templateUrl: 'app/activity/message/message-delete-dialog.html',
                controller: 'MessageDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: () => vm.message
                }
            }).result.then(() => $state.go(previousState.name, previousState.params),
                () => {
                    // do nothing
                });
        }
    }
})();
