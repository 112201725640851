(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientController', PatientController);

    PatientController.$inject = ['_', '$filter', '$scope', '$state', '$stateParams', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Principal', 'Patient', 'HealthFacility', 'Inbox', 'CareTeam'];

    function PatientController(_, $filter, $scope, $state, $stateParams, ParseLinks, AlertService, paginationConstants, pagingParams, Principal, Patient, HealthFacility, Inbox, CareTeam) {

        const vm = this;

        vm.currentSearch = $stateParams.search;
        vm.advancedSearch = $stateParams.advancedSearch;

        vm.open = open;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.ascending = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.toggleAdvancedSearch = toggleAdvancedSearch;
        vm.search = search;
        vm.loadAll = loadAll;

        $scope.$watchGroup(['vm.filters.familyName', 'vm.filters.givenName', 'vm.filters.birthDate'], (newValues, oldValues) => {
            newValues[0] = newValues[0] || undefined;
            newValues[1] = newValues[1] || undefined;
            newValues[2] = newValues[2] || undefined;
            if (_.isEqual(newValues, oldValues)) {
                return;
            }
            const familyName = newValues[0];
            const givenName = newValues[1];
            const birthDate = newValues[2];
            if (birthDate || familyName || givenName) {
                vm.filters.ins = null;
                vm.filters.internalId = null;
            }
            loadAll();
        });

        $scope.$watch('vm.filters.ins', (newVal, oldVal) => {
            if (newVal) {
                vm.filters = {
                    ins: newVal
                };
                loadAll();
            }
        });

        $scope.$watch('vm.filters.internalId', (newVal, oldVal) => {
            if (oldVal && !newVal) {
                loadAll();
            }
        });

        $scope.$watch('vm.filters.therapyType', (newVal, oldVal) => {
            if (oldVal !== newVal) {
                loadPatient();
            }
        });

        vm.$onInit = () => {
            vm.filters = $stateParams.filters;

            Principal.identity().then(function (account) {

                const practitioner = account.practitioner;

                vm.hasAccessToHealthData = account.authorities.includes('PATIENT_HEALTH_READ');

                vm.isHospitalUser = practitioner.healthFacilityType === 'HOSPITAL';

                if (account.authorities.includes('ROLE_ADMIN')) {
                    vm.isAdmin = true;
                } else if (practitioner.job === 'J10') {
                    vm.isDoctor = true;
                    if (vm.isHospitalUser) {
                        if (angular.isUndefined(vm.filters.facilityDoctorId)) {
                            vm.filters.facilityDoctorId = practitioner.id;
                        }
                        vm.forceFacilityDoctor = practitioner;
                    }
                } else if (practitioner.job === 'J60') {
                    vm.isNurse = true;
                } else if (['J21', 'J38'].includes(practitioner.job)) {
                    vm.isPharmacist = true;
                }

                if (angular.isUndefined(vm.filters.facilityDoctorId)) {
                    vm.filters.facilityDoctorId = null;
                }
                if (angular.isUndefined(vm.filters.nurseCoordId)) {
                    vm.filters.nurseCoordId = null;
                }
                if (angular.isUndefined(vm.filters.facilityPharmacistId)) {
                    vm.filters.facilityPharmacistId = null;
                }

                if (account.settings) {
                    if (account.settings.diseaseDivisionName) {
                        // load disease division name from account settings
                        vm.diseaseDivisionName = account.settings.diseaseDivisionName;
                    }
                    if (account.settings.trial) {
                        // load trial from account settings
                        vm.filters.trial = account.settings.trial;
                    } else {
                        vm.filters.trial = undefined;
                    }
                }

                loadDiseaseDivisionsAndTrials(practitioner.healthFacilityId).then(() => {
                    loadAll();
                });
            });

            loadUnread();
        };

        vm.$onDestroy = () => {
            if (vm.unwatchDiseaseDivisionName) {
                vm.unwatchDiseaseDivisionName();
            }
            if (vm.unwatchTrial) {
                vm.unwatchTrial();
            }
        };

        function loadDiseaseDivisionsAndTrials(healthFacilityId) {
            if (!healthFacilityId) {
                vm.enableDiseaseDivisionFilter = false;
                vm.diseaseDivisionName = undefined;
                vm.filters.diseaseIds = undefined;

                vm.enableTrialFilter = false;
                vm.filters.trial = undefined;
                return Promise.resolve();
            }
            return HealthFacility.get({
                view: 'summary',
                id: healthFacilityId
            }, facility => {

                facility.diseaseDivisions = facility.diseaseDivisions || [];

                vm.enableDiseaseDivisionFilter = facility.diseaseDivisions.length > 0;

                // group by division name reduce to 1 division by name
                vm.divisions = facility.diseaseDivisions.reduce((divisions, division) => {
                    divisions[division.name] = division.diseaseIds;
                    return divisions;
                }, {});

                // restore previously selected division with updated data
                if(vm.diseaseDivisionName) {
                    if(vm.divisions[vm.diseaseDivisionName]) {
                        vm.filters.diseaseId = undefined;
                        vm.filters.diseaseIds = vm.divisions[vm.diseaseDivisionName];
                    } else {
                        vm.diseaseDivisionName = undefined;
                        vm.filters.diseaseIds = undefined;
                    }
                }

                vm.unwatchDiseaseDivisionName = $scope.$watch('vm.diseaseDivisionName', onChangeDivision);

                // trials
                vm.enableTrialFilter = facility.trials.length > 0;
                vm.trials = facility.trials;

                vm.unwatchTrial = $scope.$watch('vm.filters.trial', onChangeTrial);

            }).$promise;
        }

        function onChangeDivision(newVal, oldVal) {
            if(newVal === oldVal) {
                return;
            } else if (newVal) {
                vm.filters.diseaseId = undefined;
                vm.filters.diseaseIds = vm.divisions[newVal];
            } else {
                vm.filters.diseaseIds = undefined;
            }
            storeDiseaseDivisionName(newVal);
            loadAll();
        }

        function storeDiseaseDivisionName(value) {
            if(value)
                Principal.updateSetting('diseaseDivisionName', value);
            else
                Principal.deleteSetting('diseaseDivisionName');
        }

        function onChangeTrial(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            storeTrial(newVal);
            loadAll();
        }

        function storeTrial(value) {
            if (value)
                Principal.updateSetting('trial', value);
            else
                Principal.deleteSetting('trial');
        }

        function loadAll() {
            if (vm.isAdmin) {
                loadDistinctFacilities();
            }
            loadDistinctPrograms();
            loadDistinctFacilityDoctors();
            loadDistinctFacilityPharmacists();
            loadDistinctNurseCoords();
            if(!vm.enableDiseaseDivisionFilter) {
                loadDistinctDiseases();
            }
            loadPatient();
        }

        function loadDistinctFacilities() {
            Patient.queryDistinctFacilities({
                "sort": "healthFacility.name,asc",
                "diseaseId.equals": vm.filters.diseaseId || undefined,
                "trial.equals": vm.filters.trial === null ? undefined : vm.filters.trial,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
                "patientTherapyProgramId.equals": vm.filters.programId || undefined,
            }, (result) => {
                vm.healthfacilities = result;
            });
        }

        function loadDistinctPrograms() {
            Patient.queryDistinctPrograms({
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
                "nurseCoordId": vm.filters.nurseCoordId || undefined,
                "diseaseId.equals": vm.filters.diseaseId || undefined,
                "diseaseId.in": vm.filters.diseaseIds,
                "trial.equals": vm.filters.trial === null ? undefined : vm.filters.trial,
            }, (result) => {
                vm.programs = result;
            });
        }

        function loadDistinctFacilityDoctors() {
            Patient.queryDistinctFacilityDoctors({
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
                "nurseCoordId": vm.filters.nurseCoordId || undefined,
                "diseaseId.equals": vm.filters.diseaseId || undefined,
                "diseaseId.in": vm.filters.diseaseIds,
                "trial.equals": vm.filters.trial === null ? undefined : vm.filters.trial,
                "patientTherapyProgramId.equals": vm.filters.programId || undefined,
            }, (result) => {
                vm.facilityDoctors = result;
                if (!result.length && vm.forceFacilityDoctor) {
                    vm.facilityDoctors.push(vm.forceFacilityDoctor);
                }
            });
        }

        function loadDistinctFacilityPharmacists() {
            Patient.queryDistinctFacilityPharmacists({
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "nurseCoordId": vm.filters.nurseCoordId || undefined,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "diseaseId.equals": vm.filters.diseaseId || undefined,
                "diseaseId.in": vm.filters.diseaseIds,
                "trial.equals": vm.filters.trial === null ? undefined : vm.filters.trial,
                "patientTherapyProgramId.equals": vm.filters.programId || undefined,
            }, (result) => {
                vm.pharmacists = result;
            });
        }

        function loadDistinctNurseCoords() {
            Patient.queryDistinctNurseCoords({
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
                "diseaseId.equals": vm.filters.diseaseId || undefined,
                "diseaseId.in": vm.filters.diseaseIds,
                "trial.equals": vm.filters.trial === null ? undefined : vm.filters.trial,
                "patientTherapyProgramId.equals": vm.filters.programId || undefined,
            }, (result) => {
                vm.nurseCoords = result;
            });
        }

        function loadDistinctDiseases() {
            Patient.queryDistinctDiseases({
                "sort": "disease.name",
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "nurseCoordId": vm.filters.nurseCoordId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "trial.equals": vm.filters.trial === null ? undefined : vm.filters.trial,
                "patientTherapyProgramId.equals": vm.filters.programId || undefined,
            }, function (result) {
                vm.diseases = result;
            });
        }

        function loadPatient() {
            vm.patients = null;
            Patient.query({
                view: 'full',
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                "query": vm.currentSearch || undefined,
                "firstNameStartsWith": vm.filters.givenName || undefined,
                "lastNameOrBirthNameStartsWith": vm.filters.familyName || undefined,
                "gender.equals": vm.filters.gender || undefined,
                "birthDate.equals": vm.filters.birthDate ? $filter('date')(vm.filters.birthDate, 'yyyy-MM-dd') : undefined,
                "ins": vm.filters.ins || undefined,
                "internalId": vm.filters.internalId || undefined,
                "diseaseId.equals": vm.filters.diseaseId || undefined,
                "diseaseId.in": vm.filters.diseaseIds,
                "trial.equals": vm.filters.trial === null ? undefined : vm.filters.trial,
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "nurseCoordId": vm.filters.nurseCoordId || undefined,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
                "patientTherapyHdj.equals": vm.filters.therapyType === null ? undefined : vm.filters.therapyType === 'hdj',
                "patientTherapyHah.equals": vm.filters.therapyType === null ? undefined : vm.filters.therapyType === 'hah',
                "patientTherapyTrial.equals": vm.filters.trial === null ? undefined : vm.filters.trial,
                "patientTherapyProgramId.equals": vm.filters.programId || undefined,
            }, (data, headers) => {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.patients = data.map(mapLastEventBy);
                vm.page = pagingParams.page;
            }, (error) =>
                AlertService.error(error.data.message));

            function sort() {
                const result = [vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function mapLastEventBy(patient) {
                let lastEventByRoleType;
                if (patient.lastEventByRole) {
                    const lastEventByRoleRules = CareTeam.rules[patient.lastEventByRole];
                    lastEventByRoleType = lastEventByRoleRules && lastEventByRoleRules.type;
                }
                if (lastEventByRoleType === 'Practitioner') {
                    patient.lastEventByLabelKey1 = 'continuumplatformApp.patient.teamRole.' + patient.lastEventByRole;
                } else if (patient.lastEventByJob) {
                    patient.lastEventByLabelKey1 = 'continuumplatformApp.practitioner.job.' + patient.lastEventByJob;
                    if (patient.lastEventByFacilityType) {
                        patient.lastEventByLabelKey2 = 'continuumplatformApp.practitioner.facilityTypeQualifier.' + patient.lastEventByFacilityType;
                    }
                } else if (patient.lastEventType === 'NEW_MESSAGE') {
                    patient.lastEventByLabelKey1 = 'continuumplatformApp.patient.teamRole.PATIENT';
                }

                return patient;
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc'),
                search: vm.currentSearch,
                advancedSearch: vm.advancedSearch,
                filters: vm.filters
            });
        }

        function search() {
            if (!vm.currentSearch) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.ascending = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.ascending = false;
            vm.currentSearch = null;
            vm.patient = {};
            vm.transition();
        }

        function toggleAdvancedSearch() {
            vm.advancedSearch = !vm.advancedSearch;
            if (vm.currentSearch !== null || !vm.advancedSearch) {
                clear();
            }
        }

        function open(id) {
            vm.isOpening = true;
            let target;
            if (vm.isPharmacist) {
                target = 'patient-detail.pharmaceutics';
            } else if (vm.hasAccessToHealthData) {
                target = 'patient-detail.follow-up';
            } else {
                target = 'patient-detail.team';
            }
            $state.go(target, {id: id});
        }

        function loadUnread() {
            Inbox.getSummary().then(function (response) {
                vm.unread = {};
                angular.forEach(response.data, function (value) {
                    vm.unread[value.patientId] = {
                        countUnread: value.countUnread,
                        countUnreadImportant: value.countUnreadImportant
                    };
                });
            });
        }
    }
})();
