(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('ExpeTO', ExpeTO);

    ExpeTO.$inject = ['$rootScope', '$http'];

    function ExpeTO($rootScope, $http) {

        return {exportCSV};

        function exportCSV(file) {
            return $http.get('api/expeto/export', {
                params: {file}
            });
        }

    }

})();
