(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SettingsPatientController', SettingsPatientController);

    SettingsPatientController.$inject = ['account', 'Auth'];

    function SettingsPatientController(account, Auth) {
        var vm = this;

        vm.account = account;
        vm.patient = account.patient;

        vm.logout = logout;

        // compute age
        if (vm.patient.birthDate != null)
            vm.patient.age = moment().diff(vm.patient.birthDate, 'years');

        function logout() {
            Auth.logout();
        }
    }
})();
