(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('patient-detail.actions', {
                url: '/actions?page&sort',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'continuumplatformApp.action.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/action/actions.html',
                        controller: 'ActionController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true,
                        inherit: false
                    },
                    sort: {
                        value: 'createdDate,desc',
                        squash: true,
                        inherit: false
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('action');
                        $translatePartialLoader.addPart('actionType');
                        $translatePartialLoader.addPart('actionStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    patient: ['$stateParams', 'Patient', function ($stateParams, Patient) {
                        return Patient.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name.replace('patient-detail.actions', '') || 'patient-detail.pharmaceutics',
                            params: $transition$.from().name.replace('patient-detail.actions', '') ? $transition$.params('from') : {id: $transition$.params().id}
                        };
                    }]
                }
            });
    }

})();
