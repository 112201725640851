(function () {
    'use strict';

    angular.module('continuumplatformApp')
        .directive('jqSparkline', [function () {

            return {
                restrict: 'A',
                require: 'ngModel',
                link: function (scope, elem, attrs, ngModel) {

                    var opts = {};
                    // TODO Use $eval to get the object
                    opts.type = attrs.type || 'line';

                    scope.$watch(attrs.ngModel, function () {
                        render();
                    });
                    var render = function () {

                        if (attrs.opts) angular.extend(opts, angular.fromJson(attrs.opts));

                        var model = ngModel.$viewValue;

                        $(elem).sparkline(model[0], opts);

                        if (model[1].length > 0) {
                            opts.composite = true;
                            opts.lineColor = 'red';
                            $(elem).sparkline(model[1], opts);
                        }
                    };
                }
            }
        }]);

})();
