(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('direct', {
            parent: 'app',
            url: '/direct?facilityPatientId',
            data: {
                pageTitle: 'global.menu.home'
            },
            params: {
                facilityPatientId: {
                    value: null,
                    squash: true
                }
            },
            views: {
                'content@app': {
                    templateUrl: 'app/direct/direct.html',
                    controller: 'DirectController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                facilityPatientId: ['$transition$', $transition$ => $transition$.params().facilityPatientId],
                translatePartialLoader: ['$translate', $translate => {
                    return $translate.refresh();
                }]
            }
        });
    }
})();
