(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SideEffectReportDialogController', SideEffectReportDialogController);

    SideEffectReportDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SideEffectReport', 'SideEffectScore', 'PatientTherapy', 'Practitioner', 'HealthFacility'];

    function SideEffectReportDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, SideEffectReport, SideEffectScore, PatientTherapy, Practitioner, HealthFacility) {
        var vm = this;

        vm.sideEffectReport = entity;
        vm.clear = clear;
        vm.save = save;

        vm.pvCenters = HealthFacility.query({
            size: 50,
            "type.equals": 'PV',
            sort: 'name'
        });
        vm.pvLaboratories = HealthFacility.query({
            view: 'summary',
            'type.equals': 'PHARM_LAB',
            size: 100,
            sort: 'name'
        });

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            SideEffectReport.update({id: vm.sideEffectReport.id}, vm.sideEffectReport, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(id) {
            $scope.$emit('continuumplatformApp:sideEffectReportUpdate', id);
            $uibModalInstance.close(id);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
