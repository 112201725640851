(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('VisitShiftOrPlanDialogController', VisitShiftOrPlanDialogController);

    VisitShiftOrPlanDialogController.$inject = ['$scope', '$timeout', '$uibModal', '$uibModalInstance', 'visit'];

    function VisitShiftOrPlanDialogController($scope, $timeout, $uibModal, $uibModalInstance, visit) {
        const vm = this;

        vm.one = () => {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-plan-dialog.html',
                controller: 'VisitPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    visit: () => angular.copy(visit)
                }
            }).result.then(() => {
                $uibModalInstance.close('one');
            }, () => {
                // nothing
            });
        };

        vm.all = () => {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-shift-dialog.html',
                controller: 'VisitShiftDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    visit: angular.copy(visit)
                }
            }).result.then(() => {
                $uibModalInstance.close('all');
            }, () => {
                // nothing
            });
        };

        vm.dismiss = () => {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
