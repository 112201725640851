(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('help', {
                parent: 'app',
                url: '/help',
                data: {
                    pageTitle: 'global.menu.help'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/help/help.html',
                        controller: 'HelpController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('public-help', {
                parent: 'root',
                url: '/public-help',
                data: {
                    pageTitle: 'global.menu.help',
                    public: true
                },
                views: {
                    'root@': {
                        templateUrl: 'app/layouts/simple.html',
                        controller: 'SimpleLayoutController',
                        controllerAs: 'vm'
                    },
                    'content@public-help': {
                        templateUrl: 'app/help/help.html',
                        controller: 'PublicHelpController',
                        controllerAs: 'vm'
                    }
                }
            });
    }
})();
