(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientDetailController', PatientDetailController);

    PatientDetailController.$inject = ['$translate', '$window', '_', 'entity', 'Patient'];

    function PatientDetailController($translate, $window, _, entity, Patient) {
        const vm = this;

        const ETAT_PHYSIOPATHOLOGIE_PROPERTIES = [
            'ALLERGIES',
            'COMORBIDITES',
            'ANTECEDENTS_MEDICAUX_ET_CHIRURGICAUX',
        ];

        const HABITUDE_DE_VIE_PROPERTIES = [
            'LE_PATIENT_PRATIQUE_DES_ACTIVITES_ET_LOISIRS',
            'LE_PATIENT_PRATIQUE_UNE_ACTIVITE_PHYSIQUE',
            'HABITUDES_ALIMENTAIRES',
            'LE_PATIENT_PRESENTE_T_IL_UNE_MODIFICATION_DE_SON_ALIMENTATION',
            'LE_PATIENT_PREND_DES_COMPLEMENTS_ALIMENTAIRES',
            'SI_OUI_NOMBRE_DE_PRISES_PAR_JOUR',
            'LE_PATIENT_EST_IL_SUIVI_PAR_UN_DIETETICIEN',
            'CONSOMMATION_DE_TABAC',
            'SI_OUI_ESTIMATION_DE_LA_CONSOMMATION_TABAC',
            'CONSOMMATION_D_ALCOOL',
            'SI_OUI_ESTIMATION_DE_LA_CONSOMMATION_D_ALCOOL',
        ];

        const SITUATION_SOCIO_PROFESSIONNELLE_PROPERTIES = [
            'FAMILIALE',
            'PROFESSIONNELLE',
            'COUVERTURE_SOCIALE',
            'SECURITE_SOCIALE',
            'COMPLEMENTAIRE_SANTE',
            'CMU',
            'PRISE_EN_CHARGE_ALD',
            'ENVIRONNEMENT_SOCIAL',
            'VIT_SEUL',
            'LOGEMENT_1',
            'LOGEMENT_2',
            'LE_PATIENT_EST_IL_VULNERABLE',
            'AUTONOMIE_DANS_LES_ACTES_DE_LA_VIE_QUOTIDIENNE',
            'AUTONOMIE_DANS_LA_GESTION_DES_TACHES_DE_LA_VIE_QUOTIDIENNE',
            'SITUATION_DE_HANDICAP',
            'SI_OUI_PRECISIONS_SITUATION_DE_HANDICAP',
            'PRESENCE_D_UN_ENTOURAGE_AIDANT_EN_CAS_DE_BESOIN',
            'SI_OUI_COORDONNEES_DES_AIDANTS',
            'PRESENCE_D_UN_SERVICE_D_AIDE_A_DOMICILE',
            'SI_OUI_COORDONNEES_DU_SERVICE_D_AIDE_A_DOMICILE'
        ];

        vm.modify = modify;
        vm.cancel = cancel;
        vm.save = save;

        vm.$onInit = function () {
            vm.patient = entity;

            $translate('continuumplatformApp.patient.detail.title', {initials: entity.initials}).then(title => {
                $window.document.title = title;
            });

            vm.etatPhysiopathologieData = {};
            vm.habitudeDeVieData = {};
            vm.situationSocioProfessionnelleData = {};
            ETAT_PHYSIOPATHOLOGIE_PROPERTIES.forEach((p) => vm.etatPhysiopathologieData[p] = _.clone(vm.patient.details[p]));
            HABITUDE_DE_VIE_PROPERTIES.forEach((p) => vm.habitudeDeVieData[p] = _.clone(vm.patient.details[p]));
            SITUATION_SOCIO_PROFESSIONNELLE_PROPERTIES.forEach((p) => vm.situationSocioProfessionnelleData[p] = _.clone(vm.patient.details[p]));

            // états d'affichage des blocks de données
            vm.readonlyEtatPhysiopathologie = true;
            vm.readonlyHabitudeDeVie = true;
            vm.readonlySituationSocioProfessionnelle = true;
            vm.readonlyInformationsComplementaire = true;
        };

        /**
         * Action réalisée lorsqu'on clique sur le bouton "modifier" d'un groupe de données du profil médico-social.
          * @param dataGroup le groupe de données que le veut modifier
         */
        function modify(dataGroup) {
            switch (dataGroup) {
            case 'ETAT_PHYSIOPATHOLOGIE':
                vm.readonlyEtatPhysiopathologie = false;
                break;
            case 'HABITUDE_DE_VIE':
                vm.readonlyHabitudeDeVie = false;
                break;
            case 'SITUATION_SOCIO_PROFESSIONNELLE':
                vm.readonlySituationSocioProfessionnelle = false;
                break;
            case 'INFORMATIONS_COMPLEMENTAIRES':
                vm.readonlyInformationsComplementaire = false;
                break;
            }
        }

        /**
         * Action réalisée lorsqu'on clique sur le bouton "Annuler"
         *
         * Les modifications ne sont annulées qe sur le groupe
         * @param dataGroup
         */
        function cancel(dataGroup) {
            switch (dataGroup) {
            case 'ETAT_PHYSIOPATHOLOGIE':
                vm.readonlyEtatPhysiopathologie = true;
                ETAT_PHYSIOPATHOLOGIE_PROPERTIES.forEach((p) => vm.etatPhysiopathologieData[p] = _.clone(vm.patient.details[p]));
                break;

            case 'HABITUDE_DE_VIE':
                vm.readonlyHabitudeDeVie = true;
                HABITUDE_DE_VIE_PROPERTIES.forEach((p) => vm.habitudeDeVieData[p] = _.clone(vm.patient.details[p]));
                break;

            case 'SITUATION_SOCIO_PROFESSIONNELLE':
                vm.readonlySituationSocioProfessionnelle = true;
                SITUATION_SOCIO_PROFESSIONNELLE_PROPERTIES.forEach((p) => vm.situationSocioProfessionnelleData[p] = _.clone(vm.patient.details[p]));
                break;

            case 'INFORMATIONS_COMPLEMENTAIRES':
                vm.readonlyInformationsComplementaire = true;
                break;
            }
        }

        /**
         * Action réalisée lorsqu'on clique le bouton "sauvegarder"
         */
        function save(dataGroup) {
            vm.isSaving = true;

            deleteOldData();

            switch (dataGroup) {
            case 'ETAT_PHYSIOPATHOLOGIE':
                vm.readonlyEtatPhysiopathologie = true;
                Object.keys(vm.etatPhysiopathologieData).forEach((key) => vm.patient.details[key] = vm.etatPhysiopathologieData[key]);
                break;

            case 'HABITUDE_DE_VIE':
                vm.readonlyHabitudeDeVie = true;
                Object.keys(vm.habitudeDeVieData).forEach((key) => vm.patient.details[key] = vm.habitudeDeVieData[key]);
                break;

            case 'SITUATION_SOCIO_PROFESSIONNELLE':
                vm.readonlySituationSocioProfessionnelle = true;
                Object.keys(vm.situationSocioProfessionnelleData).forEach((key) => vm.patient.details[key] = vm.situationSocioProfessionnelleData[key]);
                break;
            case 'INFORMATIONS_COMPLEMENTAIRES':
                vm.readonlyInformationsComplementaire = true;
                break;
            }

            // On ajoute ou incrémente la versiond u profile patient
            vm.patient.details.version = vm.patient.details.version ? vm.patient.details.version + 1 : 1;

            Patient.updateDetails(
                {id: vm.patient.id},
                {details: vm.patient.details},
                function onSaveSuccess() {
                    vm.isSaving = false;

                }, function onSaveError() {
                    vm.isSaving = false;
                }
            );
        }


        /**
         * Nettoyage des anciennes données.
         */
        function deleteOldData() {

            if (!vm.readonlyHabitudeDeVie) {
                delete vm.patient.details.habitudes;
            }

            if (!vm.readonlySituationSocioProfessionnelle) {
                delete vm.patient.details.autonome;
                delete vm.patient.details.rdv;
                delete vm.patient.details.medic;
            }
        }

    }
})();
