(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SettingsPractitionerController', SettingsPractitionerController);

    SettingsPractitionerController.$inject = ['$scope', '$rootScope', 'practitioner', '$state', 'Auth', 'Principal', 'Practitioner', 'Address'];

    function SettingsPractitionerController($scope, $rootScope, practitioner, $state, Auth, Principal, Practitioner, Address) {
        const vm = this;

        vm.save = save;
        vm.searchAddress = searchAddress;
        vm.onSelectAddress = onSelectAddress;

        vm.$onInit = function () {
            vm.isSaving = false;
            vm.practitioner = practitioner;
            vm.addressInvalid = false;

            Practitioner.getSpecialities().then(function (response) {
                vm.specialities = response;
            }).catch(function (response) {
                vm.specialities = [];
            });

            if (vm.practitioner) {
                vm.address = {properties: {
                    label: `${vm.practitioner.address || ''} ${vm.practitioner.postalCode || ''} ${vm.practitioner.city || ''}`
                }};
            }
        };

        function save() {
            vm.isSaving = true;

            Practitioner.selfUpdate(vm.practitioner).$promise.then(() => {
                vm.isSaving = false;

                Principal.identity(true).then(account => {
                    $rootScope.practitionerInfoMissing = !account.practitioner.complete;
                    $state.go('settings-practitioner-detail');
                });

            }).catch(() => vm.isSaving = false);
        }

        function searchAddress(search) {
            vm.addressInvalid = !!search;

            if (!search) {
                vm.practitioner.address = null;
                vm.practitioner.postalCode = null;
                vm.practitioner.city = null;

                vm.practitioner.latitude = null;
                vm.practitioner.longitude = null;
                vm.practitioner.cityCode = null;
            }

            if (!search || search.length < 3) {
                return;
            }

            return Address.search(search);
        }

        function onSelectAddress($item, $model, $label, $event)  {
            vm.addressInvalid = false;
            if (vm.practitioner && vm.practitioner.address !== $item.properties.label) {
                vm.practitioner.address = ['housenumber', 'street', 'locality'].includes($item.properties.type) ? $item.properties.name : null;
                vm.practitioner.postalCode = $item.properties.postcode;
                vm.practitioner.city = $item.properties.city;
                vm.practitioner.latitude = $item.geometry && $item.geometry.coordinates && $item.geometry.coordinates[1] ? $item.geometry.coordinates[1] : null;
                vm.practitioner.longitude = $item.geometry && $item.geometry.coordinates && $item.geometry.coordinates[0] ? $item.geometry.coordinates[0] : null;
                vm.practitioner.cityCode = $item.properties.citycode;
            }
        }
    }
})();
