(function () {
    /*jshint bitwise: false*/
    'use strict';

    // eslint-disable-next-line angular/no-service-method
    angular
        .module('continuumplatformApp')
        .service('WorkingDayService', WorkingDayService);

    WorkingDayService.$inject = ['Principal', 'HealthFacility'];

    function WorkingDayService(Principal, HealthFacility) {

        this.isWorkingHours = isWorkingHours;

        /**
         * Checks if the given health facility is currently within its working hours.
         *
         * @param {number} healthFacilityId - The ID of the health facility to check.
         * @return {Promise<boolean>} - Returns a promise that resolves to true if the health facility is within its working hours, and false otherwise.
         */
        function isWorkingHours(healthFacilityId) {

            return HealthFacility.get({id: healthFacilityId, view: 'summary'})
                .$promise
                .then((healthFacility) => {
                    return healthFacility.postalCode;

                }).then((postalCode) => {
                    const now = moment();

                    // Récupère le jour de la semaine (0 est dimanche, 1 est lundi, etc.)
                    const dayOfWeek = now.day();
                    const hour = now.hour();

                    const holidays = getHolidays(now.year(), postalCode);
                    const holidaysAsString = holidays.map((d) => moment(d).format('yyyy-MM-DD'));

                    // Vérifie si c'est un jour non ouvrable, une heure non ouvrable ou un jour férié ou weekend
                    const today = now.format('yyyy-MM-DD');
                    if (dayOfWeek === 0 || dayOfWeek === 6 || hour < 9 || hour >= 18 || holidaysAsString.includes(today)) {
                        return false;
                    }

                    // Si on est ici, cela signifie que nous sommes pendant les heures ouvrables d'un jour ouvrable et que ce n'est pas un jour férié.
                    return true;
                });

        }

        /**
         * Retrieves a list of holidays for a given year and postal code.
         *
         * @param {number} year - The year for which to retrieve holidays.
         * @param {string} postalCode - The postal code used to determine specific holidays.
         *
         * @return {Array<Date>} - An array of holiday dates.
         */
        function getHolidays(year, postalCode) {

            const fixedHolidays = getFixedHolidays(year, postalCode);

            const G = year % 19;
            const C = Math.floor(year / 100);
            const H = (C - Math.floor(C / 4) - Math.floor((8 * C + 13) / 25) + 19 * G + 15) % 30;
            const I = H - Math.floor(H / 28) * (1 - Math.floor(H / 28) * Math.floor(29 / (H + 1)) * Math.floor((21 - G) / 11));
            const J = (year + Math.floor(year / 4) + I + 2 - C + Math.floor(C / 4)) % 7;
            const L = I - J;
            const MoisPaques = 3 + Math.floor((L + 40) / 44);
            const JourPaques = L + 28 - 31 * Math.floor(MoisPaques / 4);
            const Paques = new Date(year, MoisPaques - 1, JourPaques);

            const LundiPaques = new Date(year, MoisPaques - 1, JourPaques + 1);
            const Ascension = new Date(year, MoisPaques - 1, JourPaques + 39);
            const Pentecote = new Date(year, MoisPaques - 1, JourPaques + 49);
            const LundiPentecote = new Date(year, MoisPaques - 1, JourPaques + 50);

            const holidays = [
                ...fixedHolidays,
                Paques,
                LundiPaques,
                Ascension,
                Pentecote,
                LundiPentecote,
            ];

            // Only add Vendredi Saint holiday for Alsace-Moselle (postal codes starting with 57, 67, 68).
            if (isAlsaceMoselle(postalCode)) {
                const VendrediSaint = new Date(year, MoisPaques - 1, JourPaques - 2);
                holidays.push(VendrediSaint);
            }

            return holidays;
        }

        /**
         * Returns an array of fixed holidays for the given year and postal code.
         *
         * @param {number} year - The year for which holidays are to be calculated.
         * @param {string} postalCode - The postal code of the location.
         * @return {Date[]} - An array of Date objects representing the fixed holidays.
         */
        function getFixedHolidays(year, postalCode) {
            const JourAn = new Date(year, 0, 1);
            const FeteTravail = new Date(year, 4, 1);
            const Victoire1945 = new Date(year, 4, 8);
            const FeteNationale = new Date(year, 6, 14);
            const Assomption = new Date(year, 7, 15);
            const Toussaint = new Date(year, 10, 1);
            const Armistice = new Date(year, 10, 11);
            const Noel = new Date(year, 11, 25);

            const holidays = [ JourAn, FeteTravail, Victoire1945, FeteNationale, Assomption, Toussaint, Armistice, Noel ];

            // Only add Saint Etienne holiday for Alsace-Moselle (postal codes starting with 57, 67, 68).
            if (isAlsaceMoselle(postalCode)) {
                const SaintEtienne = new Date(year, 11, 26);
                holidays.push(SaintEtienne);
            }

            return holidays;
        }

        /**
         * Checks if a given postal code belongs to the Alsace-Moselle region.
         *
         * @param {string} postalCode - The postal code to check.
         * @return {boolean} - Returns true if the postal code belongs to the Alsace-Moselle region, otherwise returns false.
         */
        function isAlsaceMoselle(postalCode) {
            return ['57', '67', '68'].includes(postalCode.substring(0, 2));
        }

    }
})();
