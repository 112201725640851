(function () {
    'use strict';

    const diseasesSelect = {
        bindings: {
            selectedDiseases: '=',
            selectedDiseasesGroup: '=',
        },
        controller: diseasesSelectController,
        controllerAs: 'vm',
        templateUrl: 'app/components/diseases-select/diseases-select.html'
    };

    angular
        .module('continuumplatformApp')
        .component('diseasesSelect', diseasesSelect);

    diseasesSelectController.$inject = ['$scope', 'Disease'];

    function diseasesSelectController($scope, Disease) {

        const vm = this;

        vm.diseases = [];
        vm.filteredDiseases = [];
        vm.diseaseFilter = null;

        vm.onClickDisease = onClickDisease;
        vm.onClickDiseasesGroup = onClickDiseasesGroup;
        vm.onChangeDiseasesFilter = onChangeDiseasesFilter;
        vm.onDiseaseFilterListToggled = onDiseaseFilterListToggled;
        vm.isDiseaseSelected = isDiseaseSelected;

        /**
         * Initialise the component.
         */
        vm.$onInit = function() {
            loadDiseases().then(() => {

                vm.selectedDiseases.forEach((disease) => {
                    // Si toutes les maladies du groupe sont sélectionnées, on l'ajoute à la liste des groupes sélectionnés.
                    if(vm.selectedDiseases.filter((d) => d.group === disease.group).length === vm.diseases.filter((d) => d.group === disease.group).length) {
                        vm.selectedDiseasesGroup.push(disease.group);
                    }
                });
            });
        };

        /**
         * Load the list of diseases.
         */
        function loadDiseases() {
            vm.diseases = [];
            return Disease.query({
                "deleted.equals": false,
                size: 999
            }).$promise.then((diseases) => {
                vm.diseases = diseases;
                onChangeDiseasesFilter();
            });
        }

        /**
         * Handles clicking a selected filter disease.
         * @param disease - The disease to filter by.
         */
        function onClickDisease(disease) {
            if (isDiseaseSelected(disease)) {
                vm.selectedDiseases = vm.selectedDiseases.filter((d) => d.id !== disease.id);

                // Si le groupe est déjà sélectionné, on le supprime de la liste des groupes sélectionnés.
                if (vm.selectedDiseasesGroup.includes(disease.group)) {
                    vm.selectedDiseasesGroup = vm.selectedDiseasesGroup.filter((dg) => dg !== disease.group);
                }

            } else {
                vm.selectedDiseases.push(disease);

                // Si toutes les maladies du groupe sont sélectionnées, on l'ajoute à la liste des groupes sélectionnés.
                if(vm.selectedDiseases.filter((d) => d.group === disease.group).length === vm.diseases.filter((d) => d.group === disease.group).length) {
                    vm.selectedDiseasesGroup.push(disease.group);
                }
            }
        }

        /**
         * Handle click on diseases group filter.
         * @param diseasesGroup - The diseases group to filter by.
         */
        function onClickDiseasesGroup(diseasesGroup) {
            if (vm.selectedDiseasesGroup.includes(diseasesGroup)) {
                vm.selectedDiseasesGroup = vm.selectedDiseasesGroup.filter((dg) => dg !== diseasesGroup);

                // on supprime de la sélection l'ensemble des maladies du groupe désélectionné.
                vm.selectedDiseases = vm.selectedDiseases.filter((d) => d.group !== diseasesGroup);

            } else {
                vm.selectedDiseasesGroup.push(diseasesGroup);
                vm.diseases.forEach((disease) => {
                    // si non inclus dans la liste des maladies sélectionnées, on l'ajoute.
                    if (disease.group === diseasesGroup && !vm.selectedDiseases.includes(disease)) {
                        vm.selectedDiseases.push(disease);
                    }
                });
            }
        }

        /**
         * Handle maladie filter change.
         */
        function onChangeDiseasesFilter() {
            vm.filteredDiseases = vm.diseases.filter((disease) => {
                return !vm.diseaseFilter || disease.name.toLowerCase().includes(vm.diseaseFilter.toLowerCase());
            });
        }

        /**
         * Handle disease filter list toggle.
         * @param open - Whether the disease filter list is open else false.
         */
        function onDiseaseFilterListToggled(open) {
            if (open) {
                document.getElementById('diseases-search-input').focus();
            } else {
                this.diseaseFilter = null;
            }
        }

        /**
         * Check if a disease is selected.
         * @param disease - The disease to check.
         * @return {boolean} - true Whether the disease is selected else false.
         */
        function isDiseaseSelected(disease) {
            return vm.selectedDiseases.filter((d) => d.id === disease.id).length > 0;
        }
    }

})();
