(function () {
    'use strict';

    const visitAohControlTest = {
        bindings: {
            patient: '<',
            data: '=',
            questions: '<'
        },
        controller: VisitAohControlTest,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-aoh-control-test.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitAohControlTest', visitAohControlTest);

    VisitAohControlTest.$inject = ['$rootScope', '$scope'];

    function VisitAohControlTest($rootScope, $scope) {

        const vm = this;

        vm.finish = finish;

        vm.$onInit = function () {
            vm.step = 'AOH_CONTROL_TEST';
            vm.isSaving = false;
        };

        vm.$onDestroy = () => {

        };

        function finish() {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        }
    }

})();
