(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('SideEffect', SideEffect);

    SideEffect.$inject = ['$resource'];

    function SideEffect($resource) {
        var resourceUrl = 'api/side-effects/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformResponse: function (data) {
                    if (data) {
                        data = {id: data};
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'getSideEffectsWithScore': {
                url: 'api/side-effects-with-score',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        });
    }
})();
