(function () {
    'use strict';

    var qualityOfLife = {
        bindings: {
            data: '=',
            questions: '<',
            patientId: '='
        },
        controller: qualityOfLifeController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/qol.html'
    };

    angular
        .module('continuumplatformApp')
        .component('qualityOfLife', qualityOfLife);

    qualityOfLifeController.$inject = ['$rootScope'];

    function qualityOfLifeController($rootScope) {

        const vm = this;

        vm.step = 'QOL_EQ_5D_5L';
        vm.wizardStep = 0;

        vm.onChangeValue = onChangeValue;

        vm.vasValueList = new Array(101);
        for (let i = 0; i < vm.vasValueList.length; i++) {
            vm.vasValueList[i] = 100 - i;
        }

        vm.arrowPosition = 58 + ((100 - 50) * (472 / 100));
        vm.showArrow = false;
        vm.arrowValue = 50;

        vm.$onInit = function () {};

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

        vm.previous = function() {
            vm.wizardStep--;
        };

        vm.next = function() {
            vm.wizardStep++;
        };

        function onChangeValue($event, key, value) {
            if (!vm.data) {
                vm.data = {};
            }

            if (vm.data[key] === value) {
                $event.preventDefault();
            } else {
                vm.data[key] = value;
            }

        }

    }

})();
