(function () {
    'use strict';

    const visitDetailPain = {
        bindings: {
            data: '=',
            questions: '<',
        },
        controller: visitDetailPainController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/steps/pain/visit-detail-pain.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitDetailPain', visitDetailPain);

    visitDetailPainController.$inject = [];

    function visitDetailPainController() {

        const vm = this;

        vm.$onInit = function () {

        };

    }

})();
