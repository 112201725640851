(function () {
    'use strict';

    const patientTreatmentsBlock = {
        bindings: {
            patientId: '=',
            expand: '='
        },
        controller: PatientTreatmentsBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient-treatment/patient-treatments-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientTreatmentsBlock', patientTreatmentsBlock);

    PatientTreatmentsBlockController.$inject = ['$rootScope', '$scope', '$log', '$uibModal', 'PatientTreatment'];

    function PatientTreatmentsBlockController($rootScope, $scope, $log, $uibModal, PatientTreatment) {

        const vm = this;

        const unsubscribe1 = $rootScope.$on('continuumplatformApp:patientTreatmentUpdate', load);
        const unsubscribe2 = $rootScope.$on('continuumplatformApp:patientTreatmentPhaseUpdate', load);
        $scope.$on('$destroy', function (event) {
            unsubscribe1(event);
            unsubscribe2(event);
        });

        vm.$onInit = load;

        function load() {
            if (vm.patientId)
                PatientTreatment.query({
                    "patientId.equals": vm.patientId,
                    "size": 1000  // TODO hard-coded size
                }, function (value) {
                    vm.treatments = value.sort((t1, t2) => {
                        const score = getTreatmentStatusSortScore(t2) - getTreatmentStatusSortScore(t1);
                        return score ? score : t1.id - t2.id;
                    });
                    $rootScope.$emit('continuumplatformApp:patientTreatmentsLoaded', value);
                });
            else {
                $log.error("Missing patient id");
                vm.treatments = [];
            }
        }

        vm.openTreatment = function (treatment) {
            $uibModal.open({
                templateUrl: 'app/activity/patient-treatment/patient-treatment-preview.html',
                controller: 'PatientTreatmentPreviewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: treatment
                }
            }).result.then(() => {
                // do nothing
            }, () => {
                // do nothing
            });
        };

        function getTreatmentStatusSortScore(treatment) {
            switch (treatment.status) {
            case 'S10_PENDING':
                return 80;
            case 'S40_FINISHED':
                return 20;
            case 'S50_STOPPED':
                return 10;
            case 'S99_CANCELED':
                return 0;
            default :
                return 90;
            }
        }


    }

})();
