(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .filter('objlength', objlength);

    function objlength() {
        return function (obj) {
            if (!obj)
                return null;
            return Object.keys(obj).length;
        }
    }
})();
