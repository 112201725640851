(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('BillDeleteController', BillDeleteController);

    BillDeleteController.$inject = ['$uibModalInstance', 'bill', 'Bill'];

    function BillDeleteController($uibModalInstance, bill, Bill) {
        var vm = this;

        vm.bill = bill;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            Bill.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
