(function () {
    'use strict';

    const patientTherapyMedicationsBlock = {
        bindings: {
            medications: '<',
            patientId: '<'
        },
        controller: PatientTherapyMedicationsBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient-therapy/patient-therapy-medications-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientTherapyMedicationsBlock', patientTherapyMedicationsBlock);

    PatientTherapyMedicationsBlockController.$inject = ['$uibModal'];

    function PatientTherapyMedicationsBlockController($uibModal) {

        const vm = this;

        vm.$onInit = () => {
        };

        vm.openMedication = (medication) => {
            $uibModal.open({
                templateUrl: 'app/entities/medication/medication-preview-modal.html',
                controller: 'MedicationPreviewModalController',
                controllerAs: 'vm',
                size: vm.patientId ? 'lg' : 'md',
                resolve: {
                    medication: () => medication,
                    patientId: () => vm.patientId,
                    options: {}
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
        };

    }

})();
