(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('mediaIcon', mediaIcon);

    function mediaIcon() {

        return {
            restrict: 'E',
            scope: {
                contentType: '=',
                additionalClass: '@'
            },
            templateUrl: 'app/activity/media/media-icon.html'
        };


    }

})();
