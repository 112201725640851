(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HealthFacilityDivisionDialogController', HealthFacilityDivisionDialogController);

    HealthFacilityDivisionDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'division', 'Disease'];

    function HealthFacilityDivisionDialogController($timeout, $scope, $uibModalInstance, division, Disease) {
        const vm = this;

        vm.division = division;
        vm.clear = clear;
        vm.save = save;

        vm.selectedDiseasesGroup = [];

        vm.onClickDisease = onClickDisease;
        vm.onClickDiseasesGroup = onClickDiseasesGroup;
        vm.isDiseaseSelected = isDiseaseSelected;

        vm.$onInit = () => {
            loadDiseases().then(() => {

                vm.selectedDiseases.forEach((disease) => {
                    // Si toutes les maladies du groupe sont sélectionnées, on l'ajoute à la liste des groupes sélectionnés.
                    if(vm.selectedDiseases.filter((d) => d.group === disease.group).length === vm.diseases.filter((d) => d.group === disease.group).length) {
                        vm.selectedDiseasesGroup.push(disease.group);
                    }
                });
            });
        };

        $timeout(() => {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.division.diseaseIds = vm.selectedDiseases.map(d => d.id);
            $uibModalInstance.close(vm.division);
        }

        /**
         * Load the list of diseases.
         */
        function loadDiseases() {
            return Disease.query({
                "deleted.equals": false,
                size: 999
            }).$promise.then(diseases => {
                vm.diseases = diseases;
                vm.selectedDiseases = vm.diseases.filter(d => vm.division.diseaseIds.includes(d.id));
            });
        }

        /**
         * Handles clicking a selected filter disease.
         * @param disease - The disease to filter by.
         */
        function onClickDisease(disease) {
            if (isDiseaseSelected(disease)) {
                vm.selectedDiseases = vm.selectedDiseases.filter((d) => d.id !== disease.id);

                // Si le groupe est déjà sélectionné, on le supprime de la liste des groupes sélectionnés.
                if (vm.selectedDiseasesGroup.includes(disease.group)) {
                    vm.selectedDiseasesGroup = vm.selectedDiseasesGroup.filter((dg) => dg !== disease.group);
                }

            } else {
                vm.selectedDiseases.push(disease);

                // Si toutes les maladies du groupe sont sélectionnées, on l'ajoute à la liste des groupes sélectionnés.
                if(vm.selectedDiseases.filter((d) => d.group === disease.group).length === vm.diseases.filter((d) => d.group === disease.group).length) {
                    vm.selectedDiseasesGroup.push(disease.group);
                }
            }
        }

        /**
         * Handle click on diseases group filter.
         * @param diseasesGroup - The diseases group to filter by.
         */
        function onClickDiseasesGroup(diseasesGroup) {
            if (vm.selectedDiseasesGroup.includes(diseasesGroup)) {
                vm.selectedDiseasesGroup = vm.selectedDiseasesGroup.filter((dg) => dg !== diseasesGroup);

                // on supprime de la sélection l'ensemble des maladies du groupe désélectionné.
                vm.selectedDiseases = vm.selectedDiseases.filter((d) => d.group !== diseasesGroup);

            } else {
                vm.selectedDiseasesGroup.push(diseasesGroup);
                vm.diseases.forEach((disease) => {
                    // si non inclus dans la liste des maladies sélectionnées, on l'ajoute.
                    if (disease.group === diseasesGroup && !vm.selectedDiseases.includes(disease)) {
                        vm.selectedDiseases.push(disease);
                    }
                });
            }
        }

        /**
         * Check if a disease is selected.
         * @param disease - The disease to check.
         * @return {boolean} - true Whether the disease is selected else false.
         */
        function isDiseaseSelected(disease) {
            return vm.selectedDiseases.filter((d) => d.id === disease.id).length > 0;
        }

    }
})();
