(function() {
    'use strict';

    const jhiAlertError = {
        template: '<div class="alerts" ng-cloak="" role="alert">' +
                        '<div ng-repeat="alert in $ctrl.alerts" ng-class="[alert.position, {\'toast\': alert.toast}]">' +
                            '<div  ng-cloak="" uib-alert ng-class="\'alert-\' + alert.type" close="alert.close($ctrl.alerts)"><pre>{{ alert.msg }}</pre></div>' +
                        '</div>' +
                  '</div>',
        controller: jhiAlertErrorController
    };

    angular
        .module('continuumplatformApp')
        .component('jhiAlertError', jhiAlertError);

    jhiAlertErrorController.$inject = ['$log', '$scope', 'AlertService', '$rootScope', '$translate'];

    function jhiAlertErrorController($log, $scope, AlertService, $rootScope, $translate) {
        const vm = this;

        vm.alerts = [];

        function addErrorAlert(message, key, data) {
            const msg = key || message;
            const alert = AlertService.add(
                {
                    type: 'danger',
                    msg: msg,
                    params: data,
                    timeout: 5000,
                    toast: AlertService.isToast(),
                    scoped: true
                },
                vm.alerts
            );
            if(alert) {
                vm.alerts.push(alert);
            }
        }

        const cleanHttpErrorListener = $rootScope.$on('continuumplatformApp.httpError', function (event, httpResponse) {
            event.stopPropagation();
            if(httpResponse.xhrStatus === 'abort') {
                return;
            }
            switch (httpResponse.status) {
            case -1:
            case 0:
                addErrorAlert('Server not reachable','error.server.not.reachable');
                break;

            case 400:
                handleBadRequest(httpResponse);
                break;

            case 404:
                addErrorAlert('Not found','error.url.not.found');
                break;

            default:
                if (httpResponse.data && httpResponse.data.message) {
                    addErrorAlert(httpResponse.data.message);
                } else {
                    $log.error('Unexpected error', httpResponse);
                    addErrorAlert(httpResponse.status + ' ' + httpResponse.statusText, 'error.http.' + httpResponse.status);
                }
            }
        });

        $scope.$on('$destroy', function () {
            if(angular.isDefined(cleanHttpErrorListener) && cleanHttpErrorListener !== null){
                cleanHttpErrorListener();
                vm.alerts = [];
            }
        });

        function handleBadRequest(httpResponse) {
            const headers = Object.keys(httpResponse.headers()).filter(function (header) {
                return header.indexOf('app-error', header.length - 'app-error'.length) !== -1 || header.indexOf('app-params', header.length - 'app-params'.length) !== -1;
            }).sort();
            const errorHeader = httpResponse.headers(headers[0]);
            if (angular.isString(errorHeader)) {
                const params = {};
                if (headers[1]) {
                    const entityKey = httpResponse.headers(headers[1]);
                    params.entityName = $translate.instant('global.menu.entities.' + entityKey);
                }
                addErrorAlert(errorHeader, errorHeader, params);
            } else if (httpResponse.data && httpResponse.data.fieldErrors) {
                for (const fieldError of httpResponse.data.fieldErrors) {
                    const fieldName = fieldError.objectName + '.' + fieldError.field;
                    addErrorAlert('Field ' + fieldName + ' cannot be empty', 'error.' + fieldError.message, {fieldName: fieldName});
                }
            } else if (httpResponse.data && httpResponse.data.message) {
                $log.error('Bad request', httpResponse);
                addErrorAlert(httpResponse.data.message, httpResponse.data.message, httpResponse.data);
            } else {
                $log.error('Unexpected bad request', httpResponse);
                addErrorAlert('Bad Request', 'error.http.400');
            }
        }
    }
})();
