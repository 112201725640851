(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('payment', {
                parent: 'remuneration',
                url: '/payment?page&sort&payerId',
                data: {
                    authorities: ['ROLE_FINANCE'],
                    pageTitle: 'continuumplatformApp.payment.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/payment/payments.html',
                        controller: 'PaymentController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'createdDate,desc',
                        squash: true
                    },
                    payerId: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            payerId: parseInt($stateParams.payerId) || null
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('payment');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('payment-detail', {
                parent: 'payment',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_FINANCE'],
                    pageTitle: 'continuumplatformApp.payment.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/payment/payment-detail.html',
                        controller: 'PaymentDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('payment');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Payment', function ($stateParams, Payment) {
                        return Payment.get({id: $stateParams.id}).$promise;
                    }]
                }
            });
    }

})();
