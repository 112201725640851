(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PaymentDetailController', PaymentDetailController);

    PaymentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity'];

    function PaymentDetailController($scope, $rootScope, $stateParams, entity) {
        var vm = this;

        vm.payment = entity;

    }
})();
