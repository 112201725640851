(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MedicationVariantDeleteController', MedicationVariantDeleteController);

    MedicationVariantDeleteController.$inject = ['$uibModalInstance', 'entity', 'MedicationVariant'];

    function MedicationVariantDeleteController($uibModalInstance, entity, MedicationVariant) {
        var vm = this;

        vm.medicationVariant = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            MedicationVariant.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
