(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Address', Address);

    Address.$inject = ['$http'];

    function Address($http) {

        return {
            search: (query) => {
                if (!query) {
                    throw new Error("Missing search query");
                }
                return Promise.all([
                    searchStreetNumber(query),
                    searchStreet(query),
                    searchLocality(query),
                    searchCity(query)]
                ).then((values) => values.flat().map(r => {
                    r.properties.typeLabel = mapTypeLabel(r.properties.type);
                    return r;
                }));
            },

        };

        function mapTypeLabel(type) {
            switch (type) {
            case 'housenumber':
                return 'numéro';
            case 'street':
                return 'rue';
            case 'locality':
                return 'lieu-dit';
            case 'municipality':
                return 'commune';
            }
        }

        function searchStreetNumber(search) {
            return $http({
                method: 'GET',
                url: `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(search)}&type=housenumber&autocomplete=1&limit=7`
            }).then(response => response.data.features);
        }

        function searchStreet(query) {
            return $http({
                method: 'GET',
                url: `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(query)}&type=street&autocomplete=1&limit=7`
            }).then(response => response.data.features);
        }

        function searchLocality(query) {
            return $http({
                method: 'GET',
                url: `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(query)}&type=locality&autocomplete=1&limit=7`
            }).then(response => response.data.features);
        }

        function searchCity(query) {
            return $http({
                method: 'GET',
                url: `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(query)}&type=municipality&autocomplete=1&limit=50`
            }).then(response =>
                response.data.features.sort((c1, c2) => {
                    return c1.properties.label.localeCompare(c2.properties.label);
                }).map((c) => {
                    c.properties.label = `${c.properties.name} (${c.properties.postcode})`;
                    return c;
                }));
        }
    }
})();
