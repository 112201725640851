(() => {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('VisitConfirmDialogController', VisitConfirmDialogController);

    VisitConfirmDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'visit', 'Visit'];

    function VisitConfirmDialogController($timeout, $scope, $uibModalInstance, visit, Visit) {
        const vm = this;

        vm.visit = visit;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.clear = () => {
            $uibModalInstance.dismiss('cancel');
        };

        vm.save = () => {
            vm.isSaving = true;
            Visit.confirm({
                id: vm.visit.id
            }, {
                plannedDate: vm.visit.plannedDate,
            }, onSaveSuccess, onSaveError);
        };

        function onSaveSuccess() {
            $scope.$emit('continuumplatformApp:visitUpdate', vm.visit.id);
            $uibModalInstance.close(vm.visit);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
