(function () {
    'use strict';

    const visitPainStep = {
        bindings: {
            data: '=',
            painMeasure: '=',
            previousMeasures: '<',
            questions: '<',
            isPractitioner: '<',
        },
        controller: VisitPainStepController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/steps/pain/visit-pain-step.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitPainStep', visitPainStep);

    VisitPainStepController.$inject = ['$rootScope', '$scope'];

    function VisitPainStepController($rootScope, $scope) {

        const vm = this;

        vm.step = 'pain';

        vm.onSelectedBodyPartChange = onSelectedBodyPartChange;

        /**
         * Initialize the component.
         */
        vm.$onInit = function () {
            if (!vm.data) {
                vm.data = {};
            }

            // si l'utilisateur est un professionnel de santé, on oblige à renseigner le type de douleur.
            if (vm.isPractitioner) {
                vm.questions.Q3.required = true;
            }

            vm.unsubscribeHavePainChange = $scope.$watch("vm.data.Q1", onHavePainChange);

        };

        /**
         * Destroy the component.
         */
        vm.$onDestroy = function () {
            if(vm.unsubscribeHavePainChange) {
                vm.unsubscribeHavePainChange();
            }
        };

        /**
         * Execute when the user change the selected body part.
         *
         * @param newSelectedBodyPart - the new selected body part.
         */
        function onSelectedBodyPartChange(newSelectedBodyPart) {
            vm.data.Q2 = newSelectedBodyPart.join(', ');
        }

        function onHavePainChange() {
            if (vm.data['Q1'] === 'N') {
                vm.painMeasure.value = 0;
                vm.data.Q2 = null;
                vm.data.Q3 = null;
                vm.data.Q4 = null;
                vm.data.Q5_1 = null;
                vm.data.Q5_2 = null;
                vm.data.Q5_3 = null;
                vm.data.Q5_4 = null;
                vm.data.Q6 = null;
                vm.data.Q7 = null;
                vm.data.Q8 = null;
            }

        }

        /**
         * Execute when the user validate the step by clicking on the "Valider" button.
         */
        vm.finish = function () {

            if (vm.data.Q1 === 'Y') {
                vm.painMeasure.comment = `${vm.data.Q2}. ${vm.data.Q3 ? '\n\n' + vm.data.Q3 : ''}`;
            } else {
                vm.painMeasure.comment = null;
            }

            if (vm.data['Q6'] === 'N') {
                vm.data['Q7'] = null;
                vm.data['Q8'] = null;
            }

            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };
    }

})();
