(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('AppLayoutController', AppLayoutController);

    AppLayoutController.$inject = [];

    function AppLayoutController() {
        const vm = this;

    }
})();
