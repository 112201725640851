(function () {
    'use strict';

    var patientQualityOfLifeBlock = {
        bindings: {
            visits: '=',
        },
        controller: PatientQualityOfLifeBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/qol/patient-qol-EQ5D5L-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientQualityOfLifeEq5d5lBlock', patientQualityOfLifeBlock);

    PatientQualityOfLifeBlockController.$inject = ['$state'];

    function PatientQualityOfLifeBlockController($state) {

        const vm = this;

        vm.openVisitDetails = openVisitDetails;
        vm.toggleVisitSelection = toggleVisitSelection;
        vm.selectedVisits = {};

        vm.$onInit = function () {
            initGraph();
            initSelectedVisits();
            initGraphData();
        };

        function initSelectedVisits() {
            const visitsWithQol = vm.visits.filter( (v) => v.steps ? v.steps.includes('QOL_EQ_5D_5L') : false);
            const last3VisitsWithQol = visitsWithQol.slice(0,3);

            vm.selectedVisits = last3VisitsWithQol.reduce( (acc, v) => {
                acc[v.id] = v;
                return acc;
            }, {});

        }

        function initGraph() {
            vm.chart = {
                labels: ["Activités habituelles", "Anxiété / Dépression", "Mobilité", "Douleur / Gêne", "Prendre soin de soi"],
                data: [],
                colors: ['#273FDC99', '#ded73f', '#de5b5b', '#81bd41'],
                options: {
                    scale: {
                        angleLines: {
                            display: false
                        },
                        beginAtZero: true,
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 5,
                            min: 0,
                            max: 5,
                            stepSize: 1,
                        }
                    },
                    legend: {
                        display: true,
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                },
                datasetOverride: []
            };
        }

        function initGraphData() {
            vm.qolData = [];
            vm.chart.data = [];
            vm.chart.datasetOverride = [];

            Object.values(vm.selectedVisits).forEach( (v) => {
                if (v.data && v.data.details && v.data.details.QOL_EQ_5D_5L) {
                    const data = Object.assign({}, v.data.details.QOL_EQ_5D_5L);
                    data.date = v.date;
                    vm.qolData.push(data);

                    vm.chart.data.push([data.activity, data.anxiety, data.mobility, data.pain, data.selfCare]);
                    //
                    const dateInterview = (v.date ? moment(v.date) : moment()).format("DD/MM/YYYY");
                    vm.chart.datasetOverride.push({label: dateInterview});
                }
            });
        }

        function openVisitDetails(visitId) {
            $state.go('visit-detail', {id: visitId});
        }

        /**
         * Toggle the selection of a visit
         * @param visit the visit to toggle
         */
        function toggleVisitSelection(visit) {
            if (!vm.selectedVisits[visit.id]) {
                delete vm.selectedVisits[visit.id];
            }

            initGraphData();
        }

    }

})();
