(function () {
    'use strict';

    const newMessageItem = {
        bindings: {
            mto: '=',
        },
        controller: NewMessageItemController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/new-messages/new-message-item.html'
    };

    angular
        .module('continuumplatformApp')
        .component('newMessageItem', newMessageItem);

    NewMessageItemController.$inject = ['$scope', '$state', 'Principal'];

    function NewMessageItemController($scope, $state, Principal) {

        const vm = this;

        /**
         * Executes when the component is initialized.
         */
        vm.$onInit = function () {
            Principal.identity().then(account => {
                vm.account = account;
                vm.link = generateOpenLink(vm.mto, vm.account);
            });

            vm.messageCreatedDateIsToday = moment(vm.mto.message.createdDate).isSame(moment(), 'day');
        };

        /**
         * Generates an open link to message based on the given message and account.
         *
         * @param {object} mto - The user message object.
         * @param {object} account - The account object.
         * @return {object} - An object that contains state and parameters.
         */
        function generateOpenLink(mto, account) {
            if(mto.message.attachments && mto.message.attachments.visit && mto.message.attachments.visit.length) {
                return {
                    state : 'visit-detail',
                    params: {id: mto.message.attachments.visit[0].id}
                };
            } else if (mto.userLogin === account.login) {
                return {
                    state : 'messaging',
                    params: {message: mto.id}
                };
            } else {
                return {
                    state : 'message-detail',
                    params: {id: mto.message.id}
                };
            }
        }

    }

})();
