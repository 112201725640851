(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('CompressImage', CompressImage);

    CompressImage.$inject = [];

    function CompressImage() {

        const service = {
            compress: function(imgFile, quality= 75, mimeType = "image/jpeg") {
                return loadFile(imgFile).then( (fileData) => {
                    return loadImage(fileData, imgFile.name);
                }).then( (image) => {
                    return new Promise((resolve, reject) => {

                        try {
                            const canvas = imageToCanvas(image);

                            const newImageBase64 = canvasToBase64(canvas, quality/100, mimeType);
                            const newFile = base64ToFile(newImageBase64, mimeType, imgFile.name);

                            resolve(newFile);
                        } catch (error) {
                            reject(error);
                        }

                    });
                }).catch( (error) => {
                    console.log(error);
                });
            }

        };

        function loadFile(file) {
            return new Promise((resolve, reject) => {
                const fileReader = new window.FileReader();
                fileReader.addEventListener('load', (evt) => resolve(evt.target.result), false);
                fileReader.addEventListener('error', reject, false);

                fileReader.readAsDataURL(file);
            });
        }


        function loadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new window.Image();
                img.addEventListener('load', () => resolve(img), false);
                img.addEventListener('error', reject, false);
                img.src = src;
            });
        }

        function imageToCanvas(image) {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;

            const ctx = canvas.getContext("2d");
            // on met du blanc pour le fond pour éventuellement gérer la transparence des images png ou autre
            ctx.fillStyle = 'white';
            ctx.fillRect(0,0,canvas.width, canvas.height);
            // on ajoute ensuite l'image
            ctx.drawImage(image, 0, 0, image.width, image.height);
            return canvas;
        }

        function canvasToBase64(canvas, quality = 0.75, mimeType) {
            // in order to compress the final image format has to be jpeg
            const base64 = canvas.toDataURL(mimeType, quality);
            return base64.replace(/^data:image\/\w+;base64,/, "");
        }

        function base64ToFile(base64, mimeType = "image/jpeg", name) {

            const byteString = window.atob(base64);
            const content = [];
            for (let i = 0; i < byteString.length; i++) {
                content[i] = byteString.charCodeAt(i);
            }

            const imageBlob = new window.Blob([new Uint8Array(content)], { type: mimeType });

            return new File([imageBlob], `${name.substr(0, name.lastIndexOf('.'))}.jpg`, {type: imageBlob.type,});
        }

        return service;
    }
})();
