(function () {
    'use strict';

    var pharmaItwEvalPicto = {
        bindings: {
            data: '=',
            questions: '<',
            patientTherapyId: '='
        },
        controller: pharmaItwEvalPictoController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/pharma-itw-eval-picto.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmaItwEvalPicto', pharmaItwEvalPicto);

    pharmaItwEvalPictoController.$inject = ['$rootScope', 'Visit'];

    function pharmaItwEvalPictoController($rootScope, Visit) {

        var vm = this;

        vm.step = 'PHARMA_ITW_EVAL_PICTO';
        vm.showCorrected = 0;

        vm.$onInit = () => {
            loadVisits();
        };

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

        function loadVisits() {
            Visit.query({
                "patientTherapyId.equals": vm.patientTherapyId,
                "sort": "dueDate,asc",
                "size": 1000
            }, function (visits) {
                vm.visits = visits;
                visits.forEach((v) => {
                    vm.showCorrected = v.date && v.steps && v.steps.includes('PHARMA_ITW_EVAL_PICTO') ? vm.showCorrected + 1 : vm.showCorrected;
                });
            });
        }

    }

})();
