(function () {
    'use strict';

    const visitSelectQuestion = {
        bindings: {
            form: '=',
            key: '@',
            questions: '=',
            data: '=',
            fieldName: '=',
            disabled: '='
        },
        controller: VisitSelectQuestion,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-select-question.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitSelectQuestion', visitSelectQuestion);

    VisitSelectQuestion.$inject = [];

    function VisitSelectQuestion() {

        const vm = this;
    }

})();
