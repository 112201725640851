(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientDetailsIdentityController', PatientDetailsIdentityController);

    PatientDetailsIdentityController.$inject = ['$timeout', '$uibModalInstance', 'entity'];

    function PatientDetailsIdentityController($timeout, $uibModalInstance, entity) {
        const vm = this;

        vm.patient = entity;

        vm.close = close;

        function close() {
            $uibModalInstance.dismiss('cancel');
        }


    }
})();
