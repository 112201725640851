(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('StateSaver', StateSaver);

    StateSaver.$inject = ['$log', '$localStorage'];

    function StateSaver($log, $localStorage) {

        return {getPreviousState, resetPreviousState, storePreviousState};

        function getPreviousState() {
            return $localStorage.previousState;
        }

        function resetPreviousState() {
            delete $localStorage.previousState;
            $log.info('Cleared stored state');
        }

        function storePreviousState(previousStateName, previousStateParams) {
            if (['home', 'login', 'terms', 'accessdenied', 'error'].includes(previousStateName)) {
                $log.info('Ignoring state, no later redirect needed', previousStateName);
                return;
            }
            $localStorage.previousState = {"name": previousStateName, "params": previousStateParams};
            $log.info('Stored state for later redirect', previousStateName, previousStateParams)
        }
    }
})();
