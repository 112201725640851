(function() {
    'use strict';

    angular.module('continuumplatformApp')
        .controller('WaitingLogoutModalController', WaitingLogoutModalController);

    WaitingLogoutModalController.$inject = ['$scope', '$uibModalInstance', 'logoutStatus', 'Auth'];

    function WaitingLogoutModalController($scope, $uibModalInstance, logoutStatus, Auth) {
        const vm = this;
        vm.logoutStatus = logoutStatus;

        vm.close = () => {
            $uibModalInstance.close('cancel');
        };

        vm.retry = () => {
            Auth.logout();
        };

    }
})();
