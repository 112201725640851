(function () {
    'use strict';

    var ToasterLevel = {
        SUCCESS: 'success',
        INFO: 'info',
        WARNING: 'warning',
        ERROR: 'error',
    };

    angular
        .module('continuumplatformApp')
        .provider('ToasterService', ToasterService);

    function ToasterService() {
        /*jshint validthis: true */
        this.$get = getService;

        getService.$inject = ['$translate', '$injector'];

        function getService($translate, $injector) {

            return {
                add: addAlert,
                success: success,
                error: error,
                info: info,
                warning: warning
            };

            function success(msg, params) {
                return this.add({
                    type: ToasterLevel.SUCCESS,
                    msg: msg,
                    params: params,
                });
            }

            function error(msg, params) {
                return this.add({
                    type: ToasterLevel.ERROR,
                    msg: msg,
                    params: params,
                });
            }

            function warning(msg, params) {
                return this.add({
                    type: ToasterLevel.WARNING,
                    msg: msg,
                    params: params,
                });
            }

            function info(msg, params) {
                return this.add({
                    type: ToasterLevel.INFO,
                    msg: msg,
                    params: params,
                });
            }

            function addAlert(alertOptions) {
                alertOptions.msg = $translate.instant(alertOptions.msg, alertOptions.params);

                var Notification = $injector.get('Notification');
                Notification({message: alertOptions.msg}, alertOptions.type);
            }
        }
    }
})();
