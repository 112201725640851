(function () {
    'use strict';

    var patientTreatmentPhaseSumDayText = {
        bindings: {
            phase: '='
        },
        controller: PatientTreatmentPhaseSumDayTextController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient-treatment-phase/patient-treatment-phase-sum-day-text.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientTreatmentPhaseSumDayText', patientTreatmentPhaseSumDayText);

    PatientTreatmentPhaseSumDayTextController.$inject = [];

    function PatientTreatmentPhaseSumDayTextController() {

        var vm = this;

        vm.$onInit = function () {
            if (vm.phase && vm.phase.hours && vm.phase.hours.length)
                vm.sumDay = Object.values(vm.phase.hours).reduce(function (acc, v) {
                    return acc + v;
                });
        };

    }

})();
