(function() {
    'use strict';

    angular.module('continuumplatformApp')
        .controller('PatientTherapyCancelRequestModalController', PatientTherapyCancelRequestModalController);

    PatientTherapyCancelRequestModalController.$inject = ['$uibModalInstance', 'currentPatient'];

    function PatientTherapyCancelRequestModalController($uibModalInstance, currentPatient) {
        var vm = this;

        vm.currentPatient = currentPatient;

        vm.deleteDraft = deleteDraft;
        vm.saveDraft = saveDraft;
        vm.cancel = cancel;

        function deleteDraft() {
            $uibModalInstance.close('deleteDraft');
        }

        function saveDraft() {
            $uibModalInstance.close('saveDraft');
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
