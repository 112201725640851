(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('MetricsService', MetricsService);

    MetricsService.$inject = ['$rootScope', '$http'];

    function MetricsService($rootScope, $http) {

        return {getMetrics};

        function getMetrics(metric) {
            return $http.get('management/metrics/' + metric).then(response => response.data);
        }

    }
})();
