(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PractitionerModalController', PractitionerModalController);

    PractitionerModalController.$inject = ['practitioner', '$uibModal', '$uibModalInstance', '$state'];

    function PractitionerModalController(practitioner, $uibModal, $uibModalInstance, $state) {
        var vm = this;

        vm.practitioner = practitioner;

        vm.clear = clear;
        vm.edit = edit;
        vm.sendInvitationEmail = sendInvitationEmail;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function edit() {
            clear();
            $state.go('practitioner-detail.edit', {id: vm.practitioner.id});
        }

        function sendInvitationEmail() {
            $uibModal.open({
                templateUrl: 'app/entities/practitioner/practitioner-invite.html',
                controller: 'PractitionerInviteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    practitioner: () => practitioner
                }
            }).result.then(() => {
                // do nothing
            }, () => {
                // do nothing
            });
        }

    }
})();
