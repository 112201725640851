(function () {
    'use strict';

    var visitImportPrescriptions = {
        bindings: {
            patient: '<'
        },
        controller: VisitImportPrescriptionsController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-import-prescriptions.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitImportPrescriptions', visitImportPrescriptions);

    VisitImportPrescriptionsController.$inject = ['$rootScope', '$sce', '$uibModal', 'Prescription', 'Media'];

    function VisitImportPrescriptionsController($rootScope, $sce, $uibModal, Prescription, Media) {

        const vm = this;

        vm.step = 'IMPORT_PRESCRIPTIONS';

        vm.finish = () => {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

        vm.open = (prescriptionId) => {
            Prescription.get({id: prescriptionId}, (prescription) => {

                if (!prescription.mediaId) {

                    alert('Fichier introuvable');

                } else if (prescription.mediaId && (prescription.mediaContentType === 'application/pdf' || prescription.mediaContentType.startsWith('image/'))) {

                    Media.get({id: prescription.mediaId}, openModal);

                } else if (prescription.mediaId) {

                    $rootScope.warnBeforeUnload = false;
                    vm.trustedInlineUrl = $sce.trustAsResourceUrl(`/api/media/${prescription.mediaId}/download?disposition=attachment`);
                    vm.downloadURI(vm.trustedInlineUrl);
                    $rootScope.warnBeforeUnload = true;

                }
            });
        };

        function openModal(media) {
            $uibModal.open({
                templateUrl: 'app/activity/media/media-preview.modal.html',
                controller: 'MediaPreviewModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    media: () => media,
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
        }

    }

})();
