(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .filter('translatePractitionerName', translatePractitionerName);

    translatePractitionerName.$inject = ['$translate'];

    function translatePractitionerName($translate) {

        // interpolation keys in message
        var FIELD = {
            lastName: 'lastName',
            firstName: 'firstName',
            title: 'title',
            job: 'job',
            jobOrSpeciality: 'jobOrSpeciality'
        };

        // message format
        var FORMAT_FIELD = {
            LASTNAME: [FIELD.lastName],
            FULLNAME: [FIELD.lastName, FIELD.firstName],
            TITLE_LASTNAME: [FIELD.title, FIELD.lastName],
            TITLE_FULLNAME: [FIELD.title, FIELD.lastName, FIELD.firstName],
            TITLE_FULLNAME_JOB: [FIELD.title, FIELD.lastName, FIELD.firstName, FIELD.job],
            TITLE_FULLNAME_JOB_SPECIALITY: [FIELD.title, FIELD.lastName, FIELD.firstName, FIELD.jobOrSpeciality],
        };

        return getFormattedName;


        function getFormattedName(practitioner, format) {

            if (!practitioner) {
                return "";
            }

            var key = resolveFormatKey(format);
            var data = resolveData(practitioner, format);

            return $translate.instant(key, data).trim();
        }

        function resolveFormatKey(format) {
            return "continuumplatformApp.practitioner.nameFormat." + format;
        }

        function resolveData(practitioner, format) {

            if (!practitioner){
                return "";
            }

            var data = {
                lastName: practitioner.lastName
            };

            if (formatRequiresField(format, FIELD.firstName)) {
                data[FIELD.firstName] = practitioner.firstName;
            }

            if (formatRequiresField(format, FIELD.title) && practitionerHasField(practitioner, 'title')) {
                data[FIELD.title] = $translate.instant("continuumplatformApp.practitioner.title." + practitioner.title + "-short");
            }

            if (formatRequiresField(format, FIELD.job)) {
                data[FIELD.job] = $translate.instant("continuumplatformApp.practitioner.job." + practitioner.job);
            }

            if (formatRequiresField(format, FIELD.jobOrSpeciality)) {
                var practitionerJob = practitioner.job;
                var jobOrSpeciality = '';
                if (practitioner.speciality) {
                    jobOrSpeciality = $translate.instant("continuumplatformApp.PractitionerSpeciality." + practitioner.speciality + "-short");
                } else {
                    jobOrSpeciality = $translate.instant("continuumplatformApp.practitioner.job." + practitionerJob);
                }

                data[FIELD.jobOrSpeciality] = jobOrSpeciality;
            }

            return data;
        }

        function formatRequiresField(format, field) {
            return FORMAT_FIELD[format].indexOf(field) !== -1;
        }

        function practitionerHasField(practitioner, field) {
            return !!practitioner[field];
        }
    }

})();
