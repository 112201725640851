(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .constant('errorConstants', (function () {
            var problemBaseUrl = 'http://www.jhipster.tech/problem'; // TODO unify
            return {
                INVALID_PASSWORD_TYPE: problemBaseUrl + '/invalid-password',
                EMAIL_ALREADY_USED_TYPE: problemBaseUrl + '/email-already-used',
                LOGIN_ALREADY_USED_TYPE: problemBaseUrl + '/login-already-used',
                BAD_CURRENT_PASSWORD_TYPE: 'http://www.continuum.plus/problem' + '/bad-current-password',
                EMAIL_NOT_FOUND_TYPE: 'http://www.continuum.plus/problem' + '/email-not-found',
                PRACTITIONER_ALREADY_EXISTS: 'http://www.continuum.plus/problem' + '/practitioner-already-exists'
            };
        })());
})();
