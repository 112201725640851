(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('exportButton', exportButton);

    function exportButton() {
        return {
            restrict: 'E',
            scope: {
                label: '=',
                icon: '@',
                css: '@',
                exportInProgress: '=',
                onClick: '&'
            },
            link: link,
            templateUrl: 'app/components/form/button/export-button.html'
        };
    }

    function link(scope, element, attrs) {

        scope.$watch(attrs.exportInProgress, onExportInProgressChange);

        element.on('click', onClick);

        function onExportInProgressChange(value) {
            element.exportInProgress = value;
        }

        function onClick() {
            scope.$apply(scope.onClick());
        }

    }

})();
