(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('stateHandler', stateHandler);

    stateHandler.$inject = ['$log', '$rootScope', '$translate', 'JhiLanguageService', 'translationHandler', '$window', 'Authorization', '$transitions'];

    function stateHandler($log, $rootScope, $translate, JhiLanguageService, translationHandler, $window, Authorization, $transitions) {

        return {initialize};

        function initialize() {

            $transitions.onBefore({}, transition => {
                $log.info('Authorizing transition to', transition.to().name);
                return Authorization.authorize(transition);
            });

            $transitions.onStart({}, transition => {

                $rootScope.toState = transition.to();
                $rootScope.toStateParams = transition.params();
                $rootScope.fromState = transition.from();

                // TODO? Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
                //if (toState.external) {
                //    event.preventDefault();
                //    $window.open(toState.url, '_self');
                //}

                // Update the language
                JhiLanguageService.getCurrent().then(function (language) {
                    $translate.use(language);
                });
            });

            $transitions.onSuccess({}, transition => {

                var toState = transition.to();
                var fromState = transition.from();

                // Set the page title key to the one configured in state
                // or use default one, or leave up to the controller if 'custom'
                if (toState.data && toState.data.pageTitle !== 'custom') {
                    let titleKey = toState.data.pageTitle || 'global.title';
                    translationHandler.updateTitle(titleKey);
                }

                if (!toState.onEnter && !fromState.onEnter)
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
            });

            // TODO?
            //$rootScope.$on('$destroy', function () {
            //    if(angular.isDefined(stateChangeStart) && stateChangeStart !== null){
            //        stateChangeStart();
            //    }
            //    if(angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null){
            //        stateChangeSuccess();
            //    }
            //});

        }
    }
})();
