(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('BiologyResultDeleteController', BiologyResultDeleteController);

    BiologyResultDeleteController.$inject = ['$uibModalInstance', 'entity', 'BiologyResult'];

    function BiologyResultDeleteController($uibModalInstance, entity, BiologyResult) {
        var vm = this;

        vm.biologyResult = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            BiologyResult.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
