(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('PatientSearch', PatientSearch);

    PatientSearch.$inject = ['$http', '$q', 'moment', 'DateUtils'];

    function PatientSearch($http, $q, moment, DateUtils) {
        const url = 'api/patient-search';

        return {
            importPatient: importPatient,
            search: search,
        };

        function importPatient(id) {
            return $http({
                method: 'POST',
                url,
                params: {id}
            });
        }

        function search(criteria) {
            criteria.familyName = criteria.familyName || null;
            criteria.givenName = criteria.givenName || null;
            if(criteria.birthDate)
                criteria.birthDate = DateUtils.convertLocalDateToServer(criteria.birthDate);
            let deferredAbort  = $q.defer();

            let request = $http({
                method: 'GET',
                url,
                params: criteria,
                timeout: deferredAbort.promise,
                transformResponse,
            });

            let promise = request
                .then(response => response.data)
                .catch(response => $q.reject(response));

            promise.abort = (reason) => {
                deferredAbort.resolve(reason);
                return true;
            };

            promise.finally(() => {
                promise.abort = angular.noop;
                deferredAbort = request = promise = null;
            });

            return promise;
        }

        function transformResponse (data) {
            if (data) {
                data = angular.fromJson(data);

                if(data.content) {
                    data.content.forEach((patient) => {
                        // compute age
                        if (patient.birthDate)
                            patient.age = moment().diff(patient.birthDate, 'years');

                        patient.birthDate = DateUtils.convertLocalDateFromServer(patient.birthDate);
                    });
                }
            }
            return data;
        }
    }
})();
