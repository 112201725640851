(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MedicationVariantDialogController', MedicationVariantDialogController);

    MedicationVariantDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MedicationVariant', 'Medication', 'HealthFacility', 'ReferenceData'];

    function MedicationVariantDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, MedicationVariant, Medication, HealthFacility, ReferenceData) {
        var vm = this;

        vm.medicationVariant = entity;
        vm.clear = clear;
        vm.save = save;

        vm.medications = Medication.query({
            sort: "name",
            size: 1000
        }); // TODO compositions...

        vm.pharmlabs = HealthFacility.query({
            view: 'summary',
            'type.equals': 'PHARM_LAB',
            size: 100,
            sort: 'name'
        });

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.medicationVariant.id !== null) {
                MedicationVariant.update(vm.medicationVariant, onSaveSuccess, onSaveError);
            } else {
                MedicationVariant.save(vm.medicationVariant, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('continuumplatformApp:medicationVariantUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
