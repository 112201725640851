(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('notification-settings', {
            parent: 'account',
            url: '/notification-settings',
            data: {
                pageTitle: 'global.menu.account.notification-settings'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/account/notification-settings/notification-settings.html',
                    controller: 'NotificationSettingsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settings');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
