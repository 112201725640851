(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientMediaController', PatientMediaController);

    PatientMediaController.$inject = ['$translate', '$window', '$scope', '$rootScope', '$stateParams', '$uibModal', 'entity'];

    function PatientMediaController($translate, $window, $scope, $rootScope, $stateParams, $uibModal, entity) {
        var vm = this;

        vm.patient = entity;

        $translate('continuumplatformApp.patient.media.title', {initials: entity.initials}).then(title => {
            $window.document.title = title;
        });

    }
})();
