(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('practitioner-planning', {
                parent: 'patients',
                url: '/practitioner-planning',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'continuumplatformApp.visit.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/visit/practitioner-planning/practitioner-planning.html',
                        controller: 'PractitionerPlanningController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('entity-audit');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
