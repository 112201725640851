(function () {
    'use strict';

    const telemonitoringRequestsBlock = {
        controller: TelemonitoringRequestsBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/telemonitoring-requests/telemonitoring-requests-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('telemonitoringRequestsBlock', telemonitoringRequestsBlock);

    TelemonitoringRequestsBlockController.$inject = ['$scope', '$state', 'DashboardService'];

    function TelemonitoringRequestsBlockController($scope, $state, DashboardService) {

        const vm = this;

        /**
         * Initialize the component.
         */
        vm.$onInit = function () {

            vm.datasource = DashboardService.createUiScrollDatasource(DashboardService.loadTelemonitoringRequests);
            // On récupère toutes les données à l'initialisation, donc on ne peut pas charger de données supplémentaires par la suite
            vm.datasource.shouldLoadMoreData = () => {
                if (vm.initialDataLoaded) {
                    return false;
                } else {
                    vm.initialDataLoaded = true;
                    return true;
                }
            };
        };

        vm.onClick = () => {
            $state.go('telemonitoring-requests');
        };

    }

})();
