(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('ChangePassword', ChangePassword);

    ChangePassword.$inject = ['$http'];

    function ChangePassword($http) {

        return {changePassword};

        function changePassword(currentPassword, newPassword) {
            return $http.post('api/account/change-password', {currentPassword, newPassword});
        }
    }

})();
