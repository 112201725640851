(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('SecurityConfig', SecurityConfigService);

    SecurityConfigService.$inject = ['$rootScope', '$http'];

    function SecurityConfigService($rootScope, $http) {

        return {
            get: get,
            set: set,
        };

        function get(key) {
            let url = 'api/security-config';
            if(key) {
                url += '/' + key;
            }
            return $http.get(url).then((response) => response.data);
        }

        function set(parameter, value) {
            return $http
                .put(`api/security-config/${parameter}`, value)
                .then((response) => response);
        }
    }

})();



