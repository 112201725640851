(function () {
    'use strict';

    const visitAohCrisisEvaluation = {
        bindings: {
            patient: '<',
            data: '=',
            questions: '<'
        },
        controller: VisitAohCrisisEvaluation,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-aoh-crisis-evaluation.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitAohCrisisEvaluation', visitAohCrisisEvaluation);

    VisitAohCrisisEvaluation.$inject = ['$rootScope', '$scope', 'Visit'];

    function VisitAohCrisisEvaluation($rootScope, $scope, Visit) {

        const vm = this;

        vm.maxEndDate = moment().format('YYYY-MM-DD');
        vm.wizardStep = 1;
        vm.selectedDate = {};
        vm.loading = true;

        vm.onClickAddCrise = onClickAddCrise;
        vm.onClickDeleteCrise = onClickDeleteCrise;
        vm.onChangeCriseState = onChangeCriseState;
        vm.onChangeCriseDates = onChangeCriseDates;
        vm.onChangeCriseAbsenteismeDates = onChangeCriseAbsenteismeDates;
        vm.onClickPrevious = onClickPrevious;
        vm.onClickNext = onClickNext;
        vm.finish = finish;

        /**
         * Initialisation du composant
         */
        vm.$onInit = function () {
            vm.step = 'AOH_CRISIS_EVALUATION';
            vm.isSaving = false;


            loadCrisisEvaluationHistory().then((previousCriseEvaluationInProgress) => {
                if (previousCriseEvaluationInProgress) {
                    vm.wizardStep = 0;
                }

                if (!vm.data) {
                    vm.data = {
                        crisis: [],
                        previousCriseEvaluationInProgress: previousCriseEvaluationInProgress
                    };
                }

                // conversion des dates
                [
                    ...(vm.data.previousCriseEvaluationInProgress ? [vm.data.previousCriseEvaluationInProgress] : []),
                    ...vm.data.crisis
                ].forEach((c) => {
                    c.Q02_2 = c.Q02_2 ? new Date(c.Q02_2) : c.Q02_2;
                    c.Q02_3 = c.Q02_3 ? new Date(c.Q02_3) : c.Q02_3;
                    c.Q08_2 = c.Q08_2 ? new Date(c.Q08_2) : c.Q08_2;
                    c.Q08_3 = c.Q08_3 ? new Date(c.Q08_3) : c.Q08_3;
                });

                vm.loading = false;
            });

            vm.unWatchQ01Change = $scope.$watch('vm.data.Q01', onQ01Change);
        };

        /**
         * Destruction du composant.
         */
        vm.$onDestroy = () => {
            vm.unWatchQ01Change();
        };

        function loadCrisisEvaluationHistory() {
            return Visit.query({
                "patientId.equals": vm.patient.id,
                "sort": "date,desc",
                "status.equals": "DONE",
                "steps.contains": "AOH_CRISIS_EVALUATION",
                "size": 1000
            }).$promise.then(function (visits) {
                vm.visitsWithCrisisEvaluation = visits;

                vm.firstEvaluation = !vm.visitsWithCrisisEvaluation.length;

                if (!vm.firstEvaluation) {
                    vm.lastCrisisEvaluation = vm.visitsWithCrisisEvaluation[0].data.details.AOH_CRISIS_EVALUATION;

                    [
                        ...(vm.lastCrisisEvaluation.previousCriseEvaluationInProgress ? [vm.lastCrisisEvaluation.previousCriseEvaluationInProgress] : []),
                        ...(vm.lastCrisisEvaluation.crisis || [])
                    ].forEach((c) => {

                        if (c.Q02_1 === 'En cours') {
                            vm.lastCriseEvaluationInProgress = Object.assign({}, c);

                            vm.lastCriseEvaluationInProgress.Q02_1 = null;

                            vm.disableTreatmentForLastCriseEvaluationInProgress = !!vm.lastCriseEvaluationInProgress.Q06;

                            vm.disableAbsenteeismStartDateForLastCriseEvaluationInProgress = !!vm.lastCriseEvaluationInProgress.Q08_2;

                        }

                    });

                }
                return vm.lastCriseEvaluationInProgress;
            });
        }

        /**
         * Éxecutée lorsque la valeur de la réponse à la première question est modifiée.
         * @param newVal nouvelle réponse
         * @param oldVal ancienne réponse
         */
        function onQ01Change(newVal, oldVal) {
            if (newVal !== oldVal && newVal === 'Y') {
                onClickAddCrise();

            } else if (newVal === 'N') {
                vm.data.crisis = [];
            }
        }

        function onClickAddCrise() {
            const newCrise = {
                maxStartDate: moment().format('YYYY-MM-DD'),
                minEndDate: undefined,
                maxAbsenteismeStartDate: moment().format('YYYY-MM-DD'),
                minAbsenteismeEndDate: undefined,
            };

            vm.data.crisis.push(newCrise);
        }

        function onClickDeleteCrise(index, form) {
            vm.data.crisis.splice(index, 1);

            if (vm.data.crisis.length) {
                onChangeCriseState(vm.data.crisis[0], form);
            }
        }


        /**
         * Éxecutée lorsque l'état d'une crise est modifié.
         * @param crise la crise dont l'état à été modifié.
         * @param form le formulaire de saisi.
         */
        function onChangeCriseState(crise, form) {
            if (crise.Q02_1 === 'En cours') {
                crise.Q02_3 = null;
            }

            if (crise.Q08_1 === 'En cours') {
                crise.Q08_3 = null;
            }

            for (let i = 0; i < vm.data.crisis.length; i++) {
                if (form[`Q02_1_${i}`]) {
                    form[`Q02_1_${i}`].$setValidity('alreadyInProgress', true);
                }
                if (form[`Q08_1_${i}`]) {
                    form[`Q08_1_${i}`].$setValidity('alreadyInProgress', true);
                }
            }

            // on valide qu'on à une seul crise en cours
            for (let i = 0; i < vm.data.crisis.length; i++) {
                for (let j = i + 1; j < vm.data.crisis.length; j++) {
                    const c1 = vm.data.crisis[i];
                    const c2 = vm.data.crisis[j];

                    if (c1.Q02_1 === 'En cours' && c2.Q02_1 === 'En cours') {
                        form['Q02_1_' + i].$setValidity('alreadyInProgress', false);
                        form['Q02_1_' + j].$setValidity('alreadyInProgress', false);
                    }

                    if (c1.Q08_1 === 'En cours' && c2.Q08_1 === 'En cours') {
                        form['Q08_1_' + i].$setValidity('alreadyInProgress', false);
                        form['Q08_1_' + j].$setValidity('alreadyInProgress', false);
                    }
                }
            }
            // on revalide les dates des crises et d'absentéisme pour vérifier s'il n'y a pas de chevauchement
            onChangeCriseDates(crise, form);
            onChangeCriseAbsenteismeDates(crise, form);
        }

        /**
         * Éxecutée lorsque les dates d'une crise sont modifiées.
         * @param crise la crise qui a été modifiée
         * @param form le formulaire de saisi.
         */
        function onChangeCriseDates(crise, form) {
            // si crise précédent en cours lors du démarrage de la visite on se sert de la date de la fin de cette crise comme date min
            if (vm.data.previousCriseEvaluationInProgress && vm.data.previousCriseEvaluationInProgress.Q02_3) {

                crise.minStartDate = moment(vm.data.previousCriseEvaluationInProgress.Q02_3).add(1, 'day').format('YYYY-MM-DD');

            // sinon on utilise la date de la dernière visite ou les crise on été évalué
            } else if (vm.visitsWithCrisisEvaluation && vm.visitsWithCrisisEvaluation[0]) {

                crise.minStartDate = moment(vm.visitsWithCrisisEvaluation[0].date).add(1, 'day').format('YYYY-MM-DD');

            } else {

                crise.minStartDate = undefined;
            }

            // on détermine les dates min et max pour la crise
            if (crise.Q02_3 && crise.Q02_1 === 'Terminé') {
                crise.maxStartDate = moment(crise.Q02_3).format('YYYY-MM-DD');
            } else {
                crise.maxStartDate = moment().format('YYYY-MM-DD');
            }

            if (crise.Q02_2) {
                crise.minEndDate = moment(crise.Q02_2).format('YYYY-MM-DD');
            } else {
                crise.minEndDate = undefined;
            }

            // on valide les dates de début et de fin de crise pour éviter les chevauchements
            validateDate(form, 'Q02_1', 'Q02_2', 'Q02_3');
        }

        /**
         * Permet d'effectuer la validation des dates pour l'ensemble des crises pour éviter les chevauchements
         * @param form le formulaire de saisi
         * @param statusField le statut de la période ('En cours' ou 'Terminé')
         * @param starDateFieldName le nom du champs contenant la date de départ de la période
         * @param endDateFieldName le nom du champs contenant la date de fin de la période
         */
        function validateDate(form, statusField, starDateFieldName, endDateFieldName) {

            // on réinitialise la validation
            for (let i = 0; i < vm.data.crisis.length; i++) {
                if (form[`${starDateFieldName}_${i}`]) {
                    form[`${starDateFieldName}_${i}`].$setValidity('dateOverlaps', true);
                }
                if (form[`${endDateFieldName}_${i}`]) {
                    form[`${endDateFieldName}_${i}`].$setValidity('dateOverlaps', true);
                }
            }

            // génération du calendrier des dates de crises pour pouvoir effectuer la validation des dates
            const selectedDateCalendar = generateSelectedDateCalendar(
                [...vm.data.crisis, ...(vm.data.previousCriseEvaluationInProgress ? [vm.data.previousCriseEvaluationInProgress] : []) ],
                statusField,
                starDateFieldName,
                endDateFieldName
            );

            // on revalide les dates des crises et d'absentéisme pour vérifier s'il n'y a pas de chevauchement
            for (let i = 0; i < vm.data.crisis.length; i++) {
                const c = vm.data.crisis[i];

                const startDate = c[starDateFieldName] ? moment(c[starDateFieldName]).format('YYYY-MM-DD') : null;
                const endDate = c[endDateFieldName] ? moment(c[endDateFieldName]).format('YYYY-MM-DD') : null;

                if (startDate && selectedDateCalendar.get(startDate) && selectedDateCalendar.get(startDate).length > 1) {
                    form[`${starDateFieldName}_${i}`].$setValidity('dateOverlaps', false);
                }

                if (endDate && selectedDateCalendar.get(endDate) && selectedDateCalendar.get(endDate).length > 1) {
                    form[`${endDateFieldName}_${i}`].$setValidity('dateOverlaps', false);
                }
            }
        }

        function generateSelectedDateCalendar(crisisList, statusField, starDateFieldName, endDateFieldName) {
            const selectedDateCalendar = new Map();

            for (let i = 0; i < crisisList.length; i++) {
                const c = crisisList[i];

                let startDate = null;
                let endDate = null;
                let currentDate = null;

                if (c[statusField] === 'Terminé') {
                    startDate = moment(c[starDateFieldName]);
                    endDate = c[endDateFieldName] ? moment(c[endDateFieldName]) : moment(c[starDateFieldName]);

                } else if (c[statusField] === 'En cours') {
                    startDate = moment(c[starDateFieldName]);
                    endDate = moment();
                }

                // si la date est trop ancienne on ne fait pas la validation pour des raisons de performance
                if (startDate && startDate.isBefore(moment().subtract(1, 'year'))) {
                    continue;
                }

                if (c[statusField] && startDate && endDate) {
                    currentDate = moment(startDate);

                    do {
                        if (!selectedDateCalendar.get(currentDate.format('YYYY-MM-DD'))) {
                            selectedDateCalendar.set(currentDate.format('YYYY-MM-DD'), [c]);
                        } else {
                            selectedDateCalendar.get(currentDate.format('YYYY-MM-DD')).push(c);
                        }
                        currentDate.add(1, 'day');

                    } while(!currentDate.isAfter(endDate, 'day'));
                }
            }

            return selectedDateCalendar;
        }

        /**
         * Éxecutée lorsque les dates d'absentéisme d'une crise sont modifiées.
         * @param crise la crise qui a été modifiée
         * @param form le formulaire de saisi.
         */
        function onChangeCriseAbsenteismeDates(crise, form) {
            if (crise.Q08_3 && crise.Q08_1 === 'Terminé') {
                crise.maxAbsenteismeStartDate = moment(crise.Q08_3).format('YYYY-MM-DD');
            } else {
                crise.maxAbsenteismeStartDate = moment().format('YYYY-MM-DD');
            }

            if (crise.Q08_2) {
                crise.minAbsenteismeEndDate = moment(crise.Q08_2).format('YYYY-MM-DD');
            } else if (crise.Q02_2) {
                crise.minAbsenteismeEndDate = moment(crise.Q02_2).format('YYYY-MM-DD');
            } else {
                crise.minAbsenteismeEndDate = undefined;
            }

            // on valide les dates de début et de fin d'absentéisme pour éviter les chevauchements
            validateDate(form, 'Q08_1','Q08_2', 'Q08_3');
        }

        function onClickPrevious() {
            this.wizardStep--;
        }

        function onClickNext(form) {
            this.wizardStep++;

            if (vm.data.crisis.length) {
                onChangeCriseState(vm.data.crisis[0], form);
            }

        }

        function finish() {
            if (vm.data.previousCriseEvaluationInProgress && vm.data.previousCriseEvaluationInProgress.Q02_1 === 'En cours') {
                vm.data.Q01 = null;
                vm.data.crisis = [];
            }

            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        }
    }

})();
