(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MyVisitDialogController', MyVisitDialogController);

    MyVisitDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Visit'];

    function MyVisitDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Visit) {
        var vm = this;

        vm.visit = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        // default date and time
        vm.visit.date = new Date();
        vm.visit.date.setSeconds(0);
        vm.visit.date.setMilliseconds(0);

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            Visit.finish({id: vm.visit.id}, vm.visit, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('continuumplatformApp:visitUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
