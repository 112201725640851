(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('SideEffectReportView', SideEffectReportView);

    SideEffectReportView.$inject = ['$resource'];

    function SideEffectReportView($resource) {
        var resourceUrl = 'api/side-effect-report/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'queryDistinctTherapies': {url: 'api/side-effect-report/distinct-therapies', method: 'GET', isArray: true},
            'queryDistinctDiseases': {url: 'api/side-effect-report/distinct-diseases', method: 'GET', isArray: true},
            'queryDistinctMedications': {url: 'api/side-effect-report/distinct-medications', method: 'GET', isArray: true},
            'queryDistinctFacilities': {url: 'api/side-effect-report/distinct-facilities', method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            }
        });
    }
})();
