(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTreatmentPlanDialogController', PatientTreatmentPlanDialogController);

    PatientTreatmentPlanDialogController.$inject = ['$uibModalInstance', '_', 'medication', 'medicationPlan', 'readonly', 'Principal'];

    function PatientTreatmentPlanDialogController($uibModalInstance, _, medication, medicationPlan, readonly, Principal) {
        const vm = this;

        vm.medication = medication;
        vm.plan = medicationPlan;

        vm.readonly = readonly;

        vm.onChangeSchedule = onChangeSchedule;
        vm.onClickCancel = onClickCancel;
        vm.close = close;
        vm.save = save;

        /**
         * Initialisation du composant.
         */
        vm.$onInit = function() {
            if (vm.plan) {
                vm.plan.startDate = vm.plan.startDate ? new Date(vm.plan.startDate) : null;
                vm.plan.endDate = vm.plan.endDate ? new Date(vm.plan.endDate) : null;

                vm.copyPlan = _.cloneDeep(vm.plan);
            }

            getAccount();
        };

        function getAccount() {
            return Principal.identity().then(function (account) {
                vm.account = account;
                vm.isPharmacist = vm.account.practitioner && vm.account.practitioner.job === 'J21';
            });
        }

        function onChangeSchedule() {

            switch (vm.plan.schedule) {
            case 'ifNeeded' :
                vm.plan.comment = null;
                vm.plan.nbDayTaken = null;
                vm.plan.nbDayTreatmentBreak = null;
                vm.plan.hours = {};
                break;
            case 'continuous':
                vm.plan.dosage = null;
                vm.plan.nbDayTaken = null;
                vm.plan.nbDayTreatmentBreak = null;
                break;
            case 'discontinuous':
                vm.plan.dosage = null;
                break;
            }
        }

        function close() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            $uibModalInstance.close(vm.copyPlan);
        }

        function onClickCancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
