(function () {
    'use strict';

    angular
        .module('continuum-alert')
        .controller('AlertController', AlertController);

    AlertController.$inject = ['$scope', '$state', '$uibModal', '$filter', '$log', 'Principal', 'Visit', 'paginationConstants', 'page', 'scoreGreaterThanOrEqualTo', 'history', 'search', 'facilityDoctorId', 'previousState', 'patient'];

    function AlertController($scope, $state, $uibModal, $filter, $log, Principal, Visit, paginationConstants, page, scoreGreaterThanOrEqualTo, history, search, facilityDoctorId, previousState, patient) {

        const vm = this;

        vm.patient = patient;

        vm.previousState = previousState;

        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.page = page;
        vm.scoreGreaterThanOrEqualTo = scoreGreaterThanOrEqualTo || 0;
        vm.history = history;
        vm.search = search;
        vm.facilityDoctorId = facilityDoctorId;

        vm.loadDistinctFacilityDoctors = loadDistinctFacilityDoctors;
        vm.transition = transition;
        vm.openDetail = openDetail;

        vm.$onInit = () => {
            Principal.identity().then(account => {
                if (account && account.practitioner && account.practitioner.job === 'J10' && account.practitioner.healthFacilityType === 'HOSPITAL') {
                    if (angular.isUndefined(vm.facilityDoctorId)) {
                        vm.facilityDoctorId = account.practitioner.id;
                    }
                    vm.forceFacilityDoctor = account.practitioner;
                }

                if (angular.isUndefined(vm.facilityDoctorId)) {
                    vm.facilityDoctorId = null;
                }

                loadDistinctFacilityDoctors();
            });
        };

        function loadDistinctFacilityDoctors() {
            Visit.queryDistinctFacilityDoctors()
                .$promise
                .then(result => {
                    vm.facilityDoctors = result.map(d => ({
                        id: d.id,
                        name: $filter('translatePractitionerName')(d, 'TITLE_FULLNAME')
                    }));
                    if (!result.length && vm.forceFacilityDoctor) {
                        vm.facilityDoctors.push({
                            id: vm.forceFacilityDoctor.id,
                            name: $filter('translatePractitionerName')(vm.forceFacilityDoctor, 'TITLE_FULLNAME')
                        });
                    }
                });
        }

        function transition(params) {
            $state.go($state.$current.name, params, {
                inherit: true,
                reload: false
            });
        }

        function openDetail(id) {
            $state.go('visit-detail', {id});
        }

    }
})();
