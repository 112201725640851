(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PharmAnalysisReadController', PharmAnalysisReadController);

    PharmAnalysisReadController.$inject = ['$state', 'pharmAnalysis', '$transition$', 'Patient'];

    function PharmAnalysisReadController($state, pharmAnalysis, $transition$, Patient) {
        const vm = this;

        vm.pharmAnalysis = pharmAnalysis;
        vm.patient = Patient.get({id: pharmAnalysis.patientId});

        vm.previousState = {
            name: $transition$.from().name || 'patient-detail.pharmaceutics',
            params: $transition$.from().name ? $transition$.params('from') : {id: vm.pharmAnalysis.patientId}
        };

        vm.$onInit = function () {
        };

        vm.close = () => {
            $state.go(vm.previousState.name, vm.previousState.params, {reload: false});
        };

    }
})();
