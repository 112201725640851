(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('patientTreatmentPlanHours', patientTreatmentPlanHours);

    function patientTreatmentPlanHours() {

        function link(scope, element, attrs, formCtrl) {

            scope.form = formCtrl;

            scope.$watch('[medicationPlan.hours, medicationPlan.schedule]', () => {
                scope.formValide = true;

                if (['continuous', 'discontinuous'].includes(scope.medicationPlan.schedule)) {

                    if (scope.medicationPlan.hours) {
                        const pillsTabByHours = Object.values(scope.medicationPlan.hours).map(mapFloat);

                        // le nombre de comprimés à prendre doit être un multiple de 0,25
                        pillsTabByHours.forEach((pills) => {
                            scope.formValide = scope.formValide && (pills % .25 === 0);
                        });

                        // La somme du nombre de comprimés à prendre doit être supérieur à 0.
                        const sum = pillsTabByHours.reduce((a, b) => a + b, 0);
                        scope.formValide = scope.formValide && (sum > 0);

                    } else {
                        scope.formValide = false;
                    }

                } else {
                    scope.formValide = true;
                }
                scope.form.$setValidity('hours', scope.formValide, scope.form);
            }, true);

            scope.mapFloat = mapFloat;
        }

        function mapFloat(i) {
            if (typeof i === 'string') {
                return i ? parseFloat(i.replace(',', '.')) : 0;
            } else {
                return i ? parseFloat(i) : 0;
            }
        }

        return {
            require: '^form',
            restrict: 'E',
            scope: {
                medicationPlan: '=',
                readonly: '='
            },
            templateUrl: 'app/activity/patient-treatment-plan/patient-treatment-plan-hours.html',
            link: link
        };

    }

})();
