(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$rootScope', '$scope', '$interval', '$state', '$filter', '$uibModal', 'Auth', 'Principal', 'Practitioner', 'ProfileService', 'Inbox', 'MessageTo', 'webNotification', '$log', 'ToasterService', 'A2HS'];

    function NavbarController($rootScope, $scope, $interval, $state, $filter, $uibModal, Auth, Principal, Practitioner, ProfileService, Inbox, MessageTo, webNotification, $log, ToasterService, A2HS) {
        const vm = this;

        $rootScope.inbox = null;
        $rootScope.unreadMessages = 0;

        vm.isMessagingInitialized = false;
        vm.isLoadingMessages = false;
        vm.$state = $state;
        vm.lastMessageId = 0;

        vm.login = login;
        vm.logout = logout;
        vm.read = read;
        vm.reportMeAway = reportMeAway;
        vm.reportMeNotAway = reportMeNotAway;

        // A2HS
        vm.showInstallPromotion = A2HS.isShowInstallPromotion;
        vm.install = A2HS.install;

        vm.$onInit = function () {
            getAccount();
            subscribeAccountSelfUpdate();
            subscribeLogout();
            getProfileInfo();
        };

        vm.$onDestroy = function() {
            vm.unsubscribeOnLogout();
            vm.unsubscribeAccountSelfUpdate();
        };

        function getAccount() {
            Principal.identity().then(loadAccount);
        }

        function loadAccount(account) {
            vm.account = account;
            vm.isPractitioner = !!account.practitioner;

            if (vm.isPractitioner) {
                vm.practitioner = account.practitioner;
                vm.isHospitalUser = vm.practitioner.healthFacilityType === 'HOSPITAL';
                vm.isCoordination = vm.practitioner.healthFacilityType === 'COORDINATION';
                vm.isDoctor = vm.practitioner.job === 'J10';
                // seuls le personnel hospitalier, de structure de coordination et les médecins peuvent se signaler absent
                vm.canBeAway = vm.isHospitalUser || vm.isCoordination || vm.isDoctor;
                vm.isPractitionerAway = isPractitionerAway(vm.practitioner);
            }
            initMessages(account);
        }

        function subscribeAccountSelfUpdate() {
            vm.unsubscribeAccountSelfUpdate = $rootScope.$on('continuumplatformApp:accountSelfUpdate', (event, account) => {
                loadAccount(account);
            });
        }

        function subscribeLogout() {
            vm.unsubscribeOnLogout = $rootScope.$on('logout', function (event) {
                vm.account = null;
            });
        }

        function getProfileInfo() {
            ProfileService.getProfileInfo().then(function (response) {
                vm.inProduction = response.inProduction;
                vm.swaggerEnabled = response.swaggerEnabled;
            });
        }

        function login() {
            $state.go('login');
        }

        function logout() {
            Auth.logout();
        }

        function read(messageTo) {
            $state.go('messaging', {message: messageTo.id});
        }

        /**
         * Init message loading
         *
         * @param account
         */
        function initMessages(account) {
            if (!account || vm.isMessagingInitialized) {
                return;
            }

            const isPractitionerAllowedToReadPatientHealthData = account.practitioner && account.authorities.includes('PATIENT_HEALTH_READ');

            if (!isPractitionerAllowedToReadPatientHealthData && !account.patient) {
                return;
            }

            vm.userId = account.id;

            loadMessages();

            var stopLoad = $interval(loadMessages, 30000);
            $scope.$on('$destroy', function (event) {

                $rootScope.inbox = null;
                $rootScope.unreadMessages = 0;

                $interval.cancel(stopLoad);
            });
            $rootScope.$on('logout', function (event) {

                $rootScope.inbox = null;
                $rootScope.unreadMessages = 0;

                $interval.cancel(stopLoad);
            });

            vm.isMessagingInitialized = true;
        }

        /**
         * Load messages
         */
        $rootScope.loadMessages = loadMessages;

        function loadMessages() {
            if (vm.isLoadingMessages)
                return;

            Inbox.countUnread().then(function (response) {

                // TODO check response 1

                var unreadMessages = response.data;

                if (unreadMessages > $rootScope.unreadMessages) {

                    $log.debug("Unread messages", unreadMessages);

                    // show notification TODO i18n
                    try {
                        webNotification.showNotification('Messagerie Continuum+', {
                            body: unreadMessages > 1
                                ? 'Vous avez ' + unreadMessages + ' nouveaux messages'
                                : 'Vous avez un nouveau message',
                            icon: '/content/images/icon180.png',
                            onClick: function onNotificationClicked(e) {
                                $state.go('messaging');
                            },
                            autoClose: 4000 //auto close the notification after 4 seconds (you can manually close it via hide function)
                        }, function onShow(error, hide) {
                            if (error) {
                                $log.error('Unable to show notification', error);
                            }
                        });
                    } catch (e) {
                        $log.error('Unable to use notifications', e);
                    }
                }

                $rootScope.unreadMessages = unreadMessages;

            }).catch(function (response) {
                $log.debug("Unable to count unread messages", response);
            });
        }

        function reportMeAway() {
            $uibModal.open({
                templateUrl: 'app/account/absence/absence-modal.html',
                controller: 'AbsenceModalController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    practitioner: () => vm.practitioner
                }
            }).result.then((result) => {

                Practitioner.absenceUpdate(result)
                    .$promise
                    .then(() => {
                        vm.practitioner.leaveFrom = result.leaveFrom;
                        vm.practitioner.leaveTo = result.leaveTo;
                        vm.practitioner.leaveMessage = result.leaveMessage;
                        vm.practitioner.leaveReplacedById = result.leaveReplacedById;
                        vm.isPractitionerAway = isPractitionerAway(vm.practitioner);
                        Principal.identity(true).then(account => {
                            $scope.$emit('continuumplatformApp:accountSelfUpdate', account);
                        });
                        ToasterService.success('Absence enregistrée');
                    });
            }, () => {
                // nothing
            });
        }

        function reportMeNotAway() {
            Practitioner.absenceUpdate({
                leaveFrom: null,
                leaveTo: null,
                leaveMessage: vm.practitioner.leaveMessage,
                leaveReplacedById: null
            }).$promise.then(() => {
                vm.practitioner.leaveFrom = null;
                vm.practitioner.leaveTo = null;
                vm.isPractitionerAway = isPractitionerAway(vm.practitioner);
                Principal.identity(true).then(account => {
                    $scope.$emit('continuumplatformApp:accountSelfUpdate', account);
                });
                ToasterService.success('Absence annulée');
            });
        }

        function isPractitionerAway(practitioner) {
            const today = moment().format('yyyy-MM-DD');
            return (!!practitioner.leaveFrom && practitioner.leaveTo >= today) || (!!practitioner.leaveFrom && !practitioner.leaveTo);
        }

    }
})();
