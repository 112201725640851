(function () {
    'use strict';

    var pharmaItwEnd = {
        bindings: {
            data: '=',
            questions: '<'
        },
        controller: PharmaItwEndController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/pharma-itw-end.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmaItwEnd', pharmaItwEnd);

    PharmaItwEndController.$inject = ['$rootScope'];

    function PharmaItwEndController($rootScope) {

        var vm = this;

        vm.step = 'PHARMA_ITW_END';

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

    }

})();
