(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MeasureController', MeasureController);

    MeasureController.$inject = ['$state', '$uibModal', '$scope', '$rootScope', 'Measure', 'MeasureType', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'previousState', 'patient'];

    function MeasureController($state, $uibModal, $scope, $rootScope, Measure, MeasureType, ParseLinks, AlertService, paginationConstants, pagingParams, previousState, patient) {

        var vm = this;

        vm.patient = patient;

        vm.previousState = previousState;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.rules = MeasureType.getRules();

        vm.loadPage = loadPage;
        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.modifyMeasure = modifyMeasure;
        vm.deleteMeasure = deleteMeasure;

        var unsubscribe = $rootScope.$on('continuumplatformApp:measureUpdate', loadAll);
        $scope.$on('$destroy', unsubscribe);

        loadAll();

        function loadAll() {
            Measure.query({
                "patientId.equals": patient.id,
                "type.notIn": ['PAIN'],
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.measures = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                id: vm.patient.id,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function modifyMeasure(id) {
            $uibModal.open({
                templateUrl: 'app/activity/measure/measure-dialog.html',
                controller: 'MeasureDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Measure', function (Measure) {
                        return Measure.get({id: id}).$promise;
                    }]
                }
            });
        }

        function deleteMeasure(id) {
            $uibModal.open({
                templateUrl: 'app/activity/measure/measure-delete-dialog.html',
                controller: 'MeasureDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: ['Measure', function (Measure) {
                        return Measure.get({id: id}).$promise;
                    }]
                }
            });
        }
    }
})();
