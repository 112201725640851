(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('MessageTo', MessageTo);

    MessageTo.$inject = ['$resource', 'DateUtils'];

    function MessageTo($resource, DateUtils) {
        var resourceUrl = 'api/message-tos/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }
})();
