(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HelpController', HelpController);

    HelpController.$inject = ['$scope', 'Principal', 'termsConstants', 'HealthFacility', 'PatientTherapy'];

    function HelpController($scope, Principal, termsConstants, HealthFacility, PatientTherapy) {
        const vm = this;

        vm.termsUrl = '';

        vm.account = null;

        vm.$onInit = () => {
            getAccount();
        };

        function getAccount() {
            Principal.identity().then(function (account) {
                if (account) {
                    vm.isAuthenticated = true;
                    vm.account = account;

                    vm.isPractitioner = account && account.practitioner;

                    // on détermine les CGU à afficher en fonction de l'utilisateur
                    if (account && account.practitioner) {
                        vm.termsUrl = termsConstants.attachmentUrlPractitioner;
                    } else if (account && account.patient) {
                        vm.termsUrl = termsConstants.attachmentUrlPatient;
                    }

                    if (vm.account.patient) {
                        PatientTherapy.query({"patientId.equals": vm.account.patient.id, 'startDate.specified': true, "sort": "startDate,desc"}, function (patientTherapies) {
                            vm.patientTherapies = patientTherapies;

                            if (patientTherapies && patientTherapies[0]) {
                                vm.article51_19GRE08 = patientTherapies[0].article51 === '19GRE08';
                                vm.commercial = patientTherapies[0].commercial;
                                vm.akoadom = patientTherapies[0].programCode === 'akoadom';
                                vm.akoapharma = patientTherapies[0].programCode === 'akoapharma';
                                vm.akoapro = patientTherapies[0].programCode === 'akoapro';
                                vm.picto = patientTherapies[0].programCode === 'picto';
                                vm.esuivi = patientTherapies[0].programCode === 'e-suivi';
                                vm.oncolink = patientTherapies[0].programCode === 'oncolink';
                            }
                        });

                    } else if (vm.account.practitioner) {
                        if (['HOSPITAL', 'PHARMACY'].includes(vm.account.practitioner.healthFacilityType)) {
                            HealthFacility.get({
                                id: vm.account.practitioner.healthFacilityId,
                                view: 'summary'
                            }, function (healthFacility) {
                                vm.healthFacility = healthFacility;
                                vm.article51_19GRE08 = healthFacility.article51.includes('19GRE08');
                                vm.picto = vm.article51_19GRE08;
                                vm.oncolink = healthFacility.article51.includes('19N20');
                                if (healthFacility.type === 'HOSPITAL') {
                                    vm.commercial = healthFacility.commercial;
                                } else if (healthFacility.type === 'PHARMACY') {
                                    vm.commercial = !vm.article51_19GRE08;
                                }
                                vm.akoadom = vm.commercial || vm.article51_19GRE08;
                                vm.akoapharma = vm.commercial;
                                vm.akoapro = vm.commercial;
                            });
                        } else {
                            vm.article51_19GRE08 = true;
                            vm.commercial = true;
                            vm.akoadom = true;
                            vm.akoapharma = true;
                            vm.akoapro = true;
                            vm.picto = true;
                            vm.oncolink = true;
                        }
                    }
                } else {
                    vm.isAuthenticated = false;
                }
            });
        }

    }
})();
