(function () {
    'use strict';

    const patientHeaderPanel = {
        bindings: {
            patient: '=',
            readonly: '=',
            hideCoordinationButton: '<'
        },
        controller: PatientHeaderPanelController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient/patient-header-panel.html'
    };

    PatientHeaderPanelController.$inject = ['$scope', '$log', '$state', '$rootScope', '$uibModal', 'Patient', 'Principal', 'PatientTherapy', 'Coordination', 'DownloadFile', 'CareTeam'];

    angular
        .module('continuumplatformApp')
        .component('patientHeaderPanel', patientHeaderPanel);

    function PatientHeaderPanelController($scope, $log, $state, $rootScope, $uibModal, Patient, Principal, PatientTherapy, Coordination, DownloadFile, CareTeam) {
        const vm = this;

        vm.collapseDetails = true;
        vm.showCoordinationButton = null;
        vm.showNewRequestButton = null;
        vm.exportInProgress = false;
        vm.exportPatientDetailPdf = exportPatientDetailPdf;
        vm.editIdentity = editIdentity;
        vm.detailIdentity = detailIdentity;
        vm.editAddress = editAddress;
        vm.editUser = editUser;
        vm.deletePatient = deletePatient;
        vm.onClickToggleDetails = onClickToggleDetails;
        vm.managePatientTherapy = managePatientTherapy;
        vm.newRequest = newRequest;
        vm.closeAwayMessage = closeAwayMessage;

        vm.$onInit = function () {
            Principal.identity().then(account => {
                vm.account = account;
                vm.patientAccessManagement = account.practitioner && ['COORDINATION', 'HOSPITAL'].includes(account.practitioner.healthFacilityType);
            });

            subscribePatient();
        };

        vm.$onDestroy = function() {
            if (vm.subcriptionPatientChange) {
                vm.subcriptionPatientChange();
            }
        };

        function subscribePatient() {
            vm.subcriptionPatientChange = $scope.$watch("vm.patient.id", function (newVal, oldVal) {
                if (newVal) {
                    if(!vm.readonly) {
                        Promise.all([loadPatientTherapy(), Principal.hasAuthority('PATIENT_THERAPY_REQUEST'), hasActivePatientTherapies()])
                            .then(([patientTherapy, hasPatientTherapyRequestAuthority, hasActivePatientTherapies] )=> {
                                guessShowCoordinationButton(patientTherapy, hasActivePatientTherapies);
                                guessShowNewRequestButton(hasPatientTherapyRequestAuthority, hasActivePatientTherapies);
                            })
                            .catch($log.error);
                    }

                    loadCareTeam();
                }
            });
        }

        function loadPatientTherapy() {
            if (!vm.patient.patientTherapyId) {
                return Patient.get({'id': vm.patient.id}).$promise
                    .then(patient => {
                        if(!patient.patientTherapyId) {
                            return null;
                        }
                        return loadPatientTherapyById(patient.patientTherapyId);
                    });
            }

            return loadPatientTherapyById(vm.patient.patientTherapyId);
        }

        function loadPatientTherapyById(id) {
            return PatientTherapy.get({'id': id}).$promise
                .then((patientTherapy) => vm.patientTherapy = patientTherapy);
        }

        function hasActivePatientTherapies() {
            if (!vm.patient) {
                return Promise.reject(new Error("Patient is not defined"));
            }

            return PatientTherapy.query({
                'patientId.equals': vm.patient.id,
                'status.in': ['S11_VALIDATED', 'S20_ACTIVE', 'S30_PAUSED']
            }).$promise.then(patientTherapies => patientTherapies.length > 0);
        }

        function guessShowCoordinationButton(patientTherapy, hasActivePatientTherapies) {
            vm.showCoordinationButton = !vm.readonly
                && !vm.hideCoordinationButton
                && hasActivePatientTherapies
                && patientTherapy
                && ['S20_ACTIVE', 'S30_PAUSED'].includes(patientTherapy.status)
                && patientTherapy.actions.length > 0;
        }

        function guessShowNewRequestButton(hasPatientTherapyRequestAuthority, hasActivePatientTherapies) {
            vm.showNewRequestButton = !vm.readonly
                && hasPatientTherapyRequestAuthority
                && !hasActivePatientTherapies
                && !vm.patient.deceased;
        }

        function editIdentity() {
            $uibModal.open({
                templateUrl: 'app/activity/patient/patient-edit-identity.html',
                controller: 'PatientEditIdentityController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: () => Patient.get({id: vm.patient.id}).$promise
                }
            }).result.then(() => {
                $state.reload();
            }, () => {
                // do nothing
            });
        }

        function detailIdentity() {
            $uibModal.open({
                templateUrl: 'app/activity/patient/patient-details-identity.html',
                controller: 'PatientDetailsIdentityController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: () => vm.patient
                }
            }).result.then(() => {
                // do nothing
            }, () => {
                // do nothing
            });
        }

        function editAddress() {
            $uibModal.open({
                templateUrl: 'app/activity/patient/patient-edit-address.html',
                controller: 'PatientEditAddressController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: () => Patient.get({id: vm.patient.id}).$promise
                }
            }).result.then(() => {
                $state.reload();
            }, () => {
                // do nothing
            });
        }

        function editUser() {
            $uibModal.open({
                templateUrl: 'app/activity/patient/patient-edit-user.html',
                controller: 'PatientEditUserController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: () => Patient.get({id: vm.patient.id}).$promise
                }
            }).result.then(() => {
                $state.reload();
            }, () => {
                // do nothing
            });
        }

        function deletePatient() {
            $uibModal.open({
                templateUrl: 'app/activity/patient/patient-delete-dialog.html',
                controller: 'PatientDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: () => Patient.get({id: vm.patient.id}).$promise
                }
            }).result.then(() => {
                $rootScope.warnBeforeUnload = false;
                $state.go('patient', null, {reload: true});
            }, () => {
                // do nothing
            });
        }

        function onClickToggleDetails() {
            vm.collapseDetails = !vm.collapseDetails;
        }

        function managePatientTherapy() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-management.html',
                controller: 'PatientTherapyManagementController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    action: () => null,
                    patientTherapy: () => PatientTherapy.get({id: vm.patient.patientTherapyId}).$promise
                }
            }).result.then(result => {
                if (result.openStartDialog) {
                    $uibModal.open({
                        templateUrl: 'app/activity/patient-therapy/patient-therapy-start.html',
                        controller: 'PatientTherapyStartController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PatientTherapy', function (PatientTherapy) {
                                return PatientTherapy.get({id: result.patientTherapyId}).$promise;
                            }],
                        }
                    }).result.then(function () {
                        $state.go('patient-therapy-detail', {id: result.patientTherapyId});
                    }, function () {
                        $state.go('patient-therapy-detail', {id: result.patientTherapyId});
                    });
                } else {
                    $state.reload();
                }
            }, () => {
                // do nothing
            });
        }

        function newRequest() {
            if(vm.patientTherapy) {
                Coordination.getNextContractLines(vm.patientTherapy.id)
                    .then(nextContractLines => {
                        Coordination.newRequest(vm.patient.id, vm.patientTherapy, nextContractLines);
                    });
            } else {
                Coordination.newRequest(vm.patient.id);
            }
        }

        function exportPatientDetailPdf() {
            vm.exportInProgress = true;
            Patient.exportFollowUpPdf({
                id: vm.patient.id
            }).$promise
                .then(DownloadFile.downloadFile)
                .finally(function () {
                    vm.exportInProgress = !vm.exportInProgress;
                });
        }

        function loadCareTeam() {
            return CareTeam.getMembers(vm.patient.id).then(response => {
                const careTeam = response.data;
                vm.careTeamMemberAway = CareTeam.getAwayMembers(careTeam);
            });
        }

        function closeAwayMessage() {
            vm.careTeamMemberAway = [];
        }

    }

})();
