(function () {
    'use strict';

    const selectBodyPart = {
        bindings: {
            data: '<',
            readonly:  '<',
            onDataChange: '<'
        },
        controller: selectBodyPartController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/steps/pain/select-body-part.html'
    };

    const COLOR = {
        SELECTED: '#F05252',
        DEFAULT: '#FACB91'
    };

    angular
        .module('continuumplatformApp')
        .component('selectBodyPart', selectBodyPart);

    selectBodyPartController.$inject = ['$document'];

    function selectBodyPartController($document) {

        const vm = this;

        vm.selectedBodyPart = [];

        /**
         * Initialize the component.
         */
        vm.$onInit = function () {
            initImageBodyPart();
        };

        /**
         * Initialize the image body part by selected body part from data and adding listener the component is not readonly.
         */
        function initImageBodyPart() {
            if (!vm.readonly) {
                initImageAreaListener('#frontBodySvg');
                initImageAreaListener('#backBodySvg');
            }
            initSelectedBodyPartFromData(vm.data);
        }

        /**
         * Execute  when the user click on a body part.
         *
         * @param $event - the click event.
         * @param path - the area clicked.
         */
        function onClickAreaBody($event, path) {
            if ($event) {
                $event.preventDefault();
            }

            if (vm.selectedBodyPart.includes(path)) {
                vm.selectedBodyPart.splice(vm.selectedBodyPart.indexOf(path), 1);
                path.style.fill = COLOR.DEFAULT;
            } else {
                vm.selectedBodyPart.push(path);
                path.style.fill = COLOR.SELECTED;
            }

            if (vm.onDataChange) {
                // Pour conserver le fonctionnement actuel au niveau de l'historique de la douleur et du DM.
                const localisation = vm.selectedBodyPart.map((path) => {
                    return path.getAttribute('aria-description');
                });

                vm.onDataChange(localisation);
            }
        }

        /**
         * Initialize the listener on the image areas.
         *
         * @param svgSelector - the selector of the image.
         */
        function initImageAreaListener(svgSelector) {
            const pathElements = $document[0].querySelectorAll(`${svgSelector} #layer1 path:not(:first-child)`);

            pathElements.forEach(path => {
                path.addEventListener("click", function($event) {
                    onClickAreaBody($event, path);
                });
            });

            const groupeElements = $document[0].querySelectorAll(`${svgSelector} #layer2 text[aria-description]`);
            groupeElements.forEach(group => {
                group.addEventListener("click", function($event) {
                    const path = $document[0].querySelector(`path[aria-description="${group.getAttribute('aria-description')}"]`);
                    onClickAreaBody($event, path);
                });
            });

        }

        /**
         * Initialize the localisation area from data.
         */
        function initSelectedBodyPartFromData(data) {
            if (!data) {
                vm.selectedBodyPart = [];

            } else {
                data.split(', ').forEach((part) => {
                    const areaElement = $document[0].querySelector(`path[aria-description="${part}"]`);

                    onClickAreaBody(null, areaElement);
                });
            }
        }
    }

})();
