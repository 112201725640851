(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('ActivationController', ActivationController);

    ActivationController.$inject = ['$stateParams', 'Auth', '$window'];

    function ActivationController($stateParams, Auth, $window) {
        var vm = this;

        Auth.activateAccount({
            key: $stateParams.key
        }).then(function (user) {
            vm.error = null;
            vm.success = 'OK';

            if ($window.localStorage) {
                $window.localStorage.setItem('username', user.login);
            } else {
                // TODO redirect to login with param?
            }

        }).catch(function (error) {
            console.log("Error while activating account", error);
            vm.success = null;
            vm.error = 'ERROR';
        });
    }
})();
