(function () {
    'use strict';

    /** @constant {number} PAGE_SIZE Size of the page */
    const PAGE_SIZE = 10;

    const unbilledHomeVisitsBlock = {
        bindings: {},
        controller: UnbilledHomeVisitsBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/unbilled-home-visits/unbilled-home-visits-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('unbilledHomeVisitsBlock', unbilledHomeVisitsBlock);

    UnbilledHomeVisitsBlockController.$inject = ['DashboardService'];

    function UnbilledHomeVisitsBlockController(DashboardService) {

        const vm = this;

        vm.initialDataLoaded = false;

        vm.$onInit = function () {

            vm.datasource = DashboardService.createUiScrollDatasource(DashboardService.loadUnbilledHomeVisits, PAGE_SIZE);

            // On récupère toutes les données à l'initialisation, donc on ne peut pas charger de données supplémentaires par la suite
            vm.datasource.shouldLoadMoreData = () => {
                if (vm.initialDataLoaded) {
                    return false;
                } else {
                    vm.initialDataLoaded = true;
                    return true;
                }
            };
        };


    }

})();
