(function () {
    'use strict';

    const VisitOncolinkCoordination = {
        bindings: {
            data: '=',
            questions: '<',
            patient: '<',
        },
        controller: VisitOncolinkCoordinationController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-oncolink-coordination.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitOncolinkCoordination', VisitOncolinkCoordination);

    VisitOncolinkCoordinationController.$inject = ['$rootScope', 'Principal', 'PatientTherapy', 'Therapy', 'Visit', 'ContractLine', 'Program'];

    function VisitOncolinkCoordinationController($rootScope, Principal, PatientTherapy, Therapy, Visit, ContractLine, Program) {

        const vm = this;
        vm.today = new Date();

        vm.nextStep = nextStep;
        vm.previous = previous;

        vm.$onInit = function () {
            vm.step = 'ONCOLINK_COORDINATION';
            vm.navigationHistoric = ['MENU'];

            if (!vm.data) {
                vm.data =  {
                    nextFollowUpStep: null,
                    note: null,
                };
            } else {
                vm.data.stopDate = vm.data.stopDate ? moment(vm.data.stopDate).toDate(): null;
            }
            vm.data.date = new Date();

            loadCurrentPatientTherapy()
                .then((patientTherapy) => {
                    return Promise.all([
                        loadAccount(),
                        loadContractLine(patientTherapy.contractLineId),
                        loadTherapy(patientTherapy.therapyId),
                        loadProgram(patientTherapy.programId)
                    ]);
                }).then(([therapy, visits]) => {
                    // nothing
                });
        };

        function loadAccount() {
            return new Promise((resolve, reject) => {
                Principal.identity().then(function (account) {
                    vm.account = account;
                    if (account.practitioner) {
                        vm.isHospitalUser = account.practitioner.healthFacilityType === 'HOSPITAL';
                        vm.isDoctor = account.practitioner.job === 'J10';
                    }
                    resolve(account);
                });

            });
        }

        function loadCurrentPatientTherapy() {
            return new Promise((resolve, reject) => {
                PatientTherapy.query({
                    "patientId.equals": vm.patient.id,
                    'startDate.specified': true,
                    "sort": ["startDate,desc", "id,desc"]
                }, function (patientTherapies) {
                    vm.patientTherapies = patientTherapies;

                    vm.patientTherapy = patientTherapies && patientTherapies[0] ? patientTherapies[0] : null;
                    resolve(vm.patientTherapy);
                });
            });
        }

        function loadTherapy(therapyId) {
            return new Promise((resolve, reject) => {
                Therapy.get({
                    id: therapyId
                }, function (therapy) {
                    vm.therapy = therapy;
                    resolve(therapy);
                });
            });
        }

        function loadContractLine(contractLineId) {
            return new Promise((resolve, reject) => {
                ContractLine.get({id: contractLineId}, function (cl) {
                    vm.contractLine = cl;
                    vm.hasNextContractLine = !!vm.contractLine.nextContractLines && !!vm.contractLine.nextContractLines.length;
                });
            });
        }

        function loadProgram(programId) {
            return new Promise((resolve, reject) => {
                Program.get({id: programId}, program => {
                    vm.program = program;
                    resolve(resolve);
                });
            });
        }

        function nextStep(step) {
            vm.navigationHistoric.push(step);
            if (['RENEW_THERAPY', 'NEXT_THERAPY', 'STOP_THERAPY', 'FINISH_THERAPY'].includes(step)) {
                vm.data.nextFollowUpStep = step;
            }
        }

        function previous() {
            vm.navigationHistoric.pop();
        }



        vm.finish = function () {
            // permet de corriger le décalage horaire qui pose problème, le fuseau horaire n'étant pas géré correctement côté back
            if (vm.data.stopDate) {
                vm.data.stopDate = new Date(vm.data.stopDate.getTime() - vm.data.stopDate.getTimezoneOffset() * 60 * 1000);
            }

            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

    }

})();
