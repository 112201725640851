(function () {
    'use strict';

    const aohControlTestPanel = {
        bindings: {
            visits: '=',
        },
        controller: aohControlTestPanelController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient/aoh-control-test-panel.html'
    };

    angular
        .module('continuumplatformApp')
        .component('aohControlTestPanel', aohControlTestPanel);

    aohControlTestPanelController.$inject = ['$scope'];

    function aohControlTestPanelController($scope) {

        const vm = this;

        vm.visitList = [];

        vm.$onInit = function() {
            vm.unWatchVisit = $scope.$watch("vm.visits", onVisitsChange);
        };

        vm.$onDestroy = function() {
            vm.unWatchVisit();
        };

        function onVisitsChange(newVal, oldVal) {
            vm.visitList = [];

            if (newVal) {
                vm.visitList = newVal.filter((v) => v.steps.includes('AOH_CONTROL_TEST'));
            }
        }
    }

})();
