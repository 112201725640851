(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HealthEntityContractDialogController', HealthEntityContractDialogController);

    HealthEntityContractDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'HealthEntityContract'];

    function HealthEntityContractDialogController($timeout, $scope, $uibModalInstance, entity, HealthEntityContract) {
        var vm = this;

        vm.hec = entity;
        vm.form = {
            signEnvelopeId: vm.hec.signEnvelopeId,
            signed: vm.hec.signed,
            endDate: vm.hec.endDate
        };
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(0)>select').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            HealthEntityContract.update({id: vm.hec.id}, vm.form, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $scope.$emit('continuumplatformApp:healthEntityContractUpdate');
            $uibModalInstance.close();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
