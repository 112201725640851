(function () {
    'use strict';

    var pharmaItw1Step2Picto = {
        bindings: {
            data: '=',
            questions: '<'
        },
        controller: pharmaItw1Step2PictoController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/pharma-itw-1-step2-picto.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmaItw1Step2Picto', pharmaItw1Step2Picto);

    pharmaItw1Step2PictoController.$inject = ['$rootScope'];

    function pharmaItw1Step2PictoController($rootScope) {

        var vm = this;

        vm.step = 'PHARMA_ITW_1_STEP2_PICTO';

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

    }

})();
