(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('BiologyResultModifyController', BiologyResultModifyController);

    BiologyResultModifyController.$inject = ['$scope', '$uibModalInstance', 'entity', 'BiologyResult', 'HealthFacility'];

    function BiologyResultModifyController($scope, $uibModalInstance, entity, BiologyResult, HealthFacility) {
        var vm = this;

        vm.biologyResult = entity;
        vm.clear = clear;
        vm.save = save;

        vm.healthfacilities = HealthFacility.query({
            view: 'summary',
            "type.equals": "ANALYSIS_LABORATORY",
            sort: "name",
            size: 1000
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            BiologyResult.update({
                id: vm.biologyResult.id,
                laboratoryId: vm.biologyResult.laboratoryId,
                sampleDate: vm.biologyResult.sampleDate,
                comments: vm.biologyResult.comments
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $scope.$emit('continuumplatformApp:biologyResultUpdate');
            $uibModalInstance.close();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
