(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PractitionerInviteController', PractitionerInviteController);

    PractitionerInviteController.$inject = ['practitioner', 'Practitioner', '$uibModal', '$uibModalInstance', '$timeout'];

    function PractitionerInviteController(practitioner, Practitioner, $uibModal, $uibModalInstance, $timeout) {
        var vm = this;

        vm.form = {
            email: practitioner.email
        };
        vm.practitioner = practitioner;
        vm.alreadyHasEmail = !!practitioner.email;

        vm.clear = clear;
        vm.send = send;

        if(!vm.alreadyHasEmail) {
            $timeout(() => angular.element('#field_inviteEmail').focus());
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function send() {
            vm.isSending = true;
            Practitioner.sendInvitationEmail({
                email: vm.form.email
            }, () => {
                vm.isSending = false;
                $uibModalInstance.close();
            }, () => {
                vm.isSending = false;
            });
        }

    }
})();
