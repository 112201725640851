(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PersistentTokenDetailController', PersistentTokenDetailController);

    PersistentTokenDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PersistentToken', 'User'];

    function PersistentTokenDetailController($scope, $rootScope, $stateParams, entity, PersistentToken, User) {
        var vm = this;

        vm.persistentToken = entity;

        var unsubscribe = $rootScope.$on('continuumplatformApp:persistentTokenUpdate', function (event, result) {
            vm.persistentToken = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
