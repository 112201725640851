(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SymptomHistoryModalController', SymptomHistoryModalController);

    SymptomHistoryModalController.$inject = ['$uibModalInstance', 'symptom'];

    function SymptomHistoryModalController($uibModalInstance, symptom) {
        const vm = this;

        vm.symptom = symptom;
        vm.selectedSymptom = null;

        vm.selectSymptom = selectSymptom;
        vm.close = close;

        vm.$onInit = function () {
            if (vm.symptom.length) {
                selectSymptom(vm.symptom[0]);
            }
        };

        function selectSymptom(s) {
            vm.selectedSymptom = s;
        }

        function close() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
