(function () {
    'use strict';

    const visitDetailDateTreatmentDispensed = {
        bindings: {
            data: '=',
            questions: '<',
            patient: '<'
        },
        controller: VisitDetailDateTreatmentDispensedController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-detail-date-treatment-dispensed.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitDetailDateTreatmentDispensed', visitDetailDateTreatmentDispensed);

    VisitDetailDateTreatmentDispensedController.$inject = [];

    function VisitDetailDateTreatmentDispensedController() {

        const vm = this;

        vm.$onInit = function() {};

        vm.$onDestroy = function() {};

    }

})();
