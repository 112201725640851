(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('PharmAnalysis', PharmAnalysis);

    PharmAnalysis.$inject = ['$resource'];

    function PharmAnalysis($resource) {
        const resourceUrl = 'api/pharm-analyses/:id';

        const DEFAULT_PHARMA_ANALYSIS_TEXT = '- Détection d’interactions médicamenteuses et/ou de contre-indication,\n\n' +
            '- Vérification des posologies (en fonction du poids, de la taille, de l’âge, des résultats biologiques du patient...)\n\n' +
            '- Appréciation de l’observance du patient et de son adhésion au traitement...';

        const service =  $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                transformResponse: json => {
                    if (!json) {
                        return json;
                    }
                    return angular.fromJson(json).map(transformElement);
                }
            },
            'get': {
                method: 'GET',
                transformResponse: json => {
                    if (!json)
                        return json;
                    return transformElement(angular.fromJson(json));
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    const copy = angular.copy(data);
                    delete copy.media;

                    // on supprime le modèle d'analyse pharmaceutique si celui-ci n'a pas été modifié et que l'analyse est validée
                    if (copy.validated && copy.text && copy.text.trim() === DEFAULT_PHARMA_ANALYSIS_TEXT) {
                        copy.text = null;
                    }

                    return angular.toJson(copy);
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    const copy = angular.copy(data);
                    delete copy.media;

                    // on supprime le modèle d'analyse pharmaceutique si celui-ci n'a pas été modifié et que l'analyse est validée
                    if (copy.validated && copy.text && copy.text.trim() === DEFAULT_PHARMA_ANALYSIS_TEXT) {
                        copy.text = null;
                    }

                    return angular.toJson(copy);
                }
            }
        });

        service.DEFAULT_PHARMA_ANALYSIS_TEXT = DEFAULT_PHARMA_ANALYSIS_TEXT;

        function transformElement(pharmAnalysis) {
            if (pharmAnalysis.data && pharmAnalysis.data.analysisResultBimedoc) {
                pharmAnalysis.data.analysisResultBimedocIssueCount = 0;

                for (const categorie in pharmAnalysis.data.analysisResultBimedoc) {
                    // on ne doit pas compter la propriété total si est elle déjà présente
                    const removeTotalProperty = Object.prototype.hasOwnProperty.call(pharmAnalysis.data.analysisResultBimedoc[categorie], 'total') ? 1 : 0;
                    pharmAnalysis.data.analysisResultBimedoc[categorie].total = Object.keys(pharmAnalysis.data.analysisResultBimedoc[categorie]).length - removeTotalProperty;

                    pharmAnalysis.data.analysisResultBimedocIssueCount += pharmAnalysis.data.analysisResultBimedoc[categorie].total;
                }
            }
            if (pharmAnalysis.data && pharmAnalysis.data.measures) {
                for (const measureName in pharmAnalysis.data.measures) {
                    const m = pharmAnalysis.data.measures[measureName];
                    if(m) {
                        m.date = m.date ? new Date(m.date) : null;
                    }
                }
            }

            return pharmAnalysis;
        }

        return service;
    }
})();
