(function() {
    'use strict';

    angular.module('continuumplatformApp')
        .controller('MediaPreviewModalController', MediaPreviewModalController);

    MediaPreviewModalController.$inject = ['$sce', '$uibModalInstance', 'media'];

    function MediaPreviewModalController($sce, $uibModalInstance, media) {
        const vm = this;

        vm.media = media;

        vm.trustedInlineUrl = $sce.trustAsResourceUrl('/api/media/' + vm.media.id + '/download?disposition=inline');

        vm.dismiss = () => {
            $uibModalInstance.dismiss();
        };

    }
})();
