(function () {
    'use strict';

    const visitFragiliteSocialeEpices = {
        bindings: {
            patient: '<',
            data: '=',
            questions: '='
        },
        controller: VisitFragiliteSocialeEpicesControlle,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-fragilite-sociale-epices.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitFragiliteSocialeEpices', visitFragiliteSocialeEpices);

    VisitFragiliteSocialeEpicesControlle.$inject = ['$rootScope', '$scope'];

    function VisitFragiliteSocialeEpicesControlle($rootScope, $scope) {

        const vm = this;
        vm.step = 'EPICES';

        vm.$onInit = function () {
            vm.currentPage = 1;
            vm.isSaving = false;

            if (!vm.data) {
                vm.data = {};
            }

            vm.unWatchData = $scope.$watch("vm.data", function (newVal, oldVal) {

                // Si l’utilisateur sélectionne au moins une réponse (n’importe laquelle).
                // Les autres questions deviennent obligatoires.
                // Le bouton “Validé” est désactivé et l’utilisateur ne peut pas valider l'étape tant que toutes les questions n’ont pas de réponse
                if (vm.data) {

                    const dataCopy = Object.assign({}, vm.data);
                    // on supprime la propriété contenant le score de précarité celui-ci étant la conséquence des réponses aux questions
                    delete dataCopy.Q12;

                    const requiredAllResponse = [...Object.values(dataCopy)].reduce((required, data) => {
                        if (data) {
                            required = true;
                        }
                        return required;
                    }, false);

                    if (requiredAllResponse) {
                        [...Object.values(vm.questions)].forEach((q) => q.required = true);
                    }

                }
                computueEpicesScore();
            }, true);

        };

        vm.$onDestroy = () => {
            vm.unWatchData();
        };

        vm.finish = function () {

            computueEpicesScore();

            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

        function computueEpicesScore() {
            if (!vm.data) {
                return;
            }

            let score = 75.14;
            const epicesScoreByQuestion = [10.06, -11.83, -8.28, -8.28, 14.80, -6.51, -7.10, -7.10, -9.47, -9.47, -7.10];

            for (let i = 0; i < epicesScoreByQuestion.length; i++) {
                if (vm.data['Q' + (1 + i)] === 'Y') {
                    score += epicesScoreByQuestion[i];
                }
            }

            vm.data.Q12 = score;

            vm.showEpiceScore = [...Object.values(vm.data)].length > 1;
        }

    }

})();
