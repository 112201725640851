(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('AuditsService', AuditsService);

    AuditsService.$inject = ['$http'];

    function AuditsService($http) {

        return {query};

        function query(params) {
            return $http.get('api/audits', {params});
        }

    }
})();
