(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .filter('capitalizeFirst', capitalizeFirst);

    function capitalizeFirst() {
        return capitalizeFirstFilter;

        function capitalizeFirstFilter(input) {
            if (!input) {
                return input;
            }
            return input.substring(0, 1).toUpperCase() + input.substring(1);
        }
    }
})();
