
let pharmAnalysisComponent = null;

/**
 * Fonction exécutée après le chargement du script Bimedoc
 * Permet de lancer l'initialisation du composant en étant sûr d'avoir le script Bimedoc.
 */
function onloadBimedocJs() {
    pharmAnalysisComponent.initComponent();
}

function onErrorBimedocJs(event) {
    // on supprimer la balise script pour forcer le rechargement de Bimedoc lorsqu'on raffiche la page
    event.target.parentNode.removeChild(event.target);
    pharmAnalysisComponent.setBimedocError(true);
}

(function () {
    'use strict';

    const pharmAnalysisBlockBimedoc = {
        bindings: {
            pharmAnalysis: '=',
            readonly: '<',
            patientTherapy: '<',
            patient: '<',
            isBimedocLoading: '='
        },
        controller: PharmAnalysisBlockBimedocController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/pharm-analysis/pharm-analysis-block-bimedoc.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmAnalysisBlockBimedoc', pharmAnalysisBlockBimedoc);

    PharmAnalysisBlockBimedocController.$inject = ['$log', '$rootScope', '$scope', '$sce', '$uibModal', '$window', '_', 'Patient', 'Visit', 'Measure', 'PharmAnalysis', 'PatientTreatment', 'Media', 'CompressImage', 'Upload', 'MeasureType'];

    function PharmAnalysisBlockBimedocController($log, $rootScope, $scope, $sce, $uibModal, $window, _, Patient, Visit, Measure, PharmAnalysis, PatientTreatment, Media, CompressImage, Upload, MeasureType) {

        const vm = this;

        vm.ACTIVATE_BIMEDOC = true;

        vm.measureRules = MeasureType.getRules();

        vm.measureTypes = ['WEIGHT', 'SIZE', 'BMI'];
        vm.measures = {};
        vm.listCisTreatments = [];
        vm.isCollapsed = {};

        vm.uploadPharmAnalysis = uploadPharmAnalysis;
        vm.initComponent = initComponent;
        vm.setBimedocError = setBimedocError;

        /**
         * Executé à l'initialisation du composant.
         */
        vm.$onInit = function () {
            pharmAnalysisComponent = vm;

            // Si la balise script correspondant au widget Bimedoc n'existe pas, on la crée pour déclencher le chargement
            // du code du composant Bimedoc une fois le code chargé, on déclenche l'initialisation de ce composant via
            // la fonction onloadBimedocJs()
            if (document.getElementById('script-bimedoc')) {
                vm.initComponent();

            } else if(!vm.readonly) {
                const script = document.createElement('script');
                script.setAttribute('id', 'script-bimedoc');
                script.setAttribute('src', 'https://s3.eu-west-3.amazonaws.com/media.bimedoc.com/widget/bimedoc_analysis_widget.js');
                script.setAttribute('type', 'text/javascript');
                script.setAttribute('onload', 'onloadBimedocJs()');
                script.setAttribute('onerror', 'onErrorBimedocJs(event)');
                document.getElementsByTagName('head')[0].appendChild(script);
            } else {
                vm.initComponent();
            }

            if (vm.pharmAnalysis && vm.pharmAnalysis.data && vm.pharmAnalysis.data.analysisResultBimedoc) {
                vm.analysisResultBimedocHasNoData = vm.readonly && !Object.keys(this.pharmAnalysis.data.analysisResultBimedoc).length;
            }
        };

        /**
         * Executé à la destruction du composant.
         */
        vm.$onDestroy = function() {
            if (vm.bimedocAnalysisWidget) {
                // suppression des médicaments de bimedoc
                if (vm.pharmAnalysis.data.medications) {
                    vm.bimedocAnalysisWidget.deleteTreatment(vm.pharmAnalysis.data.medications, "medication");
                }

                // suppression des biologies dans bimedoc
                vm.bimedocAnalysisWidget.deleteBiology((["35591-7"]));
                vm.bimedocAnalysisWidget.deleteBiology((["62238-1"]));
                vm.bimedocAnalysisWidget.deleteBiology((["77147-7"]));

                // suppression du patient de bimedoc
                vm.bimedocAnalysisWidget.addPatient({id: null, date_birth: null, gender: null, age: null});
            }

            if (vm.unsubscribeMediaUpdate) {
                vm.unsubscribeMediaUpdate();
            }
            if (vm.unsubscribePatientTreatmentList) {
                vm.unsubscribePatientTreatmentList();
            }
            if (vm.unsubscribeClairanceChange) {
                vm.unsubscribeClairanceChange();
            }
            if (vm.unsubscribeDfgChange) {
                vm.unsubscribeDfgChange();
            }
            if (vm.unsubscribeBimedocAnalysisResult) {
                vm.unsubscribeBimedocAnalysisResult();
            }
            if (vm.unsubscribePatientTreatmentList) {
                vm.unsubscribePatientTreatmentList();
            }

        };

        function initComponent() {
            if (!vm.pharmAnalysis) {
                throw Error('Erreur analyse pharmaceutique non fourni');
            }

            new Promise((resolve, reject) => {
                if (!vm.pharmAnalysis.data && !vm.pharmAnalysis.validated) {
                    initPharmAnalysisData(vm.pharmAnalysis);

                    Promise.all([
                        loadMeasuresByPatientId(vm.pharmAnalysis.patientId), // permet de récupérer les mesures taille / poids pour Bimedoc
                        loadLastPharmAnalysis(vm.pharmAnalysis.patientId) // on récupère la dernière analyse pharmaceutique
                            .then(lastPharmAnalysis => {
                                vm.lastPharmAnalysis = lastPharmAnalysis;

                                if (lastPharmAnalysis && lastPharmAnalysis.data && lastPharmAnalysis.data.medications) {
                                    return lastPharmAnalysis.data.medications;

                                } else {
                                    // chargement de l'historique de traitement du patient
                                    return loadPatientTreatmentByPatientId(vm.pharmAnalysis.patientId)
                                        .then(mapUniqueCis);
                                }
                            }),
                    ]).then((data) => {
                        const [previousMeasures, treatmentHistoryCisCodes] = data;

                        initPatient(vm.pharmAnalysis, vm.patient);
                        initWeight(previousMeasures);
                        initSize(previousMeasures);
                        initBMI(previousMeasures);

                        if (vm.lastPharmAnalysis && vm.lastPharmAnalysis.data && vm.lastPharmAnalysis.data.medications) {
                            vm.pharmAnalysis.data.medications = vm.lastPharmAnalysis.data.medications;
                        } else if (treatmentHistoryCisCodes && treatmentHistoryCisCodes.length) {
                            // on met de côté les anciens traitements pour pouvoir les inclure en se basant sur leurs CIS
                            vm.pharmAnalysis.data.treatmentHistoryCisCodes = treatmentHistoryCisCodes;
                        }


                        return resolve(vm.pharmAnalysis);

                    }).catch((error) => {
                        reject(error);
                    });

                } else {
                    if (vm.pharmAnalysis.data && vm.pharmAnalysis.data.measures) {

                        if (vm.pharmAnalysis.data.measures.dfg && vm.pharmAnalysis.data.measures.dfg.date) {
                            vm.pharmAnalysis.data.measures.dfg.date = new Date(vm.pharmAnalysis.data.measures.dfg.date);
                        }

                        if (vm.pharmAnalysis.data.measures.clairance && vm.pharmAnalysis.data.measures.clairance.date) {
                            vm.pharmAnalysis.data.measures.clairance.date = new Date(vm.pharmAnalysis.data.measures.clairance.date);
                        }
                    }
                    resolve(vm.pharmAnalysis);
                }

            }).then((pharmAnalysis) => {
                if (!vm.readonly) {

                    if (vm.ACTIVATE_BIMEDOC) {
                        initBimedoc(pharmAnalysis);
                    }

                    subscribeClairanceChange();
                    subscribeDfgChange();
                    subscribeMediaUpdate();
                    subscribeWeightChange();
                    subscribeSizeChange();

                } else if (vm.pharmAnalysis && vm.pharmAnalysis.data && vm.pharmAnalysis.data.analysisResultBimedoc) {
                    for (const categorie in vm.pharmAnalysis.data.analysisResultBimedoc) {
                        vm.isCollapsed[categorie] = {};
                        for (let i = 0; i < vm.pharmAnalysis.data.analysisResultBimedoc[categorie].total; i++) {
                            vm.isCollapsed[categorie][i] = false;
                        }
                    }

                }

                pharmAnalysisComponent.setBimedocError(false);

            }).catch(error => {
                setBimedocError(true);
                $log.error(error);
            });
        }

        function setBimedocError(hasError) {
            vm.bimedocError = hasError;
        }

        function initPharmAnalysisData(pharmAnalysis) {
            pharmAnalysis.text = PharmAnalysis.DEFAULT_PHARMA_ANALYSIS_TEXT;

            pharmAnalysis.data = {
                analysisResultBimedoc: null,
                medications: [],
                patient: {
                    last_name: null,
                    first_name: null,
                    date_birth: null,
                    gender: null,
                    age: null,
                    weight: null,
                    size: null,
                },
                measures: {
                    SIZE: {
                        value: null,
                        type: 'SIZE'
                    },
                    WEIGHT: {
                        value: null,
                        type: 'WEIGHT'
                    },
                    BMI : {
                        value: null,
                        type: 'BMI'
                    },
                    dfg: {
                        value: null,
                        methodology: 'Ckd-epi',
                        date: null,
                        type: 'GFR'
                    },
                    clairance: {
                        value: null,
                        date: null,
                        type: 'CLAIRANCE'
                    },
                },
            };
        }

        function initPatient(pharmAnalysis, patient) {
            pharmAnalysis.data.patient = {
                id: patient.id,
                // on ne souhaite pas fournir à Bimedoc le nom du patient
                // last_name : patient.lastName,
                // first_name : patient.firstName,
                date_birth: patient.birthDate,
                gender:	patient.gender ? patient.gender.toLowerCase() : null,
                age: patient.age,
            };
        }

        function initWeight(previousMeasures) {
            const previousMeasuresWeight = previousMeasures.filter((m) => m.type === 'WEIGHT');
            if (previousMeasuresWeight && previousMeasuresWeight.length) {
                vm.pharmAnalysis.data.measures['WEIGHT'] = previousMeasuresWeight[0];
            }
        }

        function initSize(previousMeasures) {
            const previousMeasuresSize = previousMeasures.filter((m) => m.type === 'SIZE');
            if (previousMeasuresSize && previousMeasuresSize.length) {
                vm.pharmAnalysis.data.measures['SIZE'] = previousMeasuresSize[0];
            }
        }

        function initBMI(previousMeasures) {
            const previousMeasuresSize = previousMeasures.filter((m) => m.type === 'SIZE');
            const previousMeasuresWeight = previousMeasures.filter((m) => m.type === 'WEIGHT');

            if (previousMeasuresSize && previousMeasuresSize.length && previousMeasuresWeight && previousMeasuresWeight.length) {
                vm.pharmAnalysis.data.measures['BMI'] = {
                    value: +((+previousMeasuresWeight[0].value) / ((+previousMeasuresSize[0].value) * (+previousMeasuresSize[0].value) / 10000)).toFixed(1),
                    type: 'BMI',
                };

            } else {
                vm.pharmAnalysis.data.measures['BMI'] = {
                    "value": null,
                    "type": "BMI",
                };
            }
        }

        function subscribeMediaUpdate() {
            if (!vm.unsubscribeMediaUpdate) {
                vm.unsubscribeMediaUpdate = $rootScope.$on('continuumplatformApp:mediaUpdate', loadMedia);
            }
        }

        function subscribeWeightChange() {
            if (!vm.unsubscribeWeightChange) {
                vm.unsubscribeWeightChange = $scope.$watch("vm.pharmAnalysis.data.measures['WEIGHT'].value", function (newVal, oldVal) {

                    if (newVal && oldVal && newVal !== oldVal) {
                        vm.pharmAnalysis.data.measures['WEIGHT'] = {value: newVal, type:'WEIGHT'};
                    }

                    if (newVal && vm.pharmAnalysis.data.measures['SIZE'].value) {
                        vm.pharmAnalysis.data.measures['BMI'] = {
                            value: +((+newVal) / ((+vm.pharmAnalysis.data.measures['SIZE'].value) * (+vm.pharmAnalysis.data.measures['SIZE'].value) / 10000)).toFixed(1),
                            type: 'BMI',
                        };
                    } else {
                        vm.pharmAnalysis.data.measures['BMI'] = null;
                    }

                    if (newVal !== oldVal) {
                        try {
                            bimedocSetPatient(vm.pharmAnalysis);
                        } catch (e) {
                            $log.error('Erreur lors de la définition du poids dans le composant Bimedoc', e);
                            setBimedocError(true);
                        }
                    }

                });
            }
        }

        function subscribeSizeChange() {
            if (!vm.unsubscribeSizeChange) {
                vm.unsubscribeSizeChange = $scope.$watch("vm.pharmAnalysis.data.measures['SIZE'].value", function (newVal, oldVal) {
                    if (newVal && oldVal && newVal !== oldVal) {
                        vm.pharmAnalysis.data.measures['SIZE'] = {value: newVal, type:'SIZE'};
                    }

                    if (newVal && vm.pharmAnalysis.data.measures['WEIGHT'].value) {
                        vm.pharmAnalysis.data.measures['BMI'] = {
                            value: +((+vm.pharmAnalysis.data.measures['WEIGHT'].value) / ((+newVal) * (+newVal) / 10000)).toFixed(1),
                            type: 'BMI'
                        };
                    } else {
                        vm.pharmAnalysis.data.measures['BMI'] = null;
                    }

                    if (newVal !== oldVal) {
                        try {
                            bimedocSetPatient(vm.pharmAnalysis);
                        } catch (e) {
                            $log.error('Erreur lors de la définition de la taille dans le composant Bimedoc', e);
                            setBimedocError(true);
                        }
                    }
                });
            }
        }

        function subscribeClairanceChange() {
            if (!vm.unsubscribeClairanceChange) {
                vm.unsubscribeClairanceChange = $scope.$watch('vm.pharmAnalysis.data.measures.clairance.value', (newVal, oldVal) => {

                    if (newVal === oldVal) {
                        return;
                    }

                    try {
                        vm.bimedocAnalysisWidget.deleteBiology((["35591-7"]));
                        if (newVal && vm.bimedocAnalysisWidget) {
                            vm.bimedocAnalysisWidget.addBiology([{loinc: "35591-7", value: newVal}]);
                        }
                    } catch (e) {
                        $log.error('Erreur lors de la définition de la clairance dans le composant Bimedoc', e);
                        setBimedocError(true);

                    }
                });
            }
        }

        function subscribeDfgChange() {
            if (!vm.unsubscribeDfgChange) {
                vm.unsubscribeDfgChange = $scope.$watch('vm.pharmAnalysis.data.measures.dfg.value',
                    function (newValue, oldValue) {

                        if (newValue === oldValue) {
                            return;
                        }

                        try {
                            vm.bimedocAnalysisWidget.deleteBiology((["62238-1"])); // DFG Ckd-epi
                            vm.bimedocAnalysisWidget.deleteBiology((["77147-7"])); // DFG Mdrd
                            if (newValue && vm.bimedocAnalysisWidget) {
                                vm.bimedocAnalysisWidget.addBiology([{loinc: "62238-1", value: newValue}]);
                            }
                        } catch (e) {
                            $log.error('Erreur lors de la définition de la DFG dans le composant Bimedoc', e);
                            setBimedocError(true);
                        }

                    }
                );
            }
        }

        function subscribeBimedocChange() {
            try {
                for (const element of vm.bimedocAnalysisWidget.__ngContext__) {
                    if (element && element.analysisResult) {
                        vm.bimedocAngularComponent = element;
                    }
                }

                // Hack pour savoir si Bimedoc est en train d'attendre des résultats
                $scope.$watch(() => {
                    return vm.bimedocAngularComponent.loading;
                }, (newValue, oldValue) => {
                    vm.isBimedocLoading = newValue;
                });

                // Hack pour récupérer les résultats d'analyse Bimedoc en attendant qu'il fournisse ou pas un moyen de le faire
                vm.unsubscribeBimedocAnalysisResult = $scope.$watch(() => {
                    return vm.bimedocAngularComponent.analysisResult;
                }, (newValue, oldValue) => {
                    vm.analysisResultBimedoc = _.cloneDeep(newValue);
                    vm.pharmAnalysis.data.analysisResultBimedoc = vm.analysisResultBimedoc;
                }, true);

                // Hack pour récupérer la liste de traitements en attendant qu'il fournisse ou pas un moyen de le faire
                vm.unsubscribePatientTreatmentList = $scope.$watch(() => {
                    return vm.bimedocAngularComponent.patientTreatmentList;
                }, (newValue, oldValue) => {
                    vm.medications = [];
                    if (newValue) {
                        newValue.forEach((t) => {
                            vm.medications = vm.medications.concat(t.medications);
                        });
                    }
                    vm.pharmAnalysis.data.medications = vm.medications;
                }, true);


            } catch (e) {
                $log.error('Erreur lors de la souscription au changement de données du composant Bimedoc', e);
                setBimedocError(true);
            }
        }

        /**
         * Permet de récupérer la dernière analyse pharmaceutique
         * @return {Promise}
         */
        function loadLastPharmAnalysis(patientId) {
            return PharmAnalysis.query({
                "patientId.equals": patientId,
                "validated.equals": true,
                "sort": "lastModifiedDate,desc",
                "size": 1
            }).$promise
                .then((pharmAnalyses) => {
                    return vm.lastPharmAnalysis = pharmAnalyses.length ? pharmAnalyses[0] : null;
                });
        }

        /**
         * Permet de récupérer les mesures de taille, poids et Débit de filtration glomérulaire déjà mesuré pour un patient.
         * @param patientId
         * @returns {Promise<Measure[]>}
         */
        function loadMeasuresByPatientId(patientId) {
            return new Promise((resolve, reject) => {
                Measure.query({
                    view: 'base',
                    "patientId.equals": patientId,
                    "type.in": ['SIZE', 'WEIGHT', 'GFR', 'CLAIRANCE'],
                    "sort": ["date,desc"],
                    size: 9999
                }, (measures) => {
                    vm.measures = measures;
                    resolve(measures);
                });
            });
        }

        function loadPatientTreatmentByPatientId(patientId) {
            return PatientTreatment.query({
                "patientId.equals": patientId,
                "status.notIn": ['S40_FINISHED', 'S50_STOPPED', 'S99_CANCELED'],
                "size": 1000  // TODO hard-coded size
            }).$promise.then(treatments => {
                return vm.patientTreatments = treatments;
            });
        }

        function mapUniqueCis(treatments) {
            if (!treatments || !treatments.length) {
                return [];
            }

            return [...new Set(
                treatments
                    .filter(t => t.medicationVariantCis)
                    .map(t => t.medicationVariantCis))];
        }

        /**
         * Initialise le widget Bimédoc.
         * @param pharmAnalysis
         */
        function initBimedoc(pharmAnalysis) {
            vm.bimedocAnalysisWidget = $window.document.querySelector('lib-on-demand-analysis-widget');

            if (!vm.bimedocAnalysisWidget) {
                setBimedocError(true);
                $log.error("Erreur lors de l'initialisation du composant Bimedoc (vm.bimedocAnalysisWidget n'existe pas)");
            }

            // inspection du composant Bimedoc
            // console.log('__ngContext__', vm.bimedocAnalysisWidget.__ngContext__);

            try {
                vm.bimedocAnalysisWidget.state = "TOOLBOX";
                vm.bimedocAnalysisWidget.has_treatment_tab = true; // true | false (true par défaut)
                vm.bimedocAnalysisWidget.has_patient_view = false; // true | false (true par défaut)

                initBimedocTreatment();
                bimedocSetPatient(pharmAnalysis);
                initBimedocClairance(pharmAnalysis);
                initBimedocDfg(pharmAnalysis);
                subscribeBimedocChange();

            } catch (e) {
                $log.error("Erreur lors de l'initialisation du composant Bimedoc", e);
                setBimedocError(true);
            }

        }

        function initBimedocClairance(pharmAnalysis) {
            if (pharmAnalysis.data.measures.clairance.value !== undefined && pharmAnalysis.data.measures.clairance.value !== null ) {
                vm.bimedocAnalysisWidget.addBiology([{loinc: "35591-7", value: pharmAnalysis.data.measures.clairance.value}]);
            }
        }

        function initBimedocDfg(pharmAnalysis) {
            if (!!pharmAnalysis.data.measures.dfg.value && !!pharmAnalysis.data.measures.dfg.methodology) {
                switch (pharmAnalysis.data.measures.dfg.methodology) {
                case 'Ckd-epi':
                    vm.bimedocAnalysisWidget.addBiology([{loinc: "62238-1", value: pharmAnalysis.data.measures.dfg.value}]);
                    break;
                case 'Mdrd':
                    vm.bimedocAnalysisWidget.addBiology([{loinc: "77147-7", value: pharmAnalysis.data.measures.dfg.value}]);
                    break;
                }
            }

        }

        function bimedocSetPatient(pharmAnalysis) {
            if (!vm.bimedocAnalysisWidget) {
                throw Error('Composant Bimedoc non initialisé');
            }

            // mise à jour du patient dans le widget Bimédoc
            const patient = _.cloneDeep(pharmAnalysis.data.patient);
            patient.weight = pharmAnalysis.data.measures['WEIGHT'].value;
            patient.size = pharmAnalysis.data.measures['SIZE'].value;
            vm.bimedocAnalysisWidget.addPatient(patient);
        }

        function uploadPharmAnalysis(files, errFiles) {
            if (!files || !files.length) {
                return;
            }

            if (vm.pharmAnalysis.id) {
                doUploadPharmAnalysis(files, errFiles);
            } else {
                PharmAnalysis.save(vm.pharmAnalysis, function (result) {
                    onSavePharmAnalysisSuccess(result);
                    doUploadPharmAnalysis(files, errFiles);
                });
            }
        }

        function onSavePharmAnalysisSuccess(result) {
            vm.pharmAnalysis = result;
            vm.pharmAnalysisIsDraft = true;
            vm.pharmAnalysisIsTyping = false;
        }

        function doUploadPharmAnalysis(files, errFiles) {
            if (files[0].type.startsWith('image/')) {
                CompressImage.compress(files[0]).then( (compressImageFile) => {
                    files[0] = compressImageFile;
                    uploadFile(files, errFiles);
                });
            } else {
                uploadFile(files, errFiles);
            }
        }

        function uploadFile(files, errFiles) {
            vm.uploadStatus = 'uploading';
            vm.uploadProgress = 0;
            const upload = Upload.upload({
                url: 'api/pharm-analyses/' + vm.pharmAnalysis.id + '/files',
                method: 'POST',
                arrayKey: '',
                data: {
                    files: files
                }
            });

            upload.then(function (resp) {
                vm.uploadStatus = 'success';
                $rootScope.$emit('continuumplatformApp:mediaUpdate');
            }, function (resp) {
                vm.uploadStatus = 'error';
            }, function (evt) {
                vm.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
            });

            upload.catch(function (error) {
                vm.uploadStatus = 'error';
            });
        }

        vm.deleteFile = (id) => {
            vm.isLoadingMedia = true;
            let numberOfFiles = vm.pharmAnalysis.media.length;
            Media.delete({id: id}, function () {
                vm.isLoadingMedia = false;
                numberOfFiles--;
                if (numberOfFiles) {
                    loadMedia();
                } else {
                    vm.pharmAnalysis.media = [];
                }
            });
        };

        function loadMedia() {
            if (vm.pharmAnalysis && vm.pharmAnalysis.id) {
                vm.isLoadingMedia = true;
                Media.query({
                    "pharmAnalysisId.equals": vm.pharmAnalysis.id,
                    "sort": "id,desc"
                    // TODO size?
                }, function (value) {
                    vm.isLoadingMedia = false;
                    vm.pharmAnalysis.media = value;
                });
            }
        }

        function initBimedocTreatment() {
            if (vm.pharmAnalysis.data.medications && vm.pharmAnalysis.data.medications.length) {
                // TODO supprimer l'ajout dans patientTreatmentList une fois le bug Bimedoc corrigé (effectué par addTreatment normalement)
                vm.bimedocAnalysisWidget.patientTreatmentList.push({
                    id: null,
                    is_perfusion: !1,
                    posology_category: "simple",
                    complex_poso: null,
                    meal: null,
                    if_needed: !1,
                    date_start: null,
                    date_end: null,
                    frequency_category: "week",
                    comment: null,
                    origine: null,
                    probleme_forme_galenique: !1,
                    probleme_observance: !1,
                    probleme_effet_indesirable: !1,
                    is_archived: !1,
                    current_status: "en_cours",
                    administration_route: null,
                    intake_unit: null,
                    medications: vm.pharmAnalysis.data.medications,
                    posologies: []
                });

                if (vm.pharmAnalysis.data && vm.pharmAnalysis.data.medications) {
                    const medicationTab = vm.pharmAnalysis.data.medications.map((m) => {
                        return m.medication;
                    });

                    vm.bimedocAnalysisWidget.addTreatment(medicationTab, "medication");
                }
            }

            // si on a un historique de traitement, on ajoute les médicaments qu'il contient en utilisant le CIS
            if (vm.pharmAnalysis.data.treatmentHistoryCisCodes && vm.pharmAnalysis.data.treatmentHistoryCisCodes.length) {

                // format CIS : 1 234 567 8
                const listCisTreatments = vm.pharmAnalysis.data.treatmentHistoryCisCodes.map(cis => {
                    return `${cis.substring(0, 1)} ${cis.slice(1, 4)} ${cis.slice(4, 7)} ${cis.slice(7)}`;
                });

                vm.bimedocAnalysisWidget.addTreatment(listCisTreatments, "cis");

                // on n'a plus besoin de l'historique on le delete
                delete vm.pharmAnalysis.data.treatmentHistoryCisCodes;
            }
        }

        /**
         * Permet d'afficher le contenu HTML de l'analyse réalisé par Bimédoc.
         * @param html_code
         * @returns html
         */
        vm.renderHtml = (html_code) => {
            return $sce.trustAsHtml(html_code);
        };
    }
})();
