(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('Article51Controller', Article51Controller);

    Article51Controller.$inject = ['$log', '$state', '$uibModal','$window', 'moment', 'paginationConstants', 'Article51', 'DownloadFile', 'Upload', 'Principal', 'HealthFacility'];

    function Article51Controller($log, $state, $uibModal, $window, moment, paginationConstants, Article51, DownloadFile, Upload, Principal, HealthFacility) {

        $window.document.title = 'Article 51 : Facturation';

        const vm = this;

        vm.account = null;
        vm.article51Code = null;
        vm.history = false;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = 1;
        vm.countPending = null;
        vm.countHistory = null;
        vm.countPagination = null;
        vm.rows = null;

        vm.selecting = false;
        vm.selectError = null;

        vm.inserting = false;
        vm.inserted = null;
        vm.clearing = false;
        vm.insertOrClearError = null;

        vm.exporting = false;
        vm.exportError = null;

        vm.importing = false;
        vm.importError = null;
        vm.imported = null;

        const todayMoment = moment();
        const dayInMonth = todayMoment.date();
        const activePeriod = dayInMonth < 6 ? todayMoment.subtract(1, 'months') : todayMoment;
        vm.period = activePeriod.format('MMMM yyyy');

        vm.$onInit = () => {
            findArticle51();
        };

        vm.$onDestroy = () => {

        };

        function findArticle51() {
            Principal.identity().then(account => {
                if (!account.practitioner || !account.practitioner.healthFacilityId) {
                    $log.warn("Non PS, ou non affecté à un établissement");
                    return;
                }
                vm.account = account;
                HealthFacility.get({
                    id: account.practitioner.healthFacilityId,
                    view: 'summary'
                }).$promise.then(onFacilityLoaded);
            });
        }

        function onFacilityLoaded(facility) {
            if (!facility.article51 || !facility.article51.length) {
                $log.warn("Etablissement non participant à un article 51");
                vm.article51Code = -1;
                return;
            }
            vm.article51List = facility.article51
                .filter(code => code === '19N20' || vm.account.authorities.includes('ARTICLE51_BILL_' + code));
            if (!vm.article51List.length) {
                $log.warn("Autorisations insuffisantes pour facturer un article 51");
                vm.article51Code = -1;
                return;
            }
            vm.article51Code = vm.article51List[0];
            select();
        }

        function select() {
            vm.selecting = true;
            vm.selectError = null;
            Article51.select(vm.article51Code, vm.history, vm.page - 1, vm.itemsPerPage).then(response => {
                vm.countPagination = parseInt(response.headers('X-Total-Count'));
                if (vm.history) {
                    vm.countHistory = vm.countPagination;
                    Article51.count(vm.article51Code, false).then(response => {
                        vm.countPending = response.data;
                    });
                } else {
                    vm.countPending = vm.countPagination;
                    Article51.count(vm.article51Code, true).then(response => {
                        vm.countHistory = response.data;
                    });
                }
                vm.rows = response.data.map(mapRow);
            }).catch(response => {
                vm.selectError = response.data.detail;
            }).finally(() =>
                vm.selecting = false
            );
        }

        function mapRow(row) {
            row.missing = (!row.nir
                || !row.dateNaissance
                || !row.rang
                || !row.sexe
                || !row.identifiantVersement);
            row.ko = (row.controleDoublonFichier && 'OK' !== row.controleDoublonFichier)
                || (row.controleFormat && 'OK' !== row.controleFormat)
                || (row.controleIdentitePatient && 'OK' !== row.controleIdentitePatient)
                || (row.controleIdentiteActeur && 'OK' !== row.controleIdentiteActeur)
                || (row.controleCoordonneesBancairesActeur && 'OK' !== row.controleCoordonneesBancairesActeur)
                || (row.controleDoublonHistorique && 'OK' !== row.controleDoublonHistorique)
                || (row.controleDate && 'OK' !== row.controleDate)
                || (row.controleActeurIncompatibleDateSoins && 'OK' !== row.controleActeurIncompatibleDateSoins);
            row.nirKo = 'NIR KO' === row.controleIdentitePatient
                || 'A' === row.controleFormat;
            row.dateNaissanceKo = 'Date de naissance / Rang KO' === row.controleIdentitePatient
                || 'B' === row.controleFormat;
            row.rangKo = 'Date de naissance / Rang KO' === row.controleIdentitePatient
                || 'C' === row.controleFormat;
            row.sexeKo = 'D' === row.controleFormat;
            row.identifiantVersementKo = 'Numéro KO' === row.controleIdentiteActeur
                || 'Acteur non déclaré' === row.controleIdentiteActeur
                || 'Coordonnées bancaires acteur KO' === row.controleCoordonneesBancairesActeur
                || 'KO' === row.controleActeurIncompatibleDateSoins
                || 'E' === row.controleFormat;
            return row;
        }

        vm.insert = () => {
            vm.inserting = true;
            vm.inserted = null;
            vm.insertOrClearError = null;
            Article51.insert(vm.article51Code)
                .then(response => {
                    vm.inserted = parseInt(response.data);
                    select();
                })
                .catch(response => {
                    vm.insertOrClearError = response.data.detail;
                })
                .finally(() => {
                    vm.inserting = false;
                });
        };

        vm.exportConvention = () => {
            vm.exporting = true;
            vm.exportError = null;
            Article51.exportConvention(vm.article51Code)
                .then(response => DownloadFile.downloadFile(transformExport(response)))
                .catch(response => {
                    vm.exportError = response.data.detail;
                })
                .finally(() => {
                    vm.exporting = false;
                });
        };

        vm.exportPaiement = () => {
            vm.exporting = true;
            vm.exportError = null;
            Article51.exportPaiement(vm.article51Code)
                .then(response => DownloadFile.downloadFile(transformExport(response)))
                .catch(response => {
                    vm.exportError = response.data.detail;
                })
                .finally(() => {
                    vm.exporting = false;
                });
        };

        function transformExport(response) {
            return {
                data: new Blob([response.data], {type: 'text/plain;charset=utf-8'}),
                headers: response.headers
            };
        }

        vm.importResults = (file) => {
            if (vm.importing) {
                return;
            }
            vm.uploadProgress = null;
            vm.imported = null;
            vm.importError = null;
            if (!file) {
                return;
            }
            vm.importing = true;
            Article51.importResults(file)
                .then(
                    response => {
                        vm.inserted = null;
                        vm.imported = response.data;
                        select();
                    },
                    response => {
                        vm.importError = response.data.detail;
                    },
                    evt => vm.uploadProgress = Math.round(100 * evt.loaded / evt.total))
                .finally(
                    () => vm.importing = false
                );
        };

        vm.clear = () => {
            if (!confirm("Confirmez-vous la suppression des forfaits à facturer ?")) {
                return;
            }
            vm.clearing = true;
            vm.insertOrClearError = null;
            Article51.clear(vm.article51Code)
                .then(() => {
                    vm.inserted = null;
                    vm.countHistory -= vm.countPending;
                    vm.countPending = 0;
                    if (vm.history) {
                        vm.countPagination = vm.countHistory;
                    } else {
                        vm.countPagination = 0;
                        vm.rows = [];
                    }
                    vm.page = 1;
                    select();
                })
                .catch(response => {
                    vm.insertOrClearError = response.data.detail;
                })
                .finally(() => {
                    vm.clearing = false;
                });
        };

        vm.changeArticle51 = () => {
            vm.page = 1;
            vm.countPending = null;
            vm.countHistory = null;
            vm.countPagination = null;
            vm.rows = null;
            select();
        };

        vm.changePage = () => {
            select();
        };

        vm.toggleHistory = () => {
            vm.history = !vm.history;
            select();
        };

        vm.openPatientTherapy = (id) => {
            if(vm.account.authorities.includes('ROLE_PRACTITIONER')) {
                const url = $state.href('patient-therapy-detail', {id});
                $window.open(url, '_blank');
            } else {
                const url = $state.href('patient-therapy-detail', {id}, {absolute: true});
                $uibModal.open({
                    templateUrl: 'app/activity/article51/article51-pt-preview.html',
                    controller: ['id', 'url', '$uibModalInstance', function (id, url, $uibModalInstance) {
                        const vm = this;
                        vm.id = id;
                        vm.url = url;
                        vm.close = () => $uibModalInstance.close();
                    }],
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        id: () => id,
                        url: () => url
                    }
                }).result.then(() => {
                    // do nothing
                }, () => {
                    // do nothing
                });
            }
        };

    }
})();
