(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HealthEntityContractSignController', HealthEntityContractSignController);

    HealthEntityContractSignController.$inject = ['hec', '$state', '$sce', '$timeout', '$log', 'previousState', 'HealthEntityContract', 'Principal'];

    function HealthEntityContractSignController(hec, $state, $sce, $timeout, $log, previousState, HealthEntityContract, Principal) {
        var vm = this;

        vm.previousState = previousState;

        vm.back = back;

        Principal.identity().then(function (account) {

            vm.account = account;
            vm.isAdmin = account.authorities.includes('ROLE_ADMIN');

            if (account.practitioner && account.practitioner.complete && !hec.signed) {
                HealthEntityContract.requestSignature(hec.id).then(response => {
                    vm.signUrl = $sce.trustAsResourceUrl(response.data);

                    check();

                }, function (response) {
                    vm.error = true;
                });
            } else if (account.practitioner && !account.practitioner.complete) {
                $state.go('settings-practitioner-detail');
            } else {
                $state.go('health-entity-contract-detail', {id: hec.id});
            }

        });

        function check() {
            HealthEntityContract.get(
                {id: hec.id},
                (result => {
                    $log.debug("Checking for signature...");
                    if (result.signed) {
                        $log.info("Signature complete!");
                        $state.go('health-entity-contract-detail', {id: hec.id});
                    } else {
                        $timeout(check, 3000);
                    }
                }));
        }

        function back() {
            if (vm.previousState.name) {
                $state.go(vm.previousState.name, vm.previousState.params);
            } else if (vm.account.practitioner && !vm.isAdmin) {
                $state.go('settings-practitioner-detail');
            } else {
                $state.go('home');
            }
        }
    }
})();
