(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('patientDetailPropertyBool', patientDetailPropertyBool);

    function patientDetailPropertyBool() {


        return {
            restrict: 'E',
            scope: {
                label: '@',
                value: '='
            },
            templateUrl: 'app/activity/patient/patient-detail-property-bool.html',
            link: function (scope) {
                scope.isDefined = function (value) {
                    return angular.isDefined(value);
                };
            }
        };


    }

})();
