(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('QuestionsController', QuestionsController);

    QuestionsController.$inject = ['Question'];

    function QuestionsController(Question) {

        var vm = this;

        vm.questions = [];

        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            Question.getAll().then(function (result) {
                vm.questions = result;
            });
        }
    }
})();
