(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('patient-detail.messages', {
                url: '/messages?page&sort',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'continuumplatformApp.message.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/message/messages.html',
                        controller: 'MessageController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('message');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        return $translate.refresh();
                    }],
                    patient: ['$stateParams', 'Patient', function ($stateParams, Patient) {
                        return Patient.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        const fromStateName = $transition$.from().name;
                        if (!fromStateName
                            || ['patient-detail.follow-up', 'patient-detail.messages', 'message-detail'].includes(fromStateName)) {
                            return {
                                name: 'patient-detail.follow-up',
                                params: {id: $transition$.params().id}
                            };
                        }
                        return {
                            name: $transition$.from().name,
                            params: $transition$.params('from')
                        };
                    }]
                }
            })
            .state('message-detail', {
                parent: 'patients',
                url: '/message/{id}',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'continuumplatformApp.message.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/message/message-detail.html',
                        controller: 'MessageDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('message');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Message', function ($stateParams, Message) {
                        return Message.read({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name,
                            params: $transition$.params('from')
                        };
                    }]
                }
            });
    }

})();
