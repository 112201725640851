(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('VisitCancelDialogController', VisitCancelDialogController);

    VisitCancelDialogController.$inject = ['$scope', '$uibModal', '$uibModalInstance', 'visit', 'PatientTherapy', 'Visit'];

    function VisitCancelDialogController($scope, $uibModal, $uibModalInstance, visit, PatientTherapy, Visit) {
        const vm = this;

        vm.visit = visit;

        vm.cancelVisit = () => {
            Visit.cancel({id: vm.visit.id}, {}, () => {
                $scope.$emit('continuumplatformApp:visitUpdate', vm.visit.id);
                $uibModalInstance.close('cancelVisit');
            });
        };

        vm.stopTherapy = () => {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-management.html',
                controller: 'PatientTherapyManagementController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    action: () => 'STOP_THERAPY',
                    patientTherapy: () => PatientTherapy.get({id: vm.visit.patientTherapyId}).$promise,
                }
            }).result.then(() => {
                $uibModalInstance.close('stopTherapy');
            }, () => {
                // do nothing
            });
        };

        vm.dismiss = () => {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
