(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MessageController', MessageController);

    MessageController.$inject = ['$rootScope', '$timeout', 'Message', 'Inbox', 'AlertService', 'previousState', 'patient', 'Principal'];

    function MessageController($rootScope, $timeout, Message, Inbox, AlertService, previousState, patient, Principal) {

        const vm = this;

        vm.patient = patient;

        vm.previousState = previousState;

        vm.loadAll = loadAll;
        vm.sendMessage = sendMessage;

        loadAll();

        $timeout(function () {
            angular.element('.messaging textarea').focus();
        });

        Principal.identity().then(account => {
            vm.account = account;
        });

        function loadAll() {
            Message.query({
                "patientId.equals": vm.patient.id,
                sort: 'date'
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.messages = data;
                markAllRead();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function sendMessage() {
            vm.isSendingMessage = true;

            Message.send({
                important: vm.messageImportant,
                body: vm.sendMessageBody,
                patientId: vm.patient.id
            }, () => {
                vm.isSendingMessage = false;
                vm.sendMessageBody = null;
                vm.messageImportant = null;
                $timeout(function () {
                    angular.element('.messaging textarea').focus();
                });
                loadAll();
            }, () => {
                vm.isSendingMessage = false;
            });
        }

        function markAllRead() {
            Inbox.readAll(vm.patient.id).then(response => {
                if (response.data) {
                    $rootScope.unreadMessages -= response.data;
                    $rootScope.$emit('continuumplatformApp:messageRead', response.data);
                }
            });
        }
    }
})();
