(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('sideEffectReportScore', sideEffectReportScore);

    function sideEffectReportScore() {

        return {
            restrict: 'E',
            scope: {
                score: '@'
            },
            templateUrl: 'app/activity/side-effect-report/side-effect-report-score.html'
        };


    }

})();
