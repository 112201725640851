(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PractitionerSelectionController', PractitionerSelectionController);

    PractitionerSelectionController.$inject = ['$scope', 'Practitioner', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$uibModalInstance', 'search', 'restrict'];

    function PractitionerSelectionController($scope, Practitioner, ParseLinks, AlertService, paginationConstants, pagingParams, $uibModalInstance, search, restrict) {

        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.ascending = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 100;
        vm.page = 1;

        vm.loadAll = loadAll;
        vm.close = close;
        vm.select = select;
        vm.clearSearch = clearSearch;
        vm.reapplySearch = reapplySearch;
        vm.nextPage = nextPage;

        vm.restrict = restrict || getDefaultFilter();
        vm.initialSearch = search && angular.copy(search);
        vm.search = search || getDefaultFilter();

        $scope.$watch('vm.search', function () {
            vm.page = 1;
            loadAll(false);
        }, true);

        function loadAll(increment) {
            vm.isLoading = true;
            vm.increment = increment;
            Practitioner.query({
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                "job.equals": vm.restrict.job || vm.search.job,
                "lastName.startsWithNormalized": vm.restrict.lastName || vm.search.lastName,
                "firstName.startsWithNormalized": vm.restrict.firstName || vm.search.firstName,
                "postalCodeOrFacilityPostalCode.startsWith": vm.restrict.postalCode || vm.search.postalCode,
                "cityOrFacilityCity.containsNormalized": vm.restrict.city || vm.search.city,
                "healthFacilityId.equals": vm.restrict.healthFacilityId || vm.search.healthFacilityId,
                "healthFacilityName.containsNormalized": vm.restrict.healthFacilityName || vm.search.healthFacilityName,
                "healthFacilityType.equals": vm.restrict.healthFacilityType || vm.search.healthFacilityType,
                "badges.contains": vm.restrict.badge,
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.isLoading = false;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                if (vm.practitioners && vm.increment)
                    vm.practitioners.push.apply(vm.practitioners, data);
                else
                    vm.practitioners = data;
                vm.increment = false;
            }

            function onError(error) {
                vm.isLoading = false;
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            vm.page = 1;
            loadAll(false);
        }

        function close() {
            $uibModalInstance.dismiss('cancel');
        }

        function select(practitionerId) {
            $uibModalInstance.close(practitionerId);
        }

        function getDefaultFilter() {
            return {
                job: null,
                firstName: null,
                lastName: null,
                postalCode: null,
                city: null,
                healthFacilityId: null,
                healthFacilityName: null,
                healthFacilityType: null,
                badge: null
            };
        }

        function clearSearch() {
            vm.search = getDefaultFilter();
            vm.practitioners = null;
        }

        function reapplySearch() {
            vm.search = vm.initialSearch;
            vm.practitioners = null;
        }

        function nextPage() {
            vm.page++;
            loadAll(true);
        }

    }
})();
