(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SettingsPractitionerDetailController', SettingsPractitionerDetailController);

    SettingsPractitionerDetailController.$inject = ['practitioner', '$state', 'HealthEntityContract'];

    function SettingsPractitionerDetailController(practitioner, $state, HealthEntityContract) {
        var vm = this;

        vm.error = null;
        vm.practitioner = practitioner;
        vm.success = null;

        vm.agreement = false;

        vm.openContract = openContract;
        vm.isSigning = false;

        loadContracts();

        function loadContracts() {
            HealthEntityContract.query({
                "executorId.equals": vm.practitioner.id,
                "sort": "signed"
            }, function (contracts) {
                vm.contracts = contracts;
            });
        }

        function openContract(c) {
            if (c.signed) {
                if (c.signEnvelopeId) {
                    $state.go('health-entity-contract-detail', {id: c.id});
                } else {
                    alert("Envelope non disponible");
                }
            } else {
                $state.go('health-entity-contract-sign', {id: c.id});
            }
        }
    }
})();
