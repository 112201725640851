(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('nurseCoordinationIcons', nurseCoordinationIcons);

    function nurseCoordinationIcons() {

        return {
            restrict: 'E',
            scope: {
                nurse: '='
            },
            templateUrl: 'app/activity/coordination/nurse-coordination-icons.html'
        };


    }

})();
