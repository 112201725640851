(function () {
    'use strict';

    const visitEmotionalPatientProfile = {
        bindings: {
            patient: '<',
            data: '=',
            questions: '<'
        },
        controller: VisitEmotionalPatientProfile,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-emotional-patient-profile.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitEmotionalPatientProfile', visitEmotionalPatientProfile);

    VisitEmotionalPatientProfile.$inject = ['$rootScope'];

    function VisitEmotionalPatientProfile($rootScope) {

        const vm = this;

        vm.$onInit = function () {
            vm.step = 'EMOTIONAL_PATIENT_PROFILE';
            vm.isSaving = false;
        };

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };
    }

})();
