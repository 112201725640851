(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PharmacyOrderDetailController', PharmacyOrderDetailController);

    PharmacyOrderDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$uibModal', 'entity', 'previousState', 'PharmacyOrder', 'PatientTherapy', 'Patient'];

    function PharmacyOrderDetailController($scope, $rootScope, $state, $stateParams, $uibModal, entity, previousState, PharmacyOrder, PatientTherapy, Patient) {
        var vm = this;

        vm.pharmacyOrder = entity;

        vm.previousState = previousState;
        if (!vm.previousState.name) {
            vm.previousState = {
                name: 'patient-therapy-detail',
                params: {id: vm.pharmacyOrder.patientTherapyId}
            };
        }

        Patient.get({id: vm.pharmacyOrder.patientId}).$promise.then(patient => vm.patient = patient);

        vm.modifyPharmacyOrder = modifyPharmacyOrder;
        vm.deletePharmacyOrder = deletePharmacyOrder;

        var unsubscribe = $rootScope.$on('continuumplatformApp:pharmacyOrderUpdate', function () {
            PharmacyOrder.get({id: vm.pharmacyOrder.id}, result => vm.pharmacyOrder = result);
        });
        $scope.$on('$destroy', unsubscribe);

        function modifyPharmacyOrder() {
            $uibModal.open({
                templateUrl: 'app/activity/pharmacy-order/pharmacy-order-dialog.html',
                controller: 'PharmacyOrderDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['PharmacyOrder', function (PharmacyOrder) {
                        return PharmacyOrder.get({id: $stateParams.id}).$promise;
                    }]
                }
            });
        }

        function deletePharmacyOrder() {
            $uibModal.open({
                templateUrl: 'app/activity/pharmacy-order/pharmacy-order-delete-dialog.html',
                controller: 'PharmacyOrderDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: ['PharmacyOrder', function (PharmacyOrder) {
                        return PharmacyOrder.get({id: $stateParams.id}).$promise;
                    }]
                }
            }).result.then(function () {
                $state.go(vm.previousState.name, vm.previousState.params);
            });
        }

    }
})();
