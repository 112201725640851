(function () {
    'use strict';

    const unbilledHomeVisitsItem = {
        bindings: {
            unbilledHomeVisit: '=',
            datasource: '=',
        },
        controller: UnbilledHomeVisitsItemController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/unbilled-home-visits/unbilled-home-visits-item.html'
    };

    angular
        .module('continuumplatformApp')
        .component('unbilledHomeVisitsItem', unbilledHomeVisitsItem);

    UnbilledHomeVisitsItemController.$inject = ['$state', '$uibModal', '$window', 'Principal', 'HealthFacility', 'Practitioner'];

    function UnbilledHomeVisitsItemController($state, $uibModal, $window, Principal, HealthFacility, Practitioner) {

        const vm = this;

        vm.openPractitioner = openPractitioner;
        vm.openHealthFacility = openHealthFacility;
        vm.billVisits = billVisits;

        vm.$onInit = function () {};

        function openPractitioner(practitionerId) {
            Principal.hasAuthority('ROLE_ADMIN').then(function (hasAuthority) {
                if (hasAuthority) {
                    const url = $state.href('practitioner-detail', {id: practitionerId});
                    $window.open(url, 'practitioner-detail-' + practitionerId);
                }
            });
        }

        function billVisits(entityId) {
            // IDEL
            if (!vm.unbilledHomeVisit.type) {
                Practitioner.get({id: entityId}).$promise.then((practitioner) => {
                    openBillingModal(practitioner);
                });
            }
        }

        function openBillingModal(practitioner) {
            $uibModal.open({
                templateUrl: 'app/activity/bill/billing-entity.html',
                controller: 'BillingEntityController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: () => practitioner
                }
            }).result.then(() => {
                vm.datasource.removeData(vm.unbilledHomeVisit);
            }, () => {
                // do nothing
            });
        }

        function openHealthFacility(healthFacilityId) {
            Principal.hasAuthority('ROLE_ADMIN').then(function (hasAuthority) {
                if (hasAuthority) {
                    $state.go('health-facility-detail', {id: healthFacilityId});
                }
            });
        }

    }

})();
