(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('prescription', {
                parent: 'patients',
                url: '/prescription?page&sort',
                data: {
                    authorities: ['ROLE_PATIENT'],
                    pageTitle: 'continuumplatformApp.prescription.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/prescription/prescriptions.html',
                        controller: 'PrescriptionController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'date,desc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('prescription');
                        $translatePartialLoader.addPart('prescriptionItem');
                        $translatePartialLoader.addPart('healthFacility');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('patientTreatment');
                        $translatePartialLoader.addPart('patientTreatmentStatus');
                        $translatePartialLoader.addPart('patientTreatmentPhase');
                        $translatePartialLoader.addPart('medicationInfoType');
                        $translatePartialLoader.addPart('medicationVariant');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    patient: () => null,
                    previousState: () => {
                        return {
                            name: 'home',
                            params: {}
                        };
                    }
                }
            })
            .state('patient-detail.prescriptions', {
                url: '/prescriptions?page&sort',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'continuumplatformApp.prescription.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/prescription/prescriptions.html',
                        controller: 'PrescriptionController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true,
                        inherit: false
                    },
                    sort: {
                        value: 'date,desc',
                        squash: true,
                        inherit: false
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('prescription');
                        $translatePartialLoader.addPart('prescriptionItem');
                        $translatePartialLoader.addPart('healthFacility');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('patientTreatment');
                        $translatePartialLoader.addPart('patientTreatmentStatus');
                        $translatePartialLoader.addPart('patientTreatmentPhase');
                        $translatePartialLoader.addPart('medicationInfoType');
                        $translatePartialLoader.addPart('medicationVariant');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    patient: ['$stateParams', 'Patient', function ($stateParams, Patient) {
                        return Patient.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name.replace('prescription-detail', '') || 'patient-detail.follow-up',
                            params: $transition$.from().name.replace('prescription-detail', '') ? $transition$.params('from') : {id: $transition$.params().id}
                        };
                    }]
                }
            })
            .state('prescription.import', {
                url: '/import',
                data: {
                    authorities: ['ROLE_PATIENT']
                },
                onEnter: ['$transition$', '$state', '$uibModal', 'Principal', function ($transition$, $state, $uibModal, Principal) {
                    Principal.identity().then(function (account) {
                        $uibModal.open({
                            templateUrl: 'app/activity/prescription/prescription-import.html',
                            controller: 'PrescriptionImportController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            resolve: {
                                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                    return $translate.refresh();
                                }],
                                entity: function () {
                                    return {
                                        date: new Date(),
                                        patientId: account.patient.id,
                                        patientTherapyId: null,
                                        comment: null
                                    };
                                },
                                account: function () {
                                    return account;
                                },
                                notify: function () {
                                    return true;
                                }
                            }
                        }).result.then(function (value) {
                            $state.go('^', {}, {reload: true});
                        }, function () {
                            $state.go('^');
                        });

                    });
                }]
            })
            .state('prescription-detail', {
                parent: 'prescription',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_PATIENT', 'PATIENT_HEALTH_READ'],
                    pageTitle: 'continuumplatformApp.prescription.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/prescription/prescription-detail.html',
                        controller: 'PrescriptionDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('prescription');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Prescription', function ($stateParams, Prescription) {
                        return Prescription.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name,
                            params: $transition$.params('from')
                        };
                    }]
                }
            });
    }

})();
