(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('NotificationSettingsController', NotificationSettingsController);

    NotificationSettingsController.$inject = ['$scope', 'Account'];

    function NotificationSettingsController($scope, Account) {
        var vm = this;

        vm.isSaving = false;
        vm.success = null;
        vm.error = null;
        vm.save = save;

        // Permet de générer l'affichage de la partie "suivi clinique"
        vm.visitsClinic = [
            {type: 'BIO', label: 'Analyses de biologie médicale', description: 'Résultats d\'analyses importés'},
            {type: 'PRO', label: 'Auto-évaluation', description: 'Questionnaire renseigné par la patient ou son aidant'},
            {type: 'STD_HOME', label: 'Visite de suivi à domicile', description: 'Questionnaire renseigné par l\'infirmière de ville'},
            {type: 'MEDICAL_CONSULT_EXT',label: 'Consultation médicale extérieure', description: 'Questionnaire renseigné par le médecin de ville'},
            {type: 'NCC_PHONE',label: 'Appel de coordination infirmière', description: 'Questionnaire renseigné par la structure de coordination'},

            {type: 'GLUTTER'},

            {type: 'MEDICAL_CONSULT',label: 'Consultation médicale', description: 'Questionnaire renseigné par le médecin hospitalier'},
            {type: 'NCH_PHONE', label: 'Appel IDEC hospitalière', description: 'Questionnaire renseigné par l\'infirmière de coordination hospitalière'},
            {type: 'ANNOUNCEMENT_CONSULTATION',label: 'Consultation infirmière', description: 'Questionnaire renseigné par l\'infirmière de coordination hospitalière'},
            {type: 'HOSPITAL', label: 'Hôpital de jour', description: 'Questionnaire de passage en hôpital de jour renseigné par l\'hôpital'},
            {type: 'RENEWAL_CONSULT',label: 'Consultation de renouvellement', description: 'Questionnaire de renouvellement de suivi renseigné par l\'hôpital'},

            // Vistes pour lesquels il n'y a pas de paramétrage de notifcation
            // {type: 'PLUS_HOME',label: 'Visite d\'appui à domicile', description: ''},

        ];

        // Permet de générer l'affichage de la partie "suivi pharmaceutique"
        vm.visitsPharmaceutic = [
            {type: 'PHARMA_CONSULT',label: 'Consultation pharmaceutique', description: 'Questionnaire renseigné par le pharmacien hospitalier ou d\'officine'},
            {type: 'PHARMA_ITW', label: 'Entretien/Séance pharmaceutique', description: 'Questionnaire renseigné par le pharmacien d\'officine'}
        ];

        Account.getNotificationSettings().$promise.then(function (result) {
            vm.notificationSettings = result;
            $scope.$watch("vm.notificationSettings", save, true);
        });

        function save(newSettings, oldSettings) {
            if (!angular.equals(newSettings, oldSettings)) { // prevent running on init
                vm.isSaving = true;
                Account.updateNotificationSettings(newSettings).$promise.then(function () {
                    vm.isSaving = false;
                    vm.error = null;
                    vm.success = 'OK';
                }).catch(function () {
                    vm.isSaving = false;
                    vm.success = null;
                    vm.error = 'ERROR';
                    vm.notificationSettings = oldSettings;
                });
            }
        }
    }
})();
