(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('AdherenceReport', AdherenceReport);

    AdherenceReport.$inject = ['$resource'];

    function AdherenceReport($resource) {
        const resourceUrl = 'api/adherence-reports/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'history': {
                method: 'GET',
                url: 'api/adherence-reports/history',
                isArray: true
            }
        });
    }
})();
