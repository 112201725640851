(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            parent: 'root',
            views: {
                'root@': {
                    templateUrl: 'app/layouts/app.html',
                    controller: 'AppLayoutController',
                    controllerAs: 'vm'
                },
                'navbar@app': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                },
                'sidebar@app': {
                    templateUrl: 'app/layouts/sidebar/sidebar.html',
                    controller: 'SidebarController',
                    controllerAs: 'vm'
                },
                'alertbar@app': {
                    templateUrl: 'app/layouts/alertbar/alertbar.html',
                    controller: 'AlertbarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('practitioner');
                }]
            }
        });
    }
})();
