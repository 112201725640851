(function () {
    'use strict';

    var patientAddProButton = {
        bindings: {},
        controller: patientAddProButtonController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/patient-add-pro-button.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientAddProButton', patientAddProButton);

    patientAddProButtonController.$inject = ['$state', 'Principal', 'Visit', 'PatientTherapy'];

    function patientAddProButtonController($state, Principal, Visit, PatientTherapy) {

        const vm = this;

        vm.addVisit = addVisit;

        vm.$onInit = function() {
            getAccount().then((patient) => {
                vm.patient = patient;
                loadPatientTherapies(vm.patient.id);
                loadPatientVisits(vm.patient);
            }).catch((error) => {
                // nothing
            });
        };

        function getAccount() {
            return Principal.identity().then(function (account) {

                if (!account) {
                    return Promise.reject(new Error("Utilisateur non connecté !!!"));
                }

                if (!account.patient) {
                    return Promise.reject(new Error("L'utilisateur n'est pas un patient"));
                }

                return account.patient;
            });
        }

        function loadPatientTherapies(patientId) {
            PatientTherapy.query({
                "patientId.equals": patientId,
                "status.in": ["S11_VALIDATED", "S20_ACTIVE", "S30_PAUSED"]
            }).$promise.then((patientTherapies) =>{
                return vm.patientTherapies = patientTherapies;
            });
        }

        function loadPatientVisits(patient) {
            Visit.query({
                "patientId.equals": patient.id,
                "status.in": ['TODO', 'IN_PROGRESS'],
                "sort": "date,desc",
                "size": 1000
            }).$promise.then((visits) => {

                return vm.patientVisits = visits;

            }).then((visits) => {

                vm.patientCanCreateVisit = true;

                visits.forEach((v) => {

                    if (patient.diseaseCim11Code === '4A00.14') {
                        // AOH
                        if ((Visit.isStartable(v) || Visit.isResumable(v)) && v.steps.some((s) => ['AOH_SYMPTOMS', 'AOH_CRISIS_EVALUATION'].includes(s))) {
                            vm.patientCanCreateVisit = false;
                        }

                    } else {
                        // autres maladies
                        if ((Visit.isStartable(v) || Visit.isResumable(v)) && v.steps.some((s) => ['measures', 'pain', 'symptoms'].includes(s))) {
                            vm.patientCanCreateVisit = false;
                        }
                    }

                });

            });
        }

        function addVisit(visitType) {
            createVisit(visitType).then((visit) => startVisit(visit.id));
        }

        function createVisit(visitType) {
            if (!vm.patient) {
                return Promise.reject();
            }

            if (!vm.patientTherapies || !vm.patientTherapies.length) {
                return Promise.reject();
            }

            let type;
            let steps;

            if (vm.patient.diseaseCim11Code !== '4A00.14') {
                type = 'PRO';
                steps = ['measures', 'pain', 'symptoms', 'end'];

            } else if (vm.patient.diseaseCim11Code === '4A00.14') {
                type = 'PRO';
                steps = ['AOH_CRISIS_EVALUATION', 'AOH_SYMPTOMS', 'end'];

            } else {
                return Promise.reject();
            }

            return new Promise((resolve, reject) => {

                let visit = {
                    comment: null,
                    dueDate: null,
                    frequency: null,
                    limitDate: null,
                    nextReminderDate: moment().add(1, 'days').toDate(),
                    patientTherapyId: vm.patientTherapies[0].id,
                    plannedDate: null,
                    reminderDay: null,
                    startDate: null,
                    steps: steps,
                    type: type,
                    adhoc: true
                };

                Visit.createOwn(visit, (data) => {
                    visit.id = data.id;
                    resolve(visit);
                }, (error) => {
                    // nothing;
                });
            });
        }

        function startVisit(visitId) {
            $state.go('visit-wizard', {id: visitId});
        }

    }

})();
