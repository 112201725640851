(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('system-metrics', {
            parent: 'system',
            url: '/metrics',
            data: {
                authorities: ['ROLE_SYSTEM'],
                pageTitle: 'global.menu.system.metrics'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/system/metrics/metrics.html',
                    controller: 'MetricsMonitoringController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', $translate => $translate.refresh()]
            }
        });
    }
})();
