(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MedicationVariantController', MedicationVariantController);

    MedicationVariantController.$inject = ['$state', '$timeout', '$stateParams', '$scope', 'MedicationVariant'];

    function MedicationVariantController($state, $timeout, $stateParams, $scope, MedicationVariant) {

        var vm = this;

        vm.search = $stateParams.search;

        searchMedication();

        $scope.$watch("vm.search", function (newVal, oldVal) {
            searchMedication(newVal);
        });

        $timeout(function () {
            angular.element('#searchQuery').focus();
        });

        function searchMedication(query) {
            if (query && query.length > 1)
                MedicationVariant.search({
                    "query": query
                }, function (result) {
                    vm.medicationVariants = result;
                });
            else
                MedicationVariant.query({
                    sort: ["name"],
                    size: 1000 // TODO search
                }, function (result) {
                    vm.medicationVariants = result;
                });
        }
    }
})();
