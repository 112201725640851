(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('PasswordReset', PasswordReset);

    PasswordReset.$inject = ['$http'];

    function PasswordReset($http) {

        return {init, check, finish};

        function init(email) {
            return $http.post('api/account/reset-password/init', email);
        }

        function check(key) {
            return $http.post('api/account/reset-password/check', key);
        }

        function finish(key, newPassword, otp) {
            return $http.post('api/account/reset-password/finish', {key, newPassword, otp});
        }
    }
})();
