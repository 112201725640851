(function () {
    'use strict';

    const suspendedFollowUpItem = {
        bindings: {
            suspendedFollowUp: '=',
        },
        controller: SuspendedFollowUpItemController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/suspended-follow-up/suspended-follow-up-item.html'
    };

    angular
        .module('continuumplatformApp')
        .component('suspendedFollowUpItem', suspendedFollowUpItem);

    SuspendedFollowUpItemController.$inject = [];

    function SuspendedFollowUpItemController() {

        const vm = this;

        vm.$onInit = () => {
            // init
        };

    }

})();
