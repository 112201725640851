(function () {
    'use strict';

    var pharmaItw1Prev = {
        bindings: {
            patientId: '=',
            data: '=',
            questions: '<'
        },
        controller: pharmaItw1PrevPictoController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/pharma-itw-1-prev-picto.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmaItw1PrevPicto', pharmaItw1Prev);

    pharmaItw1PrevPictoController.$inject = ['$rootScope', 'PatientTreatment', 'MedicationInfo'];

    function pharmaItw1PrevPictoController($rootScope, PatientTreatment, MedicationInfo) {

        var vm = this;

        vm.currentPage = 1;

        vm.step = 'PHARMA_ITW_1_PREV_PICTO';

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

    }

})();
