(function () {
    'use strict';

    var internalNoteList = {
        bindings: {
            notes: '<',
            readonly: '<',
            onCreate: '&',
            text: '=',
            loading: '<',
            disabled: '<'
        },
        controller: InternalNoteListController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/coordination/internal-note-list.html'
    };

    angular
        .module('continuumplatformApp')
        .component('internalNoteList', internalNoteList);

    InternalNoteListController.$inject = ['Principal', 'InternalNoteService'];

    function InternalNoteListController(Principal, InternalNoteService) {

        const vm = this;

        vm.selectedNote = null;

        vm.modifyNote = modifyNote;
        vm.cancelNoteModification = cancelNoteModification;
        vm.saveNoteModification = saveNoteModification;

        vm.$onInit = function () {
            Principal.identity().then(function (account) {
                vm.account = account;
            });
        };

        function modifyNote(note) {
            vm.selectedNote = Object.assign({}, note);
        }

        function cancelNoteModification() {
            vm.selectedNote = null;
        }

        function saveNoteModification(note) {
            note.isSaving = true;
            InternalNoteService.update({id: vm.selectedNote.id}, {text: vm.selectedNote.text})
                .$promise
                .then(() => {
                    note.text = vm.selectedNote.text;
                    note.lastModifiedDate = new Date();
                    vm.selectedNote = null;
                })
                .finally(() =>
                    note.isSaving = false);
        }

    }

})();
