(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SideEffectReportDetailController', SideEffectReportDetailController);

    SideEffectReportDetailController.$inject = ['$scope', 'moment', '$rootScope', '$stateParams', '$sce', '$uibModal', 'entity', 'SideEffectReport', 'PatientTherapy', 'PatientTreatmentPhase', 'Principal', 'Media', 'Lightbox', 'Patient'];

    function SideEffectReportDetailController($scope, moment, $rootScope, $stateParams, $sce, $uibModal, entity, SideEffectReport, PatientTherapy, PatientTreatmentPhase, Principal, Media, Lightbox, Patient) {
        var vm = this;

        vm.sideEffectReport = entity;

        vm.openImage = openImage;
        vm.updatePvCenterStatus = updatePvCenterStatus;
        vm.openMedication = openMedication;

        Principal.identity().then(function (account) {
            if (account.practitioner) {
                vm.canUpdatePvCenterStatus = vm.sideEffectReport.pvCenterId
                    && vm.sideEffectReport.pvCenterId === account.practitioner.healthFacilityId;

                Patient.query({
                    'id.equals': vm.sideEffectReport.patientId
                }).$promise.then(patients => {
                    vm.hasPatientAccess = patients.length === 1;
                });
            }
        });

        if (vm.sideEffectReport.patientBirthDate)
            vm.sideEffectReport.patientAge = moment(vm.sideEffectReport.date).diff(vm.sideEffectReport.patientBirthDate, 'years');

        loadHistory();

        loadMedia();

        var unsubscribe = $rootScope.$on('continuumplatformApp:sideEffectReportUpdate', () => {
            loadReport();
            loadHistory();
        });
        $scope.$on('$destroy', unsubscribe);

        function loadReport() {
            SideEffectReport.get({id: vm.sideEffectReport.id}, result => vm.sideEffectReport = result);
        }

        function loadHistory() {
            vm.history = SideEffectReport.query({
                "patientTherapyId.equals": vm.sideEffectReport.patientTherapyId,
                "sideEffectId.equals": vm.sideEffectReport.sideEffectId,
                sort: "date,desc",
                size: 1000 // TODO all
            });
        }

        function loadMedia() {
            Media.query({
                "sideEffectReportId.equals": vm.sideEffectReport.id,
                "contentType.contains": "image/" // TODO only images?
                // TODO size?
            }, function (media) {
                vm.media = media.map(function (m) {
                    m.trustedInlineUrl = $sce.trustAsResourceUrl('/api/media/' + m.id + '/download?disposition=inline');
                    return m;
                });
            });
        }

        function openImage(index) {
            Lightbox.openModal(vm.media
                .map(function (m) {
                    return {
                        caption: m.title,
                        url: m.trustedInlineUrl
                    };
                }), index);
        }

        function updatePvCenterStatus(status) {
            if (status === 'PROCESSED') {
                // Traitement du Side effect Report
                // ouverture d'une modale pour demander l'identifiant BNPV
                $uibModal.open({
                    templateUrl: 'app/activity/side-effect-report/side-effect-report-identifiant-bnpv.html',
                    controller: 'SideEffectReportIdentifiantBNPVController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        bnpvId: () => vm.sideEffectReport.bnpvId
                    }
                }).result.then((bnpvId) => {
                    SideEffectReport.updatePvCenterStatus({id: vm.sideEffectReport.id}, {bnpvId, status}, () => {
                        loadReport();
                        loadHistory();
                    });
                }, () => {});
            } else {
                // remise en attente du Side effect Report
                SideEffectReport.updatePvCenterStatus({id: vm.sideEffectReport.id}, {bnpvId: vm.sideEffectReport.bnpvId, status}, () => {
                    loadReport();
                    loadHistory();
                });
            }
        }

        function openMedication(medication) {
            $uibModal.open({
                templateUrl: 'app/entities/medication/medication-preview-modal.html',
                controller: 'MedicationPreviewModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    medication: () => medication,
                    patientId: () => vm.sideEffectReport.patientId,
                    options: {}
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });

        }
    }
})();
