(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('pharm-analysis-read', {
                parent: 'patients',
                url: '/pharm-analysis/{id}',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'continuumplatformApp.pharmAnalysis.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/pharm-analysis/pharm-analysis-read.html',
                        controller: 'PharmAnalysisReadController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pharmAnalysis: ['$transition$', 'PharmAnalysis', function ($transition$, PharmAnalysis) {
                        return PharmAnalysis.get({id: $transition$.params().id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('measure');
                        $translatePartialLoader.addPart('pharmAnalysis');
                        $translatePartialLoader.addPart('medicationInfoType');
                        $translatePartialLoader.addPart('medicationVariant');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
