(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('A2HS', A2HS);

    A2HS.$inject = ['$log'];

    function A2HS($log) {

        let deferredPrompt, showInstallPromotion;

        subscribeBeforeinstallprompt();
        subscribeAppinstalled();

        return {
            isShowInstallPromotion,
            install
        };

        function isShowInstallPromotion() {
            return showInstallPromotion;
        }

        function subscribeBeforeinstallprompt() {
            window.addEventListener('beforeinstallprompt', function (e) {
                $log.debug("beforeinstallprompt", e);
                // Stash the event so it can be triggered later.
                deferredPrompt = e;

                // Update UI notify the user they can add to home screen
                showInstallPromotion = true;
                $log.debug("showing install promotion");
            });
        }

        function subscribeAppinstalled() {
            window.addEventListener('appinstalled', function (e) {
                $log.debug('a2hs installed', e);
                showInstallPromotion = false;
            });
        }

        function install() {
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice
                .then(function (choiceResult) {
                    if (choiceResult.outcome === 'accepted') {
                        $log.debug('User accepted the A2HS prompt');
                        showInstallPromotion = false;
                    } else {
                        $log.debug('User dismissed the A2HS prompt');
                    }
                    deferredPrompt = null;
                });
        }

    }
})();
