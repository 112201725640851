(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientPecamController', PatientPecamController);

    PatientPecamController.$inject = ['$window', 'entity', 'Pecam', 'HealthFacility', 'Prescription', 'PatientTherapy'];

    function PatientPecamController($window, entity, Pecam, HealthFacility, Prescription, PatientTherapy) {
        const vm = this;

        vm.patient = entity;
        vm.featureEnabled = null;
        vm.prescriptions = null;
        vm.telemonitorings = null;
        vm.hasCurrentTelemonitoring = null;
        vm.hasCurrentPecamPatientTherapies = null;
        vm.telemonitoringRequests = null;

        HealthFacility.get({
            'view': 'summary',
            id: vm.patient.healthFacilityId
        }).$promise.then(facility => {
            vm.featureEnabled = facility.features.includes('PECAM');
            if (vm.featureEnabled) {
                load();
            }
        });

        PatientTherapy.query({
            'patientId.equals': vm.patient.id,
            'status.in': ['S20_ACTIVE', 'S30_PAUSED'],
            'pecam.equals': true
        }).$promise.then(patientTherapies => {
            vm.hasCurrentPecamPatientTherapies = patientTherapies.length > 0;
        });

        vm.$onInit = () => {
            setTitle();
        };

        vm.$onDestroy = () => {

        };

        vm.start = (prescriptionId) => {
            vm.isStarting = true;
            Pecam.startTelemonitoring(prescriptionId)
                .then(load)
                .finally(() => {
                    vm.isStarting = false;
                });
        }

        function load() {
            Prescription.query({
                'patientId.equals': vm.patient.id,
                'type.equals': 'TELEMONITORING',
                'sort': 'date,desc'
            }).$promise.then(prescriptions => {
                vm.prescriptions = prescriptions;

                Pecam.queryTelemonitoring(vm.patient.id).then(response => {
                    vm.telemonitorings = response.data;
                    vm.hasCurrentTelemonitoring = vm.telemonitorings.some(t => t.current);
                    vm.prescriptions.forEach(prescription => {
                        prescription.telemonitorings = vm.telemonitorings.filter(telemonitoring => telemonitoring.prescriptionId === prescription.id);
                    });
                });
            });

            Pecam.queryTelemonitoringRequestsByPatient(vm.patient.id).then(response => {
                vm.telemonitoringRequests = response.data;
            });
        }

        function setTitle() {
            $window.document.title = 'PEC AM ' + vm.patient.initials;
        }

    }
})();
