(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('visitTypeIcon', visitTypeIcon);

    function visitTypeIcon() {

        return {
            restrict: 'E',
            scope: {
                type: '@'
            },
            templateUrl: 'app/activity/visit/visit-type-icon.html'
        };


    }

})();
