(function() {
    'use strict';

    angular.module('continuumplatformApp')
        .controller('AdherenceHistoryModalController', AdherenceHistoryModalController);

    AdherenceHistoryModalController.$inject = ['$uibModalInstance', 'adherenceReportList'];

    function AdherenceHistoryModalController($uibModalInstance, adherenceReportList) {
        const vm = this;

        vm.adherenceReportList = adherenceReportList;

        vm.cancel = cancel;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
