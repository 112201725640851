(function () {
    'use strict';

    const visitDetailUnderstandingTreatment = {
        bindings: {
            data: '=',
            questions: '<',
            patient: '<'
        },
        controller: VisitDetailUnderstandingTreatmentController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-detail-understanding-treatment.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitDetailUnderstandingTreatment', visitDetailUnderstandingTreatment);

    VisitDetailUnderstandingTreatmentController.$inject = [];

    function VisitDetailUnderstandingTreatmentController() {

        const vm = this;

        vm.getNumber = (num) => new Array(num);

    }

})();
