(function () {
    'use strict';

    const satisfactionScreen = {
        bindings: {
            patientTherapy: '=',
            visit: '='
        },
        controller: satisfactionScreenController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/satisfaction.screen.html'
    };

    angular
        .module('continuumplatformApp')
        .component('satisfactionScreen', satisfactionScreen);

    satisfactionScreenController.$inject = ['$rootScope', '$scope', '$state', 'PatientTherapy'];

    function satisfactionScreenController($rootScope, $scope, $state, PatientTherapy) {

        const vm = this;

        vm.$onInit = () => {
            vm.possibleScores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

            if (vm.patientTherapy) {
                vm.isPatientPromoterScoreSelected = Number.isInteger(vm.patientTherapy.patientPromoterScore);

                vm.answers = {
                    patientPromoterScore: vm.patientTherapy.patientPromoterScore,
                };
            }
        };

        vm.close = () => {
            $rootScope.warnBeforeUnload = false;
            $state.go('visit-detail', {id: vm.visit.id});
        };

        vm.send = () => {
            PatientTherapy.satisfaction({id: vm.patientTherapy.id}, vm.answers, () => {
                $rootScope.warnBeforeUnload = false;
                $state.go('visit-detail', {id: vm.visit.id});
            });
        };

        vm.onClickPatientPromoterScore = (score) => {
            vm.answers.patientPromoterScore = score;
            vm.isPatientPromoterScoreSelected = true;
        };

    }
})();
