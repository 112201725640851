(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('FegalaReportController', FegalaReportController);

    FegalaReportController.$inject = ['FegalaReport'];

    function FegalaReportController(FegalaReport) {

        const vm = this;

        FegalaReport.get().then((response) => {

            const data = response.data.map(row => {
                row.pct = Math.round(row.count*100 / row.quota);
                return row;
            });

            vm.total = data.filter(row => !row.program_group && !row.disease_group)[0];
            vm.byDiseaseGroup = data.filter(row => !row.program_group && row.disease_group);
            vm.byProgramGroup = data.filter(row => row.program_group && !row.disease_group);
            vm.byProgramGroupAndDiseaseGroup = data.filter(row => row.program_group && row.disease_group);
        });

    }
})();
