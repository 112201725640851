(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('TherapyDetailController', TherapyDetailController);

    TherapyDetailController.$inject = ['entity'];

    function TherapyDetailController(entity) {
        var vm = this;

        vm.therapy = entity;
    }
})();
