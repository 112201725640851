(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientProfileChangeController',PatientProfileChangeController);

    PatientProfileChangeController.$inject = ['$uibModalInstance'];

    function PatientProfileChangeController($uibModalInstance) {
        const vm = this;

        vm.close = close;

        function close () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
