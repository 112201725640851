(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MedicationInfoDeleteController', MedicationInfoDeleteController);

    MedicationInfoDeleteController.$inject = ['$uibModalInstance', '$scope', 'medicationInfo', 'MedicationInfo'];

    function MedicationInfoDeleteController($uibModalInstance, $scope, medicationInfo, MedicationInfo) {
        var vm = this;

        vm.medicationInfo = medicationInfo;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            MedicationInfo.delete({id: id},
                function () {
                    $scope.$emit('continuumplatformApp:medicationInfoUpdate');
                    $uibModalInstance.close(true);
                });
        }
    }
})();
