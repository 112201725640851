(function () {
    'use strict';

    var personalizedPharmaceuticalStrategyBlock = {
        bindings: {
            patientId: '=',
            readonly: '=',
            expand: '='
        },
        controller: personalizedPharmaceuticalStrategyBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/personalized-pharmaceutical-strategy/personalized-pharmaceutical-strategy-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('personalizedPharmaceuticalStrategyBlock', personalizedPharmaceuticalStrategyBlock);

    personalizedPharmaceuticalStrategyBlockController.$inject = ['$rootScope', '$scope', '$log', '$uibModal', 'Action'];

    function personalizedPharmaceuticalStrategyBlockController($rootScope, $scope, $log, $uibModal, Action) {

        var vm = this;

        vm.addAction = addAction;
        vm.editAction = editAction;
        vm.changeActionStatus = changeActionStatus;
        vm.removeAction = removeAction;

        vm.$onInit = function () {
            load();
        };

        var unsubscribe = $rootScope.$on('continuumplatformApp:actionUpdate', load);
        $scope.$on('$destroy', function (event) {
            unsubscribe(event);
        });

        function load() {
            Action.query({
                "patientId.equals": vm.patientId,
                "sort": "createdDate",
                "size": 1000  // TODO hard-coded size
            }, function (value) {
                vm.actions = value;
            });
        }

        function addAction() {
            $uibModal.open({
                templateUrl: 'app/activity/action/action-dialog.html',
                controller: 'ActionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            patientId: vm.patientId,
                            type: 'PHARMA',
                            text: null,
                            status: 'S10_TODO',
                            actor: null,
                            id: null
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('action');
                        $translatePartialLoader.addPart('actionStatus');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {

            }, function () {

            });
        }

        function editAction(action) {
            $uibModal.open({
                templateUrl: 'app/activity/action/action-dialog.html',
                controller: 'ActionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return Action.get({id: action.id});
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('action');
                        $translatePartialLoader.addPart('actionStatus');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {

            }, function () {

            });
        }

        function changeActionStatus(action) {
            Action.update(action, function (result) {
                // no op
            });
        }

        function removeAction(action) {
            $uibModal.open({
                templateUrl: 'app/activity/action/action-delete-dialog.html',
                controller: 'ActionDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: () => action
                }
            }).result.then(load, () => {
                // nothing
            });
        }

    }

})();
