(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('threads', {
            parent: 'system',
            url: '/threads',
            data: {
                authorities: ['ROLE_SYSTEM'],
                pageTitle: 'global.menu.system.threads'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/system/threads/threads.html',
                    controller: 'ThreadsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('threads');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
