(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('visitStatusIcon', visitStatusIcon);

    function visitStatusIcon() {

        return {
            restrict: 'E',
            scope: {
                status: '@'
            },
            templateUrl: 'app/activity/visit/visit-status-icon.html'
        };


    }

})();
