(function () {
    'use strict';

    const messageAttachments = {
        bindings: {
            message: '='
        },
        controller: MessageAttachmentsController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/message/message-attachments.html'
    };

    angular
        .module('continuumplatformApp')
        .component('messageAttachments', messageAttachments);

    MessageAttachmentsController.$inject = ['$rootScope', '$sce', '$scope', '$state', '$log', '$uibModal', '$window', 'Principal', 'Lightbox', 'Prescription'];

    function MessageAttachmentsController($rootScope, $sce, $scope, $state, $log, $uibModal, $window, Principal, Lightbox, Prescription) {

        const vm = this;

        vm.openImage = openImage;
        vm.openPrescription = openPrescription;

        vm.$onInit = function () {
            getAccount();
        };

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAdmin = account.authorities && account.authorities.includes('ROLE_ADMIN');
                vm.isPatient = account.authorities.includes('ROLE_PATIENT');

                if (account.practitioner) {
                    vm.isHospitalUser = account.practitioner.healthFacilityType === 'HOSPITAL';
                    vm.isPharmacist = account.practitioner.job === 'J21';
                }
            });
        }


        function openImage(media, index) {
            Lightbox.openModal(media
                .map(function (m) {
                    return {
                        caption: m.text,
                        url: '/api/media/' + m.id + '/download?disposition=inline'
                    };
                }), index);
        }

        function openPrescription(prescriptionId) {
            Prescription.get({id: prescriptionId}, (prescription) => {
                if (!prescription.mediaId
                    || (prescription.mediaContentType
                        && (prescription.mediaContentType === 'application/pdf' || prescription.mediaContentType.startsWith('image/')))) {
                    $state.go('prescription-detail', {id: prescriptionId});
                } else if (prescription.mediaId) {
                    vm.trustedUrl = $sce.trustAsResourceUrl('/api/media/' + prescription.mediaId + '/download?disposition=attachment');
                    vm.downloadURI(vm.trustedUrl);
                }
            });
        }

        vm.downloadURI = (uri, name) => {
            const downloadLink = angular.element('<a></a>');
            downloadLink.attr('href', uri);
            if (name) {
                downloadLink.attr('download', name);
            }
            downloadLink[0].click();
        };

    }

})();
