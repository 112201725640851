(function () {
    'use strict';

    const visitUnderstandingTreatment = {
        bindings: {
            patient: '<',
            data: '=',
            questions: '<'
        },
        controller: VisitUnderstandingTreatment,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-understanding-treatment.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitUnderstandingTreatment', visitUnderstandingTreatment);

    VisitUnderstandingTreatment.$inject = ['$rootScope'];

    function VisitUnderstandingTreatment($rootScope) {

        const vm = this;
        vm.step = 'UNDERSTANDING_TREATMENT';

        vm.questionsByStep = [
            {
                questionNumber: 'Q1',
                label: 'Autonomie dans le traitement',
                possibleScores : [
                    {value: 1, placeholder: 'Pas du tout autonome' },
                    {value: 2},
                    {value: 3},
                    {value: 4},
                    {value: 5},
                    {value: 6},
                    {value: 7},
                    {value: 8},
                    {value: 9},
                    {value: 10, placeholder: 'Tout à fait autonome'},
                ],
            },
            {
                questionNumber: 'Q2',
                label: 'Reconnaissances des effets indésirables et du degré de sévérité',
                possibleScores : [
                    {value: 1, placeholder: 'Pas du tout autonome' },
                    {value: 2},
                    {value: 3},
                    {value: 4},
                    {value: 5},
                    {value: 6},
                    {value: 7},
                    {value: 8},
                    {value: 9},
                    {value: 10, placeholder: 'Tout à fait autonome'},
                ],
            },
            {
                questionNumber: 'Q3',
                label: 'Gestion des effets indésirables',
                possibleScores : [
                    {value: 1, placeholder: 'Pas du tout autonome' },
                    {value: 2},
                    {value: 3},
                    {value: 4},
                    {value: 5},
                    {value: 6},
                    {value: 7},
                    {value: 8},
                    {value: 9},
                    {value: 10, placeholder: 'Tout à fait autonome'},
                ],
            },
            {
                questionNumber: 'Q4',
                label: 'Identification des personnes à contacter',
                possibleScores : [
                    {value: 1, placeholder: 'Pas du tout autonome' },
                    {value: 2},
                    {value: 3},
                    {value: 4},
                    {value: 5},
                    {value: 6},
                    {value: 7},
                    {value: 8},
                    {value: 9},
                    {value: 10, placeholder: 'Tout à fait autonome'},
                ],
            },
            {
                questionNumber: 'Q5',
                label: 'Compréhension des règles hygiéno-diététiques',
                possibleScores : [
                    {value: 1, placeholder: 'Pas du tout autonome' },
                    {value: 2},
                    {value: 3},
                    {value: 4},
                    {value: 5},
                    {value: 6},
                    {value: 7},
                    {value: 8},
                    {value: 9},
                    {value: 10, placeholder: 'Tout à fait autonome'},
                ],
            },
            {
                questionNumber: 'Q6',
                label: 'Gestion du stress',
                possibleScores : [
                    {value: 1, placeholder: 'Pas du tout autonome' },
                    {value: 2},
                    {value: 3},
                    {value: 4},
                    {value: 5},
                    {value: 6},
                    {value: 7},
                    {value: 8},
                    {value: 9},
                    {value: 10, placeholder: 'Tout à fait autonome'},
                ],
            },
        ];

        vm.$onInit = function () {
            vm.currentPage = 1;
            vm.isSaving = false;

            vm.data = vm.data ? vm.data : {};
            for (let i = 0; i < vm.questionsByStep.length; i++) {
                vm.questionsByStep[i].score = vm.data[`Q${i + 1}`];
            }
        };

        vm.onClickValue = (question, score) => {
            if (!vm.data) {
                vm.data = {};
            }

            question.score = score;
            vm.data[question.questionNumber] = score;
        };

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };
    }

})();
