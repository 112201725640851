(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SideEffectScoreDialogController', SideEffectScoreDialogController);

    SideEffectScoreDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'sideEffectId', 'sideEffectName', 'sideEffectScore', 'SideEffectScore'];

    function SideEffectScoreDialogController($timeout, $scope, $stateParams, $uibModalInstance, sideEffectId, sideEffectName, sideEffectScore, SideEffectScore) {
        var vm = this;

        vm.sideEffectName = sideEffectName;
        vm.sideEffectScore = sideEffectScore;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            SideEffectScore.put({sideEffectId: sideEffectId}, vm.sideEffectScore, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('continuumplatformApp:sideEffectScoreUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
