(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .filter('authorAvatar', authorAvatar);

    authorAvatar.$inject = ['Avatar'];

    function authorAvatar(Avatar) {

        return getAvatarPath;

        function getAvatarPath(item) {

            if (item.author) {
                return Avatar.getPractitionerAvatar(item.author);
            }

            return Avatar.getDefaultAvatar();
        }

    }

})();
