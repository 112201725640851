(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyReassignController', PatientTherapyReassignController);

    PatientTherapyReassignController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$filter', '$translate', 'entity', 'PatientTherapy', 'CareTeam', 'Visit'];

    function PatientTherapyReassignController($timeout, $scope, $stateParams, $uibModalInstance, $filter, $translate, entity, PatientTherapy, CareTeam, Visit) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;

        vm.patientTherapy = entity;
        vm.visitTypes = {};
        vm.selectedExecutors = {};

        Visit.query({
            "patientTherapyId.equals": vm.patientTherapy.id,
            size: 1000 // TODO hard-coded size
        }, function (visits) {
            vm.visits = visits;

            CareTeam.getMembers(vm.patientTherapy.patientId).then(response => {
                vm.team = response.data;
                vm.visits
                    .filter(v => ['TODO', 'PAUSED'].includes(v.status) && v.type !== 'PRO')
                    .sort((a, b) => a.type.localeCompare(b.type))
                    .forEach(v => {

                        Visit.availableMembers(v.type, vm.team, vm.patientTherapy.patient).then((availableMembers) => {
                            vm.visitTypes[v.type] = vm.visitTypes[v.type] || {
                                options: availableMembers,
                                assigneeRequired: v.assigneeRequired
                            };
                        });

                        vm.selectedExecutors[v.type] = vm.selectedExecutors[v.type] || null;
                        if (v.assignee) {
                            vm.selectedExecutors[v.type] = v.assignee.id;
                        }
                    });
            });
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            PatientTherapy.reassign({
                id: vm.patientTherapy.id
            }, {
                internalNote: vm.internalNote,
                executorIds: vm.selectedExecutors
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $scope.$emit('continuumplatformApp:patientTherapyUpdate');
            $scope.$emit('continuumplatformApp:visitUpdate');
            $scope.$emit('continuumplatformApp:internalNotesUpdate');
            $uibModalInstance.close();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
