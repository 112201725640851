(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Inbox', Inbox);

    Inbox.$inject = ['$rootScope', '$http'];

    function Inbox($rootScope, $http) {

        return {
            countUnread: countUnread,
            getSummary: getSummary,
            getMessages: getMessages,
            getMessage: getMessage,
            readMessage: readMessage,
            readVisitReport: readVisitReport,
            readAll: readAll
        };

        /**
         * Count unread messages
         *
         * @returns {*}
         */
        function countUnread() {
            return $http.get('api/inbox/count-unread').then(function (response) {
                return response;
            });
        }

        /**
         * Get summary
         *
         * @returns {*}
         */
        function getSummary() {
            return $http.get('api/inbox/summary');
        }

        /**
         * Get messages related to given patient
         *
         * @param patientId
         * @returns {*}
         */
        function getMessages(patientId) {
            if (patientId)
                return $http.get('api/inbox/patient/' + patientId);
            else
                return $http.get('api/inbox');
        }

        /**
         * Get message by id
         *
         * @param messageToId
         * @returns {*}
         */
        function getMessage(messageToId) {
            return $http.get('api/inbox/' + messageToId);
        }

        /**
         * Mark a message as read
         *
         * @param messageId
         * @returns {*}
         */
        function readMessage(messageId) {
            return $http.get('api/inbox/read-message/' + messageId);
        }

        /**
         * Mark a visit report as read
         *
         * @param visitId
         * @returns {*}
         */
        function readVisitReport(visitId) {
            return $http.get('api/inbox/read-visit-report/' + visitId);
        }

        /**
         * Mark all message as read for patient
         *
         * @param patientId
         * @returns {*}
         */
        function readAll(patientId) {
            if (patientId)
                return $http.get('api/inbox/read-all/' + patientId);
            else
                return $http.get('api/inbox/read-all');
        }
    }

})();
