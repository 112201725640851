(function () {
    'use strict';

    angular
        .module('continuumplatformApp.referenceData')
        .factory('ReferenceData', ReferenceData);

    ReferenceData.$inject = ['$http', '$translate'];

    function ReferenceData($http, $translate) {

        const service = {getTitles};

        function getTitles() {

            var codes = [
                'DR',
                'PR',
            ];

            return translateCodes(codes, "continuumplatformApp.practitioner.title." );

        }

        function translateCodes(codesToTranslate, keyPrefix){
            var translationKeys = codesToTranslate.map(function (code) {
                return keyPrefix + code;
            });

            return $translate(translationKeys).then(function (translations) {
                return codesToTranslate.map(function (code) {
                    return ({
                        code: code,
                        label: translations[keyPrefix + code]
                    });
                });
            }, function (translationIds) {
                //?
            });
        }

        return service;
    }

})();
