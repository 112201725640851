(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('security-config', {
                parent: 'security',
                url: '/security-config',
                data: {
                    authorities: ['ROLE_SECURITY'],
                    pageTitle: 'global.menu.security.config'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/security/security-management/security-config.html',
                        controller: 'SecurityConfigController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]

                }
            });
    }
})();
