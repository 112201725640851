(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('VisitReplyDialogController', VisitReplyDialogController);

    VisitReplyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$filter', '$translate', 'visitId', 'Visit'];

    function VisitReplyDialogController($timeout, $scope, $stateParams, $uibModalInstance, $filter, $translate, visitId, Visit) {
        var vm = this;

        vm.visitId = visitId;

        vm.clear = clear;
        vm.send = send;

        $timeout(function () {
            angular.element('textarea:eq(0)').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function send() {
            vm.isSaving = true;
            Visit.reply({
                id: vm.visitId
            }, {
                comment: vm.comment,
                notify: true // TODO keep?
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $scope.$emit('continuumplatformApp:visitUpdate', vm.visitId);
            $uibModalInstance.close();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
