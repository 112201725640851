(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('Article51ReportController', Article51ReportController);

    Article51ReportController.$inject = ['$window', 'Article51', 'DownloadFile'];

    function Article51ReportController($window, Article51, DownloadFile) {

        $window.document.title = 'Article 51 : Rapport';

        const vm = this;

        vm.exporting = false;
        vm.exportError = null;

        vm.$onInit = () => {

        };

        vm.$onDestroy = () => {

        };

        vm.export = () => {
            vm.exporting = true;
            vm.exportError = null;
            Article51.export19GRE08FacilityReport()
                .then(response => DownloadFile.downloadFile(transformExport(response)))
                .catch(response => {
                    vm.exportError = response.data.detail;
                })
                .finally(() => {
                    vm.exporting = false;
                });
        };

        function transformExport(response) {
            return {
                data: new Blob([response.data], {type: 'text/plain;charset=utf-8'}),
                headers: response.headers
            };
        }

    }
})();
