(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('PatientTreatment', PatientTreatment);

    PatientTreatment.$inject = ['$resource', 'DateUtils', 'PatientTreatmentPhase'];

    function PatientTreatment($resource, DateUtils, PatientTreatmentPhase) {
        var resourceUrl = 'api/patient-treatments/:id';

        var service = $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        var decoded = angular.fromJson(data);
                        if (angular.isArray(decoded)) {
                            var transformed = [];
                            angular.forEach(decoded, function (v, k) {
                                transformed.push(transformResponse(v));
                            });
                            return transformed;
                        } else {
                            return decoded;
                        }
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: transformResponse
            }
        });

        return service;

        function transformResponse(data) {
            if (data) {
                data = angular.fromJson(data);
                data.activePhase = PatientTreatmentPhase.transformResponse(data.activePhase);
            }
            return data;
        }
    }
})();
