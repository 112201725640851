(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('liquibase', {
            parent: 'system',
            url: '/liquibase',
            data: {
                authorities: ['LIQUIBASE'],
                // TODO pageTitle: 'liquibase.title'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/system/liquibase/liquibase.html',
                    controller: 'LiquibaseController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    // TODO $translatePartialLoader.addPart('liquibase');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
