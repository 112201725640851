(function () {
    'use strict';

    var sideEffectReportMatrix = {
        bindings: {
            patientId: '=',
            showPatientLabels: '=?'
        },
        controller: SideEffectReportMatrixController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/side-effect-report/side-effect-report-matrix.html'
    };

    angular
        .module('continuumplatformApp')
        .component('sideEffectReportMatrix', sideEffectReportMatrix);

    SideEffectReportMatrixController.$inject = ['$rootScope', '$scope', '$log', '$uibModal', 'SideEffectReport', 'Media'];

    function SideEffectReportMatrixController($rootScope, $scope, $log, $uibModal, SideEffectReport, Media) {

        var vm = this;

        vm.showAllSideEffects = false;
        vm.toggleShowAllSideEffects = toggleShowAllSideEffects;

        vm.preview = preview;

        vm.$onInit = function () {
            load();
        };

        function toggleShowAllSideEffects() {
            vm.showAllSideEffects = !vm.showAllSideEffects;
        }

        function load() {
            SideEffectReport.query({
                view: 'base',
                "patientId.equals": vm.patientId,
                "sort": ["date,desc"],
                size: 1000 // TODO all
            }, function (sideEffectReports) {
                Media.query({
                    "patientId.equals": vm.patientId,
                    "sideEffectReportId.specified": true,
                    size: 1000 // TODO all
                }, function (pictures) {
                    vm.sideEffectMatrix = SideEffectReport.buildMatrix(sideEffectReports, pictures, vm.showPatientLabels);
                });
            });
        }

        function preview(ser) {
            $uibModal.open({
                templateUrl: 'app/activity/side-effect-report/side-effect-report-preview.html',
                controller: 'SideEffectReportPreviewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return ser;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sideEffectReport');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {

            }, function () {

            });
        }

    }

})();
