(function () {
    'use strict';

    const visitDetailEmotionalPatientProfile = {
        bindings: {
            data: '=',
            questions: '<',
            patient: '<'
        },
        controller: VisitDetailEmotionalPatientProfileController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-detail-emotional-patient-profile.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitDetailEmotionalPatientProfile', visitDetailEmotionalPatientProfile);

    VisitDetailEmotionalPatientProfileController.$inject = [];

    function VisitDetailEmotionalPatientProfileController() {
    }

})();
