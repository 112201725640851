(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('sideEffectReportCause', sideEffectReportCause);

    function sideEffectReportCause() {

        return {
            restrict: 'E',
            scope: {
                canBeCausedByTreatment: '=',
                showText: '=?'
            },
            templateUrl: 'app/activity/side-effect-report/side-effect-report-cause.html'
        };


    }

})();
