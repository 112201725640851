(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PainHistoryController', PainHistoryController);

    PainHistoryController.$inject = ['$uibModal', '$scope', '$rootScope', '$timeout', '$window', 'Measure', 'ParseLinks', 'AlertService', 'paginationConstants', 'previousState', 'patient'];

    function PainHistoryController($uibModal, $scope, $rootScope, $timeout, $window, Measure, ParseLinks, AlertService, paginationConstants, previousState, patient) {
        const vm = this;

        const TYPE = 'PAIN';
        const GRAPH_PERIOD = 3; // plage de temps pour l'affichage du graph en mois
        let INIT_DATE = moment().endOf('day');

        vm.modifyMeasure = modifyMeasure;
        vm.deleteMeasure = deleteMeasure;

        vm.previousChartData = previousChartData;
        vm.nextChartData = nextChartData;

        vm.$onInit = function () {
            vm.patient = patient;
            vm.previousState = previousState;

            loadData();

            vm.unsubscribeMeasureUpdate = $rootScope.$on('continuumplatformApp:measureUpdate', () => {
                loadData();
            });
        };

        $scope.$on('$destroy', vm.unsubscribeMeasureUpdate);

        /**
         * Effectue le chargement des données nécessaire à l'affichage du graphe et du tableau de données.
         */
        function loadData() {
            loadMeasure(vm.patient.id, [TYPE]).then((measures) => {
                vm.measures = measures;

                INIT_DATE = moment(measures[measures.length - 1].date).endOf('day');

                // calcul de la période initiale
                vm.graphStartDate = moment(INIT_DATE).subtract(GRAPH_PERIOD, 'months');
                vm.graphEndDate = moment(INIT_DATE);

                return filteredMeasures(vm.measures, vm.graphStartDate, vm.graphEndDate);
            }).then((filteredMeasures) => {
                vm.filteredMeasures = filteredMeasures;

                vm.graphData = {};
                vm.graphData.x = filteredMeasures.map((m) => moment(m.date).toDate());
                vm.graphData.y = [filteredMeasures.map((m) => m.value)];

                vm.options = defineOptions(vm.graphData, vm.graphStartDate, vm.graphEndDate);
                vm.series = null;
                vm.datasetOverride = [{
                    pointRadius: 5,
                    pointHoverRadius: 13,
                    fill: false,
                    showLine: false,
                }];

                vm.isPreviousGraphDataPossible = moment(vm.graphStartDate).subtract(GRAPH_PERIOD, 'months').isAfter(moment(vm.measures[0].date));
                vm.isNextGraphDataPossible = moment(vm.graphEndDate).add(GRAPH_PERIOD, 'months').isBefore(moment(vm.measures[vm.measures.length -1].date));

            });
        }

        /**
         * Charge les mesures nécessaires
         * @param patientId
         * @param types
         * @returns {Promise<unknown>}
         */
        function loadMeasure(patientId, types) {
            return Measure.query({
                "patientId.equals": patientId,
                "type.in": types,
                "sort": ["date,asc"],
                size: 9999
            }).$promise;
        }

        /**
         * Définit les options des axes pour l'affichage du graph
         * @param data
         * @param startDate
         * @param endDate
         * @returns {[{ticks: {min: *, major: {fontStyle: string, fontColor: string}, max: *}, display: boolean, scaleLabel: {labelString: string, display: boolean}, time: {unit: string, tooltipFormat: string}, type: string, distribution: string}]}
         */
        function defineAxesOptions(data, startDate, endDate) {
            return [{
                type: 'time',
                distribution: 'linear',
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Date'
                },
                ticks: {
                    major: {
                        fontStyle: 'bold',
                        fontColor: '#000000'
                    },
                    min: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
                    max: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
                },
                time: {
                    tooltipFormat: 'LLL',
                    unit: 'week',
                },
            }];

        }

        /**
         * Définit les options pour l'affichage du graph
         * @param data
         * @param startDate
         * @param endDate
         * @returns {{responsive: boolean, scales: {yAxes: [{ticks: {suggestedMin: number, suggestedMax: number}, display: boolean, scaleLabel: {display: boolean}}], xAxes: {ticks: {min: *, major: {fontStyle: string, fontColor: string}, max: *}, display: boolean, scaleLabel: {labelString: string, display: boolean}, time: {unit: string, tooltipFormat: string}, type: string, distribution: string}[]}, maintainAspectRatio: boolean}}
         */
        function defineOptions(data, startDate, endDate) {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: defineAxesOptions(data, startDate, endDate),
                    yAxes: [{
                        display: true,
                        scaleLabel: {display: true},
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 10
                        }
                    }],
                },
            };
        }

        /**
         * Permet d'afficher la période précédente des données sur le graph.
         */
        function previousChartData() {
            vm.loadingData = true;
            vm.graphStartDate.subtract(GRAPH_PERIOD, 'months');
            vm.graphEndDate.subtract(GRAPH_PERIOD, 'months');

            filteredMeasures(vm.measures, vm.graphStartDate, vm.graphEndDate).then((filteredMeasures) => {
                vm.filteredMeasures = filteredMeasures;

                vm.graphData = {};
                vm.graphData.x = filteredMeasures.map((m) => moment(m.date).toDate());
                vm.graphData.y = [filteredMeasures.map((m) => m.value)];

                vm.options = defineOptions(vm.graphData, vm.graphStartDate, vm.graphEndDate);

                vm.isPreviousGraphDataPossible = moment(vm.graphStartDate).subtract(GRAPH_PERIOD, 'months').isAfter(moment(vm.measures[0].date));
                vm.isNextGraphDataPossible = moment(vm.graphEndDate).add(GRAPH_PERIOD, 'months').isSameOrBefore(INIT_DATE);
                vm.loadingData = false;
            });
        }

        /**
         * Permet d'afficher la période suivante des données sur le graph.
         */
        function nextChartData() {
            vm.loadingData = true;
            vm.graphStartDate.add(GRAPH_PERIOD, 'months');
            vm.graphEndDate.add(GRAPH_PERIOD, 'months');
            filteredMeasures(vm.measures, vm.graphStartDate, vm.graphEndDate).then((filteredMeasures) => {
                vm.filteredMeasures = filteredMeasures;

                vm.graphData = {};
                vm.graphData.x = filteredMeasures.map((m) => moment(m.date).toDate());
                vm.graphData.y = [filteredMeasures.map((m) => m.value)];

                vm.options = defineOptions(vm.graphData, vm.graphStartDate, vm.graphEndDate);

                vm.isPreviousGraphDataPossible = moment(vm.graphStartDate).subtract(GRAPH_PERIOD, 'months').isAfter(moment(vm.measures[0].date));
                vm.isNextGraphDataPossible = moment(vm.graphEndDate).add(GRAPH_PERIOD, 'months').isSameOrBefore(INIT_DATE);
                vm.loadingData = false;
            });
        }

        /**
         * Ouvre la modale de modification d'une mesure.
         * @param id
         */
        function modifyMeasure(id) {
            $uibModal.open({
                templateUrl: 'app/activity/measure/measure-dialog.html',
                controller: 'MeasureDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Measure', function (Measure) {
                        return Measure.get({id: id}).$promise;
                    }]
                }
            });
        }

        /**
         * Ouvre la modale de suppression d'une mesure pour confirmation
         * @param id
         */
        function deleteMeasure(id) {
            $uibModal.open({
                templateUrl: 'app/activity/measure/measure-delete-dialog.html',
                controller: 'MeasureDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: ['Measure', function (Measure) {
                        return Measure.get({id: id}).$promise;
                    }]
                }
            });
        }

        /**
         * Filtre les mesures fourni en entré en fonction de la date de début et de fin souhaité
         * @param measures les measures à filtrer
         * @param startDate la date de début de période
         * @param endDate la date de fin de période
         * @returns {Promise<unknown>}
         */
        function filteredMeasures(measures, startDate, endDate) {
            return new Promise((resolve, reject) => {
                resolve(measures.filter((m) => moment(m.date).isBetween(startDate, endDate)));
            });
        }

    }
})();
