(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('ExpiredController', ExpiredController);

    ExpiredController.$inject = ['$scope', '$state', '$log', '$uibModal', 'paginationConstants', 'pagingParams', 'filters', 'ParseLinks', 'Principal', 'Visit', 'DownloadFile', 'HealthFacility', 'Practitioner'];

    function ExpiredController($scope, $state, $log, $uibModal, paginationConstants, pagingParams, filters, ParseLinks, Principal, Visit, DownloadFile, HealthFacility, Practitioner) {

        const vm = this;

        vm.filters = filters;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.visitTypes = null;

        vm.loadPage = loadPage;
        vm.transition = transition;

        vm.$onInit = () => {

            getAccount()
                .then((account) => {

                    vm.visitTypes = getVisitTypeForUser();

                    if (account.settings && account.settings.diseaseDivisionName) {
                        // load disease division name from account settings
                        vm.diseaseDivisionName = account.settings.diseaseDivisionName;
                    }

                    let promise;
                    if (account.practitioner && account.practitioner.healthFacilityId) {
                        promise = loadDiseaseDivisions(account.practitioner.healthFacilityId);
                    } else {
                        promise = Promise.resolve();
                    }
                    promise.then(() => {

                        if (vm.isPractitioner) {
                            vm.unwatchVisitType = $scope.$watch('vm.filters.type', onChangeFilter);
                            if (vm.isCoordination) {
                                loadUnicorns();
                            }
                        }

                        loadVisits();
                    });

                });
        };

        vm.$onDestroy = () => {
            if (vm.unwatchDiseaseDivisionName) {
                vm.unwatchDiseaseDivisionName();
            }
            if (vm.unwatchVisitType) {
                vm.unwatchVisitType();
            }
            if (vm.unwatchFeasibleByPractitionerId) {
                vm.unwatchFeasibleByPractitionerId();
            }
        };

        function loadDiseaseDivisions(healthFacilityId) {
            if (!healthFacilityId) {
                vm.enableDiseaseDivisionFilter = false;
                vm.diseaseDivisionName = undefined;
                vm.filters.diseaseIds = undefined;
                return Promise.resolve();
            }
            return HealthFacility.get({
                view: 'summary',
                id: healthFacilityId
            }, facility => {

                facility.diseaseDivisions = facility.diseaseDivisions || [];

                vm.enableDiseaseDivisionFilter = facility.diseaseDivisions.length > 0;

                // group by division name reduce to 1 division by name
                vm.divisions = facility.diseaseDivisions.reduce((divisions, division) => {
                    divisions[division.name] = division.diseaseIds;
                    return divisions;
                }, {});

                // restore previously selected division with updated data
                if (vm.diseaseDivisionName) {
                    if (vm.divisions[vm.diseaseDivisionName]) {
                        vm.filters.diseaseId = undefined;
                        vm.filters.diseaseIds = vm.divisions[vm.diseaseDivisionName];
                    } else {
                        vm.diseaseDivisionName = undefined;
                        vm.filters.diseaseIds = undefined;
                    }
                }

                vm.unwatchDiseaseDivisionName = $scope.$watch('vm.diseaseDivisionName', onChangeDivision);
            }).$promise;
        }

        function onChangeDivision(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            } else if (newVal) {
                vm.filters.diseaseId = undefined;
                vm.filters.diseaseIds = vm.divisions[newVal];
            } else {
                vm.filters.diseaseIds = undefined;
            }
            storeDiseaseDivisionName(newVal);
            loadVisits();
        }

        function storeDiseaseDivisionName(value) {
            if (value)
                Principal.updateSetting('diseaseDivisionName', value);
            else
                Principal.deleteSetting('diseaseDivisionName');
        }

        function loadUnicorns() {
            if (angular.isUndefined(vm.filters.patientTherapyAssigneeId)) {
                vm.filters.patientTherapyAssigneeId = vm.account.practitioner.id;
            }
            if (!vm.account.practitioner.healthFacilityId) {
                return;
            }
            Practitioner.query({
                'healthFacilityId.equals': vm.account.practitioner.healthFacilityId,
                size: 1000,
                sort: ['lastName,asc'],
            }).$promise.then((unicorns) => {
                vm.unicorns = unicorns;
                vm.unwatchFeasibleByPractitionerId = $scope.$watch('vm.filters.patientTherapyAssigneeId', onChangeFilter);
            });
        }

        /**
         * Get the user account.
         * @return {*} a promise with the user account.
         */
        function getAccount() {
            return Principal.identity().then(function (account) {
                if (account) {
                    vm.isPatient = account.authorities && account.authorities.includes('ROLE_PATIENT');
                    vm.isAdmin = account.authorities && account.authorities.includes('ROLE_ADMIN');

                    if (account.practitioner) {
                        vm.isPractitioner = !!account.practitioner;
                        vm.isCoordination = account.practitioner.healthFacilityType === 'COORDINATION';
                        vm.isHospitalUser = account.practitioner.healthFacilityType === 'HOSPITAL';

                        vm.practitioner = account.practitioner;
                        vm.isDoctor = account.practitioner.job === 'J10';
                        vm.isPharmacist = account.practitioner.job === 'J21';
                        vm.isNurse = account.practitioner.job === 'J60';
                        vm.isOtherPractitioner = account.practitioner.job === 'J99';
                    }
                }
                return vm.account = account;
            });
        }

        function loadVisits() {
            Visit.query({
                "status.equals": 'EXPIRED',
                "patientTherapyAssigneeId.equals": vm.filters.patientTherapyAssigneeId || undefined,
                "patientTherapyStatus.in": ['S20_ACTIVE', 'S30_PAUSED'],
                "diseaseId.in": getDiseaseIdIn(),
                "type.in": vm.filters.type ? [vm.filters.type] : vm.visitTypes,
                coordinator: true,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: ['orderingDate,desc', 'id,desc'],
            }, onSuccess);

        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.visits = data;
            vm.page = pagingParams.page;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
            });
        }

        function getDiseaseIdIn() {
            if (vm.filters.diseaseIds && vm.filters.diseaseIds.length) {
                return vm.filters.diseaseIds;
            }
            return undefined;
        }

        function onChangeFilter(newVal, oldVal) {
            if (angular.equals(newVal, oldVal)) {
                return;
            }
            loadVisits();
        }

        /**
         * Generate the visit types list available for the user
         * @return {*[]} - an array of the visit types
         */
        function getVisitTypeForUser() {
            if (vm.isHospitalUser) {
                return [
                    'PRO',
                    'PHARMA_ITW',
                    'MEDICAL_CONSULT_EXT'
                ];
            }
            return Visit.visitTypes;
        }

    }
})();
