(function () {
    'use strict';

    var pharmaItwQolPicto = {
        bindings: {
            data: '=',
            questions: '<',
            patientId: '='
        },
        controller: PharmaItwQolPictoController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/pharma-itw-qol-picto.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmaItwQolPicto', pharmaItwQolPicto);

    PharmaItwQolPictoController.$inject = ['$rootScope', '$scope', 'Visit'];

    function PharmaItwQolPictoController($rootScope, $scope, Visit) {

        var vm = this;

        vm.step = 'PHARMA_ITW_QOL_PICTO';

        vm.$onInit = function () {

            vm.chart = {
                labels: Object.values(vm.questions).map(function (q) {
                    return q.label;
                }),
                data: [],
                colors: ['#273FDC99', '#ded73f', '#de5b5b', '#81bd41'],
                options: {
                    scale: {
                        angleLines: {
                            display: false
                        },
                        beginAtZero: true,
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 10
                        }
                    },
                    legend: {
                        display: true,
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                },
                datasetOverride: []
            };

            loadVisits();
        };

        $scope.$watch('vm.data', function (newVal, oldVal) {
            drawChart();
        }, true);

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

        function loadVisits() {
            Visit.query({
                "patientId.equals": vm.patientId,
                "sort": "date,desc",
                "size": 1000
            }, function (visits) {
                vm.visits = visits;
                drawChart();
            });
        }

        function drawChart() {
            if (vm.data && Object.keys(vm.data).length === 5 && vm.visits) {
                vm.chart.data = [];

                // récuépration des données des entretiens précédent pour le graphe
                let filteredVisits = vm.visits.filter((v) => ['DONE'].includes(v.status) && v.data && v.data.details && v.data.details.PHARMA_ITW_QOL_PICTO);
                filteredVisits = filteredVisits.slice(filteredVisits.length - 4 >= 0 ?  filteredVisits.length - 4 : 0, filteredVisits.length);

                // Ajout des données de l'entretien courant pour le graph
                vm.chart.data.push([vm.data['Q1'], vm.data['Q2'], vm.data['Q3'], vm.data['Q4'], vm.data['Q5'] ]);
                vm.chart.datasetOverride.push({label: 'Entretien du ' + moment().format("DD/MM/YYYY")});

                // Ajout des données des précédents entretients
                filteredVisits.forEach( (visit) => {
                    const d = visit.data.details.PHARMA_ITW_QOL_PICTO;
                    vm.chart.data.push([d.Q1, d.Q2, d.Q3, d.Q4, d.Q5]);

                    const dateInterview = (visit.date ? moment(visit.date) : moment()).format("DD/MM/YYYY");
                    vm.chart.datasetOverride.push({label: 'Entretien du ' + dateInterview});
                });
            }
        }

    }

})();
