(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('persistent-token', {
                parent: 'security',
                url: '/persistent-token?page&sort',
                data: {
                    authorities: ['ROLE_SECURITY'],
                    pageTitle: 'continuumplatformApp.persistentToken.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/security/persistent-token/persistent-tokens.html',
                        controller: 'PersistentTokenController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'tokenDate,desc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('persistentToken');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('persistent-token-detail', {
                parent: 'persistent-token',
                url: '/persistent-token/{id}',
                data: {
                    authorities: ['ROLE_SECURITY'],
                    pageTitle: 'continuumplatformApp.persistentToken.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/security/persistent-token/persistent-token-detail.html',
                        controller: 'PersistentTokenDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('persistentToken');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PersistentToken', function ($stateParams, PersistentToken) {
                        return PersistentToken.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('persistent-token.delete', {
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SECURITY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/security/persistent-token/persistent-token-delete-dialog.html',
                        controller: 'PersistentTokenDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PersistentToken', function (PersistentToken) {
                                return PersistentToken.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('persistent-token', null, {reload: 'persistent-token'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
