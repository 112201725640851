(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('DiseaseDetailController', DiseaseDetailController);

    DiseaseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$filter', 'entity', 'Disease'];

    function DiseaseDetailController($scope, $rootScope, $stateParams$filter, $filter, entity, Disease) {
        var vm = this;

        vm.disease = entity;

        loadSideEffects();

        var unsubscribe = $rootScope.$on('continuumplatformApp:diseaseUpdate', function (event, result) {
            vm.disease = result;
            loadSideEffects();
        });
        $scope.$on('$destroy', unsubscribe);

        function loadSideEffects() {
            Disease.getSideEffects({
                id: vm.disease.id,
            }, function (sideEffects) {
                vm.sideEffects = {};
                vm.sideEffects['CLINICAL'] = $filter('groupBy')($filter('where')(sideEffects, {category: 'CLINICAL'}), 'group');
                vm.sideEffects['BIOLOGICAL'] = $filter('groupBy')($filter('where')(sideEffects, {category: 'BIOLOGICAL'}), 'group');
            });
        }
    }
})();
