(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('medication', {
                parent: 'master',
                url: '/medication?page&sort',
                data: {
                    authorities: ['ROLE_EDITOR'],
                    pageTitle: 'continuumplatformApp.medication.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/medication/medications.html',
                        controller: 'MedicationController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'name,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('medication');
                        $translatePartialLoader.addPart('medicationVariant');
                        $translatePartialLoader.addPart('medicationInfo');
                        $translatePartialLoader.addPart('medicationInfoType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('medication.new', {
                url: '/new',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/medication/medication-dialog.html',
                        controller: 'MedicationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    routeType: null,
                                    groupId: null,
                                    description: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('medication', null, {reload: 'medication'});
                    }, function () {
                        $state.go('medication');
                    });
                }]
            })
            .state('medication-detail', {
                parent: 'medication',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_EDITOR'],
                    pageTitle: 'continuumplatformApp.medication.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/medication/medication-detail.html',
                        controller: 'MedicationDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('medication');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Medication', function ($stateParams, Medication) {
                        return Medication.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('medication-detail.edit', {
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/medication/medication-dialog.html',
                        controller: 'MedicationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Medication', function (Medication) {
                                return Medication.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('medication-detail.add-info', {
                url: '/info/add',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, entity) {
                    $uibModal.open({
                        templateUrl: 'app/entities/medication-info/medication-info-dialog.html',
                        controller: 'MedicationInfoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            medicationInfo: function () {
                                return {
                                    type: null,
                                    name: null,
                                    link: null,
                                    date: null,
                                    description: null,
                                    source: null,
                                    medicationId: $stateParams.id,
                                    medicationName: entity.name,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('medication-detail.edit-info', {
                url: '/info/{infoId}/edit',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/medication-info/medication-info-dialog.html',
                        controller: 'MedicationInfoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            medicationInfo: ['MedicationInfo', function (MedicationInfo) {
                                return MedicationInfo.get({id: $stateParams.infoId}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('medication-detail.delete-info', {
                url: '/info/{infoId}/delete',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/medication-info/medication-info-delete-dialog.html',
                        controller: 'MedicationInfoDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            medicationInfo: ['MedicationInfo', function (MedicationInfo) {
                                return MedicationInfo.get({id: $stateParams.infoId}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('medication-detail.delete', {
                url: '/delete',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/medication/medication-delete-dialog.html',
                        controller: 'MedicationDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Medication', function (Medication) {
                                return Medication.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('medication', null, {reload: 'medication'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
