(function () {
    'use strict';

    const noDataDatasourceIndicator = {
        bindings: {
            datasource: '=',
            noDataMessage: '=',
        },
        controller: NoDataDatasourceIndicatorController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/no-data-datasource-indicator.html'
    };

    angular
        .module('continuumplatformApp')
        .component('noDataDatasourceIndicator', noDataDatasourceIndicator);

    NoDataDatasourceIndicatorController.$inject = [];

    function NoDataDatasourceIndicatorController() {
        // empty
    }

})();
