(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('InternalNoteService', InternalNoteService);


    InternalNoteService.$inject = ['$resource'];

    function InternalNoteService($resource) {
        var resourceUrl = 'api/internal-notes/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                url: 'api/internal-notes',
                method: 'GET',
                isArray: true
            },
            'save': {
                method: 'POST',
                transformResponse: (data) => {
                    if (data) {
                        data = {id: data};
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
