(function () {
    'use strict';

    var visitQuestion = {
        bindings: {
            form: '=',
            key: '@',
            required: '='
        },
        controller: VisitQuestion,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-question.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitQuestion', visitQuestion);

    VisitQuestion.$inject = [];

    function VisitQuestion() {

        // var vm = this;

    }

})();
