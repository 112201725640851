(() => {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('FegalaReport', FegalaReport);

    FegalaReport.$inject = ['$http'];

    function FegalaReport($http) {

        return {get};

        function get() {
            return $http.get('api/fegala-report');
        }
    }
})();
