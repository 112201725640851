(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('ContractualTreatmentGroup', ContractualTreatmentGroup);

    ContractualTreatmentGroup.$inject = ['$resource'];

    function ContractualTreatmentGroup($resource) {
        var resourceUrl = 'api/contractual-treatment-groups/:id';

        var service = $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'update': {
                method: 'PUT'
            },
            'save': {
                method: 'POST'
            }
        });

        return service;
    }
})();
