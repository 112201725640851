(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTreatmentDetailController', PatientTreatmentDetailController);

    PatientTreatmentDetailController.$inject = ['$scope', '$rootScope', '$state', '$uibModal', 'entity', 'previousState', 'PatientTreatment', 'PatientTreatmentPhase', 'Patient', 'PatientTherapy', 'MedicationInfo'];

    function PatientTreatmentDetailController($scope, $rootScope, $state, $uibModal, entity, previousState, PatientTreatment, PatientTreatmentPhase, Patient, PatientTherapy, MedicationInfo) {
        const vm = this;

        vm.patientTreatment = entity;
        guessShowHistory();

        vm.previousState = previousState;
        if (!vm.previousState.name) {
            vm.previousState = {
                name: 'patient-detail.treatments',
                params: {id: vm.patientTreatment.patientId}
            };
        }

        Patient.get({id: vm.patientTreatment.patientId}, patient => vm.patient = patient);

        vm.toggleHistory = toggleHistory;

        vm.onlyFuture = onlyFuture;
        vm.onlyNone = onlyNone;
        vm.onlyHistory = onlyHistory;
        vm.omitHistory = omitHistory;

        const unsubscribe = $rootScope.$on('continuumplatformApp:patientTreatmentUpdate', function (event, result) {
            vm.patientTreatment = result;
        });
        $scope.$on('$destroy', unsubscribe);

        if (vm.patientTreatment.medicationId) {
            vm.medicationInfos = MedicationInfo.query({
                "medicationId.equals": vm.patientTreatment.medicationId
            });
        }

        loadPhases();

        function loadPhases() {
            vm.phases = [];
            return PatientTreatmentPhase.query({
                "patientTreatmentId.equals": vm.patientTreatment.id,
                "sort": "startDate,desc"
            }, function (result) {
                vm.phases = result;
            });
        }

        function onlyFuture(phase) {
            return phase.status !== 'S10_PENDING';
        }

        function onlyNone(phase) {
            return phase.status !== 'SXX_NONE';
        }

        function onlyHistory(phase) {
            return phase.status !== 'S40_FINISHED' && phase.status !== 'S50_STOPPED' && phase.status !== 'S99_CANCELED';
        }

        function omitHistory(phase) {
            return !onlyHistory(phase);
        }

        function toggleHistory() {
            vm.isHistoryShown = !vm.isHistoryShown;
        }

        function guessShowHistory() {
            vm.isHistoryShown = vm.patientTreatment.status === 'S40_FINISHED' || vm.patientTreatment.status === 'S50_STOPPED';
        }

    }
})();
