(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientQualityOfLifeController', PatientQualityOfLifeController);

    PatientQualityOfLifeController.$inject = ['$translate', '$window', 'entity', 'PatientQualityOfLifeService'];

    function PatientQualityOfLifeController($translate, $window, entity, PatientQualityOfLifeService) {
        const vm = this;

        vm.patient = entity;
        vm.visitWithQol = {'QOL_EQ_5D_5L': [], 'QOL_QLQ_C30': [], 'PHARMA_ITW_QOL_PICTO': []};

        $translate('continuumplatformApp.patient.quality-of-life.title', {initials: entity.initials}).then(title => {
            $window.document.title = title;
        });

        vm.$onInit = () => {
            PatientQualityOfLifeService.loadVisitWithQol(vm.patient.id).then((visits) => {
                vm.visitWithQol = visits;
            });

        };

    }
})();
