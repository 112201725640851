(function () {
    'use strict';

    var medicationSelection = {
        bindings: {

            /**
             * Identifiant patient
             */
            patientId: '=',

            /**
             * Identifiant maladie, pour restrictions et suggestions
             */
            diseaseId: '=',

            /**
             * true pour omettre les traitements existants du patient
             * false ou non défini pour ne rien omettre
             */
            omit: '=?',

            /**
             * true pour permettre une recherche
             * false ou non défini pour ne pas permettre de recherche
             */
            search: '=?',

            /**
             * true pour autofocus sur le champ de recherche (par défaut)
             */
            autofocus: '=?',

            /**
             * true pour appliquer la restriction aux traitements éligibles (patient / maladie)
             * false ou non défini pour ne pas appliquer de restriction
             */
            restrict: '=?',

            /**
             * tableau d'identifiants de médicaments à ne pas proposer
             */
            exclusions: '=?',
        },
        controller: MedicationSelectionController,
        controllerAs: 'vm',
        templateUrl: 'app/entities/medication/medication-selection.html'
    };

    angular
        .module('continuumplatformApp')
        .component('medicationSelection', medicationSelection);

    MedicationSelectionController.$inject = ['$rootScope', '$scope', '$timeout', '$log', 'Medication'];

    function MedicationSelectionController($rootScope, $scope, $timeout, $log, Medication) {

        const vm = this;

        vm.isLoading = false;
        vm.isSuggestion = false;

        vm.select = select;
        vm.omitAlreadyAdded = omitAlreadyAdded;
        vm.isAlreadyAdded = isAlreadyAdded;

        vm.$onInit = function () {

            if(vm.autofocus !== false) {
                $timeout(() => {
                    angular.element('#field_query').focus();
                });
            }

            // load suggestions
            suggest();
        };

        $scope.$watch("vm.query", function (newVal, oldVal) {
            if (newVal !== oldVal) {
                if (oldVal && oldVal.length && !newVal) {
                    suggest();
                } else {
                    search(newVal);
                }
            }
        });

        function select(medication) {
            $rootScope.$emit('continuumplatformApp:medication-selection:select', medication);
        }

        function omitAlreadyAdded(item) {
            return vm.omit && isAlreadyAdded(item);
        }

        function isAlreadyAdded(item) {
            return vm.exclusions && vm.exclusions.indexOf(item.id) !== -1;
        }

        function suggest() {
            vm.isSuggesting = true;
            getSuggestions().then(medications => {
                vm.isSuggesting = false;
                vm.medications = medications;
                vm.isSuggestion = !!vm.medications.length;
            });
        }

        function getSuggestions() {
            if(vm.exclusions && vm.exclusions.length) {
                return Medication.suggestAssoc({
                    diseaseId: vm.diseaseId,
                    medicationIds: vm.exclusions
                }).$promise;
            }
            return Medication.suggestRestrict({
                patientId: vm.patientId,
                diseaseId: vm.diseaseId
            }).$promise;
        }

        function search(query) {
            if (query && query.length > 2) {
                vm.isLoading = true;
                vm.isSuggestion = false;
                $log.debug("Searching...", query, vm.restrict);
                Medication.search({
                    "query": query,
                    "patientId": vm.restrict ? vm.patientId : undefined,
                    "diseaseId": vm.restrict ? vm.diseaseId : undefined
                }, medications => {
                    vm.medications = medications;
                    vm.isLoading = false;
                });
            } else {
                vm.medications = null;
            }
        }

    }

})();
