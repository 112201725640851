(function () {
    'use strict';

    var adherence = {
        bindings: {
            data: '=',
            questions: '<',
            adherenceReport: '=',
            patientTherapyId: '='
        },
        controller: AdherenceController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/adherence.html'
    };

    angular
        .module('continuumplatformApp')
        .component('adherence', adherence);

    AdherenceController.$inject = ['$rootScope', 'AdherenceReport', 'Principal'];

    function AdherenceController($rootScope, AdherenceReport, Principal) {

        var vm = this;

        vm.step = 'adherence';
        vm.keys = [1, 2, 3, 4, 5, 6];
        vm.sum = null;

        vm.$onInit = function () {
            loadAccount();
            calculateAdherenceScore();
            loadPreviousAdherenceReport();
        };

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

        vm.onChange = function () {
            calculateAdherenceScore();
        };

        function loadAccount() {
            Principal.identity().then(function (account) {
                vm.isPharmacist = account.practitioner && ['J21', 'J38'].includes(account.practitioner.job);
            });
        }

        function calculateAdherenceScore() {
            if (Object.keys(vm.adherenceReport).length === vm.keys.length) {
                vm.sum = Object.values(vm.adherenceReport).reduce(function (a, b) {
                    return a + b;
                });
            }
        }

        function loadPreviousAdherenceReport() {
            AdherenceReport.query({
                "patientTherapyId.equals": vm.patientTherapyId,
                "sort": "date,asc",
                "size": 1000
            }, function (adherenceReport) {
                vm.previousAdherenceReport = [];

                vm.previousScores = {};

                // on regroupe les comptes rendu par question et trié par date
                for (let i = 0; i < adherenceReport.length; i++) {
                    let aReport = adherenceReport[i];

                    if (!vm.previousAdherenceReport[aReport.question]) {
                        vm.previousAdherenceReport[aReport.question] = [];
                    }
                    aReport.date = moment(aReport.date).format('DD/MM/YYYY');
                    vm.previousAdherenceReport[aReport.question].push(aReport);
                }

                // on prend juste au max les 3 derniers contrendu par question
                for (let i = 1; i < vm.previousAdherenceReport.length; i++) {
                    let aReports  = vm.previousAdherenceReport[i];
                    vm.previousAdherenceReport[i] = aReports.slice(aReports.length - 3 >= 0 ?  aReports.length - 3 : 0, aReports.length);
                    for (let j = 0; j < vm.previousAdherenceReport[i].length; j++) {
                        var r = vm.previousAdherenceReport[i][j];

                        let score = (vm.previousScores[r.date] ? vm.previousScores[r.date] : 0) + r.score;
                        vm.previousScores[r.date] = score;

                    }
                }

            });
        }

    }

})();
