(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('Auth', Auth);

    Auth.$inject = ['$cookies', '$rootScope', '$q', '$translate', '$timeout', '$uibModal', 'Principal', 'AuthServerProvider', 'Account', 'Activate'];

    function Auth($cookies, $rootScope, $q, $translate, $timeout, $uibModal, Principal, AuthServerProvider, Account, Activate) {

        const service = {
            logoutStatus: {isLogoutError: false},
            activateAccount: activateAccount,
            login: login,
            logout: logout,
            hasRememberMeEnabled: hasRememberMeEnabled
        };

        return service;

        function activateAccount(key, callback) {
            var cb = callback || angular.noop;

            return Activate.get(key,
                function (response) {
                    return cb(response);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;
        }

        function login(credentials, callback) {
            var cb = callback || angular.noop;
            var deferred = $q.defer();

            AuthServerProvider.login(credentials)
                .then(loginThen)
                .catch(function (err) {
                    deferred.reject(err);
                    return cb(err);
                }.bind(this));

            function loginThen(data) {
                Principal.identity(true).then(function (account) {
                    // After the login the language will be changed to
                    // the language selected by the user during his registration
                    if (account !== null) {
                        $translate.use(account.langKey).then(function () {
                            $translate.refresh();
                        });
                    }
                    deferred.resolve(data);
                    $rootScope.$emit('login', account);
                });
                return cb();
            }

            return deferred.promise;
        }

        function logout() {
            service.logoutStatus.status = 'IN_PROGRESS';
            if (!service.logoutModal) {
                service.logoutModal = $uibModal.open({
                    templateUrl: 'app/account/logout/waiting-logout.modal.html',
                    controller: 'WaitingLogoutModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        logoutStatus: () => service.logoutStatus
                    }
                }).result.then((result) => {
                    service.logoutModal = null;
                }, () => {
                });
            }

            AuthServerProvider.logout().then(response => {
                service.logoutStatus.status = 'SUCCESS';
                $rootScope.$emit('logout');
                $rootScope.warnBeforeUnload = false;
                Principal.authenticate(null);
                $timeout(() => {
                    location.href = response.headers("X-Redirect-To") || '/';
                }, 1000);
            }).catch((error) => {
                if(error && error.status === 401) {
                    service.logoutStatus.status = 'UNAUTHENTICATED';
                    $timeout(() => {
                        location.reload();
                    }, 1000);
                } else {
                    service.logoutStatus.status = 'ERROR';
                }
            });

            // delete dashboard filters from session storage
            sessionStorage.removeItem('dashboardFilters');

        }

        function hasRememberMeEnabled() {
            return $cookies.get('remember-me-enabled') === 'true';
        }

    }
})();
