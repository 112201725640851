(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTreatmentPreviewController', PatientTreatmentPreviewController);

    PatientTreatmentPreviewController.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'MedicationInfo', 'PatientTreatment', 'PatientTreatmentPhase'];

    function PatientTreatmentPreviewController($scope, $rootScope, $stateParams, $uibModalInstance, entity, MedicationInfo, PatientTreatment, PatientTreatmentPhase) {
        var vm = this;

        vm.patientTreatment = entity;

        guessShowHistory();

        vm.clear = clear;
        vm.toggleHistory = toggleHistory;

        vm.onlyFuture = onlyFuture;
        vm.onlyNone = onlyNone;
        vm.onlyHistory = onlyHistory;
        vm.omitHistory = omitHistory;

        if (vm.patientTreatment.medicationId) {
            vm.medicationInfos = MedicationInfo.query({
                "medicationId.equals": vm.patientTreatment.medicationId
            });
        }

        loadPhases();

        function loadPhases() {
            vm.phases = [];
            return PatientTreatmentPhase.query({
                "patientTreatmentId.equals": vm.patientTreatment.id,
                "sort": "startDate,desc"
            }, function (result) {
                vm.phases = result;
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onlyFuture(phase) {
            return phase.status !== 'S10_PENDING';
        }

        function onlyNone(phase) {
            return phase.status !== 'SXX_NONE';
        }

        function onlyHistory(phase) {
            return phase.status !== 'S40_FINISHED' && phase.status !== 'S50_STOPPED' && phase.status !== 'S99_CANCELED';
        }

        function omitHistory(phase) {
            return !onlyHistory(phase);
        }

        function toggleHistory() {
            vm.isHistoryShown = !vm.isHistoryShown;
        }

        function guessShowHistory() {
            vm.isHistoryShown = vm.patientTreatment.status === 'S40_FINISHED' || vm.patientTreatment.status === 'S50_STOPPED';
        }

    }
})();
