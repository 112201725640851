(function () {
    'use strict';

    const visitHemavieTopicsAddressed = {
        bindings: {
            patient: '<',
            data: '=',
            questions: '<'
        },
        controller: VisitHemavieTopicsAddressed,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-hemavie-topics-addressed.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitHemavieTopicsAddressed', visitHemavieTopicsAddressed);

    VisitHemavieTopicsAddressed.$inject = ['$rootScope'];

    function VisitHemavieTopicsAddressed($rootScope) {

        const vm = this;

        vm.$onInit = function () {
            vm.step = 'HEMAVIE_TOPICS_ADDRESSED';
            vm.isSaving = false;
        };

        vm.finish = function () {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };
    }

})();
