(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MedicationPreviewModalController', MedicationPreviewModalController);

    MedicationPreviewModalController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'medication', 'patientId', 'options', 'MedicationVariant', 'MedicationInfo', 'IntakeRecord'];

    function MedicationPreviewModalController($timeout, $scope, $stateParams, $uibModalInstance, medication, patientId, options, MedicationVariant, MedicationInfo, IntakeRecord) {
        const vm = this;

        vm.clear = clear;

        vm.$onInit = () => {
            vm.medication = medication;
            vm.patientId = patientId;
            vm.showVariants = !!options.showVariants;

            if(vm.showVariants) {
                loadVariants();
            }

            loadInfo();
            loadIntakeRecord();
        };

        function loadVariants() {
            return MedicationVariant.query({
                "available.equals": true,
                "medicationId.equals": vm.medication.id,
                sort: 'name',
                size: 1000 // TODO all
            }).$promise
                .then((variants) => vm.medicationVariants = variants);
        }

        function loadInfo() {
            return MedicationInfo.query({
                "medicationId.equals": vm.medication.id
            }).$promise
                .then((infos) => vm.medicationInfos = infos);
        }

        function loadIntakeRecord() {
            return IntakeRecord.getHistory({
                patientId: vm.patientId,
                medicationId: vm.medication.id,
                visitId: null
            }).$promise
                .then((intakeRecordHistory) => vm.intakeRecordHistory = intakeRecordHistory);
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
