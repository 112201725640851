(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('VisitPlanDialogController', VisitPlanDialogController);

    VisitPlanDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$filter', '$translate', 'visit', 'Visit'];

    function VisitPlanDialogController($timeout, $scope, $stateParams, $uibModalInstance, $filter, $translate, visit, Visit) {
        var vm = this;

        vm.visit = visit;

        vm.next = next;
        vm.clearPlan = clearPlan;
        vm.clearReminder = clearReminder;
        vm.setReminderDaysBefore = setReminderDaysBefore;
        vm.clear = clear;
        vm.save = save;

        vm.step = 1;
        vm.askForReminder = vm.visit.steps.length > 0 || vm.visit.nextReminderDate || vm.visit.reminderDay;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            Visit.plan({
                id: vm.visit.id
            }, {
                plannedDate: vm.visit.plannedDate,
                nextReminderDate: vm.visit.nextReminderDate,
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $scope.$emit('continuumplatformApp:visitUpdate', vm.visit.id);
            $uibModalInstance.close(vm.visit);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function clearPlan() {
            vm.visit.plannedDate = null;
        }

        function clearReminder() {
            vm.visit.nextReminderDate = null;
        }

        function next() {
            vm.step = 2;
            if (vm.visit.plannedDate && (!vm.visit.nextReminderDate || moment(vm.visit.plannedDate).isBefore(vm.visit.nextReminderDate))) {
                setReminderDaysBefore(2);
            }
        }

        function setReminderDaysBefore(d) {
            vm.visit.nextReminderDate = moment.max(moment(), moment(vm.visit.plannedDate).subtract(d, 'd')).milliseconds(0).toDate();
        }
    }
})();
